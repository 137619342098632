import React, { useState, useEffect, useRef } from "react";
import cranesetup1 from "../../images/Crane-setup1.png";

function LiftConditions(props) {
    const handleLiftConditions = (key, val) => {
        props.setLiftConditions((prev) => ({ ...prev, [key]: val }))
    }

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div>
                            <p style={{ color: "#2C53A5", fontWeight: "600" }}>SETUP 1</p>
                        </div>
                        <div>
                            <img src={cranesetup1} alt="Cranesetup" style={{ height: "215px", width: "314px" }} />
                        </div>
                        <div>
                            <p className="mt-3" style={{ color: "#000000", fontWeight: "600" }}>For all cranes:(us the diagram to the above to fill in the information below)</p>
                        </div>

                        <div className="form-group row mt-2">
                            <label className="col-4 col-form-label">
                                R= <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="col-8 d-flex">
                                <input
                                    className="form-control col-8"
                                    id="r"
                                    placeholder=""
                                    value={props.data?.r || ""}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                        handleLiftConditions("r", numericValue);
                                    }}
                                />
                                <label className="col-2 col-form-label">ft</label>
                            </div>
                        </div>


                        <div class="form-group row mt-2">
                            <label class="col-4 col-form-label">H= <span style={{ color: "red" }}> *</span> </label>
                            <div class="col-8 d-flex">
                                <input
                                    className="form-control col-8"
                                    id="h"
                                    placeholder=""
                                    value={props.data?.h || ""}

                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                        handleLiftConditions("h", numericValue);
                                    }}
                                />
                                <label class="col-2 col-form-label">ft</label>

                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <label class="col-4 col-form-label">L= <span style={{ color: "red" }}> *</span> </label>
                            <div class="col-8 d-flex">
                                <input
                                    className="form-control col-8"
                                    id="l"
                                    placeholder=""
                                    value={props.data?.l || ""}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                        handleLiftConditions("l", numericValue);
                                    }}
                                />
                                <label class="col-2 col-form-label">ft</label>
                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <label class="col-4 col-form-label">W= <span style={{ color: "red" }}> *</span> </label>
                            <div class="col-8 d-flex flex-row">
                                <input
                                    className="form-control col-8"
                                    id="w"
                                    placeholder=""
                                    value={props.data?.w || ""}
                                    onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                        handleLiftConditions("w", numericValue);
                                    }}
                                />
                                <label class="col-2 col-form-label">ft</label>
                            </div>
                        </div>



                        <div class="form-group">
                            <label class="form-check-label">Lifting in Quadrants</label>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">

                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="w"
                                        placeholder=""
                                        checked={props.data?.one || false} 
                                        onChange={(e) => handleLiftConditions('one', e.target.checked)}
                                    />

                                    <label class="form-check-label">1</label>
                                </div>
                                <div class="form-check m-2">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="w"
                                        placeholder=""
                                        checked={props.data?.two || false} 
                                        onChange={(e) => handleLiftConditions('two', e.target.checked)}
                                    />

                                    <label class="form-check-label">2</label>
                                </div>
                                <div class="form-check m-2">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="w"
                                        placeholder=""
                                        checked={props.data?.three || false} 
                                        onChange={(e) => handleLiftConditions('three', e.target.checked)}
                                    />

                                    <label class="form-check-label">3</label>
                                </div>
                                <div class="form-check m-2">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="w"
                                        placeholder=""
                                        checked={props.data?.four || false} 
                                        onChange={(e) => handleLiftConditions('four', e.target.checked)}
                                    />

                                    <label class="form-check-label">4</label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className="mt-3" style={{ color: "#000000", fontWeight: "600" }}>Outrigger mat sizes(front and back)</p>
                        </div>

                        <table class="table tablecrane">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" colSpan={2}>LENGTH</th>
                                    <th scope="col" colSpan={2}>WIDTH</th>
                                    <th scope="col">HEIGHT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">M front</th>
                                    <td>
                                        <input class="col-12" id="inputPassword" placeholder=""
                                            value={props.data?.m_front_length || ""}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                                handleLiftConditions("m_front_length", numericValue);
                                            }}
                                            style={{ width: "50px", padding: "0px" }}
                                        /></td>
                                    <td>x</td>
                                    <td>
                                        <input class="col-12" id="inputPassword" placeholder=""

                                            value={props.data?.m_front_width || ""}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                                handleLiftConditions("m_front_width", numericValue);
                                            }}
                                            style={{ width: "50px", padding: "0px" }}
                                        /></td>
                                    <td>x</td>
                                    <td><input class="col-12" id="inputPassword" placeholder=""
                                        value={props.data?.m_front_height || ""}
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                            handleLiftConditions("m_front_height", numericValue);
                                        }}
                                        style={{ width: "50px", padding: "0px" }}
                                    /></td>
                                </tr>
                                <tr>
                                    <th scope="row">M back</th>
                                    <td>
                                        <input class="col-12" id="inputPassword" placeholder=""
                                            value={props.data?.m_back_length || ""}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                                handleLiftConditions("m_back_length", numericValue);
                                            }}
                                            style={{ width: "50px", padding: "0px" }}
                                        /></td>

                                    <td>x</td>
                                    <td><input class="col-12" id="inputPassword" placeholder=""
                                        value={props.data?.m_back_width || ""}
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                            handleLiftConditions("m_back_width", numericValue);
                                        }}
                                        style={{ width: "50px", padding: "0px" }}
                                    /></td>

                                    <td>x</td>
                                    <td><input class="col-12" id="inputPassword" placeholder=""
                                        value={props.data?.m_back_height || ""}
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/[^0-9]/g, ""); 
                                            handleLiftConditions("m_back_height", numericValue);
                                        }}
                                        style={{ width: "50px", padding: "0px" }}
                                    /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="form-group">
                            <p>Mat sizes are approved by an engineer?</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">

                                    <input class="form-check-input " type="checkbox"
                                        checked={props.data.question3_ans === 'yes'}
                                        onChange={(e) => {
                                            handleLiftConditions('question3_ans', props.data.question3_ans !== 'yes' ? 'yes' : '')
                                        }} id="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">

                                    <input class="form-check-input " type="checkbox"
                                        checked={props.data.question3_ans === 'N/A'}
                                        onChange={(e) => {
                                            handleLiftConditions('question3_ans', props.data.question3_ans !== 'N/A' ? 'N/A' : '')
                                        }} id="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="mt-3" style={{ color: "#000000", fontWeight: "600" }}>For crawler cranes:</p>
                        </div>
                        <div class="form-group">
                            <label >Maximum pressure applied by the tracks=</label>
                            <div>
                                <input class="form-control col-12" id="inputPassword" placeholder=""
                                    value={props.data?.uestion5_ans || ""}
                                    onChange={(e) => handleLiftConditions('uestion5_ans', e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>If track pressure is gretaer than allowable surface bearing pressure,how will surface be stabilized?</label>
                            <div>
                                <input class="form-control col-12" id="inputPassword" placeholder=""
                                    value={props.data?.question6_ans || ""}
                                    onChange={(e) => handleLiftConditions('question6_ans', e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <p style={{ color: "#2C53A5", fontWeight: "600" }}>SETUP 2</p>
                        </div>
                        <div>
                            <img src={cranesetup1} alt="Cranesetup" style={{ height: "215px", width: "315px" }} />
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )

};
export default LiftConditions;


