import React, { useState, useEffect, useRef } from "react";
import "datatables.net";
import { Link, useSearchParams, useNavigate, json } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    saveCorePermitForm,
    ApproveRejectPermit,
    transferCorePermit,
    getAllPermitsByCorePermitID,
    getAllPermits,
    getCorePermits,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import {
    corePermitInitialsConst,
    coreChemicalConstant,
    coreEmergencyConst,
    coreEnergyConstant,
    environMentConstants,
    CloseOutConstants,
    changeConstants,
    responsibilitites,
    corePermitReceiverConstants,
    trainingConstants,
    coreEquipmentConstant,
    corePeopleConstant,
    coreProtectionConstant,
    coreWorkEnvConstant,
} from "../Utilities/corePermit.constant";
import CorePermitM2People from "../components/corePermitM2/CorePermitM2People";
import CorePermitEmergency from "../components/corePermitM2/corePermitEmergency";
import CorePermitM2Energy from "../components/corePermitM2/CorePermitM2Energy";
import CorePermitM2Equipment from "../components/corePermitM2/CorePermitM2Equipment";
import CorePermitM2Chemical from "../components/corePermitM2/CorePermitM2Chemical";
import CorePermitM2Protection from "../components/corePermitM2/CorePermitM2Protection";
import CorePermitM2Work from "../components/corePermitM2/CorePermitM2Work";
import CorePermitEnvironment from "../components/corePermitM2/CorePermitEnvironment";
import CorePermitCloseOut from "../components/corePermitM2/corePermitCloseOut";
import CorePermitChange from "../components/corePermitM2/corePermitChange";
import CorePermitResponsibilities from "../components/corePermitM2/corePermitResponsibilities";
import CorePermitReceiver from "../components/corePermitM2/corePermitReceiver";
import CorePermitTraining from "../components/corePermitM2/corePermitTraining";
import CorePermitInputFields from "../components/corePermitM2/corePermitInputFields";
import arrowIcon from "../images/Left-arrow.png";

import {
    USER_PROFILE,
    retrieveData,
    checkForEmptyValues,
    COREPERMIT_DATE,
    COREPERMIT_VENDOR_NAME,
    COREPERMIT_MOBILE_NUMBER,
    END_TIME,
    PEOPLE_DATE_FOR_CLOUSEOUT_DATE,
} from "../Utils";
import moment from "moment";
import Accordion from "../Accordation/Accordian";
import Circle from "../images/CircleAccordianImg.png";

//people-images
import teachpeople from "../images/Teach-People-CorePermit.png";
import stepbysteppeople from "../images/Step-by-step-People-Corepermit.png";
import stalline from "../images/Stalline-People-Corepermit.png";
//environment-images
import envi1 from "../images/Window-man-enivron-corepermit.png";
import suit from "../images/suit-man-envi-corepermit.png";
import globe from "../images/Hand-glob-envi.png";
//Energy -images
import FireImg from "../images/FireAccordionImg.png";
import Thunder from "../images/Thunder.png";
import water from "../images/waterdrop-energy-corepermit.png";
//Equipment-Images
import Car from "../images/Car-euip-corepermit.png";
import cropmachine from "../images/cropmachine-euip-corepermit.png";
import van from "../images/Van-equip-corepermit.png";
//Chemical -Images
import spraytracktor from "../images/spray-tractor-chem-corepermit.png";
import buerate from "../images/Buret-chem-corepermit.png";
import tine from "../images/tin-chem-corepermit.png";
//Change-images
import couple from "../images/couple-change-corepermit.png";
import cloudthunder from "../images/cloud-thunder-change-corepermit.png";
import chart from "../images/chartlist-change-corepermit.png";
import sun from "../images/sun-change-corepermit.png";
import wave from "../images/Wave-change-corepermit.png";

//Approve
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import ButtonAccordion from "../Accordation/ButtonAccordion";
import Swal from "sweetalert2";


const CorePermitM2Acceptor = (props) => {
    const peopleImg = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: teachpeople,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: stepbysteppeople,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: stalline,
                width: 20,
                height: 20,
            },
        },
    ];
    const enviImg = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: envi1,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: suit,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: globe,
                width: 20,
                height: 20,
            },
        },
    ];
    const energyImg = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: FireImg,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: Thunder,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: water,
                width: 20,
                height: 20,
            },
        },
    ];
    const equipImg = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: Car,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: van,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: cropmachine,
                width: 20,
                height: 20,
            },
        },
    ];
    const chemiImg = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: spraytracktor,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: buerate,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: tine,
                width: 20,
                height: 20,
            },
        },
    ];
    const changeimages = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: couple,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: chart,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: cloudthunder,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: sun,
                width: 20,
                height: 20,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: wave,
                width: 20,
                height: 20,
            },
        },
    ];
    const buttonData = [
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: FireImg,
                width: 30,
                height: 25,
            },
        },
        {
            backgroundImage: `url(${Circle})`,
            imageData: {
                imageUrl: Thunder,
                width: 30,
                height: 25,
            },
        },
    ];
    const [formData, setFormData] = useState({
        name: "",
        sign: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [loaderImage, setLoaderImage] = useState(
        require("../images/rmloader.gif")
    );
    const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
    const [emergency, setEmergency] = useState(coreEmergencyConst);
    const [people, setPeople] = useState(corePeopleConstant);
    const [energy, setEnergy] = useState(coreEnergyConstant);
    const [equipment, setEquipment] = useState(coreEquipmentConstant);
    const [chemical, setChemical] = useState(coreChemicalConstant);
    const [protection, setProtection] = useState(coreProtectionConstant);
    const [workEnvironment, setWorkEnvironment] = useState(coreWorkEnvConstant);
    const [environmentData, setEnvironMentData] = useState(environMentConstants);
    const [closeOutData, setCloseOutData] = useState(CloseOutConstants);
    const [changeData, setChangeData] = useState(changeConstants);
    const [respdata, setRespData] = useState(responsibilitites);
    const [recData, setRecData] = useState(corePermitReceiverConstants);
    const [trainData, setTrainData] = useState(trainingConstants);
    const [finalSubmit, setFinalSubmit] = useState(false);
    const [serverRecordId, setServerRecordId] = useState(null);
    //View
    const [data, setData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [additionalPermit, setAdditionalPermit] = useState("");
    const [generalHotWorkPermitMenu, setGeneralHotWorkPermitMenu] = useState();
    const [totalCorePermitMenu, setTotalCorePermitMenu] = useState();
    const [corePermitMenu, setCorePermitMenu] = useState();
    const [cranePermitMenu, setCranePermitMenu] = useState();
    const [criticalElevatedPermitMenu, setCriticalElevatedPermitMenu] =
        useState();
    const [excavationPermitMenu, setExcavationPermitMenu] = useState();
    const [permitId, setPermitId] = useState(-1);
    const [aprove, setAprove] = useState();

    //Save
    const [showModal, setShowModal] = useState(false);
    //Transfer
    const [showTransfer, setShowTransfer] = useState(false);
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    //Approve
    const [showApproved, setShowApproved] = useState(false);
    const [status, setStatus] = useState("");
    const [apiStatus, setApiStatus] = useState("");
    //Reject
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showReject, setShowReject] = useState(false);
    //Suspend
    const [suspend, setSuspend] = useState(false);
    const [suspendReason, setSuspendReason] = useState("");
    // const [suspendedBy, setSuspendedBy] = useState("");
    const [userId, setUserId] = useState("");
    const [showConfirmationSuspendPopup, setShowConfirmationSuspendPopup] = useState("")
    const [remarks, setRemarks] = useState("");
    const [searchParams] = useSearchParams();
    const [isEdit, setIsEdit] = useState(false);
    const [permitStatus, setPermitStatus] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [permitObject, setPermitObject] = useState(null);
    const IdFromURL = searchParams.get("corePermitID");
    const PermitNamefromURL = searchParams.get("permitName");
    const PermitUserIdfromURL = searchParams.get("acceptorUserId");
    const AditionpermitIDFromURL = searchParams.get("additionalPermitID");
    const ActionTypefromURL = searchParams.get("actionType");
    const JobSite = searchParams.get("jobSite");
    const [iscoreVE, setIscoreVE] = useState();
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);

    //Validations
    const [openAccordion, setOpenAccordion] = useState(null);
    const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
    const [peopleToggleOn, setPeopleToggleOn] = useState(true);
    const [environmnetToggleOn, setEnvironmentToggleOn] = useState(true);
    const [energyToggleOn, setEnergyToggleOn] = useState(true);
    const [equipmentToggleOn, setEquipmentToggleOn] = useState(true);
    const [chemicalToggleOn, setChemicalToggleOn] = useState(true);
    const [protectionToggleOn, setProtectionToggleOn] = useState(true);
    const [workEnvironmentToggleOn, setWorkEnvironmentToggleOn] = useState(true);
    const [trainDataToggleOn, setTrainDataToggleOn] = useState(true);
    const [recDataToggleOn, setRecDataToggleOn] = useState(true);
    const [responsibleToggleOn, setResponsibleToggleOn] = useState(true);
    const [changeToggleOn, setChangeToggleOn] = useState(true);
    const [closeOutToggleOn, setCloseOutToggleOn] = useState(true);
    const [permitIntials, setPermitIntials] = useState(true);


    //CORE Permit #:
    const [primaryRequesterName, setPrimaryRequesterName] = useState("");
    const [primaryRequesterMobileNumber, setPrimaryRequesterMobileNumber] = useState("");
    const [suspendedBy, setSuspendedBy] = useState("");
    const [suspendTime, setSuspendTime] = useState("");
    const [suspendDate, setSuspendDate] = useState("");
    const [suspendedRemarks, setSuspendedRemarks] = useState("");
    const [approveOrRejectedByName, setApproveOrRejectedByName] = useState("");
    const [isTransferred, setIsTransferred] = useState("");
    const [transferToName, setTransferToName] = useState("");
    const [permitIssuerPhoneNumber, setPermitIssuerPhoneNumber] = useState("");
    const [closeOutTime, setCloseOutTime] = useState("");
    const [closeOutDate, setCloseOutDate] = useState("");
    const [issuerTransferTo, setIssuerTransferTo] = useState("");
    const [receiverTransferTo, setReceiverTransferTo] = useState("");
    const [jobSiteInspected, setJobSiteInspected] = useState(null);
    const [jobSiteInspectorId, setJobSiteInspectorId] = useState("");

    useEffect(() => {
        const search = props.location;
        const params = new URLSearchParams(search);
        const IdFromURL = searchParams.get("corePermitID");
        var isEdit = "true";
        setIsEdit(isEdit);
        getFormData(
            IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
                ? IdFromURL
                : 0
        );
        return () => { };
    }, [isEdit]);

    async function filterArray(array, keyName, searchText) {
        console.log("responseFFFF", keyName + "---" + searchText);
        var filteredArray = [];
        for (let i = 0; i < array.length; i++) {
            if (array[i].permitID.toString() === searchText.toString()) {
                filteredArray.push(array[i]);
                break;
            }
        }
        console.log("Myfilter array::", filteredArray);
        return filteredArray[0];
        fetchdata();

    }
    useEffect(() => { }, [isExpanded]);

    const handleToggleItems = () => {
        setEmergencyToggleOn(!emergencyToggleOn)
    }
    const handlePeopleToggleItems = () => {
        setPeopleToggleOn(!peopleToggleOn)
    }

    const handleEnvironmentToggleItems = () => {
        setEnvironmentToggleOn(!environmnetToggleOn)
    }

    const handleEnergyToggleItems = () => {
        setEnergyToggleOn(!energyToggleOn)
    }

    const handleEquipmentToggleItems = () => {
        setEquipmentToggleOn(!equipmentToggleOn)
    }

    const handleChemicalToggleItems = () => {
        setChemicalToggleOn(!chemicalToggleOn)
    }
    const handleProtectionToggleItems = () => {
        setProtectionToggleOn(!protectionToggleOn)
    }
    const handleworkEnvironmentToggleItems = () => {
        setWorkEnvironmentToggleOn(!workEnvironmentToggleOn)
    }
    const handleTrainDataToggleItems = () => {
        setTrainDataToggleOn(!trainDataToggleOn)
    }

    const handleRecDataToggleOnToggleItems = () => {
        setRecDataToggleOn(!recDataToggleOn)
    }
    const handleResponsibleToggleOnToggleItems = () => {
        setResponsibleToggleOn(!responsibleToggleOn)
    }


    const handleChangeToggleItems = () => {
        setChangeToggleOn(!changeToggleOn)
    }

    const handleCloseOutToggleItems = () => {
        setCloseOutToggleOn(!closeOutToggleOn)
    }

    const getFormData = async (permitID) => {
        const dataFromStore = await retrieveData(USER_PROFILE);
        const headers = await GetApiHeaders();
        const dataToSend = {
            mobileNumber: headers.mobileNumber == null ? "" : headers.mobileNumber,
            name: "",
            PermitID: IdFromURL,
        };
        setIsLoading(true);
        const response = await axios.post(getAllPermitsByCorePermitID, dataToSend, {
            headers,
        });
        console.log("id:::", dataFromStore);
        if (response.data.statusCode == 200) {
            if (
                response.data.response != undefined &&
                response.data.response != null
            ) {
                console.log(
                    "response123:::",
                    JSON.stringify(response.data.response.core_permit)
                );
                if (
                    response.data.response.core_permit != null &&
                    response.data.response.core_permit.length > 0
                ) {
                    setIsExpanded(
                        response.data.response.core_permit.find(
                            (item) => item.permitID === IdFromURL
                        )?.permitStatus == "Approve"
                            ? true
                            : false
                    );
                    const checkPstatus = response.data.response.core_permit.find(
                        (item) => item.permitID === IdFromURL
                    )?.permitStatus;
                    console.log("check this", checkPstatus);
                    setPermitStatus(
                        response.data.response.core_permit.find(
                            (item) => item.permitID === IdFromURL
                        )?.permitStatus
                    );
                }
                var data = await filterArray(
                    response.data.response.core_permit,
                    "permitID",
                    IdFromURL
                );
                console.log("data:::", JSON.stringify(data));
                var dataLast =
                    data.permitObject != undefined && data.permitObject != null
                        ? data.permitObject
                        : undefined;
                if (dataLast != undefined) {
                    console.log("dataLastCorepermit-Responce:::::::::::::::::::", JSON.stringify(dataLast));
                    setPermitObject(dataLast);
                    console.log(response.serverRecordId);

                    console.log()
                    setPermitInitials(
                        dataLast.permitInitials ? dataLast?.permitInitials : permitInitials
                    );
                    setEmergency(dataLast.emergency ? dataLast.emergency : emergency);
                    setPeople(dataLast?.people ? dataLast?.people : people);
                    setEnergy(dataLast.energy ? dataLast.energy : energy);
                    setEquipment(dataLast.equipment ? dataLast.equipment : equipment);
                    setChemical(dataLast.chemical ? dataLast.chemical : chemical);
                    setProtection(dataLast.protection ? dataLast.protection : protection);
                    setWorkEnvironment(
                        dataLast.workEnvironment
                            ? dataLast.workEnvironment
                            : workEnvironment
                    );
                    setEnvironMentData(
                        dataLast.environmentData
                            ? dataLast.environmentData
                            : environmentData
                    );
                    setCloseOutData(
                        dataLast.closeOutData ? dataLast.closeOutData : closeOutData
                    );
                    setChangeData(dataLast.changeData ? dataLast.changeData : changeData);
                    setRespData(
                        dataLast.respdata ? dataLast.respdata : responsibilitites
                    );
                    setRecData(
                        dataLast.recData ? dataLast.recData : corePermitReceiverConstants
                    );
                    setTrainData(dataLast.trainData ? dataLast.trainData : trainData);
                    setServerRecordId(dataLast.serverRecordId);

                    setPrimaryRequesterName(data.primaryRequesterName);
                    setPrimaryRequesterMobileNumber(data.primaryRequesterMobileNumber);
                    setSuspendedBy(data.suspendedBy);
                    setSuspendTime(data.suspendTime);
                    setSuspendDate(data.suspendDate);
                    setSuspendedRemarks(data.suspendedRemarks);
                    setApproveOrRejectedByName(data.approveOrRejectedByName);
                    setPermitIssuerPhoneNumber(data.phonenumber);
                    setIsTransferred(data.isTransferred);
                    setTransferToName(data.transferToName);
                    setCloseOutTime(data.closeOutTime);
                    setCloseOutDate(data.closeOutDate);
                    setIssuerTransferTo(data.issuerTransferTo);
                    setReceiverTransferTo(data.receiverTransferTo);
                    console.log("fetched jobinspectiedid", data?.jobSiteInspected);
                    setJobSiteInspected(data?.jobSiteInspected || false);
                    setJobSiteInspectorId(data?.jobSiteInspectorId || "");
                    setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
                    setPeopleToggleOn(dataLast.peopleToggleOn !== undefined ? dataLast.peopleToggleOn : peopleToggleOn);
                    setEnvironmentToggleOn(dataLast.environmnetToggleOn !== undefined ? dataLast.environmnetToggleOn : environmnetToggleOn);
                    setEnergyToggleOn(dataLast.energyToggleOn !== undefined ? dataLast.energyToggleOn : energyToggleOn);
                    setEquipmentToggleOn(dataLast.equipmentToggleOn !== undefined ? dataLast.equipmentToggleOn : equipmentToggleOn);
                    setChemicalToggleOn(dataLast.chemicalToggleOn !== undefined ? dataLast.chemicalToggleOn : chemicalToggleOn);
                    setProtectionToggleOn(dataLast.protectionToggleOn !== undefined ? dataLast.protectionToggleOn : protectionToggleOn);
                    setWorkEnvironmentToggleOn(dataLast.workEnvironmentToggleOn !== undefined ? dataLast.workEnvironmentToggleOn : workEnvironmentToggleOn);
                    setTrainDataToggleOn(dataLast.trainDataToggleOn !== undefined ? dataLast.trainDataToggleOn : trainDataToggleOn);
                    setRecDataToggleOn(dataLast.recDataToggleOn !== undefined ? dataLast.recDataToggleOn : recDataToggleOn);
                    setResponsibleToggleOn(dataLast.responsibleToggleOn !== undefined ? dataLast.responsibleToggleOn : responsibleToggleOn);
                    setChangeToggleOn(dataLast.changeToggleOn !== undefined ? dataLast.changeToggleOn : changeToggleOn);
                    setCloseOutToggleOn(dataLast.closeOutToggleOn !== undefined ? dataLast.closeOutToggleOn : closeOutToggleOn);
                    setTimeout(() => {
                        console.log("emergency :: ", JSON.stringify(emergency));
                    }, 5000);

                    console.log("people :: ", JSON.stringify(people));
                    console.log("energy : ", JSON.stringify(energy));
                    console.log("equipment : ", JSON.stringify(equipment));
                    console.log("chemical : ", JSON.stringify(chemical));
                }
            }
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleTransferTo = (e) => {
        e.preventDefault();
        setShowTransfer(true);
        setName("");
        setMobileNumber("");
        console.log("Transfer button clicked");
    };
    const handleTransferToOk = async (e) => {
        console.log(e, "hanhandleTransferToOkdleOk");
        const TransferData = {
            transferFromMobileNumber: "9491919103",
            transferFromName: "Doondi",
            corePermitID: "4",
            transferToMobileNumber: "9966611875",
            transferToName: "Hari",
        };
        const response = await axios.post(transferCorePermit, TransferData);
        console.log("responce", response);
        if (response.data.statusCode !== "200") {
            setApiStatus(response.data.status);
            console.log("API throwing error");
        }
        setShowTransfer(false);
        console.log(status);
    };

    const handleConfirmationOk = () => {
        setShowReject(false);
        setShowConfirmationPopup(false);

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("GoBack");
        }
    };


    const handleConfirmationSuspendOk = () => {
        setSuspend(false);
        setShowConfirmationSuspendPopup(false);
        setTimeout(() => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("GoBack");
            }
        }, 500);
    };


    const handleAprrovedOk = async (e) => {
        e.preventDefault();
        setStatus("");
        setMessage("");
        setIsLoading(false);
        setShowApproved(false);

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("GoBack");
        }
    };


    async function handleApproved(e) {
        const changeDataList = changeData.changeList.filter((item) => item.checked);
        const changeDataListChecked = changeDataList.length >= 1 ? "true" : "";

        const closeOutDataList = closeOutData.closeOutList.filter(
            (item) => item.checked
        );
        const closeOutDataChecked = closeOutDataList.length >= 1 ? "true" : "";

        let valuesToCheck = [];
        // Validate permitInitials
        if (permitInitials) {
            valuesToCheck.push({
                permitInitials: {
                    initialsOne: permitInitials.initialsOne,
                    initialsTwo: permitInitials.initialsTwo,
                    initialsThree: permitInitials.initialsThree,
                    permitsOne: permitInitials.permitsOne,
                    permitsTwo: permitInitials.permitsTwo,
                },
            });
        }
        console.log("valuesToCheck", JSON.stringify(valuesToCheck));
        let allCasesValid = true;
        for (const item of valuesToCheck) {
            const returnKey = checkForEmptyValues(item);
            if (returnKey) {
                switch (returnKey) {
                    case "permitInitials":
                        const { initialsOne, initialsTwo, initialsThree, permitsOne, permitsTwo } = permitInitials;
                        if (!initialsOne || !initialsTwo || !initialsThree || !permitsOne || !permitsTwo) {
                            Swal.fire({
                                icon: "warning",
                                text: "Please Enter All Initials & Permits",
                                confirmButtonColor: "#d33",
                            });
                            allCasesValid = false;
                            return;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        if (allCasesValid) {
            if (
                (e === "Submitted") ||
                (e === "Approve" && jobSiteInspectorId !== "" && jobSiteInspectorId !== null && jobSiteInspected === false)
            ) {
                const userProfileData = {
                    primaryMobileNumber: permitObject.primaryMobileNumber,
                    primaryRequesterName: permitObject.primaryRequesterName,
                    secondaryRequesterName: permitObject.secondaryRequesterName,
                    secondaryMobileNumber: permitObject.secondaryMobileNumber,
                    corePermits: permitObject.corePermits,
                    corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
                    corePermitInitials: permitObject.corePermitInitials,
                    serverRecordId: IdFromURL ? IdFromURL : 0,
                };
                const finalJson = {
                    permitInitials,
                    primaryRequesterName,
                    primaryRequesterMobileNumber,
                    suspendedBy,
                    suspendTime,
                    suspendDate,
                    suspendedRemarks,
                    approveOrRejectedByName,
                    isTransferred, transferToName,
                    permitIssuerPhoneNumber,
                    emergency,
                    people,
                    energy,
                    equipment,
                    chemical,
                    protection,
                    workEnvironment,
                    environmentData,
                    closeOutData,
                    changeData,
                    respdata,
                    recData,
                    trainData,
                    ...userProfileData,
                    emergencyToggleOn,
                    peopleToggleOn,
                    environmnetToggleOn,
                    energyToggleOn,
                    equipmentToggleOn,
                    chemicalToggleOn,
                    protectionToggleOn,
                    workEnvironmentToggleOn,
                    trainDataToggleOn,
                    recDataToggleOn,
                    responsibleToggleOn,
                    changeToggleOn,
                    closeOutToggleOn,
                };
                setIsLoading(true);
                const response = await axios.post(saveCorePermitForm, finalJson);
                console.log("response", response);
                if (response.data.statusCode === "200") {
                    const ApproveData = {
                        acceptorUserId: PermitUserIdfromURL,
                        permitName: PermitNamefromURL,
                        corePermitID: IdFromURL,
                        additionalPermitID:
                            AditionpermitIDFromURL === "" ? "0" : AditionpermitIDFromURL,
                        status: "Approve",
                        deviceType: "iOS, Android",
                        remarks: "",
                        actionType: ActionTypefromURL,
                    };
                    try {
                        setIsLoading(true);
                        const approveResponse = await axios.post(
                            ApproveRejectPermit,
                            ApproveData
                        );
                        if (approveResponse.data.statusCode !== "200") {
                            setTimeout(() => {
                                setIsLoading(false);
                                setApiStatus(approveResponse.data.status);
                                console.log("API throwing error");
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                setIsLoading(false);
                                setIsExpanded(true);
                                setShowApproved(true);
                            }, 1000);
                        }
                    } catch (err) {
                        setIsLoading(false);
                        console.error(err);
                    }
                }
                setIsLoading(false);
            } else if (e === "Approve") {
                // alert(jobSiteInspected)
                console.log("jobSiteInspectorIdddddd", jobSiteInspectorId);
                // (jobSiteInspectorId);
                console.log("e", e);
                // (e);
                console.log("jobSiteInspectedddddd", jobSiteInspected);
                // (jobSiteInspected);
                handleSubmit();
            }
        }
    }


    const fetchdata = async () => {
        const apiUrl = getAllPermits;
        const headers = await GetApiHeaders();
        try {
            setIsLoading(true);
            const response = await axios.get(apiUrl, { headers });
            console.log(response);
            setData(response.data.response.core_permit);
            setFullData(response.data.response.core_permit);
            console.log("ttt", additionalPermit);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (data != undefined && data.response != undefined) {
            console.log("data1::", data.response.corePermits);
        }
    }, [data]);
    useEffect(() => {
        console.log("CorepermitMenu", corePermitMenu);
    }, [corePermitMenu]);


    const handleView = async (permitID) => {
        const dataToSend = {
            mobileNumber: mobileNumber,
            name: name,
            PermitID: permitID.toString(),
        };
        var URL = getAllPermitsByCorePermitID;
        try {
            setIsLoading(true);
            var response = await axios.post(URL, dataToSend);
            setIsLoading(false);
            console.log("response:::", response);
            if (response.data.statusCode == 200) {
                if (
                    response.data.response != undefined &&
                    response.data.response != null
                ) {
                    setTotalCorePermitMenu(response.data.response);
                    console.log("setCorePermitMenu:::", totalCorePermitMenu);
                    setTotalCorePermitMenu(response.data.response.core_permit);
                    console.log(
                        "CorepermitMenu12345::::::::::",
                        response.data.response.core_permit
                    );
                    setCranePermitMenu(response.data.response.crane_permit);
                    setCriticalElevatedPermitMenu(
                        response.data.response.critical_elevated_permit
                    );
                    setExcavationPermitMenu(response.data.response.excavation_permit);
                    setGeneralHotWorkPermitMenu(
                        response.data.response.general_hot_Work_permit
                    );
                }
            }
        } catch (error) {
            console.error("Axios Error:", error);
        }
    };

    const handleSuspend = (e) => {
        e.preventDefault();
        setSuspend(true);
        setSuspendReason("");
    }

    const handleCancel = () => {
        setSuspend(false);
    };
    const handleCancelReject = () => {
        setShowReject(false);
    }

    const handleNewModalOk = () => {
        setShowNewModal(false);
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("GoBack");
        }
    };

    const handleSuspendOk = async () => {
        console.log("handleSuspendOk");
        if (!suspendReason.trim()) {
            return;
        }
        const SuspendData = {
            acceptorUserId: PermitUserIdfromURL,
            permitName: PermitNamefromURL,
            corePermitID: IdFromURL,
            additionalPermitID: AditionpermitIDFromURL || "0",
            status: "Suspend",
            deviceType: "iOS, Android",
            remarks: suspendReason,
            actionType: ActionTypefromURL
        };

        try {
            setIsLoading(true);
            const response = await axios.post(ApproveRejectPermit, SuspendData);
            console.log("response", response);
            if (response.data.statusCode !== "200") {
                // Handle error scenario
                setShowConfirmationSuspendPopup(true);
                setApiStatus(response.data.message);
                console.log("API throwing error");
            } else {
                // Handle success scenario
                setShowConfirmationSuspendPopup(true);
                setApiStatus("Suspend successful!");
            }
            setIsLoading(false);
            setSuspend(false);
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };
    const handleReject = (e) => {
        e.preventDefault();
        const { initialsOne, initialsTwo, initialsThree, permitsOne, permitsTwo } = permitInitials;
        if (!initialsOne || !initialsTwo || !initialsThree || !permitsOne || !permitsTwo) {
            Swal.fire({
                icon: "warning",
                text: "Please Enter All Initials & Permits",
                confirmButtonColor: "#d33",
            });
            return;
        }
        setShowReject(true);
        setRemarks("");
        setIsLoading(false);
    };


    const handleRejectOk = async (e) => {
        try {
            e.preventDefault();
            if (!remarks.trim()) {
                Swal.fire({
                    icon: "warning",
                    text: "Please Enter Remarks Before Proceeding.",
                    confirmButtonColor: "#d33",
                });
                return;
            }

            const userProfileData = {
                primaryMobileNumber: permitObject.primaryMobileNumber,
                primaryRequesterName: permitObject.primaryRequesterName,
                secondaryRequesterName: permitObject.secondaryRequesterName,
                secondaryMobileNumber: permitObject.secondaryMobileNumber,
                corePermits: permitObject.corePermits,
                corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
                corePermitInitials: permitObject.corePermitInitials,
                serverRecordId: IdFromURL ? IdFromURL : 0,
            };

            const finalJson = {
                permitInitials,
                primaryRequesterName,
                primaryRequesterMobileNumber,
                suspendedBy,
                suspendTime,
                suspendDate,
                suspendedRemarks,
                approveOrRejectedByName,
                isTransferred, transferToName,
                permitIssuerPhoneNumber,
                emergency,
                people,
                energy,
                equipment,
                chemical,
                protection,
                workEnvironment,
                environmentData,
                closeOutData,
                changeData,
                respdata,
                recData,
                trainData,
                ...userProfileData,
                emergencyToggleOn,
                peopleToggleOn,
                environmnetToggleOn,
                energyToggleOn,
                equipmentToggleOn,
                chemicalToggleOn,
                protectionToggleOn,
                workEnvironmentToggleOn,
                trainDataToggleOn,
                recDataToggleOn,
                responsibleToggleOn,
                changeToggleOn,
                closeOutToggleOn,
            };

            setIsLoading(true);
            try {
                // Save the CorePermit data
                const response = await axios.post(saveCorePermitForm, finalJson);
                console.log("Crane permit response:", response);

                if (response.data.statusCode === "200") {
                    const RejectData = {

                        acceptorUserId: PermitUserIdfromURL,
                        permitName: PermitNamefromURL,
                        corePermitID: IdFromURL,
                        additionalPermitID: AditionpermitIDFromURL || "0",
                        status: "Reject",
                        deviceType: "iOS, Android",
                        remarks: remarks,
                    };

                    // Send rejection data
                    const rejectResponse = await axios.post(ApproveRejectPermit, RejectData);
                    console.log("Rejection response:", rejectResponse);

                    if (rejectResponse.data.statusCode !== "200") {
                        setShowConfirmationPopup(true);
                        setApiStatus(rejectResponse.data.message);
                    } else {
                        setShowConfirmationPopup(true);
                        setApiStatus("Rejection successful!");
                    }
                }
            } catch (err) {
                console.error("Error in submission:", err);
            } finally {
                setIsLoading(false);
                setShowReject(false);
            }
        } catch (error) {
            console.error("Error in handleRejectOk:", error);
        }
    };



    const handleBackButtonClick = () => {
        // Handle navigation logic here
        navigate(-1);
    };

    const handleSubmit = async () => {
        const userProfileData = {
            primaryMobileNumber: permitObject.primaryMobileNumber,
            primaryRequesterName: permitObject.primaryRequesterName,
            secondaryRequesterName: permitObject.secondaryRequesterName,
            secondaryMobileNumber: permitObject.secondaryMobileNumber,
            corePermits: permitObject.corePermits,
            corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
            corePermitInitials: permitObject.corePermitInitials,
            serverRecordId: IdFromURL ? IdFromURL : 0,
        };
        const finalJson = {
            permitInitials,
            primaryRequesterName,
            primaryRequesterMobileNumber,
            suspendedBy,
            suspendTime,
            suspendDate,
            suspendedRemarks,
            approveOrRejectedByName,
            isTransferred, transferToName,
            permitIssuerPhoneNumber,
            emergency,
            people,
            energy,
            equipment,
            chemical,
            protection,
            workEnvironment,
            environmentData,
            closeOutData,
            changeData,
            respdata,
            recData,
            trainData,
            ...userProfileData,
            emergencyToggleOn,
            peopleToggleOn,
            environmnetToggleOn,
            energyToggleOn,
            equipmentToggleOn,
            chemicalToggleOn,
            protectionToggleOn,
            workEnvironmentToggleOn,
            trainDataToggleOn,
            recDataToggleOn,
            responsibleToggleOn,
            changeToggleOn,
            closeOutToggleOn,
        };
        setIsLoading(true);
        const response = await axios.post(saveCorePermitForm, finalJson);
        console.log("response", response);
        if (response.data.statusCode == "200") {
            setApiStatus(response.data.message);
        }
        setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => {
                setShowModal(true);
            }, 500);
        }, 2000);
    };


    //View Fetch Data
    var date = sessionStorage.getItem(COREPERMIT_DATE);
    var vendorname = sessionStorage.getItem(COREPERMIT_VENDOR_NAME);
    var vendormobileNumber = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
    const fetchData = async () => {
        const dataToSend = {
            name: vendorname,
            mobileNumber: vendormobileNumber,
            date: date,
        };
        var URL = getCorePermits;
        setIsLoading(true);
        var response = await axios.post(URL, dataToSend);
        if (response.data.statusCode == 200) {
            if (
                response.data.response.corePermits != undefined &&
                response.data.response.corePermits.length > 0
            ) {
                setData(response.data.response.corePermits);
            }
        }
        setIsLoading(false);
        setTimeout(() => {
            console.log("data::", data);
        }, 2000);
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <body id="page-top" style={{ paddingTop: "10px", margin: "10px" }}>
            {finalSubmit ? (
                <>
                    <div id="wrapper">
                        <div
                            className="row"
                            style={{ backgroundColor: "blue", padding: "7px" }}
                        >
                            <div className="col-md-3 col-12">
                                <img
                                    src={arrowIcon}
                                    onClick={handleBackButtonClick}
                                    alt="Back"
                                    style={{
                                        width: 40,
                                        height: 30,
                                        padding: 5,
                                        verticalAlign: "middle",
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: "white",
                                        marginLeft: 10,
                                        marginTop: 12,
                                    }}
                                >
                                    Core Permit
                                </span>
                            </div>
                        </div>

                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content">
                                <form className="CorePermit_Web-form">
                                    <div>
                                        <div>
                                            <div className="card border-0">
                                                <div className="card-body">
                                                    <div className="m-4">
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    color: "green",
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        margin: "13px 0px 14px 10px",
                                                                        fontSize: "16px",
                                                                    }}
                                                                >
                                                                    Core Permit Submitted Successfully
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    fontWeight: "bold",
                                                                    backgroundColor: "#DC275E",
                                                                    color: "white",
                                                                    margin: "10px 0px 10px 0px",
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/AdditionalPermitOptions"
                                                                    aria-expanded="true"
                                                                >
                                                                    <button
                                                                        className="btn mt-2"
                                                                        style={{
                                                                            margin: "0px 0px 6px 40px",
                                                                            color: "white",
                                                                        }}
                                                                    >
                                                                        Add Additional Permits
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    fontWeight: "bold",
                                                                    backgroundColor: "#DC275E",
                                                                    color: "white",
                                                                    margin: "10px 0px 10px 0px",
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                <Link to="/CorePermitM3" aria-expanded="true">
                                                                    <button
                                                                        className="btn mt-2"
                                                                        style={{
                                                                            margin: "0px 0px 6px 40px",
                                                                            color: "white",
                                                                        }}
                                                                    >
                                                                        Go To Core Permit Status
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* rns */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* ... End of Footer...  */}
                            </div>
                        </div>
                        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
                        <a className="scroll-to-top rounded" href="#page-top">
                            <i className="fas fa-angle-up"></i>
                        </a>
                        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
                    </div>
                </>
            ) : (
                <div id="wrapper">
                    {/* 
                <SideMenu /> */}

                    <div
                        id="content-wrapper"
                        className="d-flex flex-column"
                        style={{ backgroundColor: "white" }}
                    >
                        <div id="content">
                            {/* 
                        <Header /> */}

                            <div>
                                <div className="container-fluid">
                                    <div className="d-flex justify-content-between"></div>
                                    <div>
                                        <form className="CorePermitM2-form">
                                            <CorePermitInputFields
                                                data={permitInitials}
                                                editable={true}
                                                setPermitInitials={setPermitInitials}
                                                onDataChange={(data) => {
                                                    setPermitInitials(data);
                                                }}
                                            />

                                            <Accordion
                                                title="EMERGENCY"
                                                bgColor="#DF3A1C"
                                                handleToggle={handleToggleItems}
                                                isToggled={emergencyToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="emergency"
                                                content={
                                                    <CorePermitEmergency
                                                        data={emergency}
                                                        emergencyToggleOn={emergencyToggleOn}
                                                        setEmergency={setEmergency}
                                                        onDataChange={(data) => {
                                                            setEmergency(data);
                                                        }}
                                                    />
                                                }
                                                buttonData={peopleImg}
                                                bordercolor="#DF3A1C"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="PEOPLE"
                                                bgColor="#404040"
                                                isToggled={peopleToggleOn}
                                                handleToggle={handlePeopleToggleItems}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="people"
                                                content={
                                                    <CorePermitM2People
                                                        data={people}
                                                        peopleToggleOn={peopleToggleOn}
                                                        approveOrRejectedByName={approveOrRejectedByName}
                                                        permitIssuerPhoneNumber={permitIssuerPhoneNumber}
                                                        isTransferred={isTransferred}
                                                        transferToName={transferToName}
                                                        issuerTransferTo={issuerTransferTo}
                                                        receiverTransferTo={receiverTransferTo}
                                                        isApproved={permitStatus === "Approve"}
                                                        setPeople={setPeople}
                                                        onDataChange={(data) => {
                                                            setPeople(data);
                                                        }}
                                                    />
                                                }
                                                buttonData={peopleImg}
                                                bordercolor="#404040"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="ENVIRONMENT"
                                                bgColor="#2CC743"
                                                handleToggle={handleEnvironmentToggleItems}
                                                isToggled={environmnetToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="environment"
                                                content={
                                                    <CorePermitEnvironment
                                                        environMentData={environmentData}
                                                        environmnetToggleOn={environmnetToggleOn}
                                                        setEnvironMentData={setEnvironMentData}
                                                        onDataChange={(data) => setEnvironMentData(data)}
                                                    />
                                                }
                                                buttonData={enviImg}
                                                bordercolor="#2CC743"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="ENERGY"
                                                bgColor="#3F57B1"
                                                handleToggle={handleEnergyToggleItems}
                                                isToggled={energyToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="energy"
                                                content={
                                                    <CorePermitM2Energy
                                                        energyToggleOn={energyToggleOn}
                                                        data={energy}
                                                        setEnergy={setEnergy}
                                                        onDataChange={(val) => {
                                                            setEnergy(val);
                                                        }}
                                                    />
                                                }
                                                buttonData={energyImg}
                                                bordercolor="#3F57B1"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="EQUIPMENT"
                                                bgColor="#F17A5A"
                                                handleToggle={handleEquipmentToggleItems}
                                                isToggled={equipmentToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="equipment"
                                                content={
                                                    <CorePermitM2Equipment
                                                        data={equipment}
                                                        equipmentToggleOn={equipmentToggleOn}
                                                        setEnergy={setEquipment}
                                                        onDataChange={(val) => {
                                                            setEquipment(val);
                                                        }}
                                                    />
                                                }
                                                buttonData={equipImg}
                                                bordercolor="#F17A5A"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="CHEMICAL"
                                                bgColor="#C1403B"
                                                handleToggle={handleChemicalToggleItems}
                                                isToggled={chemicalToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="chemical"
                                                content={
                                                    <CorePermitM2Chemical
                                                        data={chemical}
                                                        chemicalToggleOn={chemicalToggleOn}
                                                        setChemical={setChemical}
                                                        onDataChange={(val) => {
                                                            setChemical(val);
                                                        }}
                                                    />
                                                }
                                                buttonData={chemiImg}
                                                bordercolor="#C1403B"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="PROTECTION"
                                                bgColor="#4E74C2"
                                                handleToggle={handleProtectionToggleItems}
                                                isToggled={protectionToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="protection"
                                                content={
                                                    <CorePermitM2Protection
                                                        data={protection}
                                                        protectionToggleOn={protectionToggleOn}
                                                        setProtection={setProtection}
                                                        onDataChange={(val) => {
                                                            setProtection(val);
                                                        }}
                                                    />
                                                }
                                                bordercolor="#4E74C2"
                                                showToggle={true}

                                            />
                                            <Accordion
                                                title="WORK ENVIRONMENT"
                                                bgColor="#636260"
                                                handleToggle={handleworkEnvironmentToggleItems}
                                                isToggled={workEnvironmentToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="workEnvironment"
                                                content={
                                                    <CorePermitM2Work
                                                        setWorkEnvironment={setWorkEnvironment}
                                                        workEnvironmentToggleOn={workEnvironmentToggleOn}
                                                        data={workEnvironment}
                                                        onDataChange={(val) => {
                                                            setWorkEnvironment(val);
                                                        }}
                                                    />
                                                }
                                                bordercolor="#636260"
                                                showToggle={true}

                                            />
                                            <Accordion
                                                title="TRAINING"
                                                bgColor="#282928"
                                                handleToggle={handleTrainDataToggleItems}
                                                isToggled={trainDataToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="training"
                                                content={
                                                    <CorePermitTraining
                                                        trainData={trainData}
                                                        trainDataToggleOn={trainDataToggleOn}
                                                        setTrainData={setTrainData}
                                                    />
                                                }
                                                bordercolor="#282928"
                                                showToggle={true}

                                            />
                                            <Accordion
                                                title="CORE PERMIT RECEIVER"
                                                bgColor="#484848"
                                                handleToggle={handleRecDataToggleOnToggleItems}
                                                isToggled={recDataToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="permitReceiver"
                                                content={
                                                    <CorePermitReceiver
                                                        recDataToggleOn={recDataToggleOn}
                                                        recData={recData}
                                                        setRecData={setRecData}
                                                    />
                                                }
                                                bordercolor="#484848"
                                                showToggle={true}
                                            />
                                            <Accordion
                                                title="RESPONSIBILITIES"
                                                bgColor="#6D6D6D"
                                                handleToggle={handleResponsibleToggleOnToggleItems}
                                                isToggled={responsibleToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="responsibilities"
                                                content={
                                                    <CorePermitResponsibilities
                                                        respData={respdata}
                                                        responsibleToggleOn={responsibleToggleOn}
                                                        setRespData={setRespData}
                                                    />
                                                }
                                                bordercolor="#6D6D6D"
                                                showToggle={true}

                                            />
                                            <Accordion
                                                isExpand={isExpanded}
                                                title="CHANGE"
                                                bgColor="#DF3A1C"
                                                handleToggle={handleChangeToggleItems}
                                                isToggled={changeToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="change"
                                                content={
                                                    <CorePermitChange
                                                        changeData={changeData}
                                                        changeToggleOn={changeToggleOn}
                                                        suspendedBy={suspendedBy}
                                                        suspendTime={suspendTime}
                                                        suspendDate={suspendDate}
                                                        primaryRequesterName={primaryRequesterName}
                                                        isTransferred={isTransferred}
                                                        transferToName={transferToName}
                                                        suspendedRemarks={suspendedRemarks}
                                                        setChangeData={setChangeData}
                                                    />
                                                }
                                                buttonData={changeimages}
                                                bordercolor="#DF3A1C"
                                            // showToggle={true}
                                            />
                                            <Accordion
                                                isExpand={isExpanded}
                                                title="CLOSEOUT"
                                                bgColor="#262721"
                                                handleToggle={handleCloseOutToggleItems}
                                                isToggled={closeOutToggleOn}
                                                openAccordion={openAccordion}
                                                setOpenAccordion={setOpenAccordion}
                                                index="closeOut"
                                                content={

                                                    <CorePermitCloseOut
                                                        closeOutData={closeOutData}
                                                        closeOutToggleOn={closeOutToggleOn}
                                                        setCloseOutData={setCloseOutData}
                                                        primaryRequesterName={primaryRequesterName}
                                                        approveOrRejectedByName={approveOrRejectedByName}
                                                        isTransferred={isTransferred}
                                                        transferToName={transferToName}
                                                        closeOutTime={closeOutTime}
                                                        closeOutDate={closeOutDate}
                                                        issuerTransferTo={issuerTransferTo}
                                                        receiverTransferTo={receiverTransferTo}
                                                    />
                                                }
                                                bordercolor="#262721"
                                            // showToggle={true}
                                            />

                                            <div>
                                                {permitStatus === "Approve" && ActionTypefromURL === "jobSite" && (
                                                    <div className="mt-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleApproved(permitStatus)}
                                                            className="d-flex justify-content-center align-items-center w-100 btn custom-rounded"
                                                            style={{
                                                                height: "50px",
                                                                backgroundColor: "#007AFF",
                                                                color: "white",
                                                                border: "none",
                                                            }}
                                                        >
                                                            Approve
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={handleReject}
                                                            className="d-flex justify-content-center align-items-center w-100 btn custom-rounded mt-2"
                                                            style={{
                                                                height: "50px",
                                                                backgroundColor: "#FC2727",
                                                                color: "white",
                                                                border: "none",
                                                            }}
                                                        >
                                                            Reject
                                                        </button>
                                                    </div>
                                                )}



                                                {
                                                    permitStatus === "Submitted" && ActionTypefromURL === "Approve" ? (
                                                        <div>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    handleApproved(permitStatus);
                                                                }}
                                                                className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                style={{
                                                                    height: "50px",
                                                                    backgroundColor: "#007AFF",
                                                                    color: "white",
                                                                    border: "none",
                                                                    marginTop: "10px",
                                                                }}
                                                            >
                                                                Approve

                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={handleReject}
                                                                className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                style={{
                                                                    height: "50px",
                                                                    backgroundColor: "#FC2727",
                                                                    color: "white",
                                                                    border: "none",
                                                                    marginTop: "10px",
                                                                }}
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    ) : permitStatus === "Approve" && ActionTypefromURL === "edit" ? (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleApproved(permitStatus);
                                                            }}
                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                            style={{
                                                                height: "50px",
                                                                backgroundColor: "#007AFF",
                                                                color: "white",
                                                                border: "none",
                                                                marginTop: "10px",
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    )
                                                        : ""
                                                }
                                                {permitStatus === "Approve" && ActionTypefromURL === "Suspend" ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={handleSuspend}
                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                            style={{
                                                                height: "50px",
                                                                backgroundColor: "#FC2727",
                                                                color: "white",
                                                                border: "none",
                                                                marginTop: "10px",
                                                            }}
                                                        >
                                                            Suspend
                                                        </button>
                                                    </>
                                                ) : ''}

                                                {ActionTypefromURL === "View" && data?.length > 0 ? (
                                                    data.map((item, index) => (
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            key={index}
                                                            onClick={() => handleView(item.permitID)}
                                                        >
                                                            <ButtonAccordion
                                                                title="View"
                                                                bgColor="#2CC743"
                                                                content={
                                                                    <div>
                                                                        {corePermitMenu?.length > 0 && corePermitMenu !== null && corePermitMenu !== undefined ? (
                                                                            corePermitMenu.map((item, index) => (
                                                                                <div className="row mt-2" key={index}>
                                                                                    <Link
                                                                                        className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                        style={{
                                                                                            height: "50px",
                                                                                            backgroundColor: "#2373BE",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Core Permit
                                                                                    </Link>
                                                                                </div>
                                                                            ))
                                                                        ) : null}

                                                                        {cranePermitMenu?.length > 0 && cranePermitMenu !== null && cranePermitMenu !== undefined ? (
                                                                            cranePermitMenu.map((item, index) => (
                                                                                <div className="row mt-2" key={index}>
                                                                                    <Link
                                                                                        className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                        style={{
                                                                                            height: "50px",
                                                                                            backgroundColor: "#F58355",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Crane Operation Permit
                                                                                    </Link>
                                                                                </div>
                                                                            ))
                                                                        ) : null}

                                                                        {excavationPermitMenu?.length > 0 && excavationPermitMenu !== null && excavationPermitMenu !== undefined ? (
                                                                            excavationPermitMenu.map((item, index) => (
                                                                                <div className="row mt-2" key={index}>
                                                                                    <Link
                                                                                        className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                        style={{
                                                                                            height: "50px",
                                                                                            backgroundColor: "#262828",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Excavation Permit
                                                                                    </Link>
                                                                                </div>
                                                                            ))
                                                                        ) : null}

                                                                        {criticalElevatedPermitMenu?.length > 0 && criticalElevatedPermitMenu !== null && criticalElevatedPermitMenu !== undefined ? (
                                                                            criticalElevatedPermitMenu.map((item, index) => (
                                                                                <div className="row mt-2" key={index}>
                                                                                    <Link
                                                                                        className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                        style={{
                                                                                            height: "50px",
                                                                                            backgroundColor: "#566060",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Critical Elevated Work
                                                                                    </Link>
                                                                                </div>
                                                                            ))
                                                                        ) : null}

                                                                        {generalHotWorkPermitMenu?.length > 0 && generalHotWorkPermitMenu !== null && generalHotWorkPermitMenu !== undefined ? (
                                                                            generalHotWorkPermitMenu.map((item, index) => (
                                                                                <div className="row mt-2" key={index}>
                                                                                    <Link
                                                                                        className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                        style={{
                                                                                            height: "50px",
                                                                                            backgroundColor: "#34B85F",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                        }}
                                                                                    >
                                                                                        Hot Work Permit
                                                                                    </Link>
                                                                                </div>
                                                                            ))
                                                                        ) : null}
                                                                    </div>
                                                                }
                                                                bordercolor="#2CC743"
                                                            />
                                                        </button>
                                                    ))
                                                ) : null}

                                            </div>

                                            <button
                                                type="button"
                                                onClick={handleTransferTo}
                                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white 
                                             ${"custom-rounded"}`}
                                                style={{
                                                    height: "50px",
                                                    backgroundColor: "#FFA200",
                                                    color: "white",
                                                    border: "none",
                                                    marginTop: "10px",
                                                    visibility: "hidden",
                                                }}
                                            >
                                                Transfer
                                            </button>

                                            {/* Modals */}
                                            <div>
                                                <Modal
                                                    show={showTransfer}
                                                    onHide={() => setShowTransfer(false)}
                                                    size="sm"
                                                    centered
                                                >
                                                    <Modal.Body>
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                marginBottom: "20px",
                                                            }}
                                                        >
                                                            <h3> Transfer to </h3>
                                                        </p>
                                                        <div className="form-group">
                                                            <label htmlFor="name">Name:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                                value={name}
                                                                onChange={(e) => {
                                                                    setName(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="mobileNumber">
                                                                Mobile Number:
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="mobileNumber"
                                                                value={mobileNumber}
                                                                onChange={(e) => {
                                                                    setMobileNumber(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ border: "0" }}>
                                                        <div
                                                            className="d-flex"
                                                            style={{
                                                                width: "100%",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                variant="primary btn-lg w-100"
                                                                onClick={handleTransferToOk}
                                                            >
                                                                Ok
                                                            </Button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>

                                            <div>
                                                <Modal
                                                    show={showApproved}
                                                    onHide={() => setShowApproved(false)}
                                                    size="sm"
                                                    id="nsmodel"
                                                    className="ppopup"
                                                >
                                                    <Modal.Body>
                                                        <div>
                                                            <div className="row">
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "15vh",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Approved}
                                                                            alt="save"
                                                                            className="popupicons"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "3vh",
                                                                    }}
                                                                >
                                                                    <h5>Core Permit approved successfully</h5>
                                                                    <h5>{apiStatus}</h5>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        margin: "0px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="primary"
                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                        style={{
                                                                            height: "43px",
                                                                            backgroundColor: "#007AFF",
                                                                            color: "white",
                                                                            border: "none",
                                                                        }}
                                                                        onClick={handleAprrovedOk}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>

                                            {/* Suspend */}
                                            <div>
                                                <Modal
                                                    show={suspend}
                                                    onHide={() => setSuspend(false)}
                                                    size="sm"
                                                    id="nsmodel"
                                                    className="ppopup"
                                                >
                                                    <Modal.Header className="no-border">
                                                        <Button
                                                            variant="link"
                                                            className="close btn btn-danger close_btn"
                                                            onClick={handleCancel}
                                                        >
                                                            X
                                                        </Button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div>
                                                            <div className="row">
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "15vh",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Reject}
                                                                            alt="save"
                                                                            className="popupicons"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "3vh",
                                                                    }}
                                                                >
                                                                    <h5>Are you sure want to Suspend?</h5>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="reason">
                                                                        <h5>Reason:</h5>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="reason"
                                                                        placeholder="Reason for Suspend"
                                                                        value={suspendReason}
                                                                        onChange={(e) => setSuspendReason(e.target.value)}
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "8vh",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="primary"
                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                        style={{
                                                                            height: "43px",
                                                                            backgroundColor: "#007AFF",
                                                                            color: "white",
                                                                            border: "none",
                                                                        }}
                                                                        onClick={handleSuspendOk}
                                                                        disabled={suspendReason.trim() === ""}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </p>
                                                            </div>

                                                            {/* Reject */}
                                                            <Modal
                                                                show={showNewModal}
                                                                onHide={() => setShowNewModal(false)}
                                                                size="sm"
                                                                id="newModal"
                                                                className="ppopup"
                                                            >

                                                                <Modal.Body>
                                                                    <div className="row">
                                                                        <div>
                                                                            <span
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "15vh",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Approved}
                                                                                    alt="save"
                                                                                    className="popupicons"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "3vh",
                                                                                    color: "green",
                                                                                }}
                                                                            >
                                                                                <h5>
                                                                                    CorePermit Rejected Successfully
                                                                                </h5>
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "3vh",
                                                                                }}
                                                                            >
                                                                                <h5>{apiStatus}</h5>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={handleNewModalOk}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </div>
                                                    </Modal.Body>

                                                </Modal>

                                                {/* Confirmation Popup for Suspend */}
                                                <Modal
                                                    show={showConfirmationSuspendPopup}
                                                    onHide={() => setShowConfirmationSuspendPopup(false)}
                                                    size="sm"
                                                    id="nsmodel"
                                                    className="ppopup"
                                                >
                                                    <Modal.Body>
                                                        <div className="row">
                                                            <div>
                                                                <span
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "13vh",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={Reject}
                                                                        alt="save"
                                                                        className="popupicons"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <p
                                                                style={{
                                                                    textAlign: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "3vh",
                                                                    color: "green",
                                                                }}
                                                            >
                                                                <h5 style={{ textAlign: "center" }}>
                                                                    CorePermit Suspended Successfully
                                                                </h5>
                                                            </p>
                                                        </div>
                                                        <Modal.Footer style={{ border: "0" }}>
                                                            <div
                                                                className="d-flex"
                                                                style={{
                                                                    width: "100%",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="primary"
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                    style={{
                                                                        height: "43px",
                                                                        backgroundColor: "#007AFF",
                                                                        color: "white",
                                                                        border: "none",
                                                                    }}
                                                                    onClick={handleConfirmationSuspendOk}
                                                                >
                                                                    Ok
                                                                </Button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>

                                            {/* Reject */}

                                            <div>
                                                <Modal
                                                    show={showReject}
                                                    onHide={() => setShowReject(false)}
                                                    size="sm"
                                                    id="nsmodel"
                                                    className="ppopup"
                                                >

                                                    <Modal.Header className="no-border">
                                                        <Button
                                                            variant="link"
                                                            className="close btn btn-danger close_btn"
                                                            onClick={handleCancelReject}
                                                        >
                                                            X
                                                        </Button>
                                                    </Modal.Header>

                                                    <Modal.Body>
                                                        <div>
                                                            <div className="row">
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "15vh",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Reject}
                                                                            alt="save"
                                                                            className="popupicons"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "3vh",
                                                                    }}
                                                                >
                                                                    <h5>Are you sure want to Reject?</h5>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="name">
                                                                        <h5>Reason:</h5>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="name"
                                                                        placeholder="Reason for Rejection"
                                                                        value={remarks}
                                                                        onChange={(e) => {
                                                                            setRemarks(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "8vh",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="primary"
                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                        style={{
                                                                            height: "43px",
                                                                            backgroundColor: "#007AFF",
                                                                            color: "white",
                                                                            border: "none",
                                                                        }}
                                                                        onClick={handleRejectOk}
                                                                        disabled={remarks.trim() === ""}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                            <Modal
                                                                show={showNewModal}
                                                                onHide={() => setShowNewModal(false)}
                                                                size="sm"
                                                                id="newModal"
                                                                className="ppopup"
                                                            >
                                                                <Modal.Body>
                                                                    <div className="row">
                                                                        <div>
                                                                            <span
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "15vh",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Approved}
                                                                                    alt="save"
                                                                                    className="popupicons"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "3vh",
                                                                                    color: "green",
                                                                                }}
                                                                            >
                                                                                <h5>
                                                                                    CorePermit Rejected Successfully
                                                                                </h5>
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "3vh",
                                                                                }}
                                                                            >
                                                                                <h5>{apiStatus}</h5>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={handleNewModalOk}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </Modal.Body>
                                                            </Modal>
                                                        </div>
                                                    </Modal.Body>

                                                </Modal>

                                                <Modal
                                                    show={showConfirmationPopup}
                                                    onHide={() => setShowConfirmationPopup(false)}
                                                    size="sm"
                                                    id="nsmodel"
                                                    className="ppopup"
                                                >
                                                    <Modal.Body>
                                                        <div className="row">
                                                            <div>
                                                                <span
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "13vh",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={Reject}
                                                                        alt="save"
                                                                        className="popupicons"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <p
                                                                style={{
                                                                    textAlign: "center",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "3vh",
                                                                    color: "green",
                                                                }}
                                                            >
                                                                <h5 style={{ textAlign: "center" }}>
                                                                    CorePermit Rejected Successfully
                                                                </h5>
                                                            </p>
                                                        </div>
                                                        <Modal.Footer style={{ border: "0" }}>
                                                            <div
                                                                className="d-flex"
                                                                style={{
                                                                    width: "100%",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="primary"
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                    style={{
                                                                        height: "43px",
                                                                        backgroundColor: "#007AFF",
                                                                        color: "white",
                                                                        border: "none",
                                                                    }}
                                                                    onClick={handleConfirmationOk}
                                                                >
                                                                    Ok
                                                                </Button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>

                                            <div>
                                                <Modal
                                                    show={showModal}
                                                    onHide={() => setShowModal(false)}
                                                    centered={true}
                                                    size="sm"
                                                    id="nsmodel"
                                                >
                                                    <Modal.Body>
                                                        <div>
                                                            <div className="row">
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "15vh",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Approved}
                                                                            alt="save"
                                                                            className="popupicons"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            textAlign: "center",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "3vh",
                                                                        }}
                                                                    >
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "3vh",
                                                                    }}
                                                                >
                                                                    <h5>{apiStatus}</h5>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        margin: "0px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="primary"
                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                        style={{
                                                                            backgroundColor: "#007AFF",
                                                                            color: "white",
                                                                            border: "none",
                                                                        }}
                                                                        onClick={() => {
                                                                            if (window.ReactNativeWebView) {
                                                                                window.ReactNativeWebView.postMessage(
                                                                                    "GoBack"
                                                                                );
                                                                            }
                                                                            setShowModal(false);
                                                                        }}
                                                                    >
                                                                        Ok
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                                {/*  Clseout end */}
                            </div>
                            {/* <Footer /> */}
                            {/* ... End of Footer...  */}
                        </div>
                    </div>
                    {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>
                    {/*  <!-- Logout Modal--> */}
                    {/* <Logout /> */}
                </div>
            )}
            {isLoading && (
                <Loader_Bulk
                    loading={isLoading}
                    // message={"Fetching Data. Please Wait..!"}
                    message={"Processing your request. This may take a moment..!"}
                    loderimage={loaderImage}
                />
            )}
        </body>
    );
};

export default CorePermitM2Acceptor;
