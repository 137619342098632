
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../Pages/SideMenu';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Logout from '../Pages/Logout';
import * as XLSX from 'xlsx';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../Utilities/utils';
import { rmGetmasters, reportForRMTracking } from '../Utilities/URLCONSTANTS';
import { GetApiHeaders } from '../Utilities/NetworkUtilities';
import Loader_Bulk from '../Utilities/Loader_Bulk';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MultiSelect } from "react-multi-select-component";

//ProductMaster

const DailyWorkAllocationReport = ({ }) => {
    const [loaderImage, setLoaderImage] = useState(require('../images/rmloader.gif'));
    const [isLoading, setIsLoading] = useState(false);
    const [searchTermOption, setSearchTermOption] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [todayDate, settodayDate] = useState(new Date());
    const [getData, setGetData] = useState([])
    const [generalWork, setGeneralWork] = useState([]);

    //Validations
    const [errgeneralWork, setErrGeneralWork] = useState('')
    const [errLocation, setErrLocation] = useState("");
    const [errSubLocation, setErrSubLocation] = useState("");
    const [errEquipment, setErrEquipment] = useState("");

    const [subLocation, setSubLocation] = useState([]);
    const [locationData, setlocationData] = useState("");
    const [sublocationData, setsublocationData] = useState("");
    const [locatinBasedsubLocation, setlocatinBasedsubLocation] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // For filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState();
    const [wmy, setwmy] = useState('');
    const [location, setLocation] = useState([]);
    const [WeekSelected, setWeekSelected] = useState('no');
    const [monthSelected, setmonthSelected] = useState('no');
    const [yearSelected, setYearSelected] = useState('no');
    const [allSelected, setAllSelected] = useState('yes');
    const [getEquipments, setGetEquipments] = useState([]);
    const [getEquipmentsArray, setGetEquipmentsArray] = useState([]);
    const [equipmentName, setEquipmentName] = useState()
    const [thCount, setThCount] = useState(0);
    const [locationName, setLocationName] = useState("");


    const handleFilter = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (data != null && data != undefined) {
            const filteredResults = data.filter((item) =>

                (item.empId &&
                    item.empId
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.role &&
                    item.role
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.emailId &&
                    item.emailId
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.mainFunction &&
                    item.mainFunction
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.subFunction &&
                    item.subFunction
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.costcenter &&
                    item.costcenter
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.reportingManagerName &&
                    item.reportingManagerName
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.functionalLead &&
                    item.functionalLead
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) ||
                (item.date && item.date.includes(searchTerm)) ||
                (item.location && item.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.workLogSubLocation && item.workLogSubLocation.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.generalList && item.generalList.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.corePermitRequired && item.corePermitRequired.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipment && item.equipment.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.assignedBy && item.assignedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.assignedTo && item.assignedTo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.workAssignedDate && item.workAssignedDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipmentCondition && item.equipmentCondition.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipmentConditionReason && item.equipmentConditionReason.toLowerCase().includes(searchTerm.toLowerCase())) ||

                (item.technicianShiftName && item.technicianShiftName.toLowerCase().includes(searchTerm.toLowerCase())) ||

                (item.workCompletedDate && item.workCompletedDate.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
            handlePageClick(1)
        }
    };

    useEffect(() => {
        if (data != null && data != undefined) {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();
            var maxDate = year + '-' + month + '-' + day;
            setFilteredData(data);
            setTotalItems(data.length);

        }
    }, [data]);

    const tableId = 'Content_data_table'; // Replace 'yourTableId' with the actual id of your table
    useEffect(() => {
        const table = document.getElementById(tableId);
        if (table) {
            const theadRow = table.querySelector('thead tr');
            const thCount = theadRow ? theadRow.querySelectorAll('th').length : 0;
            setThCount(thCount);
        } else {
            console.warn(`Table with id '${tableId}' not found.`);
        }
    }, [tableId]); // Run this effect only once when the component mounts

    const handlePrint = () => {
        const printWindow = window.open();
        const printContent = document.querySelector('.print-table');
        // Check if the printContent is available
        if (printContent) {
            printWindow.document.write('<html><head>' +
                '<meta charset="utf-8" />' +
                '<link rel="icon" href="/favicon.ico" />' +
                '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
                '<meta name="theme-color" content="#000000" />' +
                '<meta name="description" content="Web site created using create-react-app" />' +
                '<title>MCRC</title>' +
                '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
                '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
                '<script defer src="/static/js/bundle.js"></script>' +
                '<style>' +
                '@media print {' +
                '  table {' +
                '    border-collapse: collapse;' +
                '    width: 100%;' +
                '    margin-bottom: 10px;' +
                '  }' +
                '  th, td {' +
                '    border: 1px solid #ddd;' +
                '    text-align: left;' +
                '  }' +
                '}' +
                '</style>' +
                '</head><body>');

            printWindow.document.write(printContent.innerHTML);
            printWindow.document.write('</body></html>');

            // Delay the print function to ensure styles are applied
            printWindow.setTimeout(() => {
                printWindow.print();
                printWindow.close();
            });
        } else {
            // Handle the case where printContent is not found  
            alert("print is not available");
        }
    }

    let commaSeparatedString;
    if (Array.isArray(subLocation)) {
        commaSeparatedString = subLocation.join(',');
    } else if (typeof subLocation === 'string') {
        commaSeparatedString = subLocation;
    } else {
        commaSeparatedString = ''; // or any default value you want
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to the first page when changing items per page.
    };


    const pageNumbers = [];
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    const exportToExcel = () => {
        const data = [
            ['S.No',
                "Emp-ID",
                "Role",
                "Email-ID",
                "Main Function",
                "Sub Function",
                "Cost Center",
                "Reporting Manager Name",
                "Functional Lead",
                'Date',
                'Site',
                'Location',
                'General List',
                'Core Permit Required',
                'Equipment',
                'Assign BY',
                'Technician Name',
                'Work Assigned Date',
                'Equipment Condition',
                'Equipment Condition Reason',
                'Technician ShiftName',
                'Work completed date',
                'Remarks'
            ], // Custom headers
            // ...currentItems.map((item) => [
            ...filteredData.map((item, index) => [
                index + 1,
                item.empId,
                item.role,
                item.emailId,
                item.mainFunction,
                item.subFunction,
                item.costcenter,
                item.reportingManagerName,
                item.functionalLead,
                item.date,
                item.location,
                item.subLocation,
                item.generalList,
                item.corePermitRequired,
                item.equipment,
                item.assignedBy,
                item.assignedTo,
                item.workAssignedDate,
                item.equipmentCondition,
                item.equipmentConditionReason,
                item.technicianShiftName,
                item.workCompletedDate,
                item.remarks
            ]),
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Daily work Allocation Report');
        // Save the file
        XLSX.writeFile(wb, 'Daily work Allocation Report.xlsx');
    };


    const handleLocation = async (selectedOptions) => {
        const subLocationsArray = [];
        setLocation(selectedOptions);

        // Extract selected location names
        const selectedLocationNames = selectedOptions.map((option) => option.label);
        setLocationName(selectedLocationNames.join(", "));

        if (selectedOptions.length > 0) {
            // Filter sub-location data based on selected options
            selectedOptions.forEach((selectedOption) => {
                sublocationData.forEach((item) => {
                    if (item.locationId === parseInt(selectedOption.value)) {
                        subLocationsArray.push(item);
                    }
                });
            });
            setFilteredLocations(subLocationsArray);
            setErrLocation("");
            console.log("Filtered Locations:", subLocationsArray);
        } else {
            setErrLocation("Select Location");
            setFilteredLocations([]);
        }
    };

    const optionsLocation = getData.workLogLocation
        ? getData.workLogLocation.map((item) => ({
            label: item.name,
            value: item.code,
        }))
        : [];



    useEffect(() => {
        const filtered = locatinBasedsubLocation.filter(item =>
            item.name.toLowerCase().includes(searchTermOption.toLowerCase())
        );
        setFilteredLocations(filtered);
    }, [searchTermOption, locatinBasedsubLocation]);


    const subLocationOptions = filteredLocations.map((item) => ({
        label: item.name, 
        value: item.code, 
        subLocationName: item.name,
    }));


    const handleSubLocation = (selectedOption) => {
        setSubLocation(selectedOption); 
        setErrSubLocation(""); 
    };


    const equipmentOptions = getEquipmentsArray.map((item) => ({
        label: item.name, 
        value: item.code, 
        equipmentName: item.name, 
    }));

   
    const handleEquipment = (selectedOptions) => {
        setEquipment(selectedOptions); 
        setErrEquipment(""); 
        setEquipmentName(selectedOptions.map((opt) => opt.equipmentName)); 
    };

    const handleWMYChange = (e) => {
        $('.date_show_hide').hide();
        let chk_val = (e.target.value);
        setwmy(chk_val);
        if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') {
            setGeneralWork([])
            setLocation([])
            setSubLocation([])
            setEquipment([])
            setStartDate(new Date());
            setEndDate(new Date());
        }
        if (chk_val == 'W') {
            setWeekSelected('yes');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('no');
            setwmy('W')
        }
        else if (chk_val == 'M') {
            setWeekSelected('no');
            setmonthSelected('yes');
            setYearSelected('no');
            setAllSelected('no');
            setwmy('M')
        }
        else if (chk_val == 'Y') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('yes');
            setAllSelected('no');
            setwmy('Y')
        }
        else if (chk_val == 'All') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('yes');
            $('.date_show_hide').show();
            setwmy("");
        }
    }

    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable
        $(tableRef.current).DataTable({
            ordering: false, // Disable sorting
        });
        // fetchData();
        getMasterData()
    }, []);
    const getMasterData = async () => {
        const getData = rmGetmasters
        const headers = await GetApiHeaders();
        try {
            const responseGetData = await axios.get(getData, { headers })
            setGetData(responseGetData.data.response)
            setGetEquipmentsArray(responseGetData.data.response.workLogEquipment)
            setlocationData(responseGetData.data.response.workLogLocation);
            setsublocationData(responseGetData.data.response.workLogSubLocation);
            setGetEquipments(responseGetData.data.response.workLogEquipment);

        } catch (err) {
            console.log("getError", err)
        }
    }

    console.log(getData, "getData");


    // Options for MultiSelect
    const options = getData.generalWorksList
        ? getData.generalWorksList.map((item) => ({
            label: item.name,
            value: item.code,
        }))
        : [];

    // Handle Change for MultiSelect
    const handleGeneralList = (selected) => {
        setGeneralWork(selected);
        if (selected.length === 0) {
            setErrGeneralWork("Please select at least one item.");
        } else {
            setErrGeneralWork("");
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        let isValid = true; // Flag to track validation status

        if (!generalWork || generalWork.length === 0) {
            setErrGeneralWork("Please Select Type Of Work");
            isValid = false;
        }

        if (!location || location.length === 0) {
            setErrLocation("Please Select Location");
            isValid = false;
        }

        if (!subLocation || subLocation.length === 0) {
            setErrSubLocation("Please Select SubLocation");
            isValid = false;
        }

        if (!equipment || equipment.length === 0) {
            setErrEquipment("Please Select Equipment");
            isValid = false;
        }

        if (!isValid) return; // Stop form submission if validation fails

        // Format start and end date
        let formattedStartDate = startDate ? startDate.toISOString().split("T")[0] : "";
        let formattedEndDate = endDate ? endDate.toISOString().split("T")[0] : "";

        // Ensure locationName, subLocationName, generalWork, and equipmentName are arrays
        const locationString = Array.isArray(locationName) ? locationName.join(", ") : locationName;
        const equipmentString = Array.isArray(equipmentName) ? equipmentName.join(", ") : equipmentName;
        const subLocationString = Array.isArray(subLocation) ? subLocation.map(item => item.subLocationName || item.label).join(", ") : subLocation;
        const generalWorkString = Array.isArray(generalWork) ? generalWork.map(item => item.name || item.label).join(", ") : generalWork;

        const reqObj = {
            WMY: wmy,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            location: locationString,
            subLocation: subLocationString,
            reportType: "dailyWork",
            generalList: generalWorkString,
            equipment: equipmentString
        };

        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);

        try {
            setIsLoading(true);
            const res = await axios.post(fetchApi, reqObj, { headers });
            const worklog = res?.data?.response?.dailyWork || [];

            setData(worklog); // Set data, even if it's empty
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };



    const Sendmail = async (event) => {
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "permitType": "",
            "location": location,
            "subLocation": subLocation,
            "typeOfWork": "",
            "reportType": "dailyWork",
            "generalList": generalWork,
            "equipment": equipmentName
        }
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        setIsLoading(true);
        axios.post(fetchApi, reqObj, { headers }).then((response) => {
            setIsLoading(false);
            if (response.data.response == "undefined" || response.data.response == "") {
                setData([]);
            }
            else {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: 'Your work has been saved.',
                    confirmButtonColor: '#007aff'
                });
            }

        })
    }

    function resetData() {
        setwmy("w");
        setlocatinBasedsubLocation([]);
        setGeneralWork([]);
        setLocation([]);
        setEquipment([]);
        setSubLocation([]);
        setStartDate(new Date());
        setEndDate(new Date());
        settodayDate(new Date());
        // fetchData();
        setTimeout(() => {
            setErrGeneralWork('')
            setErrLocation('')
        }, 100)
        window.location.reload()
    }

    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

    const sortedItems = [...currentItems].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            // Check if values are numeric
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
            }

            // Fallback for string comparison
            const aString = aValue?.toString().toLowerCase();
            const bString = bValue?.toString().toLowerCase();
            if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
            if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        }
        return 0;
    });
    // Handle column sorting
    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
        }));
    };



    return (
        <body id="page-top">
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <SideMenu />
                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">
                        {/*  <!-- Topbar --> */}
                        <Header />
                        {/* ...Content Start... */}
                        <div>
                            <div className='container-fluid' style={{ backgroundColor: "#F7F8FA" }}>
                                <div className='card border-0' style={{ backgroundColor: "transparent" }}>
                                    <div className='card-body pt-4 pl-1'>
                                        <h5 className='Roles_text'>Daily Work Allocation Report</h5>
                                        <div className='mt-4'>
                                            <div className='d-flex gap-1 status_card'>
                                                <div className='card flex-grow-1' style={{
                                                    border: '1px solid #0000001A',
                                                    boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                                }}>
                                                    <div className='card-body' style={{ padding: "20px 20px" }}>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-12'>
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >Select Date Duration      </label>

                                                                    <div className="btn-group" id='filter-btn' role="group" aria-label="Basic radio toggle button group" onChange={handleWMYChange}>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={WeekSelected === 'yes'} value="W" />
                                                                        <label className="btn btn-outline-primary" for="btnradio1">Week</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={monthSelected === 'yes'} value="M" />
                                                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={yearSelected === 'yes'} value="Y" />
                                                                        <label className="btn btn-outline-primary" for="btnradio3">Year</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={allSelected === 'yes'} value="All" />
                                                                        <label className="btn btn-outline-primary" for="btnradio4">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 col-12'   >
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >Type Of Work </label>


                                                                    <MultiSelect
                                                                        options={options}
                                                                        value={generalWork}
                                                                        onChange={handleGeneralList}
                                                                        labelledBy="Select Type Of Work"
                                                                    />

                                                                    <span style={{ color: 'red' }}>{errgeneralWork}</span>
                                                                </div>
                                                                <div className='col-md-3 col-12'
                                                                >
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >Location  </label>
                                                                    <MultiSelect
                                                                        options={optionsLocation}
                                                                        value={location}
                                                                        onChange={handleLocation}
                                                                        labelledBy="Select Location"
                                                                    />
                                                                    <span style={{ color: 'red' }}>{errLocation}</span>
                                                                </div>

                                                                <div className='col-md-3 col-12' style={{ position: 'relative' }}>
                                                                    <label className="form-label font-weight-bold mt-1"
                                                                    >Sub Location  </label>



                                                                    <MultiSelect
                                                                        options={subLocationOptions}
                                                                        value={subLocation}
                                                                        onChange={handleSubLocation}
                                                                        labelledBy="Select Sub Location"
                                                                    />
                                                                    <span style={{ color: 'red' }}>{errSubLocation}</span>
                                                                </div>

                                                                <div className='col-md-3 col-12'
                                                                    style={{ marginLeft: "25%", marginBottom: "40px" }}
                                                                >
                                                                    <label className="form-label font-weight-bold mt-1"
                                                                    >Equipment  </label>

                                                                    <MultiSelect
                                                                        options={equipmentOptions}
                                                                        value={equipment}
                                                                        onChange={handleEquipment}
                                                                        labelledBy="Select Equipment"
                                                                    />
                                                                    <span style={{ color: 'red' }}>{errEquipment}</span>

                                                                </div>
                                                                <div className='col-sm-3 col-12 date_show_hide'  >
                                                                    <div class="mb-3">
                                                                        <label for="startdate"
                                                                            className="form-label font-weight-bold mt-1"

                                                                        >From Date</label>
                                                                        <DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select Start Date" selected={startDate}
                                                                            onChange={(date) => { setStartDate(date) }}
                                                                            maxDate={endDate} dateFormat="dd-MMM-yyyy"
                                                                            id="activitystartdate" name="activitystartdate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-3 col-12 date_show_hide'
                                                                    style={{ marginBottom: "40px" }}>
                                                                    <div class="mb-3">
                                                                        <label
                                                                            className="form-label font-weight-bold mt-1"
                                                                            for="enddate">To Date</label>
                                                                        <span><DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select End Date" selected={endDate}
                                                                            onChange={(date) => setEndDate(date)} dateFormat="dd-MMM-yyyy"
                                                                            minDate={startDate} maxDate={todayDate} id="activityenddate"
                                                                            name="activityenddate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-2 d-flex w-full justify-content-center gap-2'>
                                                                <button class="btn btn-primary mr-0"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    type='submit'>Apply</button>

                                                                <button class="btn btn-danger"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={resetData}>Clear</button>

                                                                <button
                                                                    disabled
                                                                    className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={Sendmail}>Mail</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='card flex-grow-1 mt-2' style={{
                                            border: '1px solid #0000001A',
                                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                        }}>
                                            <div className='card-body' style={{ padding: "10px 10px" }}>

                                                <div className="d-flex justify-content-between align-items-center"
                                                    style={{ paddingLeft: 25, paddingBottom: 20 }}>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                                                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            {/* Add more options as needed */}
                                                        </select>&nbsp;&nbsp;
                                                        <label>{' '} Entries</label>
                                                    </div>

                                                    <div className="d-flex align-items-center" style={{ marginleft: '5%' }}>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                type="text"
                                                                placeholder="🔍 Type to Search..."
                                                                value={searchTerm}
                                                                onChange={(e) => handleFilter(e.target.value)}
                                                                style={{
                                                                    height: "36px",
                                                                    width: "160px",
                                                                    paddingRight: "30px",
                                                                }}
                                                                className="mr-2"
                                                            />
                                                            {searchTerm && (
                                                                <button
                                                                    className="btn btn-link"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "50%",
                                                                        right: "10px",
                                                                        transform: "translateY(-50%)",
                                                                        zIndex: 1,
                                                                    }}
                                                                    onClick={() => handleFilter("")}
                                                                >
                                                                    <i className="fas fa-times"></i>
                                                                </button>
                                                            )}
                                                        </div>  &nbsp;
                                                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                                                            <button className="btn btn-secondary buttons-excel buttons-html5"
                                                                onClick={exportToExcel}>
                                                                Download Excel
                                                            </button>
                                                            {/* <button
                                                                className="btn btn-primary"
                                                                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                                                                onClick={handlePrint}
                                                            >
                                                                Print
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="print-table"
                                                    style={{ overflowX: 'auto' }}>
                                                    <Table className="table table-bordered Content_data_table table-striped" id="Content_data_table"  >

                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>S.No</th>


                                                                <th className="text-center" onClick={() => handleSort("empId")}>
                                                                    Employee ID
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "empId"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("role")}>
                                                                    Role
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "role"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("emailId")}>
                                                                    Email-ID
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "emailId"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("mainFunction")}>
                                                                    Main Function
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "mainFunction"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("subFunction")}>
                                                                    Sub Function
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "subFunction"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("costcenter")}>
                                                                    Cost Center
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "costcenter"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("reportingManagerName")}>
                                                                    Reporting Manager-Name
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "reportingManagerName"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("functionalLead")}>
                                                                    Functional-Lead
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "functionalLead"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity ml-2`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("date")}>
                                                                    Date{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "date"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("location")}>
                                                                    Site{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "location"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("subLocation")}>
                                                                    Location{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "subLocation"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("generalList")}>
                                                                    Type Of Work{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "generalList"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("corePermitRequired")}>
                                                                    Core Permit Required {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "corePermitRequired"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("equipment")}>
                                                                    Equipment{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "equipment"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("assignedBy")}>
                                                                    Assign BY {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "assignedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("assignedTo")}>
                                                                    Technician Name {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "assignedTo"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("workAssignedDate")}>
                                                                    Work Assigned Date {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "workAssignedDate"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("equipmentCondition")}>
                                                                    Equipment Condition {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "equipmentCondition"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("equipmentConditionReason")}>
                                                                    Equipment Condition Reason {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "equipmentConditionReason"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("technicianShiftName")}>
                                                                    Technician ShiftName {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "technicianShiftName"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("workCompletedDate")}>
                                                                    Work Completed Date {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "workCompletedDate"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("remarks")}>
                                                                    Remarks {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "remarks"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {currentItems && currentItems.length > 0 ? (
                                                                sortedItems.map((item, index) => (
                                                                    <React.Fragment >
                                                                        <tr>
                                                                            <td className='text-center'  >  {(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                            <td className='text-left'>{item.empId}</td>
                                                                            <td className='text-left'>{item.role}</td>
                                                                            <td className='text-left'>{item.emailId}</td>
                                                                            <td className='text-left'>{item.mainFunction}</td>
                                                                            <td className='text-left'>{item.subFunction}</td>
                                                                            <td className='text-left'>{item.costcenter}</td>
                                                                            <td className='text-left'>{item.reportingManagerName}</td>
                                                                            <td className='text-left'>{item.functionalLead}</td>
                                                                            <td className='text-left'>{item.date}</td>
                                                                            <td className='text-left'>{item.location}</td>
                                                                            <td className='text-left'>{item.subLocation}</td>
                                                                            <td className='text-left'>{item.generalList}</td>
                                                                            <td className='text-left'>{item.corePermitRequired}</td>
                                                                            <td className='text-left'>{item.equipment}</td>
                                                                            <td className='text-left'>{item.assignedBy}</td>
                                                                            <td className='text-right'>{item.assignedTo}</td>
                                                                            <td className='text-left'>{item.workAssignedDate}</td>
                                                                            <td className='text-left'>{item.equipmentCondition}</td>
                                                                            <td className='text-left'>{item.equipmentConditionReason}</td>

                                                                            <td className='text-right'>{item.technicianShiftName}</td>

                                                                            <td className='text-right'>{item.workCompletedDate}</td>
                                                                            <td className='text-right'>{item.remarks}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={thCount} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>

                                                <div className='py-3'>
                                                    <div className='card data_tableCard'>
                                                        <div className='card-body'>

                                                            {/* Pagination */}

                                                            <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                                                <div>
                                                                    Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                                                                    &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                                                                    of {totalItems} entries
                                                                </div>
                                                                <div>
                                                                    <div className="div-button text-center" >
                                                                        <button
                                                                            className='borderleft-radius-5'
                                                                            // className='ul-pagination'
                                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            Previous
                                                                        </button>


                                                                        <button className='ul-pagination page-item'
                                                                            style={{
                                                                                backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                                                                color: (currentPage == 1) ? 'white' : 'black'
                                                                            }}
                                                                            key={1}
                                                                            onClick={() => handlePageClick(1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            {1}

                                                                        </button>
                                                                        {pageNumbers.map((number) => (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == number) ? 'white' : 'black'
                                                                                }}
                                                                                key={number}
                                                                                onClick={() => handlePageClick(number)}
                                                                                disabled={currentPage === number}
                                                                            >
                                                                                {number}
                                                                            </button>
                                                                        ))}
                                                                        {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: 'white',
                                                                                    color: 'black'
                                                                                }}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                        {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                                                                }}
                                                                                key={(Math.ceil(totalItems / itemsPerPage))}
                                                                                onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                                                                disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                                                            >
                                                                                {(Math.ceil(totalItems / itemsPerPage))}

                                                                            </button>
                                                                        }
                                                                        <button className='borderright-radius-5'
                                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                                            disabled={indexOfLastItem >= totalItems}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader_Bulk loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}
                    </div>
                    {/* ...End of Main Content...*/}
                    {/* ...Footer...*/}
                    <Footer />
                    {/* ... End of Footer...  */}
                </div>
            </div>
            {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            <Logout />
            {/* {!isUserLoggedIn && <Login />} */}
        </body>
    )
}

export default DailyWorkAllocationReport;
