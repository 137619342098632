import React, { useState, useEffect } from "react";
import "datatables.net";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getCorePermitFormByNumber,
  saveCorePermitForm,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  coreChemicalConstant,
  coreEmergencyConst,
  coreEnergyConstant,
  environMentConstants,
  CloseOutConstants,
  changeConstants,
  responsibilitites,
  corePermitReceiverConstants,
  trainingConstants,
  coreEquipmentConstant,
  corePeopleConstant,
  coreProtectionConstant,
  coreWorkEnvConstant,
} from "../Utilities/corePermit.constant";
import CorePermitM2People from "../components/corePermitM2/CorePermitM2People";
import CorePermitEmergency from "../components/corePermitM2/corePermitEmergency";
import CorePermitM2Energy from "../components/corePermitM2/CorePermitM2Energy";
import CorePermitM2Equipment from "../components/corePermitM2/CorePermitM2Equipment";
import CorePermitM2Chemical from "../components/corePermitM2/CorePermitM2Chemical";
import CorePermitM2Protection from "../components/corePermitM2/CorePermitM2Protection";
import CorePermitM2Work from "../components/corePermitM2/CorePermitM2Work";
import CorePermitEnvironment from "../components/corePermitM2/CorePermitEnvironment";
import CorePermitCloseOut from "../components/corePermitM2/corePermitCloseOut";
import CorePermitChange from "../components/corePermitM2/corePermitChange";
import CorePermitResponsibilities from "../components/corePermitM2/corePermitResponsibilities";
import CorePermitReceiver from "../components/corePermitM2/corePermitReceiver";
import CorePermitTraining from "../components/corePermitM2/corePermitTraining";
// import CorePermitInputFields from "../components/corePermitM2/corePermitInputFields";
import CorePermitInputFields from "../components/corePermitM2/corePermitInputFields";

import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  COREPERMIT_VENDOR_LOGINID,
  CorePermitM2_PERMITID,
  MAINSITE_ID,
  MAINSITE_NAME,
  MOBILE_NUMBER,
  SUBSITE_ID,
  SUBSITE_NAME,
  USER_PROFILE,
  checkForEmptyValues,
  retrieveData,
} from "../Utils";
import moment from "moment";
import "../RandD.css";

import arrowIcon from "../images/back-arrow-white.png";
import Accordion from "../Accordation/Accordian";

import Circle from "../images/CircleAccordianImg.png";

//people-images
import teachpeople from "../images/Teach-People-CorePermit.png";
import stepbysteppeople from "../images/Step-by-step-People-Corepermit.png";
import stalline from "../images/Stalline-People-Corepermit.png";
//environment-images
import envi1 from "../images/Window-man-enivron-corepermit.png";
import suit from "../images/suit-man-envi-corepermit.png";
import globe from "../images/Hand-glob-envi.png";
//Energy -images
import FireImg from "../images/FireAccordionImg.png";
import Thunder from "../images/Thunder.png";
import water from "../images/waterdrop-energy-corepermit.png";
//Equipment-Images
import Car from "../images/Car-euip-corepermit.png";
import cropmachine from "../images/cropmachine-euip-corepermit.png";
import van from "../images/Van-equip-corepermit.png";
//Chemical -Images
import spraytracktor from "../images/spray-tractor-chem-corepermit.png";
import buerate from "../images/Buret-chem-corepermit.png";
import tine from "../images/tin-chem-corepermit.png";
//Change-images
import couple from "../images/couple-change-corepermit.png";
import cloudthunder from "../images/cloud-thunder-change-corepermit.png";
import chart from "../images/chartlist-change-corepermit.png";
import sun from "../images/sun-change-corepermit.png";
import wave from "../images/Wave-change-corepermit.png";

//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";
//loader img
import Loader_Bulk from "../Utilities/Loader_Bulk";
import Swal from "sweetalert2";

//Approve
import Approved from "../images/Approved.png";
const CorePermitM2 = (props) => {
  const navigate = useNavigate();
  const peopleImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: teachpeople,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: stepbysteppeople,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: stalline,
        width: 20,
        height: 20,
      },
    },
  ];
  const enviImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: envi1,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: suit,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: globe,
        width: 20,
        height: 20,
      },
    },
  ];
  const energyImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: FireImg,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: Thunder,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: water,
        width: 20,
        height: 20,
      },
    },
  ];
  const equipImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: Car,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: van,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: cropmachine,
        width: 20,
        height: 20,
      },
    },
  ];
  const chemiImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: spraytracktor,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: buerate,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: tine,
        width: 20,
        height: 20,
      },
    },
  ];
  const changeimages = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: couple,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: chart,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: cloudthunder,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: sun,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: wave,
        width: 20,
        height: 20,
      },
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    sign: "",
  });

  //CorePermit#
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(coreEmergencyConst);
  const [people, setPeople] = useState(corePeopleConstant);
  const [energy, setEnergy] = useState(coreEnergyConstant);
  const [equipment, setEquipment] = useState(coreEquipmentConstant);
  const [chemical, setChemical] = useState(coreChemicalConstant);
  const [protection, setProtection] = useState(coreProtectionConstant);
  const [workEnvironment, setWorkEnvironment] = useState(coreWorkEnvConstant);
  const [environmentData, setEnvironMentData] = useState(environMentConstants);
  const [closeOutData, setCloseOutData] = useState(CloseOutConstants);
  const [changeData, setChangeData] = useState(changeConstants);
  const [respdata, setRespData] = useState(responsibilitites);
  const [recData, setRecData] = useState(corePermitReceiverConstants);
  const [trainData, setTrainData] = useState(trainingConstants);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [serverRecordId, setServerRecordId] = useState(null);
  //Save
  const [showModal, setShowModal] = useState(false);
  //Transfer
  const [showTransfer, setShowTransfer] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  //Reject
  const [showReject, setShowReject] = useState(false);
  const [reason, setReason] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEdit, setIsEdit] = useState(false);
  const [permitstatuscheck, setpermitstatuscheck] = useState("");
  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  //Toggle
  const [openAccordion, setOpenAccordion] = useState(null);
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [peopleToggleOn, setPeopleToggleOn] = useState(true);
  const [environmnetToggleOn, setEnvironmentToggleOn] = useState(true);
  const [energyToggleOn, setEnergyToggleOn] = useState(true);
  const [equipmentToggleOn, setEquipmentToggleOn] = useState(true);
  const [chemicalToggleOn, setChemicalToggleOn] = useState(true);
  const [protectionToggleOn, setProtectionToggleOn] = useState(true);
  const [workEnvironmentToggleOn, setWorkEnvironmentToggleOn] = useState(true);
  const [trainDataToggleOn, setTrainDataToggleOn] = useState(true);
  const [recDataToggleOn, setRecDataToggleOn] = useState(true);
  const [responsibleToggleOn, setResponsibleToggleOn] = useState(true);
  const [changeToggleOn, setChangeToggleOn] = useState(true);
  const [closeOutToggleOn, setCloseOutToggleOn] = useState(true)

  //CORE Permit #:
  const [primaryRequesterName, setPrimaryRequesterName] = useState("");
  const [suspendedBy, setSuspendedBy] = useState("");
  const [suspendTime, setSuspendTime] = useState("");
  const [suspendDate, setSuspendDate] = useState("");
  const [suspendedRemarks, setSuspendedRemarks] = useState("");
  const [approveOrRejectedByName, setApproveOrRejectedByName] = useState("");
  const [isTransferred, setIsTransferred] = useState("");
  const [transferToName, setTransferToName] = useState("");
  const [permitIssuerPhoneNumber, setPermitIssuerPhoneNumber] = useState("");
  const [closeOutTime, setCloseOutTime] = useState("");
  const [closeOutDate, setCloseOutDate] = useState("");
  const [issuerTransferTo, setIssuerTransferTo] = useState("");
  const [receiverTransferTo, setReceiverTransferTo] = useState("");


  const vendorLoginId = sessionStorage.getItem(COREPERMIT_VENDOR_LOGINID);
  const isVendor = !!vendorLoginId;
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  //validate form

  // const handleBackButtonClick = () => {
  //   // Handle navigation logic here
  //   navigate(-1);
  // };


  const handleBackButtonClick = () => {
    window.location.href = "/AdditionalPermitOptions";
  };

  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }
  const handlePeopleToggleItems = () => {
    setPeopleToggleOn(!peopleToggleOn)
  }

  const handleEnvironmentToggleItems = () => {
    setEnvironmentToggleOn(!environmnetToggleOn)
  }

  const handleEnergyToggleItems = () => {
    setEnergyToggleOn(!energyToggleOn)
  }

  const handleEquipmentToggleItems = () => {
    setEquipmentToggleOn(!equipmentToggleOn)
  }

  const handleChemicalToggleItems = () => {
    setChemicalToggleOn(!chemicalToggleOn)
  }
  const handleProtectionToggleItems = () => {
    setProtectionToggleOn(!protectionToggleOn)
  }
  const handleworkEnvironmentToggleItems = () => {
    setWorkEnvironmentToggleOn(!workEnvironmentToggleOn)
  }
  const handleTrainDataToggleItems = () => {
    setTrainDataToggleOn(!trainDataToggleOn)
  }

  const handleRecDataToggleOnToggleItems = () => {
    setRecDataToggleOn(!recDataToggleOn)
  }
  const handleResponsibleToggleOnToggleItems = () => {
    setResponsibleToggleOn(!responsibleToggleOn)
  }

  const handleChangeToggleItems = () => {
    setChangeToggleOn(!changeToggleOn)
  }

  const handleCloseOutToggleItems = () => {
    setCloseOutToggleOn(!closeOutToggleOn)
  }

  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    const IdFromURLwithEDit = searchParams.get("corePermitId");
    var IdFromURL;
    var isEdit = "true";
    var additional_permit_status;
    if (IdFromURLwithEDit == null) {
    } else {
      IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
      isEdit = IdFromURLwithEDit.toString().split("/")[1];
      additional_permit_status = IdFromURLwithEDit.toString().split("/")[2];
      setpermitstatuscheck(additional_permit_status);
    }
    setIsEdit(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);
    var filteredArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }


  const getFormData = async (permitId) => {
    const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
    if (dataFromStore) {
      const response = await axios.post(
        getCorePermitFormByNumber(dataFromStore, {})
      );
      if (response.data && response.data.response) {
        console.log("permitID:::", permitId);
        console.log("responseFFFF", JSON.stringify(response.data.response));
        var dataLast = await filterArray(
          response.data.response,
          "permitID",
          permitId
        );
        if (dataLast != undefined && dataLast != null) {
          setPrimaryRequesterName(dataLast.primaryRequesterName || "");
          setSuspendedBy(dataLast.suspendedBy || "");
          setSuspendTime(dataLast.suspendTime || "");
          setSuspendDate(dataLast.suspendDate || "");
          setSuspendedRemarks(dataLast.suspendedRemarks || "");
          setApproveOrRejectedByName(dataLast.approveOrRejectedByName || "");
          setPermitIssuerPhoneNumber(dataLast.phonenumber || "");
          setIsTransferred(dataLast.isTransferred || "");
          setTransferToName(dataLast.transferToName || "");
          setCloseOutTime(dataLast.closeOutTime || "");
          setCloseOutDate(dataLast.closeOutDate || "");
          setIssuerTransferTo(dataLast.issuerTransferTo || "");
          setReceiverTransferTo(dataLast.receiverTransferTo || "");
          setPermitInitials(
            dataLast.permitInitials ? dataLast?.permitInitials : permitInitials
          );
          setEmergency(dataLast.emergency ? dataLast?.emergency : emergency);
          setPeople(dataLast.people ? dataLast?.people : people);
          setEnergy(dataLast.energy ? dataLast.energy : energy);
          setEquipment(dataLast.equipment ? dataLast.equipment : equipment);
          setChemical(dataLast.chemical ? dataLast.chemical : chemical);
          setProtection(dataLast.protection ? dataLast.protection : protection);
          setWorkEnvironment(
            dataLast.workEnvironment
              ? dataLast.workEnvironment
              : workEnvironment
          );
          setEnvironMentData(
            dataLast.environmentData
              ? dataLast.environmentData
              : environmentData
          );
          setCloseOutData(
            dataLast.closeOutData ? dataLast.closeOutData : closeOutData
          );
          setChangeData(dataLast.changeData ? dataLast.changeData : changeData);
          setRespData(dataLast.respdata ? dataLast.respdata : respdata);
          setRecData(dataLast.recData ? dataLast.recData : recData);
          setTrainData(dataLast.trainData ? dataLast.trainData : trainData)
          setServerRecordId(permitId);
          setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
          setPeopleToggleOn(dataLast.peopleToggleOn !== undefined ? dataLast.peopleToggleOn : peopleToggleOn);
          setEnvironmentToggleOn(dataLast.environmnetToggleOn !== undefined ? dataLast.environmnetToggleOn : environmnetToggleOn);
          setEnergyToggleOn(dataLast.energyToggleOn !== undefined ? dataLast.energyToggleOn : energyToggleOn);
          setEquipmentToggleOn(dataLast.equipmentToggleOn !== undefined ? dataLast.equipmentToggleOn : equipmentToggleOn);
          setChemicalToggleOn(dataLast.chemicalToggleOn !== undefined ? dataLast.chemicalToggleOn : chemicalToggleOn);
          setProtectionToggleOn(dataLast.protectionToggleOn !== undefined ? dataLast.protectionToggleOn : protectionToggleOn);
          setWorkEnvironmentToggleOn(dataLast.workEnvironmentToggleOn !== undefined ? dataLast.workEnvironmentToggleOn : workEnvironmentToggleOn);
          setTrainDataToggleOn(dataLast.trainDataToggleOn !== undefined ? dataLast.trainDataToggleOn : trainDataToggleOn);
          setRecDataToggleOn(dataLast.recDataToggleOn !== undefined ? dataLast.recDataToggleOn : recDataToggleOn);
          setResponsibleToggleOn(dataLast.responsibleToggleOn !== undefined ? dataLast.responsibleToggleOn : responsibleToggleOn);
        }
      }
    }
  };
  console.log("emergencyToggleOn", emergencyToggleOn);
  console.log("peopleToggleOn", peopleToggleOn);
  console.log("environmnetToggleOn", environmnetToggleOn);
  console.log("energyToggleOn", energyToggleOn);


  const resetForm = () => {
    setPermitInitials(corePermitInitialsConst);
    setEmergency(coreEmergencyConst);
    setPeople(corePeopleConstant);
    setEnergy(coreEnergyConstant);
    setEquipment(coreEquipmentConstant);
    setChemical(coreChemicalConstant);
    setProtection(coreProtectionConstant);
    setWorkEnvironment(coreWorkEnvConstant);
    setEnvironMentData(environMentConstants);
    setCloseOutData(CloseOutConstants);
    setChangeData(changeConstants);
    setRespData(responsibilitites);
    setRecData(corePermitReceiverConstants);
    setTrainData(trainingConstants);
  };

  const handleSubmit = async (event) => {
    // debugger;
    event.preventDefault();
    //environment
    const checkedEnvironmentList = environmentData.environmentList.filter(
      (item) => item.checked
    );
    const environmentChecked = checkedEnvironmentList.length >= 1 ? "true" : "";
    ////energy
    const checkedItems = energy.energyList.filter((item) => item.checked);
    const isenergycheckedNA =
      energy.answer === "false"
        ? "false"
        : energy.answer === "" && checkedItems.length === 0
          ? ""
          : "true";
    const energyisChecked =
      checkedItems.length >= 1
        ? "true"
        : energy.answer === "" && checkedItems.length === 0
          ? ""
          : "false";

    ////equipment
    const checkedEquipmentList = equipment.equipmentList.filter(
      (item) => item.checked
    );

    const isEquipmentcheckedNA =
      equipment.answer === "false"
        ? "false"
        : equipment.answer === "" && checkedEquipmentList.length === 0
          ? ""
          : "true";
    const equipmentchecked =
      checkedEquipmentList.length >= 1
        ? "true"
        : equipment.answer === "" && checkedEquipmentList.length === 0
          ? ""
          : "false";

    // chemical
    const checkedchemicalHazardsList = chemical.chemicalHazardsList.filter(
      (item) => item.checked
    );

    const ischemicalHazardscheckedNA =
      chemical.answer === "false"
        ? "false"
        : chemical.answer === "" && checkedchemicalHazardsList.length === 0
          ? ""
          : "true";

    const chemicalHazardschecked =
      checkedchemicalHazardsList.length >= 1
        ? "true"
        : chemical.answer === "" && checkedchemicalHazardsList.length === 0
          ? ""
          : "false";

    ////protection
    const protectionHeadFaceList = protection.headFaceList.filter(
      (item) => item.checked
    );
    const protectionChecked = protectionHeadFaceList.length >= 1 ? "true" : "";

    const WorkaccessList = workEnvironment.accessList.filter(
      (item) => item.checked
    );
    const accessListListChecked = WorkaccessList.length >= 1 ? "true" : "";

    // training
    const checkedTrainingList = trainData.trainingList.filter(
      (item) => item.checked
    );
    const isTrainingListcheckedNA =
      trainData.answer === ""
        ? "false"
        : trainData.answer === "" && checkedTrainingList.length === 0
          ? ""
          : "true";
    const isTrainingListchecked =
      checkedTrainingList.length >= 1
        ? "true"
        : trainData.answer === "" && checkedTrainingList.length === 0
          ? ""
          : "false";

    const ReceiverList = recData.corePermitReceiverList.filter(
      (item) => item.checked
    );
    const recDataListChecked = ReceiverList.length >= 1 ? "true" : "";

    const ResponsibilitiesList = respdata.responsibilitiesList.filter(
      (item) => item.checked
    );
    const ResponsibilitiesListChecked =
      ResponsibilitiesList.length >= 1 ? "true" : "";

    //CloseOut And Changed

    const changeDataList = changeData.changeList.filter((item) => item.checked);
    const changeDataListChecked = changeDataList.length >= 1 ? "true" : "";

    const closeOutDataList = closeOutData.closeOutList.filter(
      (item) => item.checked
    );
    const closeOutDataChecked = closeOutDataList.length >= 1 ? "true" : "";
    var valuesToCheck = [];

    if (emergencyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        emergency: {
          phoneNumber: emergency.phoneNumber,
          assemblyLocation: emergency.assemblyLocation,
        },
      };
      console.log("updated values", updatedValues);
      valuesToCheck = [...updatedValues];
    }

    if (peopleToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        people: {
          plantFacility: people.plantFacility,//VendorLogin
          // permitIssuer: people.permitIssuer,//Acceptor Login
          // phonenumber: people.phonenumber,//Acceptor Login
          phoneNumber: people.phoneNumber,//VendorLogin
          noOfWorkingPermit: people.noOfWorkingPermit,
          workLimit: people.workLimit,//VendorLogin
          location: people.location,//VendorLogin
          permitReceiver: people.permitReceiver,//VendorLogin
          namesWorkingPermit: people.namesWorkingPermit,//VendorLogin
          company: people.company,//VendorLogin
          date: people.date,//VendorLogin
          startTime: people.startTime,//VendorLogin
          endTime: people.endTime,//VendorLogin
        },
      };
      console.log("fixed values", valuesToCheck);
      valuesToCheck = [...updatedValues];
    }
    if (environmnetToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        environmentData: {
          environmentList: environmentChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }
    if (energyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        energy: {
          answer: isenergycheckedNA,
          energyList: energyisChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }
    if (equipmentToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        equipment: {
          answer: isEquipmentcheckedNA,
          equipmentList: equipmentchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (chemicalToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        chemical: {
          answer: ischemicalHazardscheckedNA,
          chemicalHazardsList: chemicalHazardschecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (protectionToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        protection: {
          headFaceList: protectionChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (workEnvironmentToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        workEnvironment: {
          accessList: accessListListChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (trainDataToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        trainData: {
          answer: isTrainingListcheckedNA,
          trainingList: isTrainingListchecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (recDataToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        recData: {
          corePermitReceiverList: recDataListChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (responsibleToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        respdata: {
          responsibilitiesList: ResponsibilitiesListChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }
    console.log("valuesToCheck" + JSON.stringify(valuesToCheck));
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValues(item);
      if (returnKey) {

        switch (returnKey) {
          case "emergency":
            if (emergencyToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Fill the Emergency Data",
                confirmButtonColor: "#d33",
              });
              allCasesValid = false;
              return;
            }
            break;


          case "people":
            if (peopleToggleOn) {
              const missingFields = [];
              if (!people.plantFacility) missingFields.push("Plant/Facility");
              if (!people.phoneNumber) missingFields.push("Permit Receiver Phone number ");
              if (!people.noOfWorkingPermit) missingFields.push("Number of People Working Under this Permit");
              if (!people.namesWorkingPermit) missingFields.push("Names of People working Under this Permit");
              if (!people.workLimit) missingFields.push("Job Scope / Description");
              if (!people.location) missingFields.push("Location");
              if (!people.permitReceiver) missingFields.push("Permit Receiver");
              if (!people.company) missingFields.push("Company");
              if (!people.date) missingFields.push("Date");
              if (!people.startTime) missingFields.push("Start-Time");
              if (!people.endTime) missingFields.push("End-Time");
              if (missingFields.length > 0) {
                Swal.fire({
                  icon: "warning",
                  html: `<strong>Please Fill the Following Fields In People Section:</strong>
                  <ul style="text-align: left; margin-top: 10px;">
                      ${missingFields.map(field => `<li>${field}</li>`).join("")}
                  </ul>`,
                  confirmButtonColor: "#d33",
                });
                allCasesValid = false;
                return;
              }

            }
            break;


          case "environmentData":
            if (environmnetToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Environment Data",
                confirmButtonColor: "#d33",

              });
              allCasesValid = false;
              return;
            }
            break;

          case "energy":
            if (energyToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Energy Data",
                confirmButtonColor: "#d33",

              });
              allCasesValid = false;
              return;
            }
            break;

          case "equipment":
            if (equipmentToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Equipment Data",
                confirmButtonColor: "#d33",

              });
              allCasesValid = false;
              return;
            }
            break;

          case "chemical":
            if (chemicalToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Chemical Data",
                confirmButtonColor: "#d33",

              });
              allCasesValid = false;
              return;
            }
            break;

          case "protection":
            if (protectionToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Protection Data",
                confirmButtonColor: "#d33",

              });
              allCasesValid = false;
              return;
            }
            break;

          case "workEnvironment":
            if (workEnvironmentToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Work Environment Data",
                confirmButtonColor: "#d33",
              });
              allCasesValid = false;
              return;
            }
            break;

          case "trainData":
            if (trainDataToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Training Section Data",
                confirmButtonColor: "#d33",
              });
              allCasesValid = false;
              return;
            }
            break;

          case "recData":
            if (recDataToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Core Permit Receiver Data",
                confirmButtonColor: "#d33",
              });
              allCasesValid = false;
              return;
            }
            break;

          case "respdata":
            if (responsibleToggleOn) {
              Swal.fire({
                icon: "warning",
                text: "Please Fill the Responsibilities Data",
                confirmButtonColor: "#d33",
              });
              allCasesValid = false;
              return;
            }
            break;
        }

      }
    }
    ///validating the form using the filed
    if (allCasesValid) {
      // debugger;
      var vendorname = sessionStorage.getItem(COREPERMIT_VENDOR_NAME);
      var vendormobileNumber = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
      var vendorSubSiteName = sessionStorage.getItem(SUBSITE_NAME);
      var vendorSubSiteId = sessionStorage.getItem(SUBSITE_ID);
      var vendorMainSiteName = sessionStorage.getItem(MAINSITE_NAME);
      console.log("MAINSITE_NAME", MAINSITE_NAME);
      var vendorMainSiteId = sessionStorage.getItem(MAINSITE_ID);
      var vendordate = sessionStorage.getItem(COREPERMIT_DATE);
      const dataFromStore = await retrieveData(USER_PROFILE);
      console.log("Retrieved USER_PROFILE Data:", dataFromStore);

      const userProfileData = {
        primaryMobileNumber: vendormobileNumber,
        primaryRequesterName: vendorname,
        secondaryRequesterName: "",
        secondaryMobileNumber: "",
        corePermits: "01",
        corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
        corePermitInitials: "01",
        serverRecordId: serverRecordId ? serverRecordId : 0,
        subSiteId: vendorSubSiteId,
        subSiteName: vendorSubSiteName,
        mainSiteName: vendorMainSiteName,
        mainSiteId: vendorMainSiteId,
      };
      const finalJson = {
        permitInitials,
        emergency,
        people,
        environmentData,
        energy,
        equipment,
        chemical,
        protection,
        workEnvironment,
        trainData,
        recData,
        respdata,
        changeData,
        closeOutData,
        ...userProfileData,
        emergencyToggleOn,
        peopleToggleOn,
        environmnetToggleOn,
        energyToggleOn,
        equipmentToggleOn,
        chemicalToggleOn,
        protectionToggleOn,
        workEnvironmentToggleOn,
        trainDataToggleOn,
        recDataToggleOn,
        responsibleToggleOn,
      };

      console.log("FINALJSONUPDATE" + finalJson);
      try {
        setIsLoading(true);
        const response = await axios.post(saveCorePermitForm, finalJson);
        if (response.data.statusCode == "200") {
          sessionStorage.setItem(CorePermitM2_PERMITID, response.data.response);
          setMessage(response.data.message);
          setShowModal(true);
        } else if (response.data.statusCode == "500") {
          setMessage(response.data.message);
          setShowModal(true);
        }
        setIsLoading(false);
      } catch (error) {
        setMessage("An unexpected error occurred. Please try again later.");
        setShowModal(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <body id="page-top">
      {finalSubmit ? (
        <>
          <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <div
                  className="row"
                  style={{ backgroundColor: "blue", padding: "7px" }}
                >
                  <div className="col-md-3 col-12">
                    <img
                      src={arrowIcon}
                      onClick={handleBackButtonClick}
                      alt="Back"
                      style={{
                        width: 40,
                        height: 30,
                        padding: 5,
                        verticalAlign: "middle",
                      }}
                    />
                    <span
                      style={{
                        fontSize: 20,
                        color: "white",
                        marginLeft: 10,
                        marginTop: 12,
                      }}
                    >
                      Work Permits
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    className="row"
                    style={{ backgroundColor: "blue", padding: "7px" }}
                  >
                    <div className="col-md-3 col-12">
                      <h3>CORE</h3>
                      <h3>PERMIT</h3>
                      <h6>SEEDS</h6>
                    </div>
                  </div>
                </div>

                <form className="CorePermit_Web-form">
                  <div>
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <div className="m-4">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  color: "green",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "13px 0px 14px 10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Core Permit Submitted Successfully
                                </p>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  margin: "10px 0px 10px 0px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Link
                                  to="/AdditionalPermitOptions"
                                  aria-expanded="true"
                                >
                                  <button
                                    className="btn mt-2"
                                    style={{
                                      margin: "0px 0px 6px 40px",
                                      color: "white",
                                    }}
                                  >
                                    Add Additional Permits
                                  </button>
                                </Link>
                              </div>
                            </div>

                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  margin: "10px 0px 10px 0px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Link to="/CorePermitM3" aria-expanded="true">
                                  <button
                                    className="btn mt-2"
                                    style={{
                                      margin: "0px 0px 6px 40px",
                                      color: "white",
                                    }}
                                  >
                                    Go To Core Permit Status
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* rns */}
                      </div>
                    </div>
                  </div>
                </form>
                {/* ... End of Footer...  */}
              </div>
            </div>
            {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
          </div>
        </>
      ) : (
        <div id="wrapper">
          <div
            id="content-wrapper"
            className="d-flex flex-column"
            style={{ backgroundColor: "white" }}
          >
            <div id="content">
              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>
                  <div>
                    <div
                      className="row"
                      style={{ backgroundColor: "blue", padding: "7px" }}
                    >
                      <div className="col-md-3 col-12">
                        <img
                          src={arrowIcon}
                          onClick={handleBackButtonClick}
                          alt="Back"
                          style={{
                            width: 40,
                            height: 30,
                            padding: 5,
                            verticalAlign: "middle",
                          }}
                        />
                        <span
                          style={{
                            fontSize: 20,
                            color: "white",
                            marginLeft: 10,
                            marginTop: 12,
                          }}
                        >
                          Core Permit
                        </span>
                      </div>
                    </div>

                    <div className="row" style={{ display: "none" }}>
                      <div
                        className="d-flex flex-row"
                        style={{ margin: "1px", padding: "2px" }}
                      >
                        <div style={{ backgroundColor: "red" }}>
                          <div>
                            <div className="d-flex flex-row">
                              <div>
                                <label
                                  for="inputPassword"
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "7px",
                                  }}
                                >
                                  Emergency
                                </label>
                              </div>
                              <div
                                className="d-flex flex-row"
                                style={{
                                  margin: "2px",
                                  padding: "2px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <label
                                  for="inputPassword"
                                  style={{ fontSize: "7px" }}
                                >
                                  Phone
                                  <br /> Number
                                </label>
                                <input
                                  type="text"
                                  style={{ width: "15px", border: "none" }}
                                />
                                <label
                                  for="inputPassword"
                                  style={{ fontSize: "7px" }}
                                >
                                  Assembly
                                  <br /> Location
                                </label>
                                <input
                                  type="text"
                                  style={{ width: "15px", border: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{ margin: "2px", padding: "2px" }}>
                            <span className="header-icons">
                              <img
                                src={Factory}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                            <span className="header-icons">
                              <img
                                src={HouseAndLighthouse}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                            <span className="header-icons">
                              <img
                                src={Labtubeheadercorepermit}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                            <span className="header-icons">
                              <img
                                src={shakinghandsHeadersCorepermit}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                            <span className="header-icons">
                              <img
                                src={ImageheaderCorepermit}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                            <span className="header-icons">
                              <img
                                src={DroneHeaderCorepermit}
                                alt="img"
                                className="header-img"
                              />
                            </span>
                          </div>
                        </div>

                        <div>
                          <div>
                            <span
                              style={{
                                display: "inline-block",
                                border: "none",
                                padding: "3px",
                                cursor: "pointer",
                                marginRight: "10px",
                                width: "35px",
                                height: "35px",
                                marginright: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <img
                                src={CortevaImage}
                                alt="img"
                                style={{ width: "80px", height: "20px" }}
                              />
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>

                    <form className="CorePermitM2-form">

                      <CorePermitInputFields
                        data={permitInitials}
                        editable={false}
                        setPermitInitials={setPermitInitials}
                      />
                      <div>
                        <Accordion
                          title="EMERGENCY"
                          bgColor="#DF3A1C"
                          handleToggle={handleToggleItems}
                          isToggled={emergencyToggleOn}
                          openAccordion={openAccordion}
                          setOpenAccordion={setOpenAccordion}
                          index="emergency"
                          content={
                            <div>
                              <CorePermitEmergency
                                data={emergency}
                                emergencyToggleOn={emergencyToggleOn}
                                setEmergency={setEmergency}
                                onDataChange={(data) => {
                                  setEmergency(data);
                                }}
                                approvalStatus={permitstatuscheck === "Approve"}
                              />
                            </div>
                          }
                          buttonData={peopleImg}
                          bordercolor="#DF3A1C"
                          showToggle={true}
                        />
                      </div>
                      <Accordion
                        title="PEOPLE"
                        bgColor="#404040"
                        handleToggle={handlePeopleToggleItems}
                        isToggled={peopleToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="people"
                        content={
                          <CorePermitM2People
                            data={people}
                            peopleToggleOn={peopleToggleOn}
                            approveOrRejectedByName={approveOrRejectedByName}
                            permitIssuerPhoneNumber={permitIssuerPhoneNumber}
                            isTransferred={isTransferred}
                            transferToName={transferToName}
                            primaryRequesterName={primaryRequesterName}
                            issuerTransferTo={issuerTransferTo}
                            receiverTransferTo={receiverTransferTo}
                            onDataChange={(data) => {
                              setPeople(data);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        buttonData={peopleImg}
                        bordercolor="#404040"
                        showToggle={true}
                      />
                      <Accordion
                        title="ENVIRONMENT"
                        bgColor="#2CC743"
                        handleToggle={handleEnvironmentToggleItems}
                        isToggled={environmnetToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="environment"
                        content={
                          <CorePermitEnvironment
                            environMentData={environmentData}
                            environmnetToggleOn={environmnetToggleOn}
                            setEnvironMentData={setEnvironMentData}
                            onDataChange={(data) => setEnvironMentData(data)}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        buttonData={enviImg}
                        bordercolor="#2CC743"
                        showToggle={true}
                      />
                      <Accordion
                        title="ENERGY"
                        bgColor="#3F57B1"
                        handleToggle={handleEnergyToggleItems}
                        isToggled={energyToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="energy"
                        content={
                          <CorePermitM2Energy
                            energyToggleOn={energyToggleOn}
                            data={energy}
                            setEnergy={setEnergy}
                            onDataChange={(val) => {
                              setEnergy(val);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        buttonData={energyImg}
                        bordercolor="#3F57B1"
                        showToggle={true}
                      />
                      <Accordion
                        title="EQUIPMENT"
                        bgColor="#F17A5A"
                        handleToggle={handleEquipmentToggleItems}
                        isToggled={equipmentToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="equipment"
                        content={
                          <CorePermitM2Equipment
                            data={equipment}
                            equipmentToggleOn={equipmentToggleOn}
                            setEnergy={setEquipment}
                            onDataChange={(val) => {
                              setEquipment(val);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        buttonData={equipImg}
                        bordercolor="#FF7E51"
                        showToggle={true}
                      />
                      <Accordion
                        title="CHEMICAL"
                        bgColor="#DF3A1C"
                        handleToggle={handleChemicalToggleItems}
                        isToggled={chemicalToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="chemical"
                        content={
                          <CorePermitM2Chemical
                            data={chemical}
                            chemicalToggleOn={chemicalToggleOn}
                            setChemical={setChemical}
                            onDataChange={(val) => {
                              setChemical(val);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        buttonData={chemiImg}
                        bordercolor="#DF3A1C"
                        showToggle={true}
                      />
                      <Accordion
                        title="PROTECTION"
                        bgColor="#4E74C2"
                        handleToggle={handleProtectionToggleItems}
                        isToggled={protectionToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="protection"
                        content={
                          <CorePermitM2Protection
                            data={protection}
                            protectionToggleOn={protectionToggleOn}
                            setProtection={setProtection}
                            onDataChange={(val) => {
                              setProtection(val);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        bordercolor="#4E74C2"
                        showToggle={true}
                      />
                      <Accordion
                        title="WORK ENVIRONMENT"
                        bgColor="#636260"
                        handleToggle={handleworkEnvironmentToggleItems}
                        isToggled={workEnvironmentToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="workenvironment"
                        content={
                          <CorePermitM2Work
                            workEnvironmentToggleOn={workEnvironmentToggleOn}
                            setWorkEnvironment={setWorkEnvironment}
                            data={workEnvironment}
                            onDataChange={(val) => {
                              setWorkEnvironment(val);
                            }}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        bordercolor="#636260"
                        showToggle={true}
                      />
                      <Accordion
                        title="TRAINING"
                        bgColor="#282928"
                        handleToggle={handleTrainDataToggleItems}
                        isToggled={trainDataToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="training"
                        content={
                          <CorePermitTraining
                            trainDataToggleOn={trainDataToggleOn}
                            trainData={trainData}
                            setTrainData={setTrainData}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        bordercolor="#282928"
                        showToggle={true}
                      />
                      <Accordion
                        title="CORE PERMIT RECEIVER"
                        bgColor="#484848"
                        handleToggle={handleRecDataToggleOnToggleItems}
                        isToggled={recDataToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="corepermtreceiver"
                        content={
                          <CorePermitReceiver
                            recDataToggleOn={recDataToggleOn}
                            recData={recData}
                            setRecData={setRecData}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        bordercolor="#484848"
                        showToggle={true}
                      />
                      <Accordion
                        title="RESPONSIBILITIES"
                        bgColor="#6D6D6D"
                        handleToggle={handleResponsibleToggleOnToggleItems}
                        isToggled={responsibleToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="responsibilities"
                        content={
                          <CorePermitResponsibilities
                            responsibleToggleOn={responsibleToggleOn}
                            respData={respdata}
                            setRespData={setRespData}
                            approvalStatus={
                              permitstatuscheck === "Approve" ? true : false
                            }
                          />
                        }
                        bordercolor="#6D6D6D"
                        showToggle={true}
                      />
                      <Accordion
                        title="CHANGE"
                        bgColor="#DF3A1C"
                        handleToggle={handleChangeToggleItems}
                        isToggled={changeToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="change"
                        content={
                          <CorePermitChange
                            changeData={changeData}
                            changeToggleOn={changeToggleOn}
                            suspendedBy={suspendedBy}
                            suspendTime={suspendTime}
                            suspendDate={suspendDate}
                            primaryRequesterName
                            ={primaryRequesterName}
                            isTransferred={isTransferred}
                            transferToName={transferToName}
                            suspendedRemarks={suspendedRemarks}
                            receiverTransferTo={receiverTransferTo}
                            issuerTransferTo={issuerTransferTo}
                            setChangeData={setChangeData}
                          />
                        }
                        buttonData={changeimages}
                        bordercolor="#DF3A1C"
                        showToggle={false}
                      />
                      <Accordion
                        title="CLOSEOUT"
                        bgColor="#262721"
                        handleToggle={handleCloseOutToggleItems}
                        isToggled={closeOutToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="closeout"
                        content={
                          <CorePermitCloseOut
                            closeOutData={closeOutData}
                            closeOutToggleOn={closeOutToggleOn}
                            setCloseOutData={setCloseOutData}
                            primaryRequesterName={primaryRequesterName}
                            approveOrRejectedByName={approveOrRejectedByName}
                            isTransferred={isTransferred}
                            transferToName={transferToName}
                            closeOutTime={closeOutTime}
                            closeOutDate={closeOutDate}
                            issuerTransferTo={issuerTransferTo}
                          />
                        }
                        bordercolor="#262721"
                        showToggle={false}
                      />

                      {isEdit.toString() == "true" && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-5 
                                              ${"custom-rounded"}`}
                          style={{
                            height: "43px",
                            backgroundColor: "#007AFF",
                            color: "white",
                            border: "none",
                          }}
                        >
                          Submit
                        </button>
                      )}

                      <div></div>
                      <div>
                        <Modal
                          show={showModal}
                          onHide={() => setShowModal(false)}
                          centered={true}
                          size="sm"
                          id="nsmodel"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="status"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>{message}</h5>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2 ${"custom-rounded"}`}
                                    style={{
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      handleBackButtonClick();
                                      setShowModal(false);
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          show={showTransfer}
                          onHide={() => setShowTransfer(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <h3> Transfer to </h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="mobileNumber">
                                Mobile Number:
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="mobileNumber"
                                value={mobileNumber}
                                onChange={(e) => {
                                  setMobileNumber(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={() => {
                                  setShowTransfer(false);
                                  console.log(name, mobileNumber);
                                }}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <h3>Approved</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Status:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Type here"
                                value={status}
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={() => {
                                  setShowApproved(false);
                                  console.log(status);
                                }}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "15vh",
                              }}
                            >
                              <h3> Are you sure want to Reject?</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Reason:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={reason}
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={() => {
                                  setShowReject(false);
                                  console.log(reason); // Print the 'status' value in the console
                                }}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </form>
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* ... End of Footer...  */}
            </div>
          </div>
          {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
          {/*  <!-- Logout Modal--> */}
        </div>
      )}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          // message={"Please Wait Form  Submiting!"}
          message={"Processing your submission. Please wait..."}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CorePermitM2;
