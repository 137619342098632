import React from "react";
import { getNewListWithModifyChecked } from '../../Utilities/utils';

const CorePermitEnvironment = ({ environMentData, onDataChange, approvalStatus, isEnvironmnetToggleOn }) => {

  console.log(isEnvironmnetToggleOn, "isEnvironmnetToggleOn");

  return (
    <>
      {environMentData.environmentList.map((data, index) => {
        return (
          <div class="form-group row">
            <label class="col-9 col-form-label">{`${index + 1
              }.${data.question}`}</label>
            <div class="col-3">
              <div class="form-check form-check-inline d-flex align-items-center mt-2">
                <input
                  disabled={approvalStatus}
                  class="form-check-input mt-0"
                  onChange={() =>
                    onDataChange({
                      ...environMentData,
                      environmentList: getNewListWithModifyChecked(index, environMentData.environmentList),
                    })
                  }
                  type="checkbox"
                  id="inlineCheckbox1"
                  checked={data.checked}
                  value="option2"
                />
                <label class="form-check-label mt-0" for="inlineCheckbox2">
                  N/A
                </label>

              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CorePermitEnvironment;
