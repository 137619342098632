

import React, { useState } from 'react'
import { useEffect } from 'react'
function SecondarySignPermitIssuer(props) {
    const handleGeneralInfo = (val, key, type, index = null) => {
        if (type == 1) {
            props.setSecondarySign(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            const newArr = props.data[key]
            newArr[index].cbValue = val
            props.setSecondarySign(prev => ({ ...prev, [key]: newArr }))
        }
    }

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div class="form-group">
                            <label >NAME <span  className='required'> * </span></label>
                           
                            <div>
                                <input
                                    class="form-control"
                                    id="inputPassword"
                                    placeholder=""
                                    value={props.data.name}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const alphaOnly = inputValue.replace(/[^a-zA-Z .]/g, ''); 
                                        handleGeneralInfo(alphaOnly, 'name', 1);
                                    }}
                                />
                            </div>

                        </div>
                        <div class="form-group">
                            <label >SIGNATURE</label>
                            <div>
                                <input class="form-control col-12" id="inputPassword" placeholder=""
                                    value={props.data.signature} onChange={(e) => {
                                        handleGeneralInfo(e.target.value, 'signature', 1)
                                    }}
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label >COMPANY</label>
                            <div>
                                <input class="form-control" id="inputPassword" placeholder=""
                                    value={props.data.company} onChange={(e) => {
                                        handleGeneralInfo(e.target.value, 'company', 1)
                                    }}
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                              
                                <input class="form-check-input " type="checkbox" id="secondListEle1" checked={props.data.list[0].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[0].cbValue == 'true' ? 'false' : 'true', 'list', 2, 0)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListEle1">The igntion source is identified.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                               
                                <input class="form-check-input " type="checkbox" id="secondListSurvey2" checked={props.data.list[1].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[1].cbValue == 'true' ? 'false' : 'true', 'list', 2, 1)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListSurvey2">Controls are correct(e.g cleaning,purging,distance,shielding).</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                               
                                <input class="form-check-input" type="checkbox" id="secondListSurvey3" checked={props.data.list[2].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[2].cbValue == 'true' ? 'false' : 'true', 'list', 2, 2)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListSurvey3">The fuel hazard and area reviewed.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                              
                                <input class="form-check-input " type="checkbox" id="secondListSurvey4" checked={props.data.list[3].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[3].cbValue == 'true' ? 'false' : 'true', 'list', 2, 3)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListSurvey4">The fuel source is identified.</label>

                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                              
                                <input class="form-check-input" type="checkbox" id="secondListSurvey5" checked={props.data.list[4].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[4].cbValue == 'true' ? 'false' : 'true', 'list', 2, 4)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListSurvey5">Air monitoring and locations are identified.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                               
                                <input class="form-check-input" type="checkbox" id="secondListSurvey6" checked={props.data.list[5].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[5].cbValue == 'true' ? 'false' : 'true', 'list', 2, 5)
                                }} value="option2" />
                                <label class="form-check-label" for="secondListSurvey6">The work area is prepared for hot work.</label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecondarySignPermitIssuer;
