import React, { useState, useEffect } from "react";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Footer from "./Footer";
import axios from "axios";
import moment from "moment/moment";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllPermitsByCorePermitID } from "../Utilities/URLCONSTANTS";
import { useSearchParams } from "react-router-dom";
import { USER_PROFILE, retrieveData } from "../Utils";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";

//people-images
import teachpeople from "../images/Teach-People-CorePermit.png";
import stepbysteppeople from "../images/Step-by-step-People-Corepermit.png";
import stalline from "../images/Stalline-People-Corepermit.png";
//environment-images
import envi1 from "../images/Window-man-enivron-corepermit.png";
import suit from "../images/suit-man-envi-corepermit.png";
import globe from "../images/Hand-glob-envi.png";
//Energy -images
import FireImg from "../images/FireAccordionImg.png";
import Thunder from "../images/Thunder.png";
import water from "../images/waterdrop-energy-corepermit.png";
//Equipment-Images
import Car from "../images/Car-euip-corepermit.png";
import cropmachine from "../images/cropmachine-euip-corepermit.png";
import van from "../images/Van-equip-corepermit.png";
//Chemical -Images
import spraytracktor from "../images/spray-tractor-chem-corepermit.png";
import buerate from "../images/Buret-chem-corepermit.png";
import tine from "../images/tin-chem-corepermit.png";
//Change-images
import couple from "../images/couple-change-corepermit.png";
import cloudthunder from "../images/cloud-thunder-change-corepermit.png";
import chart from "../images/chartlist-change-corepermit.png";
import sun from "../images/sun-change-corepermit.png";
import wave from "../images/Wave-change-corepermit.png";

//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";


const formatTime = (dateString) => {
  if (!dateString) return ""; // Handle empty case

  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12; // Convert 0 to 12
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};


const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty case

  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0"); // Ensure 2-digit format
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
};



const CorePermit_Web = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const IdFromURL = searchParams.get("corePermitID");
  //CORE Permit #:
  const [primaryRequesterName, setPrimaryRequesterName] = useState("");
  const [issuerTransferTo, setIssuerTransferTo] = useState("");
  const [receiverTransferTo, setReceiverTransferTo] = useState("");
  const [suspendedBy, setSuspendedBy] = useState("");
  const [suspendTime, setSuspendTime] = useState("");
  const [suspendDate, setSuspendDate] = useState("");
  const [suspendedRemarks, setSuspendedRemarks] = useState("");
  const [approveOrRejectedByName, setApproveOrRejectedByName] = useState("");
  const [isTransferred, setIsTransferred] = useState("");
  const [transferToName, setTransferToName] = useState("");
  const [closeOutTime, setCloseOutTime] = useState("");
  const [closeOutDate, setCloseOutDate] = useState("");



  const [initialsOne, setInitialsOne] = useState("");
  const [initialsTwo, setInitialsTwo] = useState("");
  const [initialsThree, setInitialsThree] = useState("");

  const [dateInputOne, setDateInputOne] = useState("");

  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");

  const [] = useState("");
  //Emergency
  const [emergency, setemergency] = useState("");
  const [al, setal] = useState("");
  //People
  const [permitIssuer, setpermitIssuer] = useState("");
  const [permitIssuerSign, setpermitIssuerSign] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [plantFacility, setplantFacility] = useState("");
  const [noOfWorkingPermit, setnoOfWorkingPermit] = useState("");
  const [coSignature, setcoSignature] = useState("");
  const [coSignatureSign, setcoSignatureSign] = useState("");
  const [jobSiteInspector, setjobSiteInspector] = useState("");
  //People List
  const [plYes0, setplYes0] = useState("");
  const [plNo0, setplNo0] = useState("");

  const [plYes1, setplYes1] = useState("");
  const [plNo1, setplNo1] = useState("");

  const [plYes2, setplYes2] = useState("");
  const [plNo2, setplNo2] = useState("");

  const [plYes3, setplYes3] = useState("");
  const [plNo3, setplNo3] = useState("");

  const [plYes4, setplYes4] = useState("");
  const [plNo4, setplNo4] = useState("");

  const [plYes5, setplYes5] = useState("");
  const [plNo5, setplNo5] = useState("");

  const [plYes6, setplYes6] = useState("");
  const [plNo6, setplNo6] = useState("");

  const [plYes7, setplYes7] = useState("");
  const [plNo7, setplNo7] = useState("");

  const [plYes8, setplYes8] = useState("");
  const [plNo8, setplNo8] = useState("");

  const [plYes9, setplYes9] = useState("");
  const [plNo9, setplNo9] = useState("");

  const [plYes10, setplYes10] = useState("");
  const [plNo10, setplNo10] = useState("");
  //Extended use
  const [euYes, seteuYes] = useState("");
  const [euNo, seteuNo] = useState("");
  const [euMon, seteuMon] = useState("");
  const [euTue, seteuTue] = useState("");
  const [euWed, seteuWed] = useState("");
  const [euThu, seteuThu] = useState("");
  const [euFri, seteuFri] = useState("");
  const [euSat, seteuSat] = useState("");
  const [euSun, seteuSun] = useState("");
  const [startTimeOne, setStartTimeOne] = useState("");
  const [endTimeOne, setEndTimeOne] = useState("");

  const [issuertransferredto, setissuertransferredto] = useState("");
  const [issueTransferToSign, setissueTransferToSign] = useState("");

  const [permitReceiver, setpermitReceiver] = useState("");
  const [permitReceiverSign, setpermitReceiverSign] = useState("");
  const [onSiteInspection, setonSiteInspection] = useState("");
  const [onSiteInspectionNo, setonSiteInspectionNo] = useState("");

  const [
    namesOfPeopleWorkingUnderthisPermit,
    setnamesOfPeopleWorkingUnderthisPermit,
  ] = useState("");
  // const [receiverTransferTo, setreceiverTransferTo] = useState("");
  const [receiverTransferTosign, setreceiverTransferTosign] = useState("");
  const [company, setcompany] = useState("");
  const [date, setdate] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");

  const [startTime2, setstartTime2] = useState("");
  const [endTime2, setendTime2] = useState("");
  const [additionalAttachment, setadditionalAttachment] = useState("");
  const [location, setLocation] = useState("");
  const [workLimit, setWorkLimit] = useState("");
  //Environment
  const [envNA0, setenvNA0] = useState("");
  const [envInput0, setenvInput0] = useState("");

  const [envNA1, setenvNA1] = useState("");
  const [envInput1, setenvInput1] = useState("");

  const [envNA2, setenvNA2] = useState("");
  const [envInput2, setenvInput2] = useState("");

  const [envNA3, setenvNA3] = useState("");
  const [envInput3, setenvInput3] = useState("");
  // 3.ENERGY
  const [energyAnswer, setenergyAnswer] = useState("");
  const [energyLine, setenergyLine] = useState("");
  const [energyHotwork, setenergyHotwork] = useState("");
  const [energyConfined, setenergyConfined] = useState("");
  const [energyCritical, setenergyCritical] = useState("");
  const [energyCrane, setenergyCrane] = useState("");
  const [energyIsolations, setenergyIsolations] = useState("");
  const [energyExcavation, setenergyExcavation] = useState("");
  const [energyHydroblasting, setenergyenergyHydroblasting] = useState("");
  const [energyPressure, setenergyPressure] = useState("");
  const [energyEnergized, setenergyEnergized] = useState("");
  const [energyRigging, setenergyRigging] = useState("");
  const [energyIOECheckbox, setenergyIOECheckbox] = useState("");
  const [energyIOEInput, setenergyIOEInput] = useState("");
  const [energyCheckbox, setenergyCheckbox] = useState("");
  const [energyInput, setenergyInput] = useState("");
  //Eqipment
  const [eqiNA, seteqiNA] = useState("");
  const [eqiList0, seteqiList0] = useState("");
  const [eqiList1, seteqiList1] = useState("");
  const [eqiList2, seteqiList2] = useState("");
  const [eqiList3, seteqiList3] = useState("");
  const [eqiList4, seteqiList4] = useState("");
  const [eqiList5, seteqiList5] = useState("");
  const [eqiList6, seteqiList6] = useState("");
  const [eqiList7, seteqiList7] = useState("");
  const [eqiList8, seteqiList8] = useState("");
  const [eqiList9, seteqiList9] = useState("");
  const [eqiList10, seteqiList10] = useState("");
  const [eqiList11, seteqiList11] = useState("");
  const [eqiList12, seteqiList12] = useState("");
  const [eqiList13, seteqiList13] = useState("");
  const [eqiList14, seteqiList14] = useState("");
  const [eqiListInput14, seteqiListInput14] = useState("");
  const [eqiList15, seteqiList15] = useState("");
  const [eqiListInput15, seteqiListInput15] = useState("");
  //CHEMICAL
  const [chemNA, setchemNA] = useState("");
  const [chemList0, setchemList0] = useState("");
  const [chemList1, setchemList1] = useState("");
  const [chemList2, setchemList2] = useState("");
  const [chemList3, setchemList3] = useState("");
  const [chemList4, setchemList4] = useState("");
  const [chemList5, setchemList5] = useState("");
  const [chemList6, setchemList6] = useState("");
  const [chemList7, setchemList7] = useState("");
  // 6.PROTECTION
  //Head/Face
  const [faceList0, setfaceList0] = useState("");
  const [faceList1, setfaceList1] = useState("");
  const [faceList2, setfaceList2] = useState("");
  const [faceListInput2, setfaceListInput2] = useState("");
  const [faceList3, setfaceList3] = useState("");
  const [faceList4, setfaceList4] = useState("");
  const [faceList5, setfaceList5] = useState("");
  const [faceList6, setfaceList6] = useState("");
  const [faceList7, setfaceList7] = useState("");
  const [faceList8, setfaceList8] = useState("");
  const [faceList9, setfaceList9] = useState("");
  const [faceListInput9, setfaceListInput9] = useState("");
  //Hands
  const [handList0, sethandList0] = useState("");
  const [handList1, sethandList1] = useState("");
  const [handList2, sethandList2] = useState("");
  const [handList3, sethandList3] = useState("");
  const [handList4, sethandList4] = useState("");
  const [handList5, sethandList5] = useState("");
  const [handList6, sethandList6] = useState("");
  const [handList7, sethandList7] = useState("");
  const [handListInput7, sethandListInput7] = useState("");
  const [handList8, sethandList8] = useState("");
  const [handListInput8, sethandListInput8] = useState("");
  const [handList9, sethandList9] = useState("");
  const [handListInput10, sethandListInput10] = useState("");
  //Arms/Body
  const [bodyList0, setbodyList0] = useState("");
  const [bodyList1, setbodyList1] = useState("");
  const [bodyList2, setbodyList2] = useState("");
  const [bodyList3, setbodyList3] = useState("");
  const [bodyList4, setbodyList4] = useState("");
  const [bodyList5, setbodyList5] = useState("");
  const [aptronBodyList5, setAptronBodyList5] = useState("");
  const [bodyList6, setbodyList6] = useState("");
  const [bodyList7, setbodyList7] = useState("");
  const [bodyList8, setbodyList8] = useState("");
  const [bodyListInput8, setBodyListInput8] = useState("");
  const [bodyList9, setbodyList9] = useState("");
  const [bodyListInput6, setbodyListInput6] = useState("");
  const [handListInput9, sethandListInput9] = useState("");
  //Respiratory
  const [respList0, setrespList0] = useState("");
  const [respList1, setrespList1] = useState("");
  const [respList2, setrespList2] = useState("");
  const [respList3, setrespList3] = useState("");
  const [respList4, setrespList4] = useState("");
  const [respList5, setrespList5] = useState("");
  const [respListInput5, setrespListInput5] = useState("");
  // Feet/Legs
  const [feetList0, setfeetList0] = useState("");
  const [feetList1, setfeetList1] = useState("");
  const [feetList2, setfeetList2] = useState("");
  const [feetList3, setfeetList3] = useState("");
  const [feetList4, setfeetList4] = useState("");
  const [feetList5, setfeetList5] = useState("");
  const [feetList6, setfeetList6] = useState("");
  const [feetListInput6, setfeetListInput6] = useState("");
  const [comment, setComment] = useState("");
  //7.WORK ENVIRONMENT
  //Access
  const [accessList0, setaccessList0] = useState("");
  const [accessList1, setaccessList1] = useState("");
  const [accessList2, setaccessList2] = useState("");
  const [accessList3, setaccessList3] = useState("");
  const [accessList4, setaccessList4] = useState("");
  const [accessList5, setaccessList5] = useState("");
  //Preventation
  const [preventList0, setpreventList0] = useState("");
  const [preventList1, setpreventList1] = useState("");
  const [preventList2, setpreventList2] = useState("");
  const [preventList3, setpreventList3] = useState("");
  const [preventList4, setpreventList4] = useState("");
  //Protection
  const [protectList0, setprotectList0] = useState("");
  const [protectList1, setprotectList1] = useState("");
  const [protectList2, setprotectList2] = useState("");
  const [protectList3, setprotectList3] = useState("");
  const [protectList4, setprotectList4] = useState("");
  const [protectList4Check, setprotectList4Check] = useState("");
  const [protectList5, setprotectList5] = useState("");

  //Barricades
  const [BarricadesList0, setBarricadesList0] = useState("");
  const [BarricadesList1, setBarricadesList1] = useState("");
  const [BarricadesList2, setBarricadesList2] = useState("");
  const [BarricadesList3, setBarricadesList3] = useState("");

  //Additional Requirements
  const [additionalList0, setadditionalList0] = useState("");
  const [additionalList1, setadditionalList1] = useState("");
  const [additionalList2, setadditionalList2] = useState("");
  const [additionalList2value, setadditionalList2value] = useState("");
  const [additionalList3, setadditionalList3] = useState("");
  const [additionalList3value, setadditionalList3value] = useState("");
  const [additionalList4, setadditionalList4] = useState("");
  const [additionalList4value, setadditionalList4value] = useState("");
  const [additionalList5value, setadditionalList5value] = useState("");
  const [additionalList5, setadditionalList5] = useState("");
  const [additionalList6, setadditionalList6] = useState("");
  //Signage
  const [signageList0, setsignageList0] = useState("");
  const [signageListInput0, setsignageListInput0] = useState("");
  const [signageList1, setsignageList1] = useState("");
  const [signageListInput1, setsignageListInput1] = useState("");
  const [signageList2, setsignageList2] = useState("");
  const [signageListInput2, setsignageListInput2] = useState("");
  const [signageList3, setsignageList3] = useState("");
  const [signageListInput3, setsignageListInput3] = useState("");
  const [signageList4, setsignageList4] = useState("");
  const [signageListInput4, setsignageListInput4] = useState("");

  //8. Training
  const [trainingNA, settrainingNA] = useState("");
  const [trainingList0, settrainingList0] = useState("");
  const [trainingList1, settrainingList1] = useState("");
  const [trainingList2, settrainingList2] = useState("");
  const [trainingList3, settrainingList3] = useState("");
  const [trainingList4, settrainingList4] = useState("");
  const [trainingList5, settrainingList5] = useState("");
  const [trainingList6, settrainingList6] = useState("");
  const [trainingList7, settrainingList7] = useState("");
  const [trainingList8, settrainingList8] = useState("");
  const [trainingList9, settrainingList9] = useState("");
  const [trainingList10, settrainingList10] = useState("");
  const [trainingList11, settrainingList11] = useState("");
  //9. Core Permit Receiver
  const [cprList0, setcprList0] = useState("");
  const [cprList1, setcprList1] = useState("");
  const [cprList2, setcprList2] = useState("");
  const [cprList3, setcprList3] = useState("");
  const [cprList4, setcprList4] = useState("");
  const [cprList5, setcprList5] = useState("");
  const [cprList6, setcprList6] = useState("");
  const [cprList7, setcprList7] = useState("");
  // 10.RESPONSIBILITIES
  const [responsebilityCommunicated, setresponsebilityCommunicated] =
    useState("");
  const [responsebilityStoppage, setresponsebilityStoppage] = useState("");
  const [responsebilitySafety, setresponsebilitySafety] = useState("");
  const [responsebilityConcerns, setresponsebilityConcerns] = useState("");
  const [responsebilityReceiver, setresponsebilityReceiver] = useState("");
  const [responsebilityOtherCheckbox, setresponsebilityOtherCheckbox] =
    useState("");
  const [responsebilityOtherInput, setresponsebilityOtherInput] = useState("");
  const [responsebilityPhone, setresponsebilityPhone] = useState("");
  const [responsebilityInitial, setresponsebilityInitial] = useState("");
  //11. Change
  const [changescop, setchangescop] = useState("");
  const [changehazard, setchangehazard] = useState("");
  const [changecontrol, setchangecontrol] = useState("");

  const [changeissuer, setchangeissuer] = useState("");
  const [changesighn, setchangesighn] = useState("");
  const [changereceiver, setchangereceiver] = useState("");
  const [changesignature, setchangesignature] = useState("");
  const [changeDate, setchangeDate] = useState("");
  const [changeTime, setchangeTime] = useState("");
  //closeout
  const [closeoutDate, setcloseoutDate] = useState("");
  const [closeoutTime, setcloseoutTime] = useState("");
  const [closeoutanswer, setcloseoutanswer] = useState("");
  const [closeoutquestion, setcloseoutquestion] = useState("");
  const [closeoutremarks, setcloseoutremarks] = useState("");
  const [closeoutCompletedYes, setcloseoutCompletedYes] = useState("");
  const [closeoutCompletedNo, setcloseoutCompletedNo] = useState("");
  const [closeoutReviewedYes, setcloseoutReviewedYes] = useState("");
  const [closeoutReviewedNo, setcloseoutReviewedNo] = useState("");
  const [reconciledYes, setreconciledYes] = useState("");
  const [reconciledNo, setreconciledNo] = useState("");



  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    getFormData();
    return () => { };
  }, []);

  async function filterArray(array, keyName, searchText) {
    var filteredArray = array.filter((data) => data[keyName] === searchText);
    return filteredArray;
  }
  const getFormData = async () => {
    const dataFromStore = await retrieveData(USER_PROFILE);
    const headers = await GetApiHeaders();
    const dataToSend = {
      mobileNumber: headers.mobileNumber == null ? "" : headers.mobileNumber,
      name: "",
      PermitID: IdFromURL,
    };
    const response = await axios.post(getAllPermitsByCorePermitID, dataToSend, {
      headers,
    });
    if (response.data.statusCode == 200) {
      if (
        response.data.response != undefined &&
        response.data.response != null
      ) {
        console.log(
          "response:::",
          JSON.stringify(response.data.response.core_permit)
        );
        var dataLast = await filterArray(
          response.data.response.core_permit,
          "permitID",
          IdFromURL
        ); //113

        if (dataLast[0] != undefined) {
          console.log(
            "dataList",
            dataLast["0"].permitObject.people.permitIssuer
          );

          setPrimaryRequesterName(dataLast[0].primaryRequesterName);
          setSuspendedBy(dataLast[0].suspendedBy);
          setSuspendTime(dataLast[0].suspendTime);
          setSuspendDate(dataLast[0].suspendDate);
          setSuspendedRemarks(dataLast[0].suspendedRemarks);
          setApproveOrRejectedByName(dataLast[0].approveOrRejectedByName);
          setIsTransferred(dataLast[0].isTransferred);
          setTransferToName(dataLast[0].transferToName);
          setCloseOutTime(dataLast[0].closeOutTime);
          setCloseOutDate(dataLast[0].closeOutDate);
          setIssuerTransferTo(dataLast[0].issuerTransferTo);
          setReceiverTransferTo(dataLast[0].receiverTransferTo);


          //Emergency
          setInitialsOne(dataLast["0"].permitObject.permitInitials.initialsOne);
          // alert("initialsOne", initialsOne);
          setInitialsTwo(dataLast["0"].permitObject.permitInitials.initialsTwo);
          setInitialsThree(
            dataLast["0"].permitObject.permitInitials.initialsThree
          );



          const rawDateInputOne = dataLast[0].permitObject.permitInitials.dateInputOne;
          // Validate and format the date
          const formattedDateInputOne = moment(rawDateInputOne, "DD-MM-YYYY", true).isValid()
            ? moment(rawDateInputOne, "DD-MM-YYYY").format("DD-MMM-YYYY")
            : rawDateInputOne;

          setDateInputOne(formattedDateInputOne);

          setPermitsOne(dataLast["0"].permitObject.permitInitials.permitsOne);
          setPermitsTwo(dataLast["0"].permitObject.permitInitials.permitsTwo);

          setemergency(dataLast["0"].permitObject.emergency.phoneNumber);
          setal(dataLast["0"].permitObject.emergency.assemblyLocation);
          setpermitIssuer(dataLast["0"].permitObject.people.permitIssuer);
          setpermitIssuerSign(
            dataLast["0"].permitObject.people.permitIssuerSign
          );
          setphoneNumber(dataLast["0"].permitObject.people.phoneNumber);
          setphonenumber(dataLast["0"].permitObject.people.phonenumber);//new key
          setplantFacility(dataLast["0"].permitObject.people.plantFacility);
          setnoOfWorkingPermit(
            dataLast["0"].permitObject.people.noOfWorkingPermit
          ); //company

          setreceiverTransferTosign(
            dataLast["0"].permitObject.people.receiverTransferToSign
          );
          setWorkLimit(dataLast["0"].permitObject.people.workLimit);
          setnamesOfPeopleWorkingUnderthisPermit(
            dataLast["0"].permitObject.people.namesWorkingPermit
          );
          setcoSignature(dataLast["0"].permitObject.people.coSignature);
          setcoSignatureSign(dataLast["0"].permitObject.people.coSignatureSign);


          setjobSiteInspector(
            dataLast["0"].permitObject.people.inspectorName
          );
          setissuertransferredto(
            dataLast["0"].permitObject.people.issueTransferTo
          );
          setissueTransferToSign(
            dataLast["0"].permitObject.people.issueTransferToSign
          );
          setpermitReceiver(dataLast["0"].permitObject.people.permitReceiver);
          setpermitReceiverSign(
            dataLast["0"].permitObject.people.permitReceiverSign
          );
          setcompany(dataLast["0"].permitObject.people.company);
          setadditionalAttachment(
            dataLast["0"].permitObject.people.additionalAttachment
          );
          setLocation(dataLast["0"].permitObject.people.location);
          setstartTime(dataLast["0"].permitObject.people.startTime);
          setendTime(dataLast["0"].permitObject.people.endTime);
          setstartTime2(dataLast["0"].permitObject.people.startTimeTwo);
          setendTime2(dataLast["0"].permitObject.people.endTimeTwo);
          setdate(dataLast["0"].permitObject.people.date);

          //People List
          if (dataLast[0].permitObject.people.onSiteInspection == "yes") {
            setonSiteInspection("checked");
          } else if (dataLast[0].permitObject.people.onSiteInspection == "no") {
            setonSiteInspectionNo("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["0"].checked == "yes"
          ) {
            setplYes0("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["0"].checked == "false"
          ) {
            setplNo0("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["1"].checked == "yes"
          ) {
            //true
            setplYes1("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["1"].checked == "false"
          ) {
            setplNo1("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["2"].checked == "yes"
          ) {
            setplYes2("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["2"].checked == "false"
          ) {
            setplNo2("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["3"].checked == "yes"
          ) {
            setplYes3("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["3"].checked == "false"
          ) {
            setplNo3("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["4"].checked == "yes"
          ) {
            setplYes4("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["4"].checked == "false"
          ) {
            setplNo4("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["5"].checked == "yes"
          ) {
            setplYes5("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["5"].checked == "false"
          ) {
            setplNo5("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["6"].checked == "yes"
          ) {
            setplYes6("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["6"].checked == "false"
          ) {
            setplNo6("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["7"].checked == "yes"
          ) {
            setplYes7("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["7"].checked == "false"
          ) {
            setplNo7("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["8"].checked == "yes"
          ) {
            setplYes8("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["8"].checked == "false"
          ) {
            setplNo8("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["9"].checked == "yes"
          ) {
            setplYes9("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["9"].checked == "false"
          ) {
            setplNo9("checked");
          }

          if (
            dataLast[0].permitObject.people.peopleList["10"].checked == "yes"
          ) {
            setplYes10("checked");
          }
          if (
            dataLast[0].permitObject.people.peopleList["10"].checked == "false"
          ) {
            setplNo10("checked");
          }
          //Extended use
          if (dataLast[0].permitObject.people.extendedUse.yes == "true") {
            seteuYes("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.NA == "true") {
            seteuNo("checked");
          }

          if (dataLast[0].permitObject.people.extendedUse.mon == "true") {
            seteuMon("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.tues == "true") {
            seteuTue("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.wed == "true") {
            seteuWed("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.thus == "true") {
            seteuThu("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.fri == "true") {
            seteuFri("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.sat == "true") {
            seteuSat("checked");
          }
          if (dataLast[0].permitObject.people.extendedUse.sun == "true") {
            seteuSun("checked");
          }
          setStartTimeOne(dataLast[0].permitObject.people.extendedUse.startTimeOne);
          setEndTimeOne(dataLast[0].permitObject.people.extendedUse.endTimeOne);

          //Environment
          if (
            dataLast[0].permitObject.environmentData.environmentList["0"]
              .checked == true
          ) {
            setenvNA0("checked");
          }
          setenvInput0(
            dataLast[0].permitObject.environmentData.environmentList["0"]
              .inputValue
          );
          if (
            dataLast[0].permitObject.environmentData.environmentList["1"]
              .checked == true
          ) {
            setenvNA1("checked");
          }
          setenvInput1(
            dataLast[0].permitObject.environmentData.environmentList["1"]
              .inputValue
          );
          if (
            dataLast[0].permitObject.environmentData.environmentList["2"]
              .checked == true
          ) {
            setenvNA2("checked");
          }
          setenvInput2(
            dataLast[0].permitObject.environmentData.environmentList["2"]
              .inputValue
          );
          if (
            dataLast[0].permitObject.environmentData.environmentList["3"]
              .checked == true
          ) {
            setenvNA3("checked");
          }
          setenvInput3(
            dataLast[0].permitObject.environmentData.environmentList["3"]
              .inputValue
          );
          // 3.ENERGY

          if (dataLast["0"].permitObject.energy.answer.toString() == "true") {
            setenergyAnswer("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "0"
            ].checked.toString() == "true"
          ) {
            setenergyLine("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "1"
            ].checked.toString() == "true"
          ) {
            setenergyHotwork("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "2"
            ].checked.toString() == "true"
          ) {
            setenergyConfined("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "3"
            ].checked.toString() == "true"
          ) {
            setenergyCritical("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "4"
            ].checked.toString() == "true"
          ) {
            setenergyCrane("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "5"
            ].checked.toString() == "true"
          ) {
            setenergyIsolations("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "6"
            ].checked.toString() == "true"
          ) {
            setenergyExcavation("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "7"
            ].checked.toString() == "true"
          ) {
            setenergyenergyHydroblasting("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "8"
            ].checked.toString() == "true"
          ) {
            setenergyPressure("checked");
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "9"
            ].checked.toString() == "true"
          ) {
            setenergyEnergized("checked");
          }

          if (
            dataLast["0"].permitObject.energy.energyList[
              "10"
            ].checked.toString() == "true"
          ) {
            setenergyRigging("checked");
          }

          if (
            dataLast["0"].permitObject.energy.energyList[
              "11"
            ].checked.toString() == "true"
          ) {
            setenergyIOECheckbox("checked");
            setenergyIOEInput(
              dataLast["0"].permitObject.energy.energyList["11"].inputValue
            );
          }
          if (
            dataLast["0"].permitObject.energy.energyList[
              "12"
            ].checked.toString() == "true"
          ) {
            setenergyCheckbox("checked");
            setenergyInput(
              dataLast["0"].permitObject.energy.energyList["12"].inputValue
            );
          }
          //CHEMICAL
          if (dataLast["0"].permitObject.chemical.answer == "true") {
            setchemNA("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["0"]
              .checked == true
          ) {
            setchemList0("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["1"]
              .checked == true
          ) {
            setchemList1("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["2"]
              .checked == true
          ) {
            setchemList2("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["3"]
              .checked == true
          ) {
            setchemList3("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["4"]
              .checked == true
          ) {
            setchemList4("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["5"]
              .checked == true
          ) {
            setchemList5("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["6"]
              .checked == true
          ) {
            setchemList6("checked");
          }
          if (
            dataLast["0"].permitObject.chemical.chemicalHazardsList["7"]
              .checked == true
          ) {
            setchemList7("checked");
          }

          //Eqipment
          if (dataLast["0"].permitObject.equipment.answer == "true") {
            seteqiNA("checked");
          }

          if (
            dataLast["0"].permitObject.equipment.equipmentList["0"].checked ==
            true
          ) {
            seteqiList0("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["1"].checked ==
            true
          ) {
            seteqiList1("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["2"].checked ==
            true
          ) {
            seteqiList2("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["3"].checked ==
            true
          ) {
            seteqiList3("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["4"].checked ==
            true
          ) {
            seteqiList4("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["5"].checked ==
            true
          ) {
            seteqiList5("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["6"].checked ==
            true
          ) {
            seteqiList6("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["7"].checked ==
            true
          ) {
            seteqiList7("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["8"].checked ==
            true
          ) {
            seteqiList8("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["9"].checked ==
            true
          ) {
            seteqiList9("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["10"].checked ==
            true
          ) {
            seteqiList10("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["11"].checked ==
            true
          ) {
            seteqiList11("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["12"].checked ==
            true
          ) {
            seteqiList12("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["13"].checked ==
            true
          ) {
            seteqiList13("checked");
          }
          if (
            dataLast["0"].permitObject.equipment.equipmentList["14"].checked ==
            true
          ) {
            seteqiList14("checked");
          }
          seteqiListInput14(
            dataLast["0"].permitObject.equipment.equipmentList["14"].inputValue
          );

          if (
            dataLast["0"].permitObject.equipment.equipmentList["15"].checked ==
            true
          ) {
            seteqiList15("checked");
          }

          //6.PROTECTION
          //Head/Face
          if (
            dataLast["0"].permitObject.protection.headFaceList["0"].checked ==
            true
          ) {
            setfaceList0("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["1"].checked ==
            true
          ) {
            setfaceList1("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["2"].checked ==
            true
          ) {
            setfaceList2("checked");
          }
          setfaceListInput2(
            dataLast["0"].permitObject.protection.headFaceList["2"].inputValue
          );

          if (
            dataLast["0"].permitObject.protection.headFaceList["3"].checked ==
            true
          ) {
            setfaceList3("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["4"].checked ==
            true
          ) {
            setfaceList4("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["5"].checked ==
            true
          ) {
            setfaceList5("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["6"].checked ==
            true
          ) {
            setfaceList6("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["7"].checked ==
            true
          ) {
            setfaceList7("checked");
          }
          if (
            dataLast["0"].permitObject.protection.headFaceList["8"].checked ==
            true
          ) {
            setfaceList8("checked");
          }
          // alert(dataLast['0'].permitObject.protection.headFaceList['9'].checked)
          if (
            dataLast["0"].permitObject.protection.headFaceList["9"].checked ==
            true
          ) {
            setfaceList9("checked");
          }
          setfaceListInput9(
            dataLast["0"].permitObject.protection.headFaceList["9"].inputValue
          );
          //Hands
          if (
            dataLast["0"].permitObject.protection.handsList["0"].checked == true
          ) {
            sethandList0("checked");
          }
          sethandListInput8(
            dataLast["0"].permitObject.protection.handsList["0"].inputValue
          );
          if (
            dataLast["0"].permitObject.protection.handsList["1"].checked == true
          ) {
            sethandList1("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["2"].checked == true
          ) {
            sethandList2("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["3"].checked == true
          ) {
            sethandList3("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["4"].checked == true
          ) {
            sethandList4("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["5"].checked == true
          ) {
            sethandList5("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["6"].checked == true
          ) {
            sethandList6("checked");
          }
          if (
            dataLast["0"].permitObject.protection.handsList["7"].checked == true
          ) {
            sethandList7("checked");
          }
          sethandListInput7(
            dataLast["0"].permitObject.protection.handsList["7"].inputValue
          );

          //Arms/Body
          if (
            dataLast["0"].permitObject.protection.armsBodyList["0"].checked ==
            true
          ) {
            setbodyList0("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["1"].checked ==
            true
          ) {
            setbodyList1("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["2"].checked ==
            true
          ) {
            setbodyList2("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["3"].checked ==
            true
          ) {
            setbodyList3("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["4"].checked ==
            true
          ) {
            setbodyList4("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["5"].checked ==
            true
          ) {
            setbodyList5("checked");
          }
          setAptronBodyList5(
            dataLast["0"].permitObject.protection.armsBodyList["5"].inputValue
          );

          if (
            dataLast["0"].permitObject.protection.armsBodyList["6"].checked ==
            true
          ) {
            setbodyList6("checked");
          }
          setbodyListInput6(
            dataLast["0"].permitObject.protection.armsBodyList["6"].inputValue
          );

          if (
            dataLast["0"].permitObject.protection.armsBodyList["7"].checked ==
            true
          ) {
            setbodyList7("checked");
          }
          if (
            dataLast["0"].permitObject.protection.armsBodyList["8"].checked ==
            true
          ) {
            setbodyList8("checked");
          }
          setBodyListInput8(
            dataLast["0"].permitObject.protection.armsBodyList["9"].inputValue
          );

          //Respiratory
          if (
            dataLast["0"].permitObject.protection.respiratoryList["0"]
              .checked == true
          ) {
            setrespList0("checked");
          }
          if (
            dataLast["0"].permitObject.protection.respiratoryList["1"]
              .checked == true
          ) {
            setrespList1("checked");
          }
          if (
            dataLast["0"].permitObject.protection.respiratoryList["2"]
              .checked == true
          ) {
            setrespList2("checked");
          }
          if (
            dataLast["0"].permitObject.protection.respiratoryList["3"]
              .checked == true
          ) {
            setrespList3("checked");
          }
          if (
            dataLast["0"].permitObject.protection.respiratoryList["4"]
              .checked == true
          ) {
            setrespList4("checked");
          }
          if (
            dataLast["0"].permitObject.protection.respiratoryList["5"]
              .checked == true
          ) {
            setrespList5("checked");
          }
          setrespListInput5(
            dataLast["0"].permitObject.protection.respiratoryList["5"]
              .inputValue
          );

          // Feet/Legs
          if (
            dataLast["0"].permitObject.protection.feetLegsList["0"].checked ==
            true
          ) {
            setfeetList0("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["1"].checked ==
            true
          ) {
            setfeetList1("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["2"].checked ==
            true
          ) {
            setfeetList2("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["3"].checked ==
            true
          ) {
            setfeetList3("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["4"].checked ==
            true
          ) {
            setfeetList4("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["5"].checked ==
            true
          ) {
            setfeetList5("checked");
          }
          if (
            dataLast["0"].permitObject.protection.feetLegsList["6"].checked ==
            true
          ) {
            setfeetList6("checked");
          }
          setfeetListInput6(
            dataLast["0"].permitObject.protection.feetLegsList["7"].inputValue
          );
          setComment(
            dataLast["0"].permitObject.protection.comments.commentValue
          );

          //7.WORK ENVIRONMENT
          //Access
          if (
            dataLast["0"].permitObject.workEnvironment.accessList["0"]
              .checked == true
          ) {
            setaccessList0("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.accessList["1"]
              .checked == true
          ) {
            setaccessList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.accessList["2"]
              .checked == true
          ) {
            setaccessList2("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.accessList["3"]
              .checked == true
          ) {
            setaccessList3("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.accessList["4"]
              .checked == true
          ) {
            setaccessList4("checked");
            setaccessList5(
              dataLast["0"].permitObject.workEnvironment.accessList["4"]
                .inputValue
            );
          }
          //Preventation
          if (
            dataLast["0"].permitObject.workEnvironment.preventionList["0"]
              .checked == true
          ) {
            setpreventList0("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.preventionList["1"]
              .checked == true
          ) {
            setpreventList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.preventionList["2"]
              .checked == true
          ) {
            setpreventList2("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.preventionList["3"]
              .checked == true
          ) {
            setpreventList3("checked");
            setpreventList4(
              dataLast["0"].permitObject.workEnvironment.preventionList["3"]
                .inputValue
            );
          }
          //Protection
          if (
            dataLast["0"].permitObject.workEnvironment.protectionList["0"]
              .checked == true
          ) {
            setprotectList0("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.protectionList["1"]
              .checked == true
          ) {
            setprotectList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.protectionList["2"]
              .checked == true
          ) {
            setprotectList2("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.protectionList["3"]
              .checked == true
          ) {
            setprotectList3("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.protectionList["4"]
              .checked == true
          ) {
            setprotectList4("checked");
          }

          setprotectList5(
            dataLast["0"].permitObject.workEnvironment.protectionList["4"]
              ?.inputValue
          );

          //Barricades
          if (
            dataLast["0"].permitObject.workEnvironment.barricadesList[
              "0"
            ].checked.toString() == "true"
          ) {
            setBarricadesList0("checked");
          }

          if (
            dataLast["0"].permitObject.workEnvironment.barricadesList[
              "1"
            ].checked.toString() == "true"
          ) {
            setBarricadesList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.barricadesList[
              "2"
            ].checked.toString() == "true"
          ) {
            setBarricadesList2("checked");
          }
          setBarricadesList3(
            dataLast["0"].permitObject.workEnvironment.barricadesList["2"]
              .inputValue
          );

          //Additional Requirements
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "0"
            ].checked == true
          ) {
            setadditionalList0("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "1"
            ].checked == true
          ) {
            setadditionalList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "2"
            ].checked == true
          ) {
            setadditionalList2("checked");
            setadditionalList2value(
              dataLast["0"].permitObject.workEnvironment
                .additionalRequirmentList["2"].inputValue
            );
          }
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "3"
            ].checked == true
          ) {
            setadditionalList3("checked");
            setadditionalList3value(
              dataLast["0"].permitObject.workEnvironment
                .additionalRequirmentList["3"].inputValue
            );
          }
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "4"
            ].checked == true
          ) {
            setadditionalList4("checked");
            setadditionalList4value(
              dataLast["0"].permitObject.workEnvironment
                .additionalRequirmentList["4"].inputValue
            );
          }
          if (
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "5"
            ].checked == true
          ) {
            setadditionalList5("checked");
          }

          setadditionalList5value(
            dataLast["0"].permitObject.workEnvironment.additionalRequirmentList[
              "6"
            ].inputValue
          );

          //Signage
          if (
            dataLast["0"].permitObject.workEnvironment.signage["0"].checked ==
            true
          ) {
            setsignageList0("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.signage["1"].checked ==
            true
          ) {
            setsignageList1("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.signage["2"].checked ==
            true
          ) {
            setsignageList2("checked");
          }
          if (
            dataLast["0"].permitObject.workEnvironment.signage["3"].checked ==
            true
          ) {
            setsignageList3("checked");
          }
          setsignageListInput0(
            dataLast["0"].permitObject.workEnvironment.signage["0"].inputValue
          );
          setsignageListInput0(
            dataLast["0"].permitObject.workEnvironment.signage["1"].inputValue
          );
          setsignageListInput0(
            dataLast["0"].permitObject.workEnvironment.signage["2"].inputValue
          );
          setsignageListInput0(
            dataLast["0"].permitObject.workEnvironment.signage["3"].inputValue
          );
          //8. Training
          if (dataLast["0"].permitObject.trainData.answer == "true") {
            settrainingNA("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["0"].checked ==
            true
          ) {
            settrainingList0("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["1"].checked ==
            true
          ) {
            settrainingList1("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["2"].checked ==
            true
          ) {
            settrainingList2("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["3"].checked ==
            true
          ) {
            settrainingList3("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["4"].checked ==
            true
          ) {
            settrainingList4("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["5"].checked ==
            true
          ) {
            settrainingList5("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["6"].checked ==
            true
          ) {
            settrainingList6("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["7"].checked ==
            true
          ) {
            settrainingList7("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["8"].checked ==
            true
          ) {
            settrainingList8("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["9"].checked ==
            true
          ) {
            settrainingList9("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["10"].checked ==
            true
          ) {
            settrainingList10("checked");
          }
          if (
            dataLast["0"].permitObject.trainData.trainingList["11"].checked ==
            true
          ) {
            settrainingList11("checked");
          }
          //9.CORE PERMIT RECEIVER
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["0"]
              .checked == true
          ) {
            setcprList0("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["1"]
              .checked == true
          ) {
            setcprList1("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["2"]
              .checked == true
          ) {
            setcprList2("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["3"]
              .checked == true
          ) {
            setcprList3("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["4"]
              .checked == true
          ) {
            setcprList4("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["5"]
              .checked == true
          ) {
            setcprList5("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["6"]
              .checked == true
          ) {
            setcprList6("checked");
          }
          if (
            dataLast["0"].permitObject.recData.corePermitReceiverList["7"]
              .checked == true
          ) {
            setcprList7("checked");
          }
          //10. Responsibility
          setresponsebilityCommunicated(
            dataLast["0"].permitObject.respdata.answer
          );
          if (
            dataLast["0"].permitObject.respdata.responsibilitiesList["0"]
              .checked == true
          ) {
            setresponsebilityStoppage("checked");
          }
          if (
            dataLast["0"].permitObject.respdata.responsibilitiesList["1"]
              .checked == true
          ) {
            setresponsebilitySafety("checked");
          }
          if (
            dataLast["0"].permitObject.respdata.responsibilitiesList["2"]
              .checked == true
          ) {
            setresponsebilityConcerns("checked");
          }
          if (
            dataLast["0"].permitObject.respdata.responsibilitiesList["3"]
              .checked == true
          ) {
            setresponsebilityReceiver("checked");
          }
          if (
            dataLast["0"].permitObject.respdata.responsibilitiesList["4"]
              .checked == true
          ) {
            setresponsebilityOtherCheckbox("checked");
          }
          setresponsebilityOtherInput(
            dataLast["0"].permitObject.respdata.responsibilitiesList["4"]
              .inputValue
          );
          setresponsebilityInitial(
            dataLast["0"].permitObject.respdata.initials
          );
          setresponsebilityPhone(dataLast["0"].permitObject.respdata.phone);

          //11. Change
          if (
            dataLast["0"].permitObject.changeData.changeList[0].checked == true
          ) {
            setchangescop("checked");
          }
          if (
            dataLast["0"].permitObject.changeData.changeList[1].checked == true
          ) {
            setchangehazard("checked");
          }
          if (
            dataLast["0"].permitObject.changeData.changeList[2].checked == true
          ) {
            setchangecontrol("checked");
          }
          setchangeissuer(dataLast["0"].permitObject.changeData.issuer);
          setchangesighn(dataLast["0"].permitObject.changeData.sighn);
          setchangereceiver(dataLast["0"].permitObject.changeData.receiver);
          setchangesignature(dataLast["0"].permitObject.changeData.signature);
          setchangeDate(dataLast["0"].permitObject.changeData.date);
          setchangeTime(dataLast["0"].permitObject.changeData.time);

          //closeout
          setcloseoutDate(dataLast["0"].permitObject.closeOutData.date);
          setcloseoutTime(dataLast["0"].permitObject.closeOutData.time);

          setcloseoutanswer(dataLast["0"].permitObject.closeOutData.answer);
          setcloseoutquestion(dataLast["0"].permitObject.closeOutData.question);
          setcloseoutremarks(dataLast["0"].permitObject.closeOutData.remarks);
          if (
            dataLast["0"].permitObject.closeOutData.closeOutList[0].checked ==
            "yes"
          ) {
            setcloseoutCompletedYes("checked");
          }
          if (
            dataLast["0"].permitObject.closeOutData.closeOutList[0].checked ==
            "false"
          ) {
            setcloseoutCompletedNo("checked");
          }
          if (
            dataLast["0"].permitObject.closeOutData.closeOutList[1].checked ==
            "yes"
          ) {
            setcloseoutReviewedYes("checked");
          }
          if (
            dataLast["0"].permitObject.closeOutData.closeOutList[1].checked ==
            "false"
          ) {
            setcloseoutReviewedNo("checked");
          }

          if (
            dataLast[
              "0"
            ].permitObject.closeOutData.closeOutList[2].checked.toString() ==
            "yes"
          ) {
            setreconciledYes("checked");
          }
          if (
            dataLast["0"].permitObject.closeOutData.closeOutList[2].checked ==
            "false"
          ) {
            setreconciledNo("checked");
          }

          console.log("setpermitIssuer", permitIssuer);
          console.log("setphoneNumber", phoneNumber);
          console.log("setplantFacility", plantFacility);
          console.log("setnoOfWorkingPermit", noOfWorkingPermit);
        }
      }
    }
  };
  useEffect(() => { }, [startTime, endTime]);

  return (
    <body id="page-top" style={{ margin: "5px" }}>
      <div style={{ textAlign: "right" }}>
        <button onClick={handlePrint} className="btn btn-primary print-hidden ">
          Print
        </button>
      </div>
      <div id="wrapper">
        {/* <SideMenu /> */}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* <Header /> */}
            <form className="CorePermit_Web-form corepermit-style">
              <div>
                <div className="container">
                  <div>
                    <div>
                      <div>
                        <div className="row">
                          <div
                            className="d-flex flex-row m-0 p-0"
                            style={{ width: "100%", textAlign: "center" }}
                          >
                            <div
                              style={{
                                backgroundColor: "#E8EAEC",
                                width: "15%",
                              }}
                            >
                              <div>
                                <h3
                                  className="d-flex"
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "25px",
                                    letterSpacing: "5px",
                                    display: "flex",
                                  }}
                                >
                                  C<p style={{ color: "#2171B9" }}>O</p>RE
                                </h3>
                              </div>
                            </div>
                            <div style={{ width: "85%" }}>
                              <div
                                className="d-flex flex-row"
                                style={{
                                  backgroundColor: "#2171B9",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "6px 6px",
                                    padding: "2px",
                                    width: "40%",
                                  }}
                                >
                                  <div>
                                    <h3
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "25px",
                                        letterSpacing: "5px",
                                      }}
                                    >
                                      PERMIT
                                    </h3>
                                  </div>
                                  <div>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "12px",
                                      }}
                                    >
                                      SEEDS
                                    </h6>
                                  </div>
                                </div>
                                <div style={{ width: "57%" }}>
                                  <div
                                    className="d-flex flex-row"
                                    style={{
                                      backgroundColor: "#ffffff",
                                      margin: "3px",
                                      padding: "2px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "12px",
                                        margin: "5px 5px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      <p>CORE Permit #:</p>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="mr-2">
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsOne} // Use initialsOne here
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsTwo}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsThree}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Intials
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="d-flex flex-row">
                                          <div className="mr-2">
                                            <input
                                              type=""
                                              className="microsite-txtbxxdate"
                                              value={dateInputOne}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Date
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div>
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsOne}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsTwo}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          #permits
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="d-flex flex-row"
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div
                              style={{ backgroundColor: "red", width: "50%" }}
                            >
                              <div
                                className="d-flex flex-row"
                                style={{ padding: "5px" }}
                              >
                                <div>
                                  <i className="phonerining m-1 p-1"></i>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "5px",
                                    }}
                                  >
                                    Emergency
                                  </label>
                                </div>
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Phone <br />
                                    Number
                                  </label>
                                  <input
                                    type="text"
                                    value={emergency}
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  />
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Assembly <br />
                                    Location
                                  </label>
                                  <input
                                    type="text"
                                    value={al}
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      padding: "0px",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                width: "50%",
                              }}
                            >
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #F4673C" }}
                              >
                                <img
                                  src={Factory}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #E0A350" }}
                              >
                                <img
                                  src={HouseAndLighthouse}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #34B85F" }}
                              >
                                <img
                                  src={Labtubeheadercorepermit}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #4275BA" }}
                              >
                                <img
                                  src={shakinghandsHeadersCorepermit}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #FFC85B" }}
                              >
                                <img
                                  src={ImageheaderCorepermit}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                className="header-icons-cp"
                                style={{ border: "1px solid #793637" }}
                              >
                                <img
                                  src={DroneHeaderCorepermit}
                                  alt="img"
                                  className="header-img-cp"
                                />
                              </span>
                              <span
                                style={{
                                  display: "inline-block",
                                  border: "none",
                                  padding: "3px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  width: "35px",
                                  height: "35px",
                                  marginright: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <img
                                  src={CortevaImage}
                                  alt="img"
                                  style={{ width: "120px", height: "23px" }}
                                />
                              </span>
                            </div>

                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                display: "none",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    border: "none",
                                    padding: "3px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    width: "35px",
                                    height: "35px",
                                    marginright: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <img
                                    src={CortevaImage}
                                    alt="img"
                                    style={{ width: "170px", height: "37px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-0">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #000",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                className={`d-flex justify-content-between  align-items-center`}
                                style={{
                                  backgroundColor: "#000",
                                  color: "#fff",
                                }}
                              >
                                <i>
                                  {" "}
                                  <p
                                    style={{
                                      margin: "5px",
                                      fontSize: "9px",
                                      fontWeight: "700",
                                      fontStyle: "normal",
                                    }}
                                  >
                                    1.PEOPLE
                                  </p>
                                </i>
                                <i>
                                  <span className="core-card-icons">
                                    <img
                                      src={teachpeople}
                                      alt="mes"
                                      className="core-card-icon-size"
                                    />
                                  </span>
                                  <span className="core-card-icons">
                                    <img
                                      src={stepbysteppeople}
                                      alt="mes"
                                      className="core-card-icon-size"
                                    />
                                  </span>
                                  <span className="core-card-icons">
                                    <img
                                      src={stalline}
                                      alt="mes"
                                      className="core-card-icon-size"
                                    />
                                  </span>
                                </i>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div>
                                <div>
                                  <div className="row">
                                    <div className="col-6">
                                      <div>
                                        <div className="m-0">
                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Permit issuer
                                            </label>
                                            <div
                                              class="col-8"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <input
                                                class="col-6"
                                                placeholder="PRINT"
                                                // value={permitIssuer}
                                                value={approveOrRejectedByName}

                                              />
                                              <input
                                                class="col-6"
                                                placeholder="SIGN"
                                                value={permitIssuerSign}
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Plant/facility
                                            </label>
                                            <div class="col-8">
                                              <input
                                                class="col-12"
                                                placeholder="PRINT"
                                                value={plantFacility}
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Co-Signature
                                            </label>
                                            <div
                                              class="col-8"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <input
                                                class="col-6"
                                                placeholder="PRINT"
                                                value={coSignature}
                                              />
                                              <input
                                                class="col-6"
                                                placeholder="SIGN"
                                                value={coSignatureSign}
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Issuer transferred to
                                            </label>
                                            <div
                                              class="col-8"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <input
                                                class="col-6"
                                                placeholder="PRINT"
                                                value={issuerTransferTo}
                                              />
                                              <input
                                                class="col-6"
                                                placeholder="SIGN"
                                                value={issueTransferToSign}
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Permit Receiver
                                            </label>
                                            <div
                                              class="col-8"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <input
                                                class="col-6"
                                                placeholder="PRINT"
                                                value={permitReceiver}
                                              // value={primaryRequesterName}
                                              // value={primaryRequesterName || receiverTransferTo}
                                              />
                                              <input
                                                class="col-6"
                                                placeholder="SIGN"
                                                value={permitReceiverSign}
                                              />
                                            </div>
                                          </div>

                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-6 col-form-label core-ppl-lbl-bg1 label-height">
                                              On-site inspection required by
                                              issuer
                                            </label>
                                            <div class="col-6 d-flex flex-row cb-mt-5px">
                                              <div class="  d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp cb-mt-5px"
                                                  type="checkbox"
                                                  checked={onSiteInspection}
                                                />
                                                <label class="form-check-label">
                                                  YES
                                                </label>
                                              </div>
                                              <div class="  d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp cb-mt-5px"
                                                  type="checkbox"
                                                  checked={onSiteInspectionNo}
                                                />
                                                <label class="form-check-label">
                                                  N/A
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="form-group row frm-grp-mb-cp">
                                            <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                              Work limited to the following
                                            </label>


                                            <label class="col-12 col-form-label core-ppl-lbl-bg1 label-height">
                                              <a>
                                                JOB
                                                SCOPE/DESCRIPTION/TASKS&AREA/EQUIPMENT&BOUNDARIES
                                              </a>
                                            </label>
                                            <div>
                                              <input
                                                class="col-12"
                                                value={workLimit}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="m-0">
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                            {/* Phone Number */}
                                            Permit Issuer Phone number
                                          </label>
                                          <div class="col-8">
                                            <input
                                              class="col-12"
                                              value={phoneNumber}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-8 col-form-label core-ppl-lbl-bg1 label-height">
                                            Number of people working under this
                                            permit
                                          </label>
                                          <div class="col-4">
                                            <input
                                              class="col-12"
                                              value={noOfWorkingPermit}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-8 col-form-label core-ppl-lbl-bg1 label-height">
                                            Name(s) of people working under this
                                            permit
                                          </label>
                                          <div class="col-4">
                                            <input
                                              class="col-12"
                                              value={
                                                namesOfPeopleWorkingUnderthisPermit
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                            Receiver transferred to
                                          </label>
                                          <div
                                            class="col-8"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <input
                                              class="col-6"
                                              placeholder="PRINT"
                                              value={receiverTransferTo}
                                            />

                                            <input
                                              class="col-6"
                                              placeholder="SIGN"
                                              value={receiverTransferTosign}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-2 col-form-label core-ppl-lbl-bg1 label-height">
                                            Company
                                          </label>
                                          <div
                                            class="col-10"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <input
                                              class="col-5"
                                              placeholder="PRINT"
                                              value={company}
                                            />
                                            <label class="col-3 col-form-label core-ppl-lbl-bg1 mr-1 label-height">
                                              Date
                                            </label>
                                            <input
                                              class="col-4"
                                              placeholder="Date"
                                              value={date}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-2 col-form-label core-ppl-lbl-bg1 label-height">
                                            Start time
                                          </label>
                                          <div
                                            class="col-10"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <input
                                              class="col-5"
                                              placeholder="Start Time"
                                              value={startTime}
                                            />
                                            <label class="col-3 col-form-label core-ppl-lbl-bg1 mr-1 label-height">
                                              End time
                                            </label>
                                            <input
                                              class="col-4"
                                              placeholder="End Time"
                                              value={endTime}
                                            />
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-4 col-form-label core-ppl-lbl-bg1 label-height">
                                            Additional Attachments
                                          </label>
                                          <div class="col-8">
                                            <input
                                              class="col-12"
                                              value={additionalAttachment}
                                            />
                                          </div>
                                        </div>



                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-2 col-form-label core-ppl-lbl-bg1 label-height">
                                            Location
                                          </label>
                                          <div
                                            class="col-10"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <input
                                              class="col-5"
                                              placeholder="Location"
                                              value={location}
                                            />
                                            <label class="col-3 col-form-label core-ppl-lbl-bg1 mr-1 label-height">
                                              {/* Phone Number */}
                                              Receiver Phone number
                                            </label>
                                            <input
                                              class="col-4"
                                              placeholder="Phone Number"
                                              value={phonenumber}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="row"> */}
                                  <div
                                    class="form-group row frm-grp-mb-cp"
                                    style={{ display: "none" }}
                                  >
                                    {/* <div class="col-12"> */}
                                    <input class="col-12" />
                                    {/* </div> */}
                                  </div>
                                  {/* </div> */}

                                  <div
                                    className="row"
                                    style={{ marginTop: "-3px" }}
                                  >
                                    <div className="col-8">
                                      <div className="ml-1">
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 label-height">
                                            All people working under this permit
                                            have the necessary site training
                                            and/or orientations
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                className="cb-size-cp cb-mt-5px"
                                                checked={plYes0}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                id="URDCheckbox"
                                                className="cb-size-cp cb-mt-5px"
                                                checked={plNo0}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                              &nbsp;&nbsp;
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg2 twolabel-height">
                                            Emergency procedures have been
                                            reviewed and are understood (alarms,
                                            locations of assembly points and
                                            evacuation routes)
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes1}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo1}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 twolabel-height">
                                            Emergency equipment has been located
                                            and reviewed (Examples include, but
                                            are not limited to: nearest safety
                                            shower, eye wash, fire extinguisher
                                            and telephone and/or intercom){" "}
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes2}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo2}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg2 twolabel-height">
                                            The scope and boundaries of any
                                            other work in the area that could
                                            impact this permitted work has been
                                            reviewed and is understood
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes3}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo3}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 twolabel-height">
                                            Other workers in the area have been
                                            notified that this permitted work
                                            could impact their work, including
                                            the locations of any barricades
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes4}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo4}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg2 label-height">
                                            All equipment to be worked on has
                                            been properly prepared and
                                            identified and is ready to work on
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes5}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo5}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 label-height">
                                            All energy sources, including
                                            radiation, are isolated, tagged and
                                            confirmed
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes6}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo6}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg2 label-height">
                                            For demolition and renovation work,
                                            has the work area been inspected for
                                            asbestos
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes7}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo7}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 label-height">
                                            A procedure is in place and reviewed
                                            for tasks as required by department
                                            Procedure Use Policy (PUP)
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes8}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo8}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg2 label-height">
                                            Line of fire hazards have been
                                            discussed and mitigation techniques
                                            will be in place
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes9}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo9}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-10 col-form-label core-ppl-lbl-bg1 label-height">
                                            Fall hazards are present and an
                                            additional assessment will need to
                                            be completed (ladders, scaffold)
                                          </label>
                                          <div class="col-2 d-flex flex-row cb-mt-5px">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plYes10}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={plNo10}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-5 col-form-label core-ppl-lbl-bg3 label-height">
                                            Job site inspection required see
                                            standard for exceptions.
                                          </label>
                                          <label class="col-5 col-form-label core-ppl-lbl-bg3 label-height">
                                            Extended use/additional inspection
                                            needed
                                          </label>
                                          <div class=" col-2 d-flex flex-row">
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={euYes}
                                              />
                                              <label class="form-check-label">
                                                YES
                                              </label>
                                            </div>
                                            <div class="d-flex align-items-center">
                                              <input
                                                class="cb-size-cp cb-mt-5px"
                                                type="checkbox"
                                                checked={euNo}
                                              />
                                              <label class="form-check-label">
                                                N/A
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div
                                            className="col-6"
                                            style={{ padding: "0px" }}
                                          >
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <label class="col-6 col-form-label core-ppl-lbl-bg4 label-height">
                                                  Job site Inspector
                                                </label>
                                                <input
                                                  class="col-6 label-height"
                                                  placeholder="PRINT"
                                                  value={jobSiteInspector}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <label class="col-3 col-form-label core-ppl-lbl-bg4 label-height">
                                                  Start Time
                                                </label>
                                                <input
                                                  class="col-3 label-height"
                                                  placeholder="Start Time"
                                                  value={startTime2}
                                                />
                                                <label class="col-3 col-form-label core-ppl-lbl-bg4 mr-1 label-height">
                                                  End Time
                                                </label>
                                                <input
                                                  class="col-3 label-height"
                                                  placeholder="End Time"
                                                  value={endTime2}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euMon}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Mon
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euTue}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Tue
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euWed}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Wed
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euThu}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Thur
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euFri}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Fri
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euSat}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Sat
                                                  </label>
                                                </div>
                                                <div class="  d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp cb-mt-5px"
                                                    type="checkbox"
                                                    checked={euSun}
                                                  />
                                                  <label class="form-check-label label-height">
                                                    Sun
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <label class="col-3 col-form-label core-ppl-lbl-bg4 label-height">
                                                  Start time
                                                </label>
                                                <input
                                                  class="col-3"
                                                  placeholder="Start time"
                                                  value={startTimeOne}
                                                />
                                                <label class="col-3 col-form-label core-ppl-lbl-bg4 mr-1 label-height">
                                                  End time
                                                </label>
                                                <input
                                                  class="col-3"
                                                  placeholder="EndTime"
                                                  value={endTimeOne}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div
                                        className="card"
                                        style={{
                                          border: "1px solid #34B85F",
                                          borderRadius: "0px",
                                        }}
                                      >
                                        <div className="">
                                          <div
                                            className={`d-flex justify-content-between  align-items-center`}
                                            style={{
                                              backgroundColor: "#34B85F",
                                              color: "#fff",
                                            }}
                                          >
                                            <i>
                                              {" "}
                                              <p
                                                style={{
                                                  margin: "5px",
                                                  fontSize: "9px",
                                                  fontWeight: "700",
                                                  fontStyle: "normal",
                                                }}
                                              >
                                                2.ENVIRONMENT
                                              </p>
                                            </i>
                                            <i>
                                              <span className="core-card-icons">
                                                <img
                                                  src={envi1}
                                                  alt="mes"
                                                  className="core-card-icon-size"
                                                />
                                              </span>
                                              <span className="core-card-icons">
                                                <img
                                                  src={suit}
                                                  alt="mes"
                                                  className="core-card-icon-size"
                                                />
                                              </span>
                                              <span className="core-card-icons">
                                                <img
                                                  src={globe}
                                                  alt="mes"
                                                  className="core-card-icon-size"
                                                />
                                              </span>
                                            </i>
                                          </div>
                                        </div>
                                        <div
                                          className="card-body"
                                          style={{ padding: "0px" }}
                                        >
                                          <div>
                                            <div>
                                              <div className="col-12 mb-2">
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <label class="col-9 col-form-label core-evi-lbl-bg twolabel-height">
                                                    Potential environmental
                                                    impact & procedures for
                                                    addressing impact: spills
                                                    and/or leaks
                                                  </label>
                                                  <div class="col-3">
                                                    <div class="  d-flex align-items-center">
                                                      <input
                                                        class="cb-size-cp cb-mt-5px"
                                                        type="checkbox"
                                                        checked={envNA0}
                                                      />
                                                      <label class="form-check-label">
                                                        N/A
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <textarea
                                                    class="form-control col-12"
                                                    id="exampleFormControlTextarea1"
                                                    rows="1"
                                                  >
                                                    {envInput0}
                                                  </textarea>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <label class="col-9 col-form-label label-height core-evi-lbl-bg">
                                                    Waste disposal &
                                                    housekeeping requirements:
                                                  </label>
                                                  <div class="col-3">
                                                    <div class="  d-flex align-items-center cb-mt-5px">
                                                      <input
                                                        class="cb-size-cp cb-mt-5px"
                                                        type="checkbox"
                                                        checked={envNA1}
                                                      />
                                                      <label class="form-check-label">
                                                        N/A
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <textarea
                                                    class="form-control col-12"
                                                    id="exampleFormControlTextarea1"
                                                    rows="1"
                                                  >
                                                    {envInput1}
                                                  </textarea>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <label class="col-9 col-form-label core-evi-lbl-bg label-height">
                                                    Special atmospheric
                                                    monitoring requirements:
                                                  </label>
                                                  <div class="col-3">
                                                    <div class=" d-flex align-items-center cb-mt-5px">
                                                      <input
                                                        class="cb-size-cp cb-mt-5px"
                                                        type="checkbox"
                                                        checked={envNA2}
                                                      />
                                                      <label class="form-check-label">
                                                        N/A
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <textarea
                                                    class="form-control col-12"
                                                    id="exampleFormControlTextarea1"
                                                    rows="1"
                                                  >
                                                    {envInput2}
                                                  </textarea>
                                                </div>

                                                <div class="form-group row frm-grp-mb-cp">
                                                  <label class="col-9 col-form-label core-evi-lbl-bg label-height">
                                                    Ergonomic concerns &
                                                    safeguards:
                                                  </label>
                                                  <div class="col-3">
                                                    <div class="  d-flex align-items-center cb-mt-5px">
                                                      <input
                                                        class="cb-size-cp cb-mt-5px"
                                                        type="checkbox"
                                                        checked={envNA3}
                                                      />
                                                      <label class="form-check-label">
                                                        N/A
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="form-group row frm-grp-mb-cp">
                                                  <textarea
                                                    class="form-control col-12"
                                                    id="exampleFormControlTextarea1"
                                                    rows="1"
                                                  >
                                                    {envInput3}
                                                  </textarea>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div></div>
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>

                      {/* sai */}
                      <div className="row">
                        <div className="col-4 m-0 p-0">
                          <div style={{ width: "100%" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #184DA9",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#184DA9",
                                    color: "#fff",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      3.ENERGY
                                    </p>
                                  </i>
                                  <i>
                                    <span className="core-card-icons">
                                      <img
                                        src={FireImg}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={Thunder}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={water}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                  </i>
                                </div>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div className="col-12">
                                    <div className="form-group row frm-grp-mb-cp">
                                      <label class="col-9 col-form-label core-engy-lbl-bg twolabel-height">
                                        For the scope of work, check the
                                        appropriate permit below. Fill out the
                                        checklist or permit and attach it to
                                        this CORE Permit using the same number
                                        as the CORE permit.
                                      </label>
                                      <div class="col-3 core-checkbox-lbl-bg">
                                        <div class="d-flex align-items-center pt-2">
                                          <input
                                            class="cb-size-cp cb-mt-5px"
                                            type="checkbox"
                                            checked={energyAnswer}
                                          />
                                          <label class="form-check-label">
                                            N/A
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7   d-flex align-items-center mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyLine}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Line/Equipment Opening Permit
                                          </label>
                                        </div>
                                        <div class="col-5   d-flex align-items-center ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-1"
                                            type="checkbox"
                                            checked={energyExcavation}
                                          />
                                          <label class="form-check-label mt-0">
                                            Excavation
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7   d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyHotwork}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Hot Work Permit
                                          </label>
                                        </div>
                                        <div class="col-5  d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={energyHydroblasting}
                                          />
                                          <label class="form-check-label mt-0 label-height">
                                            Hydroblasting
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7  d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyConfined}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Confined Space Entry Permit
                                          </label>
                                        </div>
                                        <div class="col-5   d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={energyPressure}
                                          />
                                          <label class="form-check-label mt-0 label-height">
                                            Pressure washing
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7  d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyCritical}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Critical Elevated Work Permit
                                          </label>
                                        </div>
                                        <div class="col-5  d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={energyEnergized}
                                          />
                                          <label class="form-check-label mt-0 label-height">
                                            Energized electrical work
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7   d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyCrane}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Crane Operation Permit
                                          </label>
                                        </div>
                                        <div class="col-5  d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={energyRigging}
                                          />
                                          <label class="form-check-label mt-0 label-height">
                                            Rigging Permit
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7  d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={energyIsolations}
                                          />
                                          <label class="form-check-label  mt-0 label-height">
                                            Isolations of Energy (IOE)
                                          </label>
                                        </div>
                                        <div class="col-5  d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={energyIOECheckbox}
                                          />
                                          <label class="form-check-label mt-0 label-height">
                                            IOE master
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="form-group row frm-grp-mb-cp"
                                      style={{ marginBottom: "3px" }}
                                    >
                                      <div className="d-flex flex-row p-0 m-0">
                                        <div class="col-7  d-flex align-items-center  mr-0 pr-0">
                                          <input
                                            class="cb-size-cp  mt-1"
                                            type="checkbox"
                                            checked={energyIOECheckbox}
                                          />
                                          <input
                                            class="col-6 cb-txtbx-border-none"
                                            value={energyIOEInput}
                                          />
                                        </div>
                                        <div class="col-5   d-flex align-items-center  ml-0 pl-0">
                                          <input
                                            class="cb-size-cp  mt-1"
                                            type="checkbox"
                                            checked={energyCheckbox}
                                          />
                                          <input
                                            class="col-6 cb-txtbx-border-none"
                                            value={energyInput}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 m-0 p-0">
                          <div style={{ width: "100%" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #FF7E51",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#FF7E51",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      4.EQUIPMENT
                                    </p>
                                  </i>
                                  <i>
                                    <span className="core-card-icons">
                                      <img
                                        src={Car}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={cropmachine}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={van}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                  </i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div
                                    className="col-12"
                                    style={{ marginBottom: "31px" }}
                                  >
                                    <div class="form-group row frm-grp-mb-cp">
                                      <label class="col-9 col-form-label core-equip-lbl-bg twolabel-height">
                                        List the hazards of the area, the work
                                        and the equipment for the specific task.
                                      </label>
                                      <div class="col-3 core-checkbox-lbl-bg">
                                        <div class="  d-flex align-items-center pt-2">
                                          <input
                                            class="cb-size-cp cb-mt-5px"
                                            type="checkbox"
                                            checked={eqiNA}
                                          />
                                          <label class="form-check-label">
                                            N/A
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList0}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Noise
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center p-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList1}
                                          />
                                          <label class="form-check-label mt-0">
                                            Pinch
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center p-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList2}
                                          />
                                          <label class="form-check-label mt-0">
                                            Radiation
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList3}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Thermal burn
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList4}
                                          />
                                          <label class="form-check-label mt-0">
                                            Sharp edges
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center p-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList5}
                                          />
                                          <label class="form-check-label mt-0">
                                            Insert atmosphere
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList6}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Flash fire
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList7}
                                          />
                                          <label class="form-check-label mt-0">
                                            Heat stress
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList8}
                                          />
                                          <label class="form-check-label mt-0">
                                            Asbestos
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList9}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Pressure extreme
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList10}
                                          />
                                          <label class="form-check-label mt-0">
                                            Flying debris
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList11}
                                          />
                                          <label class="form-check-label mt-0">
                                            Vibrations
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList12}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Fails
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={eqiList13}
                                          />
                                          <label class="form-check-label mt-0">
                                            Dust type
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList14}
                                          />
                                          <input
                                            class="col-12 cb-txtbx-border-none"
                                            value={eqiListInput14}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div className="d-flex flex-row">
                                        <div class="col-4   d-flex align-items-center pl-0 m-0">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={eqiList15}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Electrical/high voltage
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 m-0 p-0">
                          <div style={{ width: "100%", marginLeft: "0px" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #DF3A1C",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#DF3A1C",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      5.CHEMICAL
                                    </p>
                                  </i>
                                  <i>
                                    <span className="core-card-icons">
                                      <img
                                        src={spraytracktor}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={buerate}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={tine}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                  </i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div className="col-12 mb-2">
                                    <div class="form-group row frm-grp-mb-cp">
                                      <label class="col-9 col-form-label core-chem-lbl-bg twolabel-height">
                                        List below any contained process
                                        chemical(s) or job specific chemical(s).
                                        See Safety Data Sheet(s) for review of
                                        safety & health hazards.
                                      </label>
                                      <div class="col-3 core-checkbox-lbl-bg">
                                        <div class="  d-flex align-items-center pt-2">
                                          <input
                                            class="cb-size-cp cb-mt-5px"
                                            type="checkbox"
                                            checked={chemNA}
                                          />
                                          <label class="form-check-label">
                                            N/A
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Corper-subtxt hazards-mg">
                                      <p
                                        className="card-subheading core-chem-lbl-bg"
                                        style={{
                                          color: "#E04E51",
                                          fontWeight: "700",
                                        }}
                                      >
                                        HAZARDS
                                      </p>
                                    </div>

                                    <div class="form-group row frm-grp-mb-cp">
                                      <div
                                        class="col-12"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={chemList0}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Flammable
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={chemList1}
                                          />
                                          <label class="form-check-label mt-0">
                                            Inhalation
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={chemList2}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Toxic
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div
                                        class="col-12"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={chemList3}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Skin irritant
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={chemList4}
                                          />
                                          <label class="form-check-label mt-0">
                                            Skin absorption
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={chemList5}
                                          />
                                          <label class="form-check-label mt-0">
                                            Corrosive
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div
                                        class="col-12"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={chemList6}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Reactive
                                          </label>
                                        </div>
                                        <div class="col-4   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={chemList7}
                                          />
                                          <label class="form-check-label mt-0">
                                            Other
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Euip and Chem end */}

                      <div className="row">
                        <div className="col-8 m-0 p-0">
                          <div style={{ width: "100%" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #5299D2",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#5299D2",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      6.PROTECTION
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div>
                                    <div
                                      className="d-flex flex-row"
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className="p-0 m-0"
                                        style={{ width: "20%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <div
                                            className=""
                                            style={{
                                              backgroundColor: "#DCDAD5",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            <p
                                              className="card-subheading"
                                              style={{ color: "black" }}
                                            >
                                              Head/Face
                                            </p>
                                          </div>
                                          <div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList0}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Hard hat/bump cap
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList1}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Welding hood
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList2}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Chemical hood type
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList3}
                                                  />
                                                  <input
                                                    type="text"
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={faceListInput2}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList4}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Hearing protection
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList5}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Double hearing protection
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList6}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Face shield
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={faceList7}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Chemical goggles
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={faceList8}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Safty glasses with side
                                                    shields
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={faceList9}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Safety glasses-foam seal
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div
                                                  class="col-12   d-flex align-items-center"
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  <input
                                                    type="text"
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={faceListInput9}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div className="col-2 p-0 m-0"> */}
                                      <div
                                        className="p-0 m-0"
                                        style={{ width: "20%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <div
                                            className=""
                                            style={{
                                              backgroundColor: "#DCDAD5",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            <p
                                              className="card-subheading"
                                              style={{ color: "black" }}
                                            >
                                              Hands
                                            </p>
                                          </div>
                                          <div className="">
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList0}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Chemical resistant gloves
                                                    type
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList1}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Mechanical
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={handList2}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Welding
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList3}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Leather
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList4}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Thermal
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList5}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Electrical
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList6}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Gauntlet
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={handList7}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Cut resistant-level
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="form-group row frm-grp-mb-cp mb-3">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    type="text"
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={handListInput7}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="p-0 m-0"
                                        style={{ width: "20%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <div
                                            className=""
                                            style={{
                                              backgroundColor: "#DCDAD5",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            <p
                                              className="card-subheading"
                                              style={{ color: "black" }}
                                            >
                                              Arms/Body
                                            </p>
                                          </div>
                                          <div className="ml-2">
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList0}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Chemical suit type
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList1}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Tyvek type
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList2}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Nomex
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList3}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Flash suit
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList4}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Welding jacket
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList5}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Apron type
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div
                                                  class="col-12   d-flex align-items-center"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "10px",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={aptronBodyList5}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList6}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Lab coat
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={bodyList7}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Long sleeve
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={bodyList8}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    High visibility/reflective
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp mb-3">
                                              <div
                                                class="col-12"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                <div class="col-12   d-flex align-items-center">
                                                  <input
                                                    type="text"
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={bodyListInput8}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="p-0 m-0"
                                        style={{ width: "20%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <div
                                            className=""
                                            style={{
                                              backgroundColor: "#DCDAD5",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            <p
                                              className="card-subheading"
                                              style={{ color: "black" }}
                                            >
                                              Respiratory
                                            </p>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList0}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Supplied air
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList1}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Full face purifying
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList2}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Power air purifying
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList3}
                                                />
                                                <label class="form-check-label  mt-0"></label>
                                                1/2 FACE purifying
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList4}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Dust mask
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={respList5}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Catridge type
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              className="d-flex flex-row"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <div
                                                class="col-12   d-flex align-items-center"
                                                Respiratory
                                              >
                                                <input
                                                  type="text"
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={respListInput5}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="p-0 m-0"
                                        style={{ width: "20%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <div
                                            className=""
                                            style={{
                                              backgroundColor: "#DCDAD5",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            <p
                                              className="card-subheading"
                                              style={{ color: "black" }}
                                            >
                                              Feet/Legs
                                            </p>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList0}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Safety shoes
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList1}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Closed Toed Shoes
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList2}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Rubber boots-ankle
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList3}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Rubber boot-calfhigh
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList4}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Metataesal protection
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList5}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Knee pads
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={feetList6}
                                                />
                                                <label class="form-check-label  mt-0">
                                                  Thigh protection
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp mb-3">
                                            <div
                                              class="col-12"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  type="text"
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={feetListInput6}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="d-flex flex-column"
                                      style={{
                                        width: "100%",
                                        marginLeft: "0px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-5 col-form-label lbl-bg-color">
                                          Comments:Reason for upgrading or
                                          downgrading PPE:
                                        </label>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div class="col-12">
                                          <textarea
                                            class="col-12"
                                            id="exampleFormControlTextarea1"
                                            rows="1"
                                            value={comment}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 m-0 p-0">
                          <div style={{ width: "100%", marginLeft: "0px" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #636260",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#636260",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      7.WORK ENVIRONMENT
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontWeight: "bold",
                                      backgroundColor: "#99A4A2",
                                      color: "white",
                                      marginTop: "0px",
                                    }}
                                  >
                                    <p style={{ margin: "5px" }}>
                                      Elevated Work
                                    </p>
                                  </div>

                                  <div
                                    className="d-flex flex-row"
                                    style={{ width: "100%", marginTop: "-5px" }}
                                  >
                                    <div
                                      className="p-0 m-0"
                                      style={{ width: "33%" }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <div
                                          className=""
                                          style={{
                                            backgroundColor: "#DCDAD5",
                                            widt: "200px",
                                          }}
                                        >
                                          <p
                                            className="card-subheading"
                                            style={{ color: "black" }}
                                          >
                                            Access
                                          </p>
                                        </div>
                                        <div className="ml-0">
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div class="col-12   d-flex align-items-center">
                                              <input
                                                class="cb-size-cp  mt-0"
                                                type="checkbox"
                                                checked={accessList0}
                                              />
                                              <label class="form-check-label  mt-0">
                                                Ladder
                                              </label>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div class="col-12  d-flex align-items-center">
                                              <input
                                                class="cb-size-cp  mt-0"
                                                type="checkbox"
                                                checked={accessList1}
                                              />
                                              <label class="form-check-label  mt-0">
                                                Mobile scaffold
                                              </label>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div class="col-12  d-flex align-items-center">
                                              <input
                                                class="cb-size-cp  mt-0"
                                                type="checkbox"
                                                checked={accessList2}
                                              />
                                              <label class="form-check-label  mt-0">
                                                Aerial lift devices
                                              </label>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp">
                                            <div class="col-12  d-flex align-items-center">
                                              <input
                                                class="cb-size-cp  mt-0"
                                                type="checkbox"
                                                checked={accessList3}
                                              />
                                              <label class="form-check-label  mt-0">
                                                scaffold
                                              </label>
                                            </div>
                                          </div>
                                          <div class="form-group row frm-grp-mb-cp mb-3">
                                            <div class="col-12  d-flex align-items-center">
                                              <input
                                                class="col-6 cb-txtbx-border-none"
                                                style={{ marginRight: "10px" }}
                                                value={accessList5}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="p-0 m-0"
                                      style={{ width: "33%" }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <div
                                          className=""
                                          style={{
                                            backgroundColor: "#DCDAD5",
                                            width: "200px",
                                          }}
                                        >
                                          <p
                                            className="card-subheading"
                                            style={{ color: "black" }}
                                          >
                                            Preventation
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={preventList0}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Guardrail
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={preventList1}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Restraint line
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={preventList2}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Retractable lifeline
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={preventList3}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Designated area:
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div
                                            class="col-12   d-flex align-items-center"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <input
                                              class="col-6 cb-txtbx-border-none"
                                              value={preventList4}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="p-0 m-0"
                                      style={{ width: "34%" }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <div
                                          className=""
                                          style={{ backgroundColor: "#DCDAD5" }}
                                        >
                                          <p
                                            className="card-subheading"
                                            style={{ color: "black" }}
                                          >
                                            Protection
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12   d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={protectList0}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Harness w/lanyard
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12   d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={protectList1}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Vertical lifeline
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12   d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={protectList2}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Safety net
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12   d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={protectList3}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Horizontal lifeline
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12   d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={protectList4}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Defined anchor point
                                            </label>
                                          </div>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div
                                            class="col-12   d-flex align-items-center"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <input
                                              class="col-6 cb-txtbx-border-none"
                                              value={protectList5}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* sai */}
                                    <div
                                      class="form-group row frm-grp-mb-cp"
                                      style={{
                                        marginLeft: "0px",
                                        marginBottom: "-8px",
                                        padding: "0px",
                                      }}
                                    >
                                      <div class="d-flex flex-row cb-mt-5px">
                                        <label
                                          class="col-2 col-form-label core-we-lbl-bg1"
                                          style={{
                                            margin: "0px",
                                            padding: "3px",
                                            height: "20px",
                                          }}
                                        >
                                          Barricades
                                        </label>
                                        &nbsp;
                                        <input
                                          type="checkbox"
                                          className="cb-size-cp mt-0"
                                          id="URDCheckbox"
                                          name="response"
                                          checked={BarricadesList0}
                                        />
                                        &nbsp;
                                        <label
                                          for="WaterCheckbox"
                                          className="mt-1"
                                        >
                                          Caution/Danger tape
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <input
                                          type="checkbox"
                                          className="cb-size-cp cb-mt-5px"
                                          id="GPRCheckbox"
                                          name="response"
                                          checked={BarricadesList1}
                                        />
                                        &nbsp;
                                        <label
                                          for="ElectricalCheckbox"
                                          className="mt-1"
                                        >
                                          Physical
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <input
                                          type="text"
                                          class="cb-txtbx-border-none"
                                          style={{ width: "50px" }}
                                          value={BarricadesList3}
                                        />
                                      </div>
                                    </div>

                                    <div className="d-flex flex-row m-0 p-0">
                                      <div className="col-9 pr-0 mr-0">
                                        <div style={{ width: "100%" }}>
                                          <div className="">
                                            <p className="card-subheading core-we-lbl-bg1 ml-0 pl-0">
                                              Additional requirements
                                            </p>
                                          </div>
                                          <div className="ml-0">
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-5   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList0}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Electrical GFCI
                                                  </label>
                                                </div>
                                                <div class="col-7   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={additionalList1}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Written operating procedure
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-5   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList2}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Communication type
                                                  </label>
                                                </div>
                                                <div class="col-7   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList3}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Safety attendant(s)required:
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-5   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList2}
                                                  />
                                                  <input
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={additionalList2value}
                                                  />
                                                </div>
                                                <div class="col-7   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-1"
                                                    type="checkbox"
                                                    checked={additionalList3}
                                                  />
                                                  <input
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={additionalList3value}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-5   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList4}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Limited time exposure:
                                                  </label>
                                                </div>
                                                <div class="col-7   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="cb-size-cp  mt-0"
                                                    type="checkbox"
                                                    checked={additionalList5}
                                                  />
                                                  <label class="form-check-label  mt-0">
                                                    Fire extinguisher
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div className="d-flex flex-row">
                                                <div class="col-5   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="col-6 cb-txtbx-border-none"
                                                    value={additionalList4value}
                                                  />
                                                </div>
                                                <div class="col-7   d-flex align-items-center m-0 p-0">
                                                  <input
                                                    class="col-6 cb-txtbx-border-none"
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    value={additionalList5value}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3 ml-0 pl-0">
                                        <div style={{ width: "100%" }}>
                                          <div className="Corper-subtxt col-12 ml-0 core-we-lbl-bg1">
                                            <p className="card-subheading core-we-lbl-bg1">
                                              Signage
                                            </p>
                                          </div>
                                          <div>
                                            <div class="form-group row frm-grp-mb-cp m-0 p-0">
                                              <div class="col-12   d-flex align-items-center m-0 p-0">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={signageList0}
                                                />
                                                <input
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={signageListInput0}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp m-0 p-0">
                                              <div class="col-12   d-flex align-items-center m-0 p-0">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={signageList1}
                                                />
                                                <input
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={signageListInput0}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={signageList2}
                                                />
                                                <input
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={signageListInput0}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb-cp">
                                              <div class="col-12   d-flex align-items-center">
                                                <input
                                                  class="cb-size-cp  mt-0"
                                                  type="checkbox"
                                                  checked={signageList3}
                                                />
                                                <input
                                                  class="col-6 cb-txtbx-border-none"
                                                  value={signageListInput0}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* work envi end */}
                      <div className="row">
                        <div className="col-4  m-0 p-0">
                          <div style={{ width: "100%" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #000",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#262721",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      8.TRAINING
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div
                                    className="col-12"
                                    style={{ marginBottom: "23px" }}
                                  >
                                    <div className="form-group row frm-grp-mb-cp">
                                      <label class="col-9 col-form-label core-train-lbl-bg1 twolabel-height">
                                        Workers have specialty training as
                                        required:
                                      </label>
                                      <div class="col-3 core-checkbox-lbl-bg">
                                        <div class="  d-flex align-items-center pt-1">
                                          <input
                                            class="cb-size-cp cb-mt-5px"
                                            type="checkbox"
                                            checked={trainingNA}
                                          />
                                          <label class="form-check-label">
                                            N/A
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList0}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Equipment
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={trainingList1}
                                          />
                                          <label class="form-check-label mt-0">
                                            PPE use
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList2}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Powered industrial truck
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={trainingList3}
                                          />
                                          <label class="form-check-label mt-0">
                                            Competent person
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList4}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Aerial work platform
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={trainingList5}
                                          />
                                          <label class="form-check-label mt-0">
                                            Hazwoper
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList6}
                                          />
                                          <label class="form-check-label  mt-0">
                                            PCB,PMN,5e
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={trainingList7}
                                          />
                                          <label class="form-check-label mt-0">
                                            Certified
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList8}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Asbestos
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp mt-0"
                                            type="checkbox"
                                            checked={trainingList9}
                                          />
                                          <label class="form-check-label mt-0">
                                            Qualified person
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="d-flex flex-row p-0 m-0">
                                        <div class="col-6   d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={trainingList10}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Lead
                                          </label>
                                        </div>
                                        <div class="col-6   d-flex align-items-center">
                                          {/* <input class="cb-size-cp mt-0" type="checkbox" checked={trainingList11} /> */}
                                          <label class="form-check-label mt-0"></label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4  m-0 p-0">
                          <div style={{ width: "100%", marginLeft: "0px" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #484848",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#484848",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      9.CORE PERMIT RECEIVER
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div class="form-group row frm-grp-mb-cp ml-0">
                                    <label class="col-12 col-form-label core-train-lbl-bg1 label-height">
                                      The Core Permit Receiver will ensure that
                                      all workers.
                                    </label>
                                  </div>
                                  <div className="ml-2">
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList0}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Understand the hazards of the area,
                                            equipment and work and the
                                            safeguards in place.{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList1}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Understand potential environmental
                                            impact and procedures for addressing
                                            this
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList2}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Understand and follow Personal
                                            Protective Equipment requirements.{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList3}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Have the necessary skills and
                                            knowledge to do the permitted work
                                            safety.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList4}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Know emergency procedures, alarm and
                                            assembly points.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList5}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Know the location of and how to use
                                            emergency equipment.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList6}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Know the scope of other work that
                                            could impact this work.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb-cp">
                                      <div class="col-12">
                                        <div class="  d-flex align-items-center">
                                          <input
                                            class="cb-size-cp  mt-0"
                                            type="checkbox"
                                            checked={cprList7}
                                          />
                                          <label class="form-check-label  mt-0">
                                            Understand the scope of this
                                            permitted work..
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 m-0 p-0">
                          <div style={{ width: "100%", marginLeft: "0px" }}>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #6D6D6D",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#6D6D6D",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      10.RESPONSIBILITIES
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div>
                                    <div class="form-group row frm-grp-mb-cp ml-0 mr-0">
                                      <label class="col-12 col-form-label core-train-lbl-bg1 label-height">
                                        Responsibilities communicated to permit
                                        receiver:{responsebilityCommunicated}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="ml-2">
                                    <div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div class="  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={responsebilityStoppage}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Conditions for work stoppage{" "}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div class="  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={responsebilitySafety}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Reporting changes that affect job
                                              safety
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div class="  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={responsebilityConcerns}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Crew accountability & addressing
                                              workers’ concerns{" "}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div class="  d-flex align-items-center">
                                            <input
                                              class="cb-size-cp  mt-0"
                                              type="checkbox"
                                              checked={responsebilityReceiver}
                                            />
                                            <label class="form-check-label  mt-0">
                                              Reporting a change in the permit
                                              receiver
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb-cp ml-0 mr-0">
                                    <label class="col-12 col-form-label core-train-lbl-bg1 label-height">
                                      Responsibilities communicated to permit
                                      receiver:
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "14px",
                                      marginRight: "6px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <div class="form-group row frm-grp-mb-cp">
                                      <label class="col-2 col-form-label core-train-lbl-bg1 label-height">
                                        Initials
                                      </label>
                                      <div
                                        class="col-10"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <input
                                          class="col-4"
                                          placeholder="PRINT"
                                          value={responsebilityInitial}
                                        />
                                        <label class="col-3 col-form-label core-train-lbl-bg1 mr-1 label-height">
                                          Phone#
                                        </label>
                                        <input
                                          class="col-5"
                                          placeholder="Phone"
                                          value={responsebilityPhone}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-0">
                        <div className="col-5 m-0 p-0">
                          <div>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #DF3A1C",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#DF3A1C",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      11.CHANGE
                                    </p>
                                  </i>
                                  <i>
                                    <span className="core-card-icons">
                                      <img
                                        src={couple}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={cloudthunder}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={chart}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={sun}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                    <span className="core-card-icons">
                                      <img
                                        src={wave}
                                        alt="mes"
                                        className="core-card-icon-size"
                                      />
                                    </span>
                                  </i>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div>
                                    <div
                                      className="row"
                                      style={{
                                        padding: "0px",
                                        margin: "0px",
                                        color: "#DF3A1C",
                                      }}
                                    >
                                      <div className="col-sm-12 col-12">
                                        <input
                                          type="checkbox"
                                          id="URDCheckbox"
                                          className="cb-size-cp core-change-lbl-color"
                                          name="response"
                                          value="Water"
                                          checked={changescop}
                                        />
                                        &nbsp;
                                        <label
                                          for="WaterCheckbox"
                                          className="core-change-lbl-color"
                                        >
                                          Scop changed
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <input
                                          type="checkbox"
                                          id="GPRCheckbox"
                                          className="cb-size-cp"
                                          name="response"
                                          value="Electrical"
                                          checked={changehazard}
                                        />
                                        &nbsp;
                                        <label for="ElectricalCheckbox">
                                          Hazards changed
                                        </label>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <input
                                          type="checkbox"
                                          id="HECheckbox"
                                          className="cb-size-cp"
                                          name="response"
                                          value="SC"
                                          checked={changecontrol}
                                        />
                                        &nbsp;
                                        <label for="SCCheckbox">
                                          Control measures changed
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                      </div>
                                    </div>
                                    <div class="row">
                                      <h4
                                        style={{
                                          fontSize: "12px",
                                          marginBottom: "0px",
                                        }}
                                        className="core-change-lbl-color"
                                      >
                                        STOP WORK -notify permit issuer for
                                        review!
                                      </h4>
                                    </div>
                                    <div
                                      class="row"
                                      style={{
                                        backgroundColor: "#DF3A1C",
                                        color: "white",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontSize: "12px",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        If suspended, reauthorize permit to
                                        continue work!
                                      </h6>
                                    </div>
                                    <div className="col-12 pr-0">
                                      {/* <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-2 col-form-label core-change-issuer-lbl-bg label-height">
                                          Issuer
                                        </label>
                                        <div
                                          class="col-10"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            class="col-6"
                                            placeholder=""
                                            value={changeissuer}
                                          />
                                          <input
                                            class="col-6"
                                            placeholder="SIGNATURE"
                                            value={changesighn}
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-2 col-form-label wenv-sub-bg-color label-height">
                                          Receiver
                                        </label>
                                        <div class="col-10 d-flex flex-row">
                                          <input
                                            class="col-6"
                                            placeholder=""
                                            // value={changereceiver}
                                            value={primaryRequesterName}
                                          />
                                          <input
                                            class="col-6"
                                            placeholder="SIGNATURE"
                                            value={changesignature}
                                          />
                                        </div>
                                      </div> */}


                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-2 col-form-label wenv-sub-bg-color label-height">
                                          Issuer
                                        </label>
                                        <div
                                          class="col-10"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            class="col-5"
                                            placeholder="SIGN"
                                            // value={changeDate}
                                            // value={suspendedBy}
                                            // value={issuerTransferTo || suspendedBy}

                                            value={isTransferred ? issuerTransferTo : suspendedBy}

                                          />
                                          <label class="col-3 col-form-label wenv-sub-bg-color mr-1 label-height">
                                            Receiver
                                          </label>
                                          <input
                                            class="col-4"
                                            placeholder="SIGN"
                                            // value={primaryRequesterName || receiverTransferTo}
                                            value={isTransferred ? receiverTransferTo : primaryRequesterName}
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-2 col-form-label wenv-sub-bg-color label-height">
                                          Date
                                        </label>
                                        <div
                                          class="col-10"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            class="col-5"
                                            placeholder="PRINT"
                                            // value={changeDate}
                                            value={suspendDate}
                                          />
                                          <label class="col-3 col-form-label wenv-sub-bg-color mr-1 label-height">
                                            Time
                                          </label>
                                          <input
                                            class="col-4"
                                            placeholder="AM/PM"
                                            // value={changeTime}
                                            // value={formatTime(suspendTime)}suspendTime
                                            value={suspendTime}

                                          />
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-2 col-form-label wenv-sub-bg-color label-height">
                                          Remarks
                                        </label>
                                        <div
                                          class="col-10"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <input
                                            class="col-12"
                                            placeholder="PRINT"
                                            value={suspendedRemarks}
                                          />

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* Responsibility and change end */}

                        <div className="col-7 m-0 p-0">
                          <div className="d-flex flex-column">
                            <div
                              className="card"
                              style={{
                                border: "1px solid #262721",
                                borderRadius: "0px",
                              }}
                            >
                              <div className="">
                                <div
                                  className={`d-flex justify-content-between  align-items-center`}
                                  style={{
                                    backgroundColor: "#262721",
                                    color: "white",
                                  }}
                                >
                                  <i>
                                    {" "}
                                    <p
                                      style={{
                                        margin: "5px",
                                        fontSize: "9px",
                                        fontWeight: "700",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      12.CLOSEOUT
                                    </p>
                                  </i>
                                  <i></i>
                                </div>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div className="col-12 d-flex flex-row pr-0 mr-0">
                                    <div className="col-6 m-0 p-0">
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-8 col-form-label wenv-sub-bg-color label-height">
                                          Closeout job site inspection
                                          completed?
                                        </label>
                                        <div class="col-4 d-flex flex-row cb-mt-5px">
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={closeoutCompletedYes}
                                            />
                                            <label class="form-check-label">
                                              YES
                                            </label>
                                          </div>
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={closeoutCompletedNo}
                                            />
                                            <label class="form-check-label">
                                              N/A
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-8 col-form-label wenv-sub-bg-color label-height">
                                          Status of job and equipment reviewed?
                                        </label>
                                        <div class="col-4 d-flex flex-row cb-mt-5px">
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={closeoutReviewedYes}
                                            />
                                            <label class="form-check-label">
                                              YES
                                            </label>
                                          </div>
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={closeoutReviewedNo}
                                            />
                                            <label class="form-check-label">
                                              N/A
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-8 col-form-label wenv-sub-bg-color label-height">
                                          Additional paperwork reconciled?
                                        </label>
                                        <div class="col-4 d-flex flex-row cb-mt-5px">
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={reconciledYes}
                                            />
                                            <label class="form-check-label">
                                              YES
                                            </label>
                                          </div>
                                          <div class=" d-flex align-items-center">
                                            <input
                                              class="cb-size-cp cb-mt-5px"
                                              type="checkbox"
                                              checked={reconciledNo}
                                            />
                                            <label class="form-check-label">
                                              N/A
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 d-flex flex-row m-0 p-0">
                                      <div className="col-6 p-0 m-0">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontWeight: "bold",
                                            backgroundColor: "#6D6D6D",
                                            color: "white",
                                            marginTop: "0px",
                                          }}
                                        >
                                          <p style={{ margin: "3px" }}>
                                            Receiver closeout
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          <div class="col-12">
                                            <textarea
                                              class="col-12"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                              style={{ height: "30px" }}
                                              // value={closeoutquestion}
                                              //value={primaryRequesterName}
                                              value={primaryRequesterName || receiverTransferTo}
                                            //value={isTransferred ? receiverTransferTo : primaryRequesterName}
                                            ></textarea>
                                          </div>
                                          {/*     */}
                                        </div>
                                      </div>
                                      <div className="col-6 p-0 m-0">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            fontWeight: "bold",
                                            backgroundColor: "#4E74C2",
                                            color: "white",
                                            marginTop: "0px",
                                          }}
                                        >
                                          <p style={{ margin: "3px" }}>
                                            Issuer closeout
                                          </p>
                                        </div>
                                        <div class="form-group row frm-grp-mb-cp">
                                          {(
                                            <div class="col-12">
                                              <textarea
                                                class="col-12"
                                                id="exampleFormControlTextarea1"
                                                rows="2"
                                                style={{ height: "30px" }}
                                                // value={closeoutanswer}
                                                value={isTransferred ? issuerTransferTo : approveOrRejectedByName}
                                              ></textarea>
                                            </div>)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 d-flex flex-row">
                                    <div className="col-6 m-0 p-0">
                                      <div class="form-group row frm-grp-mb-cp">
                                        <label class="col-12 col-form-label wenv-sub-bg-color label-height">
                                          Special instruction, precautions,
                                          limitations, remarks:
                                        </label>
                                        <input
                                          placeholder=""
                                          className="col-12"
                                          value={closeoutremarks}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 d-flex flex-row m-0 p-0">
                                      <div className="col-6 m-0 p-0">
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-12 col-form-label wenv-sub-bg-color label-height">
                                            Date
                                          </label>
                                          <input
                                            placeholder=""
                                            // value={closeoutDate}
                                            // value={date}
                                            value={closeOutDate}
                                            className="col-12"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6 m-0 p-0">
                                        <div class="form-group row frm-grp-mb-cp">
                                          <label class="col-12 col-form-label wenv-sub-bg-color label-height">
                                            Time
                                          </label>
                                          <input
                                            placeholder=""
                                            // value={closeoutTime}
                                            // value={endTime}
                                            value={closeOutTime}
                                            className="col-12"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row ml-0 mr-0 mt-0">
                                    <table style={{ display: "none" }}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <label class="col-form-label wenv-sub-bg-color label-height">
                                              Special instruction, precautions,
                                              limitations, remarks:
                                            </label>
                                          </td>
                                          <td>
                                            <label class="col-form-label wenv-sub-bg-color label-height">
                                              Date
                                            </label>
                                          </td>
                                          <td>
                                            <label class="col-form-label wenv-sub-bg-color label-height">
                                              Time
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {" "}
                                            <input
                                              placeholder=""
                                              style={{ width: "419px" }}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <input
                                              placeholder=""
                                              style={{ width: "152px" }}
                                              value={closeoutDate}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <input
                                              placeholder=""
                                              style={{ width: "152px" }}
                                              value={closeoutTime}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card"
                              style={{
                                border: "1px solid #000",
                                borderRadius: "0px",
                                alignContent: "flex-end",
                                alignItems: "end",
                                backgroundColor: "#2373BE",
                              }}
                            >
                              <div
                                className="card-body"
                                style={{ padding: "0px" }}
                              >
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        backgroundColor: "#2373BE",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                    >
                                      <p style={{ margin: "5px" }}>
                                        CORTEVA. CORE PERMIT. v2.0
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*  Clseout end */}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </form>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
    </body>
  );
};
export default CorePermit_Web;
