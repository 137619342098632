import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { USER_PROFILE } from "../Utils";
import {
  getMasters,
  rmComplaintsAcceptReject,
  rmComplaints,
  rmGetmasters,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import ModalImage from "react-modal-image";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Swal from 'sweetalert2';




const RepairMaintenace = (category, index) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState("");
  const [corePermit, setCorePermit] = useState("");
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [requestedDate, setRequestedDate] = useState([]);
  const [getAccName, setGetAccName] = useState([]);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [errRemark, setErrRemark] = useState("");
  const [rejectedRequests, setRejectedRequests] = useState("");
  const [isEditMode, setIsEditMode] = useState(false); // to track edit mode
  const [selectedIndex, setSelectedIndex] = useState(null); // To store the selected index
  const [errors, setErrors] = useState({});
  //Validations
  const [errRemarks, setErrRemarks] = useState([]); // Array to track error messages for each index
  const [errDate, setErrDate] = useState([]); // Array to track date errors for each index
  const [errorsTechnician, setErrorsTechnician] = useState({});
  const [getData, setGetData] = useState([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [selectedTechnicians, setSelectedTechnicians] = useState({});
  const [technicianShiftId, setTechnicianShiftId] = useState();
  const [technicianShiftName, setTechnicianShiftName] = useState();
  const [modalShow, setModalShow] = useState(false);  // Modal visibility state
  const [selectedDate, setSelectedDate] = useState(null);
  const [technicianMastersData, setTechnicianMastersData] = useState([])


  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );


  const handleEditClick = (index) => {
    setIsEditMode(true);
    setSelectedIndex(index);
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };


  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const responseGetData = await axios.get(getData, { headers });
      console.log("GettResponse,", responseGetData);
      setTechnicianMastersData(responseGetData.data.response.technicianRolesList);
      const technicians = responseGetData.data.response.technicianRolesList;
      setTechnicianList(technicians);
      const employeeData = responseGetData.data.response.employees;
      setGetData(employeeData);
      setTechnicianList(technicians);
      setIsLoading(false);
    } catch (err) {
      console.log("getError", err);
    }
  };


  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
  };


  const handleDateChange = (e, index) => {
    const selectedDate = e.target.value;
    const updatedData = [...data];
    updatedData[index].estimatedCompletionDate = selectedDate; // Update date for the specific index
    setData(updatedData);

    // Clear date error for the specific index if a valid date is selected
    const updatedDateErrors = [...errDate];
    if (selectedDate) {
      updatedDateErrors[index] = ""; // Clear the error for this index
    }
    setErrDate(updatedDateErrors);
  };



  const handleRemark = (e, index) => {
    const updatedData = [...data];
    updatedData[index].accepterRemarks = e.target.value; // Update the specific item's remarks
    setData(updatedData);

    // Clear the error if remarks are valid
    if (e.target.value.trim() !== "") {
      const updatedErrors = [...errRemarks];
      updatedErrors[index] = ""; // Clear the error for the specific index
      setErrRemarks(updatedErrors);
    }
  };


  const handleRadioChange = (index, value) => {
    // Set corePermit value for the index
    setCorePermit((prev) => ({
      ...prev,
      [index]: value, // Update specific index
    }));

    // Update the corePermitRequired in the data array
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        corePermitRequired: value, // Set value in data
      };
      return updatedData;
    });

    // Clear any errors related to corePermit for the specific index
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index]?.corePermit; // Remove error for this index
      return updatedErrors;
    });
  };

 
  const handleTechnicianRoleList = (e, index) => {
    const selectedCode = e.target.value; // Technician ID (code)

    // Find the technician in the list based on the selected code
    const selectedTechnician = technicianMastersData.find(
      (tech) => tech.code == selectedCode
    );

    if (selectedTechnician) {
      setSelectedTechnicians((prev) => ({
        ...prev,
        [index]: { id: selectedTechnician.code, name: selectedTechnician.name },
      }));

      setErrorsTechnician((prevErrors) => ({
        ...prevErrors,
        [index]: "", // Clear error for this index
      }));
    }
  };


  const handleAprove = async (index) => {
    console.log("technicianShiftName data", technicianShiftId, technicianShiftName);

    const remarks = data[index]?.accepterRemarks;
    const date = data[index]?.estimatedCompletionDate || "";
    const corePermit = data[index]?.corePermitRequired;

    let hasError = false;
    const updatedErrors = { ...errors };
    const updatedRemarksErrors = [...errRemarks];
    const updatedDateErrors = [...errDate];
    const updatedTechnicianErrors = { ...errorsTechnician };

    // Remarks validation
    if (!remarks || remarks.trim() === "") {
      updatedRemarksErrors[index] = "Please Enter Remarks";
      hasError = true;
    } else {
      updatedRemarksErrors[index] = "";
    }

    // Date validation
    if (!date) {
      updatedDateErrors[index] = "Please Select a Date";
      hasError = true;
    } else {
      updatedDateErrors[index] = "";
    }

    // Core Permit validation
    if (!corePermit) {
      updatedErrors[index] = { corePermit: "Please Select Yes or No" };
      hasError = true;
    } else {
      updatedErrors[index] = {};
    }

    // Technician validation
    const selectedTechnician = selectedTechnicians[index]; // Get selected technician

    if (!selectedTechnician) {
      updatedTechnicianErrors[index] = "Please Select a Technician";
      hasError = true;
    } else {
      updatedTechnicianErrors[index] = "";
    }

    setErrRemarks(updatedRemarksErrors);
    setErrDate(updatedDateErrors);
    setErrors(updatedErrors);
    setErrorsTechnician(updatedTechnicianErrors);

    if (hasError) return;

    const inputData = {
      requiredDate: data[index]?.requiredDate,
      requesterName: data[index]?.requesterName,
      requesterMobileNumber: data[index]?.requesterMobileNumber,
      status: "Approved",
      requesterType: data[index]?.requesterType,
      function: data[index]?.function,
      location: data[index]?.location,
      subLocation: data[index]?.subLocation,
      acceptorName: `${getAccName?.firstName || ""} ${getAccName?.lastName || ""}`,
      acceptorID: getAccName?.userId,
      acceptorMobileNumber: getAccName?.mobileNumber,
      serverRecordId: data[index]?.serverRecordId,
      typeOfWork: data[index]?.typeOfWork,
      typeOfWorkId: data[index]?.typeOfWorkId,
      functionId: data[index]?.functionId,
      locationId: data[index]?.locationId,
      subLocationId: data[index]?.subLocationId,
      requesterRemarks: data[index]?.requesterRemarks,
      image: data[index]?.image,
      corePermitRequired: corePermit,
      estimatedCompletionDate: date,
      accepterRemarks: remarks,
      submittedDate: data[index]?.submittedDate,
      requestId: data[index]?.requestId,
      // technicianShiftId: technicianShiftId,
      // technicianShiftName: technicianShiftName,

      // Ensure selected technician is passed correctly
      technicianShiftId: selectedTechnician?.id || selectedTechnician,
      technicianShiftName: selectedTechnician?.name || selectedTechnician,
    };

    console.log("inputData", inputData);

    try {
      setIsLoading(true);
      const headers = await GetApiHeaders();
      const responseData = await axios.post(rmComplaintsAcceptReject, inputData, { headers });

      if (responseData.data.statusCode == 200) {
        setShowResponsePopup(true);
        setsubmitResponse(responseData.data.message);
        fetchData();
      } else if (responseData.data.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse("Error: " + responseData.data.message);
      }
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (err) {
      console.error("API Error:", err);
      setIsLoading(false);
      setShowResponsePopup(true);
      setsubmitResponse("An unexpected error occurred. Please try again.");
    }
  };



  const handleReject = async (index, itemsIndex) => {
    const remarks = data[index]?.accepterRemarks;
    let hasError = false;
    const updatedRemarksErrors = [...errRemarks];
    // const updatedTechnicianErrors = { ...errorsTechnician }; 

    const aproveApi = rmComplaintsAcceptReject;
    const headers = await GetApiHeaders();


    if (!remarks || remarks.trim() === "") {
      updatedRemarksErrors[index] = "Please Enter Remarks";
      hasError = true;
    } else {
      updatedRemarksErrors[index] = ""; // Clear error if remarks are valid
    }


    setErrRemarks(updatedRemarksErrors);
    // setErrorsTechnician(updatedTechnicianErrors); // Update technician errors

    if (hasError) return;

    const inputData = {
      requiredDate: data[index].requiredDate,
      requesterName: data[index].requesterName,
      // requestedDate : requestedDate.requestedDate,
      requesterMobileNumber: data[index].requesterMobileNumber,
      status: "Rejected",
      requesterType: data[index].requesterType,
      function: data[index].function,
      location: data[index].location,
      subLocation: data[index].subLocation,
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requesterId: "",
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      functionId: data[index].functionId,
      locationId: data[index].locationId,
      subLocationId: data[index].subLocationId,
      requesterRemarks: data[index].requesterRemarks,
      image: data[index].image,
      corePermitRequired: "",
      estimatedCompletionDate: "",
      accepterRemarks: remarks,
      // accepterRemarks: data[index].accepterRemarks,
      submittedDate: data[index].submittedDate,
      corePermitRequired: corePermit,
      estimatedCompletionDate: estDate,
      requestId: data[index].requestId,
      // technicianShiftId: technicianShiftId,
      technicianShiftId: "0",
      // technicianShiftName: technicianShiftName,
      technicianShiftName: "",

    };

    try {
      setIsLoading(true);
      const response = await axios.post(aproveApi, inputData, { headers });
      const { statusCode, message } = response.data;
      if (statusCode == 200 || statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(message);
        if (statusCode === 500) {
          setRejectedRequests((prev) => [...prev, index]);
          window.location.reload();
        }
      }
      setIsSubmitted(true);
    } catch (err) {
      console.error("Error occurred while processing the request:", err);
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }


  };

  const fetchData = async () => {
    const getApi = rmComplaints;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });
      setData(response.data.response.rmComplaints);
      console.log("resssssssssssssssssss:", response);
      setIsLoading(false);
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();

    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetAccName(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">R & M</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">R & M</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {data.map((item, index) => {
                                var newDateFormat = moment(
                                  item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");
                                var parsedDate = moment(item.requiredDate, "DD-MM-YYYY");

                                var requiredDate = parsedDate.format("DD-MMM-YYYY");
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mb-2"
                                        id="dns"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            <div
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductName d-flex"
                                            >
                                              <span
                                                className=""
                                                style={{ width: "17%" }}
                                              >
                                                <b className="">
                                                  Request Id &nbsp; &nbsp;:
                                                </b>{" "}
                                                <span>
                                                  {item.serverRecordId}
                                                </span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "31%" }}
                                              >
                                                <b className="">
                                                  {" "}
                                                  Requested Date &nbsp;
                                                  &nbsp;:&nbsp;
                                                </b>{" "}
                                                <span>{newDateFormat}</span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "30%" }}
                                              >
                                                <b className="quantty">
                                                  {" "}
                                                  Requester Name &nbsp;&nbsp;:{" "}
                                                  &nbsp;
                                                </b>{" "}
                                                <span style={{ textTransform: "capitalize" }}>
                                                  {/* {item.requesterName} */}
                                                  {item.requesterName?.toLowerCase()}
                                                </span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "22.7%" }}
                                              >
                                                <b>
                                                  Status &nbsp; &nbsp;: &nbsp;
                                                </b>
                                                <span>
                                                  {item.status === "Pending" ? (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Pending
                                                    </span>
                                                  ) : item.status === "Approved" ? (
                                                    <span
                                                      style={{
                                                        color: "green",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Approved
                                                    </span>
                                                  ) : null}
                                                </span>
                                                &nbsp; &nbsp;
                                              </span>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <>
                                              <div style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex" >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Requester Type
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.requesterType}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Function
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.function}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Type of work
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.typeOfWork}</span>
                                                </span>
                                              </div>
                                              <div style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.location}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Sub Location
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{item.subLocation}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Core Permit required ?
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <div style={{ width: '60%', display: 'inline-block' }}>
                                                    <div className="form-check form-check-inline">
                                                      <input
                                                        className="form-check-input mt-3"
                                                        type="radio"
                                                        value="Yes" // Set the value as "Yes"
                                                        checked={data[index]?.corePermitRequired?.toLowerCase() === 'yes'} // Normalize to lowercase
                                                        onChange={() => handleRadioChange(index, 'Yes')}
                                                        disabled={item.status === "Approved"}
                                                      />
                                                      <label className="form-check-label mt-2">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                      <input
                                                        className="form-check-input mt-3"
                                                        type="radio"
                                                        value="No" // Set the value as "No"
                                                        checked={data[index]?.corePermitRequired?.toLowerCase() === 'no'} // Normalize to lowercase
                                                        onChange={() => handleRadioChange(index, 'No')}
                                                        disabled={item.status === "Approved"}

                                                      />
                                                      <label className="form-check-label mt-2">No</label>
                                                    </div>
                                                    {errors[index]?.corePermit && (
                                                      <span style={{ color: "red", marginLeft: "-14px" }}>
                                                        {errors[index].corePermit}
                                                      </span>
                                                    )}
                                                  </div>
                                                </span>
                                              </div>

                                              <div style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Required Date
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>{requiredDate}</span>
                                                </span>

                                            
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                    Technician Role
                                                  </span>
                                                  <span>:&nbsp;</span>
                                                  <div style={{ width: '50%', display: 'inline-block', position: 'relative' }}>
                                                    <select
                                                      style={{
                                                        width: "100%",
                                                        height: "32px",
                                                        borderRadius: "5px",
                                                        padding: "4px",
                                                        border: "1px solid #ccc",
                                                      }}
                                                      value={
                                                        selectedTechnicians[index]?.id ||
                                                        data[index]?.technicianShiftId ||
                                                        ""
                                                      }
                                                      onChange={(e) => handleTechnicianRoleList(e, index)}
                                                      disabled={item.status === "Approved"}
                                                    >
                                                      <option value="" disabled>
                                                        Select Technician Role
                                                      </option>
                                                      {technicianMastersData.map((role) => (
                                                        <option key={role.code} value={role.code}>
                                                          {role.name}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    <span
                                                      style={{
                                                        color: "red",
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: 0,
                                                        minHeight: "16px",
                                                        borderRadius: "4px",
                                                        padding: "2px 4px",
                                                      }}
                                                    >
                                                      {errorsTechnician[index] && (
                                                        <span style={{ color: "red" }}>{errorsTechnician[index]}</span>
                                                      )}
                                                    </span>
                                                  </div>
                                                </span>



                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }}>
                                                    Estimated Date
                                                  </span>
                                                  <span>:&nbsp;</span>
                                                  <div style={{ width: '60%', display: 'inline-block' }}>
                                                    <input
                                                      type="date"
                                                      min={new Date().toISOString().split("T")[0]}
                                                      style={{
                                                        width: 108,
                                                        height: 32,
                                                        borderRadius: '5px', // Add border-radius for the input field
                                                        padding: '4px', // Optional: Add padding for better spacing
                                                        border: '1px solid #ccc', // Optional: Add border styling
                                                      }}
                                                      onChange={(e) => handleDateChange(e, index)} // Pass index to handleDateChange
                                                      value={
                                                        item.status === "Approved"
                                                          ? estDate || moment(item.estimatedCompletionDate).format("YYYY-MM-DD")
                                                          : data[index].estDate
                                                      }
                                                      disabled={item.status === "Approved"} // Disable input if status is "Approved"
                                                    />
                                                  </div>
                                                  <div>
                                                    <span style={{ color: "red" }}>{errDate[index]}</span> {/* Show date error for the specific index */}
                                                  </div>
                                                </span>


                                              </div>
                                              <div style={{ fontSize: 14, width: "100%", }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Images
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '60%', display: 'inline-block' }}>
                                                    {item.image.map(
                                                      (imageUrl, imgIndex) => (
                                                        <div key={imgIndex}
                                                          style={{
                                                            marginRight: "10px", display: "inline-block",
                                                            border: "1px solid",
                                                          }}  >
                                                          <div style={{ width: "60%", }} >
                                                            <img key={imgIndex} src={imageUrl} alt={`Image ${imgIndex}`}
                                                              style={{ width: "30px", height: "40px", cursor: "pointer", }}
                                                              onClick={() => handleImageClick(imageUrl)} />
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </span>
                                                </span>
                                                {
                                                  item.requesterRemarks !== "" ? (
                                                    <span style={{ width: '33.4%' }}>
                                                      <span style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                        Requester Remarks
                                                      </span>
                                                      <span >  :&nbsp;
                                                      </span>
                                                      <span style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requesterRemarks}</span>
                                                    </span>
                                                  ) : ""
                                                }
                                                <span style={{ width: '33.4%' }}>
                                                  <div style={{ width: '40%', display: 'inline-block', fontWeight: 600 }} >
                                                    Remarks
                                                  </div>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <div style={{ width: '60%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                    <textarea
                                                      type="text"
                                                      style={{ borderColor: "silver", borderRadius: 5 }}
                                                      value={item.accepterRemarks}
                                                      onChange={(e) => handleRemark(e, index)}
                                                      disabled={item.status === "Approved"} // Disable input if status is "Approved"
                                                    />
                                                  </div>
                                                  <div>
                                                    <span style={{ color: "red", marginLeft: "166px" }}>
                                                      {errRemarks[index]} {/* Display error for the specific index */}
                                                    </span>
                                                  </div>
                                                </span>
                                              </div>

                                              <div className="mt-0 d-flex justify-content-end">
                                                <span className="mt-3">
                                                  {item.status === "Pending" && (
                                                    <>
                                                      {/* Approve Button */}
                                                      <button onClick={() => handleAprove(index)} className="btns">
                                                        Approve
                                                      </button>
                                                      {/* Reject Button */}
                                                      <button
                                                        className="btnsReject ml-2 mb-2"
                                                        onClick={() => handleReject(index)}
                                                      >
                                                        Reject
                                                      </button>
                                                    </>
                                                  )}
                                                </span>

                                              </div>

                                            </>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="md"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">R & M</span>
                              </Modal.Title>
                              {/* <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button> */}
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading && (
                <Loader_Bulk
                  loading={isLoading}
                  message={"Fetching Data. Please Wait..!"}
                  loderimage={loaderImage}
                />
              )}
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div >
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      < a className="scroll-to-top rounded" href="#page-top" >
        <i className="fas fa-angle-up"></i>
      </a >

      {/*  <!-- Logout Modal--> */}
      < Logout />
    </body >
  );
};

export default RepairMaintenace;
