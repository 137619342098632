import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getDayWisePermitCountMethodCorePermit } from "../../Utilities/URLCONSTANTS";

function CorePermitInputFields(props) {
  const permitsOneRef = useRef(null);
  const permitsTwoRef = useRef(null);
  const dateInputRef = useRef(null);
  const initialsOneRef = useRef(null);
  const initialsTwoRef = useRef(null);
  const initialsThreeRef = useRef(null);
  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");


  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const defaultDate = props.data.dateInputOne || getTodayDate();

  const handleInitialPermits = (key, val) => {
    props.setPermitInitials((prev) => ({ ...prev, [key]: val }));
  };



  useEffect(() => {
    if (!props.data.dateInputOne) {
      const today = getTodayDate();
      handleInitialPermits("dateInputOne", today);
    }
    if (!props.data.permitsOne) {
      handleInitialPermits("permitsOne", permitsOne);
    }
    if (!props.data.permitsTwo) {
      handleInitialPermits("permitsTwo", permitsTwo);
    }
  }, [props.data.dateInputOne, permitsOne, permitsTwo]);

  useEffect(() => {
    if (props.data.permitsOne === '0' && props.data.permitsTwo === '0') {
      alert("Permits Cannot Start With 0,0");
      handleInitialPermits("permitsOne", "");
      handleInitialPermits("permitsTwo", "");
      permitsOneRef.current.focus();
    }
  }, [props.data.permitsOne, props.data.permitsTwo]);


  return (
    <div
      className="d-flex"
      style={{
        padding: "2px",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: " 5px",
        border: "1px solid gray",
      }}
    >
      <p
        style={{
          color: "#000",
          margin: "5px 5px",
          fontSize: "12px",
          fontWeight: "bolder",
        }}
      >
        Crane Permit #:
      </p>
      {/* Intilas */}
      <div className="d-flex">
        <div>
          <input
            ref={initialsOneRef}
            id="initialsOne"
            type="text"
            className="microsite-txtbx"
            value={props.data.initialsOne}
            disabled={!props.editable}
            maxLength={"1"}
            onChange={(e) => {
              const alphabeticValue = e.target.value.replace(/[^a-zA-Z]/g, "");
              if (e.target.value !== alphabeticValue) {
                alert("Only Alphabetic Characters are Allowed for Initials.");
              }
              e.target.value = alphabeticValue;
              handleInitialPermits("initialsOne", alphabeticValue);
              if (alphabeticValue.length === 1) {
                initialsTwoRef.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && props.data.initialsOne === "") {
                e.preventDefault();
              }
            }}
          />

          <input
            ref={initialsTwoRef}
            id="initialsTwo"
            type="text"
            className="microsite-txtbx"
            maxLength={"1"}
            value={props.data.initialsTwo}
            disabled={!props.editable}
            onChange={(e) => {
              const alphabeticValue = e.target.value.replace(/[^a-zA-Z]/g, "");
              if (e.target.value !== alphabeticValue) {
                alert("Only Alphabetic Characters are Allowed for Initials.");
              }
              e.target.value = alphabeticValue;
              handleInitialPermits("initialsTwo", alphabeticValue);
              if (alphabeticValue.length === 1) {
                initialsThreeRef.current.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && props.data.initialsTwo === "") {
                initialsOneRef.current.focus();
              }
            }}
          />

          <input
            ref={initialsThreeRef}
            id="initialsThree"
            type="text"
            className="microsite-txtbx"
            maxLength={"1"}
            value={props.data.initialsThree}
            disabled={!props.editable}
            onChange={(e) => {
              const alphabeticValue = e.target.value.replace(/[^a-zA-Z]/g, "");
              if (e.target.value !== alphabeticValue) {
                alert("Only Alphabetic Characters are Allowed for Initials.");
              }
              e.target.value = alphabeticValue;
              handleInitialPermits("initialsThree", alphabeticValue);
              if (alphabeticValue.length === 1) {
                if (permitsOneRef.current) {
                  permitsOneRef.current.focus();
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && props.data.initialsThree === "") {
                initialsTwoRef.current.focus();
              }
            }}
          />

          <p className="header-lbl">Initials</p>
        </div>


        {/* Date */}
        <div className="datePicker">
          <input
            ref={dateInputRef}
            id="dateInputOne"
            value={props.data.dateInputOne || getTodayDate()}
            style={{ padding: "0.2px", fontSize: "10px", borderRadius: "5px", width: "90px", textAlign: "center" }}
            onChange={(e) => {
              const date = e.target.value;
              if (date.length <= 10) {
                handleInitialPermits("dateInputOne", date);
              }
            }}
          // readOnly
          />
          <p className="header-lbl" style={{ textAlign: "center" }}>Date</p>
        </div>

        {/* Permits */}
        <div className="ml-1">
          <input
            ref={permitsOneRef}
            type="text"
            className="microsite-txtbx"
            value={props.data.permitsOne}
            disabled={!props.editable}
            maxLength={"1"}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              if (e.target.value !== numericValue) {
                alert("Only Numerics are Allowed for Permits.");
              }
              e.target.value = numericValue;
              handleInitialPermits("permitsOne", numericValue);
              if (numericValue.length === 1) {
                if (permitsTwoRef.current) {
                  permitsTwoRef.current.focus();
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && props.data.permitsOne === "") {
                initialsThreeRef.current.focus();
              }
            }}
          />

          <input
            ref={permitsTwoRef}
            id="permitsTwo"
            type="text"
            className="microsite-txtbx"
            value={props.data.permitsTwo}
            disabled={!props.editable}
            maxLength={"1"}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              if (e.target.value !== numericValue) {
                alert("Only Numerics are Allowed for Permits.");
              }
              e.target.value = numericValue;
              handleInitialPermits("permitsTwo", numericValue);
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && props.data.permitsTwo === "") {
                permitsOneRef.current.focus();
              }
            }}
          />
          <p className="header-lbl">#Permits</p>
        </div>

      </div>
    </div>
  );
}

export default CorePermitInputFields;
