import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils'

const currentDate = new Date().toISOString().split('T')[0];
function BasicDetails(props) {
    const handleBasicData = (key, val) => {
        props.setBasicDetails((prev) => ({ ...prev, [key]: val }));
    };

  
    const handleStartDateChange = (issueDate) => {
        if (issueDate) {
            const dateObj = new Date(issueDate);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            const year = dateObj.getFullYear();
    
            const formattedDate = `${day}-${month}-${year}`;
            props.setBasicDetails((prev) => ({ ...prev, issueDate: formattedDate }));
            console.log(formattedDate);
        }
    };
    

    const handleEndDateChange = (expirationDate) => {
        if (expirationDate) {
            const dateObj = new Date(expirationDate);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            const year = dateObj.getFullYear();
    
            const formattedDate = `${day}-${month}-${year}`;
            props.setBasicDetails((prev) => ({ ...prev, expirationDate: formattedDate }));
            console.log(formattedDate);
        }
    };
    
    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div>
                            <div className="form-group">
                                <label >Company Name<span style={{ color: "red" }}> *</span></label>
                                <div>


                                    <input
                                        class="form-control mb-1"
                                        id="inputPassword"
                                        placeholder="PRINT"
                                        value={props.data?.company || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
                                            handleBasicData('company', alphabetsOnly);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Location for Crane Work</label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        id="inputPassword"
                                        placeholder="PRINT"
                                        value={props.data?.locationForCrameWork || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(/[^a-zA-Z, .]/g, '');
                                            handleBasicData('locationForCrameWork', alphabetsOnly);
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="inputText">Crane Operator(s) Name</label>
                                <div>
                                    <input
                                        className="form-control"
                                        id="cd"
                                        placeholder=""
                                        type="text"
                                        value={props.data?.craneOperatorDate || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, ''); 
                                            handleBasicData('craneOperatorDate', alphaValue);
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="form-group">
                                <label htmlFor="inputText">Issue Date</label>
                                <div>
                                   
                                      <DatePicker 
                                        className="form-control"
                                        placeholderText="Issue Date" 
                                        value={props.data?.issueDate || ""}
                                        onChange={(issueDate) => handleStartDateChange(issueDate)}
                                         dateFormat="dd-MMM-yyyy"
                                         minDate={new Date(currentDate)}
                                         customInput={<CustomDatePickerInput />}
                                        />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText">Expiration Date</label>
                                <div>
                                    

                                    <DatePicker 
                                        className="form-control"
                                        placeholderText="Expiration Date" 
                                        value={props.data?.expirationDate || ""}
                                        onChange={(expirationDate) => handleEndDateChange(expirationDate)}
                                         dateFormat="dd-MMM-yyyy"
                                         minDate={new Date(currentDate)}
                                         customInput={<CustomDatePickerInput />}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicDetails;
