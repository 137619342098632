import React, { useState } from 'react'
import { useEffect } from 'react'

function PartTWO(props) {
    const [excavationCompetent, setexcavationCompetent] = useState();
    const handlePartTwoJson = (type, key, val, index = null) => {
        if (type == 'basic') {
            const data = props.data
            const basicEle = props.data.basic[0]
            if (key == 'title1yes') {
                basicEle['title1yes'] = val
                if (val == 'true')
                    basicEle['title1no'] = 'false'
            } else {
                basicEle['title1no'] = val
                if (val == 'true')
                    basicEle['title1yes'] = 'false'
            }
            props.setPartTwo(prev => ({ ...prev, basic: [basicEle] }))
        } else if (type === 'indicate' || type == 'special') {
            const roofObj = { ...props.data }
            const newObj = roofObj[type]
            const newList = newObj[0]['list'].map((i, j) => {
                if (j == index) {
                    return { ...i, [key]: val }
                } else {
                    return i
                }
            });
            newObj[0]['list'] = newList

            props.setPartTwo(prev => ({ ...prev, [type]: newObj }))
        } else if (type == 'explain' || type == 'whatPrecautuions') {
            const explain = props.data.explain
            explain[key] = val
            props.setPartTwo(prev => ({ ...prev, [type]: explain }))
        } else if (type === 'utilities') {
            const roofObj = { ...props.data }
            const newObj = roofObj[type]
            const newList = newObj['list'].map((i, j) => {
                if (j == index) {
                    return { ...i, [key]: val }
                } else {
                    return i
                }
            });
            newObj['list'] = newList

            props.setPartTwo(prev => ({ ...prev, [type]: newObj }))
        } else {

        }
        // props.setPartTwo((prev) => ({ ...prev, [key]: val }))
    }

    const handleDataByJson = (type, key, val, opType) => {
        let dataOb = props.data[type]
        if (type == 'hasProfessionalDeeper' || type == 'hasProfessionalReduce') {
            dataOb = props.data.special[0][type]
        }
        dataOb[key] = val
        if (opType == 2) {
            if (key == 'cbYes') {
                if (val == 'true')
                    dataOb['cbNo'] = 'false'
            } else {
                if (val == 'true')
                    dataOb['cbYes'] = 'false'
            }
        } else if (opType == 3) {
            if (key == 'cbYes') {
                if (val == 'true') {
                    dataOb['cbNo'] = 'false'
                    dataOb['cbNA'] = 'false'
                }
            } else if (key == 'cbNo') {
                if (val == 'true') {
                    dataOb['cbYes'] = 'false'
                    dataOb['cbNA'] = 'false'
                }
            } else {
                if (val == 'true') {
                    dataOb['cbYes'] = 'false'
                    dataOb['cbNo'] = 'false'
                }
            }
        }
        if (type == 'hasProfessionalDeeper' || type == 'hasProfessionalReduce') {
            const special = props.data.special
            special[0][type] = dataOb
            props.setPartTwo(prev => ({ ...prev, special: special }))
        } else {
            props.setPartTwo(prev => ({ ...prev, [type]: dataOb }))
        }

    }

    const updateMobileNumberDes = (val, index, key) => {
        const specialOb = { ...props.data.special }
        const list = specialOb[0].escavationApproval.list.map((i, j) => {
            if (j == index) {
                return {
                    ...i, [key]: val
                }
            } else {
                return i
            }
        })
        specialOb[0].escavationApproval.list = list
        props.setPartTwo(prev => ({ ...prev, special: specialOb }))

    }


    const handleExcavationCompetent = (e) => {
        const selectedValue = e.target.value;
        setexcavationCompetent(selectedValue);
        //alert(excavationCompetent);
    };

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">

                    <div>
                        <div className="row" style={{ margin: "-31px -30px 3px -30px", backgroundColor: "#566060", color: "white" }}>
                            <div>
                                <p>Excavation Competent Person Completes this section </p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Have all sub-surface obstacles been identified?(if "No is checked,a contingency plan shall be prepared.)<span className='required'> *</span></label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input checked={props.data.basic[0].title1yes === 'true'} className="form-check-input  mt-0" onClick={(e) => {
                                        handlePartTwoJson('basic', 'title1yes', props.data.basic[0].title1yes == 'true' ? 'false' : 'true')
                                    }} type="checkbox" id="basicPart2Yes" value="option1" />
                                    <label className="form-check-label  mt-0" for="basicPart2Yes">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input checked={props.data.basic[0].title1no === 'true'} className="form-check-input mt-0" onClick={(e) => {
                                        handlePartTwoJson('basic', 'title1no', props.data.basic[0].title1no == 'true' ? 'false' : 'true')
                                    }} type="checkbox" id="basicPart2No" value="option2" />
                                    <label className="form-check-label mt-0" for="basicPart2No">NO</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Please indicate which two forms of evidence will be used to show confidence that all underground obstacles were found:</label>
                            <div className="col-12">
                                {
                                    props.data.indicate[0].list.map((indItemSpe, index) => (
                                        <>
                                            {
                                                indItemSpe.cbTitle != '' && <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                                    <input className="form-check-input mt-0" checked={indItemSpe.checked} onClick={() => {
                                                        handlePartTwoJson('indicate', 'checked', !indItemSpe.checked, index)
                                                    }} type="checkbox" id={`indicateItem@${index}`} value="option1" />
                                                    <label className="form-check-label  mt-0" for={`indicateItem@${index}`} >{indItemSpe.cbTitle}</label>
                                                </div>
                                            }
                                        </>
                                    ))
                                }


                                <div className="m-2">
                                    <input onChange={(e) => {
                                        handlePartTwoJson('indicate', 'description', e.target.value, props.data.indicate[0].list.length - 1)
                                    }} className="form-control" id="inputPassword" value={props.data.indicate[0].list[props.data.indicate[0].list.length - 1].description} placeholder="" />
                                </div>

                            </div>
                        </div>
                        <div className="form-group">
                            <label >Explain how the two methods whrere used to locate utilities or subsurface obstacles:</label>
                            <div>
                                <input value={props.data.explain.cbValue} onChange={(e) => {
                                    handlePartTwoJson('explain', 'cbValue', e.target.value)
                                }} className="form-control" id="inputPassword" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Have all utilities been identified and shared with the excavtion crew?(if"NO"is checked,the xcavtion Competent Person shall share the results of the sub-surface investigation with the excavtion crew).</label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.haveAll.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('haveAll', 'cbYes', props.data.haveAll.cbYes == 'true' ? 'false' : 'true', 2)
                                    }} type="checkbox" id="inhaveallutils123" value="option1" />
                                    <label className="form-check-label  mt-0" for="inhaveallutils123">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" type="checkbox" onClick={(e) => {
                                        handleDataByJson('haveAll', 'cbNo', props.data.haveAll.cbNo == 'true' ? 'false' : 'true', 2)
                                    }} checked={props.data.haveAll.cbNo == 'true'} id="inhaveallutils321" value="option2" />
                                    <label className="form-check-label mt-0" for="inhaveallutils321">NO</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Utilities Present In Excavation Area</label>
                            <div className="col-12">
                                {
                                    props.data.utilities.list.map((indItemUti, index) => (
                                        <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                            <input className="form-check-input mt-0" checked={indItemUti.isChecked == 'true'} onClick={() => {
                                                handlePartTwoJson('utilities', 'isChecked', indItemUti.isChecked == 'true' ? 'false' : 'true', index)
                                            }} type="checkbox" id={`utilitiesItem@${index}`} value="option1" />
                                            <label className="form-check-label  mt-0" for={`utilitiesItem@${index}`} >{indItemUti.cbTitle}</label>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-12 col-form-label">Can these utilities/services be de-energied and isolated?(if"NO is checked,a contingency plan shall be discussed.)</label>
                            <div className="col-12 d-flex flex-row">
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.canThese.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('canThese', 'cbYes', props.data.canThese.cbYes == 'true' ? 'false' : 'true', 2)
                                    }} type="checkbox" id="canthese123" value="option1" />
                                    <label className="form-check-label  mt-0" for="canthese123">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" onClick={(e) => {
                                        handleDataByJson('canThese', 'cbNo', props.data.canThese.cbNo == 'true' ? 'false' : 'true', 2)
                                    }} checked={props.data.canThese.cbNo == 'true'} type="checkbox" id="canthese321" value="option2" />
                                    <label className="form-check-label mt-0" for="canthese321">NO</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Does this area have any Environmental concerns by previous activities?</label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.doesThis.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('doesThis', 'cbYes', props.data.doesThis.cbYes == 'true' ? 'false' : 'true', 3)
                                    }} type="checkbox" id="doesThese1" value="option1" />
                                    <label className="form-check-label  mt-0" for="doesThese1">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" onClick={(e) => {
                                        handleDataByJson('doesThis', 'cbNo', props.data.doesThis.cbNo == 'true' ? 'false' : 'true', 3)
                                    }} checked={props.data.doesThis.cbNo == 'true'} type="checkbox" id="doesThese2" value="option2" />
                                    <label className="form-check-label mt-0" for="doesThese2">NO</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" onClick={(e) => {
                                        handleDataByJson('doesThis', 'cbNA', props.data.doesThis.cbNA == 'true' ? 'false' : 'true', 3)
                                    }} checked={props.data.doesThis.cbNA == 'true'} type="checkbox" id="doesThese3" value="option2" />
                                    <label className="form-check-label mt-0" for="doesThese3">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label >What precautions/control measures are needed for the contaminated area?</label>
                            <div>
                                <input className="form-control" value={props.data.whatPrecautuions.cbValue} onChange={(e) => {
                                    handlePartTwoJson('whatPrecautuions', 'cbValue', e.target.value)
                                }} id="inputPassword" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Is air monitoring required for the excavation?(If"YES"follow Corteva's PRCS Procedure)</label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.isAir.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('isAir', 'cbYes', props.data.isAir.cbYes == 'true' ? 'false' : 'true', 2)
                                    }} type="checkbox" id="isAirID1" value="option1" />
                                    <label className="form-check-label  mt-0" for="isAirID1">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" onClick={(e) => {
                                        handleDataByJson('isAir', 'cbNo', props.data.isAir.cbNo == 'true' ? 'false' : 'true', 2)
                                    }} checked={props.data.isAir.cbNo == 'true'} type="checkbox" id="isAirID2" value="option2" />
                                    <label className="form-check-label mt-0" for="isAirID2">NO</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Special Precautions and Safety Requirements(check those required on the excavation site):</label>
                            <div className="col-12">
                                {
                                    props.data.special[0].list.map((indItem, index) => (
                                        <>
                                            {
                                                indItem.cbTitle && <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                                    <input className="form-check-input mt-0" checked={indItem.checked} onClick={() => {
                                                        handlePartTwoJson('special', 'checked', !indItem.checked, index)
                                                    }} type="checkbox" id={`specialItem@${index}`} value="option1" />
                                                    <label className="form-check-label  mt-0" for={`specialItem@${index}`} >{indItem.cbTitle}</label>
                                                </div>
                                            }
                                        </>

                                    ))
                                }

                                <div className="mt-2">
                                    <input onChange={(e) => {
                                        handlePartTwoJson('special', 'description', e.target.value, props.data.special[0].list.length - 1)
                                    }} className="form-control" id="inputPassword" value={props.data.special[0].list[props.data.special[0].list.length - 1].description} placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Has a Professional registered Engineer approved all protection systems for excavations deeper than 20ft(6m)?</label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.special[0].hasProfessionalDeeper.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('hasProfessionalDeeper', 'cbYes', props.data.special[0].hasProfessionalDeeper.cbYes == 'true' ? 'false' : 'true', 3)
                                    }} type="checkbox" id="hasProfessionalDeeper1" value="option1" />
                                    <label className="form-check-label  mt-0" for="hasProfessionalDeeper1">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.special[0].hasProfessionalDeeper.cbNo == 'true'} onClick={(e) => {
                                        handleDataByJson('hasProfessionalDeeper', 'cbNo', props.data.special[0].hasProfessionalDeeper.cbNo == 'true' ? 'false' : 'true', 3)
                                    }} type="checkbox" id="hasProfessionalDeeper2" value="option2" />
                                    <label className="form-check-label mt-0" for="hasProfessionalDeeper2">NO</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.special[0].hasProfessionalDeeper.cbNA == 'true'} onClick={(e) => {
                                        handleDataByJson('hasProfessionalDeeper', 'cbNA', props.data.special[0].hasProfessionalDeeper.cbNA == 'true' ? 'false' : 'true', 3)
                                    }} type="checkbox" id="hasProfessionalDeeper3" value="option2" />
                                    <label className="form-check-label mt-0" for="hasProfessionalDeeper3">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Comments</label>
                            <div>

                                <input className="form-control"
                                    type='text'

                                    value={props.data?.special[0].hasProfessionalDeeper.description || ""}
                                    onChange={(e) => {
                                        handleDataByJson('hasProfessionalDeeper', 'description', e.target.value, 3)
                                    }} id="hasProfessionalDeeper4" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Has a Professional registered Engineer approved all protection systems for excavations that will reduce support of existing foundations regardless of depth?</label>
                            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input  mt-0" type="checkbox" checked={props.data.special[0].hasProfessionalReduce.cbYes == 'true'} onClick={(e) => {
                                        handleDataByJson('hasProfessionalReduce', 'cbYes', props.data.special[0].hasProfessionalReduce.cbYes == 'true' ? 'false' : 'true', 3)
                                    }} id="hasProfessionalReduce123" value="option1" />
                                    <label className="form-check-label  mt-0" for="hasProfessionalReduce123">YES</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.special[0].hasProfessionalReduce.cbNo == 'true'} onClick={(e) => {
                                        handleDataByJson('hasProfessionalReduce', 'cbNo', props.data.special[0].hasProfessionalReduce.cbNo == 'true' ? 'false' : 'true', 3)
                                    }} type="checkbox" id="hasProfessionalReduce1234" value="option2" />
                                    <label className="form-check-label mt-0" for="hasProfessionalReduce1234">NO</label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                    <input className="form-check-input mt-0"
                                        checked={props.data.special[0].hasProfessionalReduce.cbNA == 'true'}
                                        onClick={(e) => {
                                            handleDataByJson('hasProfessionalReduce', 'cbNA', props.data.special[0].hasProfessionalReduce.cbNA == 'true' ? 'false' : 'true', 3)
                                        }} type="checkbox" id="hasProfessionalReduce12345" value="option2" />
                                    <label className="form-check-label mt-0" for="hasProfessionalReduce12345">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div className="Corper-subtxt" style={{ color: "white", backgroundColor: "#DF3A1C" }}>

                            <p className="card-subheading" style={{ color: "white", backgroundColor: "#DF3A1C" }}>Excavation Approval Signatures</p>
                        </div>

                        <div style={{ backgroundColor: "#D4D4D4", marginRight: "-20px", marginLeft: "-20px", color: "#000" }}>
                            <div className="col-12">
                                <div className="form-group" style={{ margin: "5px", padding: "5px" }}>
                                    {
                                        props.data.special[0].escavationApproval.list.map((esItem, index) => (
                                            <div key={index + 1}>
                                                <label >{esItem[`cbTitle${index + 1}`]} :</label>
                                                <div>

                                                    <input
                                                        type="text"
                                                        className="form-control col-12"
                                                        id={`inputDescription${index}`}
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const onlyAlphabets = inputValue.replace(/[^A-Za-z ]/g, '');
                                                            updateMobileNumberDes(onlyAlphabets, index, 'description');
                                                        }}
                                                        value={esItem.description}
                                                    />
                                                </div>

                                                <label>Phone number:</label>
                                                <div>

                                                    <input
                                                        type="text"
                                                        className="form-control col-12"
                                                        onChange={(e) => {
                                                            updateMobileNumberDes(e.target.value, index, 'phoneNumber');
                                                        }}
                                                        value={esItem.phoneNumber}
                                                        id={`inputPhoneNumber${index}`}
                                                        placeholder=""
                                                        style={{ width: "200px" }}
                                                        minLength={10}
                                                        maxLength={10}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/\D/g, '');
                                                            if (e.target.value.length >= 1 && !['6', '7', '8', '9'].includes(e.target.value.charAt(0))) {
                                                                e.target.value = '';
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartTWO;