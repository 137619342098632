import React, { useEffect, useState } from "react";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import { Button, Modal } from "react-bootstrap";
import { CustomTable } from "../CustomTable";
import { ActivityMasterAdd, ActivityMasterGet, FarmOperation_getMasters, MainActivityMasterAdd, MainActivityMasterGet, } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import { CustomModal } from "../CustomModal";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";

export const ActivityMaster = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [getMasterData, setGetMasterData] = useState([])
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [mainActivityName, setMainActivityName] = useState()
    const [mainActivityId, setMainActivityId] = useState()
    const [status, setStatus] = useState()
    const [id, setId] = useState()
    const [saveEdit, setSaveEdit] = useState()
    const [responseMsg, setResponseMsg] = useState()
    const [msgPopup, setMsgPopup] = useState(false)
    const [error, setError] = useState('')
    const [mainActivityError, setMainActivityError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [loader, setLoader] = useState(false)

    const getData = async () => {
        const URL = ActivityMasterGet;
        const headers = GetApiHeaders()
        try {
            setLoader(true)
            const response = await axios.get(URL, { headers })
            setData(response.data.response)
            setLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getMasterDropdownsData = async () => {
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        try {
            const response = await axios.get(apiUrl, { headers })
            setGetMasterData(response.data.response.mainActivityMaster)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
        getMasterDropdownsData()
    }, [])

    const headersData = [
        {
            field: "mainActivityId.mainActivityCategory",
            header: " Category",
        },
        {
            field: "name",
            header: "Subcategory/Type of operation  ",
        },

        {
            field: "createdBy.firstName",
            header: "Created By",
        },
        {
            field: "description",
            header: "Description",
        },

        {
            field: "active",
            header: "Status",

        },
    ];

    const onEditClicked = (data) => {
        setSaveEdit('Update')
        setId(data.id)
        setShowModal(true)
        setName(data.name)
        setDescription(data.description)
        setMainActivityName(data.mainActivityId.mainActivityCategory)
        setMainActivityId(data.mainActivityId.id)
        setStatus(data.active)
        setError('')
        setMainActivityError('')
        setStatusError('')
    }

    const handleModalOpen = () => {
        setSaveEdit('Submit')
        setShowModal(true)
        setName('')
        setStatus('')
        setDescription('')
        setMainActivityName('')
        setError('')
        setMainActivityError('')
        setStatusError('')
    }
    const handleCancel_new = () => {
        setShowModal(false)
    }

    const handleName = (e) => {
        const Input = e.target.value
        const alphabeticInput = Input.replace(/[^a-zA-Z ]/g, "");
        setName(alphabeticInput)
        if (Input) {
            setError('')
        }
    }
    const handleMainActivity = (e) => {
        const mainActivityInput = e.target.value
        setMainActivityName(mainActivityInput)
        const mainActivityId = e.target.options[e.target.selectedIndex].getAttribute("data-mainActivityId");
        setMainActivityId(mainActivityId)
        if (mainActivityInput) {
            setMainActivityError('')
        }
    }


    const handleStatus = (e) => {
        const statusInput = e.target.value
        setStatus(statusInput)
        if (statusInput) {
            setStatusError('')
        }
    }
    const handleDescription = (e) => {
        const Input = e.target.value
        setDescription(Input)

    }

    const handleSubmitData = async () => {
        const apiUrl = ActivityMasterAdd;
        const headers = await GetApiHeaders()
        const reqData = {
            id: saveEdit === "Submit" ? 0 : id,
            name: name,
            mainActivityCategoryName: mainActivityName,
            description: description,
            mainActivityId: parseInt(mainActivityId),
            status: status
        }
        try {
            if (!status) {
                setStatusError("Select Status")
            }
            if (!mainActivityName) {
                setMainActivityError("Select Category")
            }

            if (!name) {
                setError('Enter Name')
            }
            if (name !== '' && mainActivityName !== '' && status !== '') {
                setLoader(true)
                const response = await axios.post(apiUrl, reqData, { headers })
                if (response.data.statusCode == 200 || response.data.status == 500) {
                    setShowModal(false)
                    setMsgPopup(true)
                    setResponseMsg(response.data.message);
                    setLoader(false)
                }
            }

        } catch (error) {
            console.log(error)
        }
    }
    const handleOk = () => {
        setMsgPopup(false)
        getData()
    }

    return (
        <body id="page-top">
            <div id="wrapper">
                <SideMenu />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Header />
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body pt-0 pl-1">
                                        <h5 className="Roles_text">  Master </h5>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="mb-0 Sub_role_text">Subcategory Master</h6>
                                            </div>
                                            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add Activity Master"  >
                                                <Button variant="primary" className="buttons" onClick={handleModalOpen}  >
                                                    <FontAwesomeIcon icon={faPlus} className="plus_icon mr-1" />
                                                    Add
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="mcrc_hrline"></div>
                                        <CustomTable
                                            headers={headersData}
                                            data={data}
                                            showAction={true}
                                            showButtons={true}
                                            ActionEdit={true}
                                            onEditClicked={onEditClicked}
                                            ActionName={"Action"}
                                            loader={loader}
                                            FileName={'Subcategory'}
                                        />

                                        <CustomModal
                                            showModal={showModal}
                                            handleCancel_new={handleCancel_new}
                                            tittle={' Subcategory Master'}
                                            buttonText={saveEdit}
                                            handleSubmitData={handleSubmitData}
                                            childrens={
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <label for="InputName" className="form-label text-sm font-weight-600 mt-2">
                                                                Category <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <select id="dropdown" type="dropdown" className="form-control" value={mainActivityName} onChange={handleMainActivity}>
                                                                <option value="" disabled>Select Category</option>
                                                                {
                                                                    getMasterData.map((item, index) =>
                                                                        <option key={item.code} value={item.name} data-mainActivityId={item.code}>
                                                                            {item.name}
                                                                        </option>)
                                                                }
                                                            </select>
                                                            <span style={{ color: "red" }}> {mainActivityError}</span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mt-2'>
                                                            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                Subcategory / Type of Operation <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <div className="input-group input-group w-100">
                                                                <input type="text" className="form-control p-2 " placeholder='Enter Subcategory Name' value={name} onChange={handleName} />
                                                            </div>
                                                            <span style={{ color: "red" }}> {error}</span>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-6 col-12 '>
                                                                <label for="exampleInputPassword1" className="form-label text-sm font-weight-600 mt-1">
                                                                    Description
                                                                </label>
                                                                <div className="input-group input-group w-100">
                                                                    <textarea class="form-control" style={{ height: 115 }} id="textAreaExample1" placeholder='Enter Description'
                                                                        rows="2" value={description} onChange={handleDescription} >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 col-12'>
                                                                <label for="InputName" className="form-label text-sm font-weight-600 mt-1"
                                                                >Status <span style={{ color: "red" }}>*</span> </label>
                                                                <select id="dropdown" type="dropdown" className="form-control p-2" value={status} onChange={handleStatus}>
                                                                    <option value="" disabled>Select Status</option>
                                                                    <option value={true}>Active</option>
                                                                    <option value={false}>In-Active</option>
                                                                </select>
                                                                <span style={{ color: "red" }}> {statusError}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <CustomModal
                                            showModal={msgPopup}
                                            msgPopup={msgPopup}
                                            handleOk={handleOk}
                                            handleCancel_new={handleCancel_new}
                                            tittle={'Subcategory Master'}
                                            childrens={<div className="p-4 d-flex justify-content-center" style={{ fontSize: "18px", color: "green", fontWeight: 500 }}>{responseMsg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            <Logout />
        </body>
    )
}