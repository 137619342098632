
import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RequestDeliverdCountAPI } from "../../Utilities/URLCONSTANTS";

Chart.register(ChartDataLabels);

const RequestDeliverdChart = () => {
  const [RequestDelvCount, setRequestDelvCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState("");

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : "";
    const formattedEndDate = endDate ? formatDate(endDate) : "";
    const requObj = {
      type: startDate && endDate ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    try {
      const response = await axios.post(RequestDeliverdCountAPI, requObj);
      setRequestDelvCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.error("RequestDeliverdRequest Error", error);
    }
  };

  useEffect(() => {
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);

  const data = {
    labels: RequestDelvLabels,
    datasets: [RequestDelvCount],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      title: {
        display: true,
        text: "",
        align: "start",
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value) => (value && value !== 0 ? value : null),
        anchor: 'end',
        align: 'start',
      },
    },
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="card p-4">
        <label className="chartname">Requested & Delivered</label>
        <div className="d-flex mb-3">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="">All</option>
            <option value="MTD">Monthly</option>
            <option value="YTD">Yearly</option>
            <option value="DTD">Custom Selection</option>
          </select>
        </div>

        {selectedValue === "DTD" && (
          <div className="d-flex gap-3 mb-3">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control"
            />
          </div>
        )}

        {error && (
          <div
            style={{ color: "red", fontSize: 12 }}
            className="mb-3"
          >
            {error}
          </div>
        )}

        <div
          className="chart-container"
          style={{
            position: "relative",
            height: "200px",
            margin: "0 auto",
          }}
        >
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default RequestDeliverdChart;
