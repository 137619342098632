
import React, { useEffect, useState } from 'react'
function CoreRoofAccess(props) {


    const handleRoofAccess = (key, val, index, mainKey, subKey = '') => {
        const roofObj = { ...props.data };
        const newObj = roofObj[mainKey];
        const newList = roofObj[mainKey][subKey].map((i, j) => {
            if (j === index) {
                const updatedItem = { ...i, [key]: val };
                if (key === 'checked' && !val) {
                    updatedItem.description = ''; 
                }
                return updatedItem;
            } else {
                return i;
            }
        });
        newObj[subKey] = newList;

        props.setRoofAccess((prev) => ({ ...prev, [mainKey]: newObj }));
    };

    return (
        <div>
            <div className="col-12 col-lg-2">
                <div>
                    <div>

                        <div className="col-12" style={{}}>
                            <div className="Corper-subtxt wenv-bg-color">
                                <p className="card-subheading wenv-bg-color" style={{ marginRight: "-23px", marginLeft: "-23px", }}>Slope type of roof(select one):<span className='required'> *</span> </p>
                            </div>
                        </div>
                        {props.data.slopeType.list.map((slopeItem, index) => (
                            <>
                                {slopeItem.checked !== undefined &&
                                    <div class="col-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input mt-3" checked={slopeItem.checked} onChange={(e) => {
                                                handleRoofAccess('checked', !slopeItem.checked, index, 'slopeType', 'list')
                                            }} type="checkbox" id={`slopeItemList${index}`} value="option2" />
                                            <label class="form-check-label mt-2" for={`slopeItemList${index}`}>{slopeItem.cbTitle}</label>
                                        </div>
                                    </div>
                                }
                                {slopeItem.description != undefined &&
                                    <div class="form-group">
                                        <div>
                                            <input class="form-control" type="text" id="empt1" value={slopeItem.description} placeholder={''}
                                                onChange={(e) => handleRoofAccess('description', e.target.value, index, 'slopeType', 'list')}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                        ))}

                        <div className="col-12" style={{}}>
                            <div className="Corper-subtxt wenv-bg-color">
                                <p className="card-subheading wenv-bg-color" style={{ marginRight: "-23px", marginLeft: "-23px", }}>Work type(select one):</p>
                            </div>
                        </div>
                        {props.data.workType.list.map((workItem, index) => (
                            <>
                                {workItem.checked !== undefined &&
                                    <div class="col-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input mt-3" checked={workItem.checked} onChange={(e) => {
                                                handleRoofAccess('checked', !workItem.checked, index, 'workType', 'list')
                                            }} type="checkbox" id={`workItemList${index}`} value="option2" />
                                            <label class="form-check-label mt-2" for={`workItemList${index}`}>{workItem.cbTitle}</label>
                                        </div>
                                    </div>
                                }
                                {workItem.description != undefined &&
                                    <div class="form-group">
                                        <div>
                                            <input class="form-control" type="text" id="empt1" value={workItem.description} placeholder={''}
                                                onChange={(e) => handleRoofAccess('description', e.target.value, index, 'workType', 'list')}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                        ))}

                        <div className="col-12" style={{}}>
                            <div className="Corper-subtxt wenv-bg-color">
                                <p className="card-subheading wenv-bg-color" style={{ marginRight: "-23px", marginLeft: "-23px", }}>Minimum distance from the edge(select one):</p>
                            </div>
                        </div>

                        {props.data.distance.list.map((distanceItem, index) => (
                            <>
                                {distanceItem.checked !== undefined &&
                                    <div class="col-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input mt-3" checked={distanceItem.checked} onChange={(e) => {
                                                handleRoofAccess('checked', !distanceItem.checked, index, 'distance', 'list')
                                            }} type="checkbox" id={`distanceItemList${index}`} value="option2" />
                                            <label class="form-check-label mt-2" for={`distanceItemList${index}`}>{distanceItem.cbTitle}</label>
                                        </div>
                                    </div>
                                }
                                {distanceItem.description != undefined &&
                                    <div class="form-group">
                                        <div>
                                            <input class="form-control" type="text" id="empt1" value={distanceItem.description} placeholder={''}
                                                onChange={(e) => handleRoofAccess('description', e.target.value, index, 'distance', 'list')}
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoreRoofAccess;