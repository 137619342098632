
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../Pages/SideMenu';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Logout from '../Pages/Logout';
import * as XLSX from 'xlsx';
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//for modal
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import { reportForRMTracking } from '../Utilities/URLCONSTANTS';
import { GetApiHeaders } from '../Utilities/NetworkUtilities';
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "../../src/Utilities/utils";
import Loader_Bulk from '../Utilities/Loader_Bulk';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MultiSelect } from "react-multi-select-component";




//ProductMaster

const WorkPermitReport = ({ }) => {
    const [thCount, setThCount] = useState(0);
    const [loaderImage, setLoaderImage] = useState(require('../images/rmloader.gif'));
    const [isLoading, setIsLoading] = useState(false);
    const [operations, setOpeartions] = useState('');
    const [ActivityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [todayDate, settodayDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState();
    const [permitType, setpermitType] = useState([]);
    const [wmy, setwmy] = useState("");
    const [WeekSelected, setWeekSelected] = useState('no');
    const [monthSelected, setmonthSelected] = useState('no');
    const [yearSelected, setYearSelected] = useState('no');
    const [allSelected, setAllSelected] = useState('yes');
    const [errorPermitType, setErrorPermitType] = useState('')


    // In your handleFilter function, update filteredData based on searchTerm
    const handleFilter = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (data != null && data != undefined) {
            const filteredResults = data.filter((item) =>
                item.corePermitId.includes(searchTerm) ||
                item.permitName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.submittedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.submittedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.submittedByMobileNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.permitStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.approvedOrRejectedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.approvedOrRejectDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.closed.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.closedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.closedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||

                item.suspendedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.suspendedOn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.suspendedRemarks.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.suspendedByMobileNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||

                item.transferredTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.transferredBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.permitExtended.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.permitExtendedStartTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.permitExtendedTo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.jobScopeWorkDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.permitExtensionApprovedBy.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredData(filteredResults);
        }
    };
    useEffect(() => {
        if (data != null && data != undefined) {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();
            var maxDate = year + '-' + month + '-' + day;
            setFilteredData(data);
            setTotalItems(data.length);

        }
    }, [data]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to the first page when changing items per page.
    };

    // const totalItems = data.length;

    const pageNumbers = [];
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };


    const tableId = 'Content_data_table';
    useEffect(() => {
        const table = document.getElementById(tableId);
        if (table) {
            const theadRow = table.querySelector('thead tr');
            const thCount = theadRow ? theadRow.querySelectorAll('th').length : 0;
            setThCount(thCount);
        } else {
            console.warn(`Table with id '${tableId}' not found.`);
        }
    }, [tableId]);

    const handlePrint = () => {
        const printWindow = window.open();
        const printContent = document.querySelector('.print-table');
        // Check if the printContent is available
        if (printContent) {
            printWindow.document.write('<html><head>' +
                '<meta charset="utf-8" />' +
                '<link rel="icon" href="/favicon.ico" />' +
                '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
                '<meta name="theme-color" content="#000000" />' +
                '<meta name="description" content="Web site created using create-react-app" />' +
                '<title>Work Permit Report</title>' +
                '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
                '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
                '<script defer src="/static/js/bundle.js"></script>' +
                '<style>' +
                '@media print {' +
                '  table {' +
                '    border-collapse: collapse;' +
                '    width: 100%;' +
                '    margin-bottom: 10px;' +
                '  }' +
                '  th, td {' +
                '    border: 1px solid #ddd;' +
                '    text-align: left;' +
                '  }' +
                '}' +
                '</style>' +
                '</head><body>');

            printWindow.document.write(printContent.innerHTML);
            printWindow.document.write('</body></html>');

            // Delay the print function to ensure styles are applied
            printWindow.setTimeout(() => {
                printWindow.print();
                printWindow.close();
            });
        } else {
            // Handle the case where printContent is not found  
            alert("print is not available");
        }
    }

    const exportToExcel = () => {
        const data = [
            ['S.no',
                'Permit Id',
                'Permit Name',
                'Job Scope / Description',
                'Submitted Date',
                'Submitted By',
                'Submitted By Mobile Number',
                'Status',
                'Approved/Rejected By',
                'JobSite InspectorName',
                'JobSite Inspection Status',
                'Approved/Rejected Date',
                'Closed',
                'Closed By',
                'Closed Date',
                'Remarks',
                "Suspended By",
                "Suspended On",
                "Suspended Remarks",
                "SuspendedBy MobileNumber",
                "Transferred To",
                "Transferred By",
                "Permit Extended",
                "Permit Extended StartTime",
                "Permit Extended EndTime",
                "Permit Extension Approved By"

            ], // Custom headers
            // ...currentItems.map((item) => [
            ...filteredData.map((item, index) => [
                index + 1,
                item.corePermitId,
                item.permitName,
                item.jobScopeWorkDescription,
                item.submittedDate,
                item.submittedBy,
                item.submittedByMobileNumber,
                item.permitStatus,
                item.approvedOrRejectedBy,
                item.jobSiteInspectorName,
                item.jobSiteInspectionStatus,
                item.approvedOrRejectDate,
                item.closed,
                item.closedBy,
                item.closedDate,
                item.remarks,
                item.suspendedBy,
                item.suspendedOn,
                item.suspendedRemarks,
                item.suspendedByMobileNumber,
                item.transferredTo,
                item.transferredBy,
                item.permitExtended,
                item.permitExtendedStartTime,
                item.permitExtendedTo,
                item.permitExtensionApprovedBy
            ])
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'WorkPermitReport');
        // Save the file
        XLSX.writeFile(wb, 'WorkPermitReport.xlsx');
    };

    const handleWMYChange = (e) => {
        $('.date_show_hide').hide();
        let chk_val = (e.target.value);
        setwmy(chk_val);
        if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') {
            setpermitType([]);
        }
        if (chk_val == 'W') {
            setWeekSelected('yes');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'M') {
            setWeekSelected('no');
            setmonthSelected('yes');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'Y') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('yes');
            setAllSelected('no');
        }
        else if (chk_val == 'All') {
            setwmy("");
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('yes');
            $('.date_show_hide').show();
        }
    }

    //for data table 


    function extractTimeFromDate(dateTimeString) {
        const dateObject = new Date(dateTimeString);
        const timeOnly = dateObject.toLocaleTimeString('en-US', { hour12: true });
        return timeOnly;
    }
    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable
        $(tableRef.current).DataTable({
            ordering: false, // Disable sorting
        });
        // fetchData();
        fetchDataReports();
        // getmasters();      

    }, []);
    const fetchData = async () => {
        // debugger;
        const apiUrl = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.get(apiUrl, { headers }).then((response) => {
            // debugger;
            setIsLoading(false);
            setData(response.data.response.rmHistory);
            const respData = response.data.response.rmHistory;
            const activityType = [...new Set(respData.map(item => item.activityType))];
            setActivityData(activityType);
            setLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        });
        // }, 900);
    };

    useEffect(() => {
        fetchData();
    }, []);


    const fetchDataReports = async () => {
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        const reqObj = {
            "WMY": "W",
            "startDate": "",
            "endDate": "",
            "permitType": "",
            "location": "",
            "subLocation": "",
            "typeOfWork": "",
            "reportType": "workPermits",
            "generalList": "",
            "equipment": ""
        }

        try {
            const response = await axios.post(fetchApi, reqObj, { headers })

            console.log("update Activity", response.data.response.workPermits)

            setData(response.data.response.workPermits)
        } catch (err) {
            console.log("err123", err)
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (permitType.length === 0) {
            setErrorPermitType("Please Select Permit Type");
            return;
        } else {
            setErrorPermitType("");
        }

        let formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : "";
        let formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : "";

        const permitTypeString = permitType.map((item) => item.value).join(',');

        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "permitType": permitTypeString,
            "reportType": "workPermits",
        };

        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);

        try {
            setIsLoading(true);
            const response = await axios.post(fetchApi, reqObj, { headers });

            if (response.data?.response?.workPermits) {
                setData(response.data.response.workPermits);
                console.log("submitted data is", response.data.response.workPermits);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const Sendmail = async (event) => {
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "permitType": permitType,
            "reportType": "workPermits"
        }


        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        setIsLoading(true);
        axios.post(fetchApi, reqObj, { headers }).then((response) => {
            setIsLoading(false);
            if (response.data.response == "undefined" || response.data.response == "") {
                setData([]);
            }
            else {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: 'Your work has been saved.',
                    confirmButtonColor: '#3085d6'
                });
            }

        })
    }

    function resetData() {
        setwmy("w");
        setpermitType([]);
        setStartDate(new Date());
        setEndDate(new Date());
        settodayDate(new Date());
        fetchData();
        setTimeout(() => {
            setErrorPermitType('')
        }, 100)
    }

    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const sortedItems = [...currentItems].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            // Check if values are numeric
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
            }
            // Fallback for string comparison
            const aString = aValue?.toString().toLowerCase();
            const bString = bValue?.toString().toLowerCase();
            if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
            if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        }
        return 0;
    });

    // Handle column sorting
    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
        }));
    };

    const options = [
        { label: "Core Permit", value: "core_permit" },
        { label: "Critical Elevated Permit", value: "critical_elevated_permit" },
        { label: "Excavation Permit", value: "excavation_permit" },
        { label: "General Hot Work Permit", value: "general_hot_Work_permit" },
        { label: "Crane Permit", value: "crane_permit" },
    ];


    const handleChange = (selected) => {
        setpermitType(selected); // Update state with selected options
        if (selected.length === 0) {
            setErrorPermitType("Please select at least one permit type.");
        } else {
            setErrorPermitType(""); // Clear the error if valid selection is made
        }
    };


    return (
        <body id="page-top">
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <SideMenu />
                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">
                        {/*  <!-- Topbar --> */}
                        <Header />
                        {/* ...Content Start... */}
                        <div>
                            <div className='container-fluid' style={{ backgroundColor: "#F7F8FA" }}>
                                <div className='card border-0' style={{ backgroundColor: "transparent" }}>
                                    <div className='card-body pt-4 pl-1'>
                                        <h5 className='Roles_text'>Work Permit Report</h5>
                                        <div className='mt-4'>
                                            <div className='d-flex gap-1 status_card'>
                                                <div className='card flex-grow-1' style={{
                                                    border: '1px solid #0000001A',
                                                    boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                                }}>
                                                    <div className='card-body' style={{ padding: "20px 20px" }}>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-12'>
                                                                    <label
                                                                        className="form-label  font-weight-bold mt-1"
                                                                    >
                                                                        Select Date Duration :      </label>

                                                                    <div className="btn-group" id='filter-btn' role="group" aria-label="Basic radio toggle button group" onChange={handleWMYChange}>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={WeekSelected === 'yes'} value="W" />
                                                                        <label className="btn btn-outline-primary" for="btnradio1">Week</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={monthSelected === 'yes'} value="M" />
                                                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={yearSelected === 'yes'} value="Y" />
                                                                        <label className="btn btn-outline-primary" for="btnradio3">Year</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={allSelected === 'yes'} value="All" />
                                                                        <label className="btn btn-outline-primary" for="btnradio4">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 col-12'
                                                                >
                                                                    <label
                                                                        className="form-label  font-weight-bold mt-1"
                                                                    >Permit Type  </label>

                                                                    <MultiSelect
                                                                        options={options}
                                                                        value={permitType}
                                                                        onChange={handleChange}
                                                                        labelledBy="Select Permit Type"
                                                                    />
                                                                    <span style={{ color: "red" }}>{errorPermitType}</span>
                                                                </div>


                                                                <div className='col-sm-3 col-12 date_show_hide' >
                                                                    <div class="mb-3">
                                                                        <label for="startdate"
                                                                            className="form-label  font-weight-bold mt-1"
                                                                        >From Date</label>
                                                                        <DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select Start Date" selected={startDate}
                                                                            onChange={(date) => { setStartDate(date) }}
                                                                            maxDate={endDate}
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            id="activitystartdate"
                                                                            name="activitystartdate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='col-sm-3 col-12 date_show_hide'
                                                                >
                                                                    <div class="mb-3">
                                                                        <label
                                                                            className="form-label  font-weight-bold mt-1"
                                                                            for="enddate">To Date</label>
                                                                        <span><DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select End Date" selected={endDate}
                                                                            onChange={(date) => setEndDate(date)}
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            minDate={startDate} maxDate={todayDate} id="activityenddate"
                                                                            name="activityenddate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-2 d-flex w-full justify-content-center gap-2'>
                                                                <button class="btn btn-primary mr-0"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    type='submit'>Apply</button>
                                                                <button class="btn btn-danger"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={resetData}>Clear</button>
                                                                <button
                                                                    disabled
                                                                    className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={Sendmail}>Mail</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='card flex-grow-1 mt-2' style={{
                                            border: '1px solid #0000001A',
                                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                        }}>
                                            <div className='card-body' style={{ padding: "10px 10px" }}>

                                                <div className="d-flex justify-content-between align-items-center"
                                                    style={{ paddingLeft: 25, paddingBottom: 20 }}>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                                                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            {/* Add more options as needed */}
                                                        </select>&nbsp;&nbsp;
                                                        <label>{' '} Entries</label>
                                                    </div>

                                                    <div className="d-flex align-items-center" style={{ marginLeft: '5%' }}>

                                                        {/* Search Input */}
                                                        <input
                                                            type="search"
                                                            placeholder="🔍 Type to Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => handleFilter(e.target.value)}
                                                            style={{ height: "36px", borderRadius: '4px' }}
                                                            className="mr-1"
                                                        /> &nbsp;
                                                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                                                            <button className="btn btn-secondary buttons-excel buttons-html5"
                                                                onClick={exportToExcel}>
                                                                Download Excel
                                                            </button>
                                                            {/* <button
                                                                className="btn btn-primary"
                                                                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                                                                onClick={handlePrint}
                                                            >
                                                                Print
                                                            </button> */}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="print-table"
                                                    style={{ overflowX: 'auto' }}>

                                                    <Table className="table table-bordered Content_data_table table-striped " id="Content_data_table">
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>S.No</th>
                                                                <th className="text-center" onClick={() => handleSort("corePermitId")}>
                                                                    Permit Id{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "corePermitId"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("permitName")}>
                                                                    Permit Name{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitName"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("jobScopeWorkDescription")}>
                                                                    Job Scope / Description{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "jobScopeWorkDescription"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("submittedDate")}>
                                                                    Submitted Date{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "submittedDate"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("submittedBy")}>
                                                                    Submitted By{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "submittedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("submittedByMobileNumber")}>
                                                                    Submitted By Mobile Number{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "submittedByMobileNumber"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>



                                                                <th className="text-center" onClick={() => handleSort("permitStatus")}>
                                                                    Status{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitStatus"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("approvedOrRejectedBy")}>
                                                                    Approved/Rejected By {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "approvedOrRejectedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("jobSiteInspectorName")}>
                                                                    JobSite Inspector Name {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "jobSiteInspectorName"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("jobSiteInspectionStatus")}>
                                                                    JobSite Inspection Status {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "jobSiteInspectionStatus"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("approvedOrRejectDate")}>
                                                                    Approved/Rejected Date {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "approvedOrRejectDate"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("closed")}>
                                                                    Closed {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "closed"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("closedBy")}>
                                                                    Closed By{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "closedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>
                                                                <th className="text-center" onClick={() => handleSort("closedDate")}>
                                                                    Closed Date{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "closedDate"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("remarks")}>
                                                                    Remarks {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "remarks"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("suspendedBy")}>
                                                                    Suspended By {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "suspendedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("suspendedOn")}>
                                                                    Suspended On {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "suspendedOn"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("suspendedRemarks")}>
                                                                    Suspended Remarks {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "suspendedRemarks"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("suspendedByMobileNumber")}>
                                                                    Suspended By MobileNumber {" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "suspendedByMobileNumber"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("transferredTo")}>
                                                                    Transferred To{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "transferredTo"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("transferredBy")}>
                                                                    Transferred By{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "transferredBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                                <th className="text-center" onClick={() => handleSort("permitExtended")}>
                                                                    Permit Extended{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitExtended"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("permitExtendedStartTime")}>
                                                                    Permit Extended StartTime{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitExtendedStartTime"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("permitExtendedTo")}>
                                                                    Permit Extended EndTime{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitExtendedTo"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>


                                                                <th className="text-center" onClick={() => handleSort("permitExtensionApprovedBy")}>
                                                                    Permit Extension Approved By{" "}
                                                                    <i
                                                                        className={`fa ${sortConfig.key === "permitExtensionApprovedBy"
                                                                            ? sortConfig.direction === "asc"
                                                                                ? "fa-sort-up"
                                                                                : "fa-sort-down"
                                                                            : "fa-sort"
                                                                            } fa-xs icon-opacity`}
                                                                    ></i>
                                                                </th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {currentItems && currentItems.length > 0 ? (
                                                                sortedItems.map((item, index) => (
                                                                    <React.Fragment  >

                                                                        <tr>
                                                                            <td className='text-center' > {(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                            <td className='text-left'>{item.corePermitId}</td>
                                                                            <td className='text-left'>{item.permitName}</td>
                                                                            <td className='text-left'>{item.jobScopeWorkDescription}</td>
                                                                            <td className='text-left'>{item.submittedDate}</td>
                                                                            <td className='text-left'>{item.submittedBy}</td>
                                                                            <td className='text-left'>{item.submittedByMobileNumber}</td>
                                                                            <td className='text-left'>{item.permitStatus}</td>
                                                                            <td className='text-left'>{item.approvedOrRejectedBy}</td>
                                                                            <td className='text-left'>{item.jobSiteInspectorName}</td>
                                                                            <td className='text-left'>{item.jobSiteInspectionStatus}</td>
                                                                            <td className='text-left'>{item.approvedOrRejectDate}</td>
                                                                            <td className='text-left'>{item.closed}</td>
                                                                            <td className='text-left'>{item.closedBy}</td>
                                                                            <td className='text-left'>{item.closedDate}</td>
                                                                            <td className='text-left'>{item.remarks}</td>
                                                                            <td className='text-left'>{item.suspendedBy}</td>
                                                                            <td className='text-left'>{item.suspendedOn}</td>
                                                                            <td className='text-left'>{item.suspendedRemarks}</td>
                                                                            <td className='text-left'>{item.suspendedByMobileNumber}</td>

                                                                            <td className='text-left'>{item.transferredTo}</td>
                                                                            <td className='text-left'>{item.transferredBy}</td>

                                                                            <td className='text-left'>{item.permitExtended}</td>
                                                                            <td className='text-left'>{item.permitExtendedStartTime}</td>
                                                                            <td className='text-left'>{item.permitExtendedTo
                                                                            }</td>
                                                                            <td className='text-left'>{item.permitExtensionApprovedBy}</td>

                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={thCount} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>

                                                <div className='py-3'>
                                                    <div className='card data_tableCard'>
                                                        <div className='card-body'>

                                                            {/* Pagination */}

                                                            <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                                                <div>
                                                                    {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                                                                    &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                                                                    of {totalItems} entries
                                                                </div>
                                                                <div>
                                                                    <div className="div-button text-center" >
                                                                        <button
                                                                            className='borderleft-radius-5'
                                                                            // className='ul-pagination'
                                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            Previous
                                                                        </button>


                                                                        <button className='ul-pagination page-item'
                                                                            style={{
                                                                                backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                                                                color: (currentPage == 1) ? 'white' : 'black'
                                                                            }}
                                                                            key={1}
                                                                            onClick={() => handlePageClick(1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            {1}

                                                                        </button>
                                                                        {pageNumbers.map((number) => (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == number) ? 'white' : 'black'
                                                                                }}
                                                                                key={number}
                                                                                onClick={() => handlePageClick(number)}
                                                                                disabled={currentPage === number}
                                                                            >
                                                                                {number}
                                                                            </button>
                                                                        ))}
                                                                        {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: 'white',
                                                                                    color: 'black'
                                                                                }}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                        {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                                                                }}
                                                                                key={(Math.ceil(totalItems / itemsPerPage))}
                                                                                onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                                                                disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                                                            >
                                                                                {(Math.ceil(totalItems / itemsPerPage))}

                                                                            </button>
                                                                        }
                                                                        <button className='borderright-radius-5'
                                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                                            disabled={indexOfLastItem >= totalItems}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader_Bulk loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}

                    </div>
                    {/* ...End of Main Content...*/}
                    {/* ...Footer...*/}
                    <Footer />
                    {/* ... End of Footer...  */}
                </div>
            </div>
            {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            <Logout />
            {/* {!isUserLoggedIn && <Login />} */}
        </body>
    )
}

export default WorkPermitReport;
