
export const MOBILE_NUMBER = undefined;
export const USER_PROFILE = "USER_PROFILE";
export const COREPERMIT_MOBILE_NUMBER = "COREPERMIT_MOBILE_NUMBER";
export const COREPERMIT_VENDOR_NAME = "COREPERMIT_VENDOR_NAME";
export const COREPERMIT_VENDOR_LOGINID = "COREPERMIT_VENDOR_LOGINID";
export const COREPERMIT_DATE = "COREPERMIT_DATE";
export const FIRST_Time = "firstTime";
export const CorePermitM2_PERMITID = "CorePermitM2_PERMITID";
export const ADDITIONAL_PERMITID = "ADDITIONAL_PERMITID";
export const PERMIT_NAME = "PERMIT_NAME";
export const SUBSITE_NAME = "SUBSITE_NAME";
export const SUBSITE_ID = "SUBSITE_ID";
export const MAINSITE_NAME = "MAINSITE_NAME";
export const MAINSITE_ID = "MAINSITE_ID";
export const ACCEPTOR_TRANSFER_TO_USERID = "ACCEPTOR_TRANSFER_TO_USERID";
export const PERMIT_EXTENSION_STATUS = "PERMIT_EXTENSION_STATUS";
export const END_TIME = "END_TIME";
export const PEOPLE_DATE_FOR_CLOUSEOUT_DATE = "PEOPLE_DATE_FOR_CLOUSEOUT_DATE";
export const SUSPENDED_REMARKS = "SUSPENDED_REMARKS";
export const SUSPENDED_BY = "SUSPENDED_BY";
export const primaryRequesterName = "primaryRequesterName";
export const approveOrRejectedByName = "approveOrRejectedByName";

export async function storeData(key, value) {
  var isStored = false;
  try {
    if (value == "") {
      if (key == undefined) {
        await sessionStorage.setItem(key, value);
      }
    }
    else {
      await sessionStorage.setItem(key, JSON.stringify(value));
    }
    isStored = true;
    return isStored;
  } catch (error) {
    return isStored;
  }
}

export async function retrieveData(key) {
  const value = await sessionStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  }
  else
    return "";
}


export async function filterObjects(array, field, code) {
  console.warn("crop filtering here", array.length + "--" + field + "--" + code);
  const codesArray = code.split(',').map(item => item.trim());
  return array.filter(data => codesArray.includes(data[field].toString()));
}


export function extractDateAsDDMMYYYY(timestamp) {
  const dateObject = new Date(timestamp);
  // Get day, month, and year from the date object
  const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits with leading zero
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = dateObject.getFullYear();
  return `${day}-${month}-${year}`;
}


function isNotEmpty(value) {
  return value !== null && value !== undefined && value !== "";
}



//..............
export function checkForEmptyValues(data) {
  // debugger;
  for (const parentKey in data) {
    if (data.hasOwnProperty(parentKey)) {
      const parentObject = data[parentKey];
      let hasEmptyValue = false;
      for (const key in parentObject) {
        if (parentObject.hasOwnProperty(key) && !isNotEmpty(parentObject[key])) {
          hasEmptyValue = true;
          break;
        }
      }
      if (hasEmptyValue) {
        return parentKey;
      }
    }
  }
  return null;
}




export function checkForEmptyValue(data) {
  // debugger;
  for (const parentKey in data) {
    if (data.hasOwnProperty(parentKey)) {
      const parentObject = data[parentKey];
      let hasNonEmptyChild = false;
      for (const key in parentObject) {
        if (parentObject.hasOwnProperty(key) && !isNotEmpty(parentObject[key])) {
          hasNonEmptyChild = true;
          break;
        }
      }
      if (hasNonEmptyChild) {
        return parentKey;
      }
    }
  }
  return null;
}
