
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import downarrowwhite from '../images/Down-arrow-white.png';
import uparrowwhite from '../images/Up-arrow-white.png';
import ToggleSwitch from "../Pages/ToggleSwitch";

const Accordion = ({ title, content, bgColor, buttonData, bordercolor, isToggled, handleToggle, showToggle, openAccordion, setOpenAccordion, index }) => {

    const isOpen = openAccordion === index; // Check if this accordion is open

    // const toggleAccordion = (e) => {
    //     e.preventDefault();
    //     setOpenAccordion(isOpen ? null : index);
    // };

    const toggleAccordion = (e) => {
        e.preventDefault();
        setOpenAccordion(!isToggled ? null : isOpen ? null : index);
    };

    // const toggleAccordion = (e) => {
    //     e.preventDefault();
    //     if (!isToggled) return; // Prevent expansion if toggle is false
    //     setOpenAccordion(isOpen ? null : index);
    // };

    return (
        <div className="mb-2 mt-1">
            <button
                className={`d-flex justify-content-between align-items-center w-100 py-2 px-2 btn btn-light text-white ${isOpen ? "custom-rounded-top" : "custom-rounded"}`}
                style={{ backgroundColor: bordercolor, border: "none" }}
                onClick={toggleAccordion}
            >
                <i>
                    <span className={`transform transition-transform m-2 ${isOpen ? "rotate-180" : ""}`}>
                        {isOpen ? (
                            <img src={uparrowwhite} alt="Open" className="inline-block" style={{ width: "22px" }} />
                        ) : (
                            <img src={downarrowwhite} alt="Close" className="inline-block" style={{ width: "22px" }} />
                        )}
                    </span>
                    <span style={{ fontSize: '12px', fontStyle: "normal", fontWeight: "601" }}>
                        {title}
                    </span>
                </i>
                <i style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {showToggle && (
                            <ToggleSwitch
                                isToggled={isToggled}
                                handleToggleToggleClicked={handleToggle}
                            />
                        )}
                        {buttonData ? (
                            buttonData.map((data, index) => (
                                <span key={index} style={{ borderRadius: "32px", display: "inline-block", border: "1px solid white", padding: "0px", cursor: "pointer", width: "27px", height: "27px" }}>
                                    <img
                                        src={data.imageData.imageUrl}
                                        alt={`Image ${index + 1}`}
                                        style={{ width: "17px", height: "17px" }}
                                    />
                                </span>
                            ))
                        ) : (
                            <span></span>
                        )}
                    </div>
                </i>
            </button>
            {isOpen && (
                <div className="p-4" style={{ fontSize: '16px', fontWeight: 'normal', lineHeight: '24px', border: `1px solid ${bordercolor}` }}>
                    {content}
                </div>
            )}
        </div>
    );
};

export default Accordion;


