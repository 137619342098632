import React, { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import "react-time-picker/dist/TimePicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import axios from "axios";
import { GetAllAcceptorList } from "../../Utilities/URLCONSTANTS";
import { COREPERMIT_VENDOR_LOGINID, END_TIME, PEOPLE_DATE_FOR_CLOUSEOUT_DATE } from "../../Utils";
import Swal from "sweetalert2";
function CorePermitM2People(props) {
    // const PermitUserIdfromURL = searchParams.get("acceptorUserId");
    const [searchParams] = useSearchParams();
    console.log("approveOrRejectedByName:", props.approveOrRejectedByName, props.permitIssuerPhoneNumber,
        props.isTransferred, props.primaryRequesterName, props.receiverTransferTo, props.issuerTransferTo,
        props.transferToName, props.isApproved, props.permitStatus, props.ActionTypefromURL);
    const [selectedInspector, setSelectedInspector] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);
    const [status, setStatus] = useState(
        props.approvalStatus === "Approve" ? true : false
    );
    const [peopleData, setPeopleData] = useState(props.data);
    const days = ["mon", "tues", "wed", "thus", "fri", "sat", "sun"];
    var vendorLoginId = sessionStorage.getItem(COREPERMIT_VENDOR_LOGINID);
    const isVendor = !!vendorLoginId;
    const handleDateChange = (date) => {
        if (date) {
            const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            const formattedDate = `${day}-${month}-${year}`;
            setPeopleData((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
            setIsOpen(false);
            // Store in sessionStorage
            sessionStorage.setItem(PEOPLE_DATE_FOR_CLOUSEOUT_DATE, formattedDate);
        }
    };

    const CustomDatePickerInput = ({ value, onClick }) => (
        <div>
            <input
                className="form-control w-full"
                type="text"
                value={value}
                onClick={onClick}
                readOnly
                style={{ cursor: "pointer", padding: "5px 10px", background: "white" }}
            />
            <FaCalendar
                style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "13px",
                    top: "10px ",
                }}
                onClick={onClick}
            />
        </div>
    );

    const CustomTimePickerInput = ({ value, onClick }) => (
        <div>
            <input
                className="form-control w-full"
                type="text"
                value={value}
                onClick={onClick}
                readOnly
                style={{ cursor: "pointer", padding: "5px 10px", background: "white" }}
            />
            <FaClock
                style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "13px",
                    top: "10px ",
                }}
                onClick={onClick}
            />
        </div>
    );

    const [time, setTime] = useState(new Date());
    const [SelectTime, SetSelectTime] = useState("");

    const handleTimeChange = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };

    //End Time
    const [timeOne, setTimeOne] = useState("");
    const handleTimeChangeOne = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString("en-US", options);
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeOne(selectedTime);
        sessionStorage.setItem(END_TIME, formattedTime);
    };


    //JobSiteInspector
    const [timeTwo, setTimeTwo] = useState("");
    const handleTimeChangeJobsite = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeTwo(selectedTime);
    };
    const [acceptorsList, setAcceptorsList] = useState([]);
    const userId = searchParams.get("acceptorUserId"); // Get value from URL

    useEffect(() => {
        const fetchAcceptorsList = async () => {
            try {
                const response = await axios.get(GetAllAcceptorList, {                
                });
                if (response.data.statusCode === "200") {
                    setAcceptorsList(response.data.response.acceptorsList || []);
                } else {
                    console.error("Error fetching acceptors list:", response.data.message);
                }
            } catch (error) {
                console.error("API Error:", error);
            }
        };
        fetchAcceptorsList();
    }, []);



    const handleSelectionChange = (e) => {
        const { name, value } = e.target;
        const selectedOption = e.target.selectedOptions[0];
        const dataCode = selectedOption ? selectedOption.dataset.code : null;
        if (dataCode) {
            sessionStorage.setItem("ACCEPTOR_TRANSFER_TO_USERID", dataCode);
            console.log("Selected code stored in sessionStorage:", dataCode);
        }
        setPeopleData((prev) => {
            const updatedData = {
                ...prev,
                [name]: value,
                code: dataCode, 
            };
            console.log("Updated peopleData:", updatedData);
            return updatedData;
        });

        console.log(`Selected code for ${name}:`, dataCode);
        if (name === "inspectorName" && value) {
            setDropdownError(false);
        }
    };


    useEffect(() => {
        if (peopleData.onSiteInspection === "yes" && !peopleData.inspectorName) {
            setDropdownError(true);
        } else {
            setDropdownError(false);
        }
    }, [peopleData.onSiteInspection, peopleData.inspectorName]);
    const [timeEnd, setTimeEnd] = useState("");

    const handleTimeChangeJobsiteEnd = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );
        setPeopleData((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeEnd(selectedTime);
    };
    //extendedUse
    const [timeExtend, setTimeExtend] = useState("");
    const handleTimeChangeExtendedUse = (key) => (selectedTime) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );

        setPeopleData((prev) => ({
            ...prev,
            extendedUse: {
                ...prev.extendedUse,
                [key]: formattedTime,
            },
        }));
        setTimeExtend(selectedTime);
    };

    const [timeExtendEnd, setTimeExtendEnd] = useState("");
    const handleTimeChangeExtendedUseEnd = (key) => (selectedTime) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString(
            "en-US",
            options
        );

        setPeopleData((prev) => ({
            ...prev,
            extendedUse: {
                ...prev.extendedUse,
                [key]: formattedTime,
            },
        }));

        setTimeExtendEnd(selectedTime);
    };

    useEffect(() => {
        setPeopleData(props.data);
        console.log("peopleData::", props.data);
    }, []);

    useEffect(() => {
        props.onDataChange(peopleData);
        console.log("peopleData", JSON.stringify(peopleData));
    }, [peopleData]);

    const currentDate = new Date().toISOString().split("T")[0];
    const handlePeopleData = (key, val) => {
        setPeopleData((prev) => {
            const updatedData = { ...prev, [key]: val };
            if (key === "onSiteInspection" && val === "no") {
                updatedData.inspectorName = ""; 
            }
            return updatedData;
        });
    };


    const handlePeopleList = (id, val) => {
        const updatedPeopleList = peopleData.peopleList.map((opItem) => ({
            ...opItem,
            checked: opItem.id == id ? val : opItem.checked,
            selectedValue:
                opItem.checked === true ? "yes" : opItem.checked === false ? "no" : "",
        }));
        handlePeopleData("peopleList", updatedPeopleList);
    };

    const handlePeopleExtendedData = (peopleData, key, val) => {
        var extendedUse = peopleData.extendedUse;
        if (
            (key !== "yes" &&
                key !== "na" &&
                val === "true" &&
                extendedUse["yes"] === "") ||
            (key !== "yes" &&
                key !== "na" &&
                val === "true" &&
                extendedUse["na"] === true)
        ) {
            alert("plz check YES");
            return;
        } else {
            if (key == "yes") {
                extendedUse["na"] = "";
            }
            if (key == "na") {
                extendedUse["yes"] = "";
                extendedUse["mon"] = "";
                extendedUse["tues"] = "";
                extendedUse["wed"] = "";
                extendedUse["thus"] = "";
                extendedUse["fri"] = "";
                extendedUse["sat"] = "";
                extendedUse["sun"] = "";
            }
            if (extendedUse["na"] === "true" && key !== "yes") {
                return;
            }
            extendedUse[key] = val;
            var temppeopleData = extendedUse;
            setPeopleData((prev) => ({ ...prev, extendedUse }));
        }
    };

    function calculateMinEndTime(startTime) {
        if (startTime) {
            const selectedTime = new Date(startTime);
            selectedTime.setMinutes(selectedTime.getMinutes() + 1);
            return selectedTime;
        } else {
            return new Date();
        }
    }

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div>
                            <div class="form-group">
                                <label>Permit issuer
                                    {!isVendor && <span style={{ color: "red" }}> *</span>}
                                </label>
                                <div>
                                  
                                    <input
                                        className="form-control mb-1"
                                        placeholder="PRINT"
                                        value={props.approveOrRejectedByName}
                                        readOnly
                                    />

                                    <input
                                        class="form-control"
                                        placeholder="SIGN"
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.permitIssuerSign || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("permitIssuerSign", alphabetsOnly);
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="form-group" >
                                <label>Permit issuer Phone number
                                    {!isVendor && <span style={{ color: "red" }}> *</span>}
                                </label>
                                <div>
                                   
                                    <input
                                        className="form-control mb-1"
                                        placeholder="PRINT"
                                        value={props.permitIssuerPhoneNumber || ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    Plant/facility
                                    {isVendor && <span style={{ color: "red" }}> *</span>}
                                </label>
                                <div>
                                    <input
                                        class="form-control col-12"
                                        readOnly={props.approvalStatus}
                                        placeholder=""
                                        value={peopleData?.plantFacility || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphanumericOnly = inputValue.replace(
                                                /[^a-zA-Z0-9 .,]/g,
                                                ""
                                            );
                                            handlePeopleData("plantFacility", alphanumericOnly);
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Co-Signature</label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        readOnly={props.approvalStatus}
                                        placeholder="PRINT"
                                        value={peopleData?.coSignature || ""}
                                        onChange={(e) =>
                                            handlePeopleData("coSignature", e.target.value)
                                        }
                                    />
                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.coSignatureSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("coSignatureSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Issuer transferred to</label>
                                <div>
                                  
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={props.issuerTransferTo || ""}
                                        readOnly
                                    />

                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.issueTransferToSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("issueTransferToSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Permit receiver
                                    {isVendor && <span style={{ color: "red" }}> *</span>}
                                </label>
                                <div>
                                    <input
                                        class="form-control mb-1"
                                        placeholder="PRINT"
                                        readOnly={props.approvalStatus}
                                        value={peopleData?.permitReceiver || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphabetsOnly = inputValue.replace(
                                                /[^a-zA-Z .]/g,
                                                ""
                                            );
                                            handlePeopleData("permitReceiver", alphabetsOnly);
                                        }}
                                    />

                                    <input
                                        class="form-control"
                                        readOnly={props.approvalStatus}
                                        placeholder="SIGN"
                                        value={peopleData?.permitReceiverSign || ""}
                                        onChange={(e) =>
                                            handlePeopleData("permitReceiverSign", e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div class="form-group">
                                    <label>Work limited to the following</label>
                                    <div>
                                        <label>
                                            JOB SCOPE / DESCRIPTION / TASKS & AREA / EQUIPMENT &
                                            BOUNDARIES
                                            {isVendor && <span style={{ color: "red" }}> *</span>}                                        </label>
                                        <div>
                                            <input
                                                className="form-control mb-1"
                                                placeholder=""
                                                value={peopleData?.workLimit || ""}
                                                readOnly={props.approvalStatus}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const alphanumericOnly = inputValue.replace(
                                                        /[^a-zA-Z0-9 .,]/g,
                                                        ""
                                                    );
                                                    handlePeopleData("workLimit", alphanumericOnly);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div class="form-group">
                        <label>Permit receiver Phone number
                            {isVendor && <span style={{ color: "red" }}> *</span>}</label>
                        <div>

                            <input
                                className="form-control"
                                id="ph"
                                type="text"
                                maxLength={10}
                                readOnly={props.approvalStatus}
                                value={peopleData?.phoneNumber || ""}
                                onChange={(e) => {
                                    const inputText = e.target.value;
                                    let sanitizedInput = inputText.replace(/[^0-9]/g, ""); 

                                    // Ensure it starts with 6, 7, 8, or 9
                                    if (!/^[6789]/.test(sanitizedInput)) {
                                        sanitizedInput = "";
                                    }

                                    // Limit input to 10 digits
                                    if (sanitizedInput.length > 10) {
                                        sanitizedInput = sanitizedInput.slice(0, 10);
                                    }

                                    handlePeopleData("phoneNumber", sanitizedInput);
                                }}
                                onBlur={() => {
                                    const phoneNumber = peopleData?.phoneNumber || "";
                                    if (phoneNumber.length < 10) {
                                        Swal.fire({
                                            icon: "warning",
                                            title: "Invalid Phone Number",
                                            text: "Phone Number Must be 10 Digits.",
                                            confirmButtonColor: "#d33",
                                            allowOutsideClick: false, 
                                            willClose: () => {
                                                document.getElementById("ph").focus();
                                            },
                                        });
                                    }
                                }}
                            />

                        </div>
                    </div>
                   


                    <div className="form-group">
                        <label>
                            Number of people working under this permit
                            {isVendor && <span style={{ color: "red" }}> *</span>}
                        </label>
                        <div>
                            <input
                                className="form-control col-12"
                                value={peopleData?.noOfWorkingPermit || ""}
                                readOnly={props.approvalStatus}
                                onChange={(e) => {
                                    let numericInput = e.target.value.replace(/[^0-9]/g, ""); 
                                    if (numericInput.startsWith("0")) {
                                        numericInput = numericInput.replace(/^0+/, "");
                                    }
                                    handlePeopleData("noOfWorkingPermit", numericInput);
                                    if (numericInput === "") {
                                        handlePeopleData("namesWorkingPermit", "");
                                    }
                                }}
                            />
                        </div>
                    </div>      

                    <div class="form-group">
                        <label>Name(s) of people working under this permit
                            {isVendor && <span style={{ color: "red" }}> *</span>}
                        </label>
                        <div>

                            <input
                                className="form-control col-12"
                                id="workingPeople"
                                value={peopleData?.namesWorkingPermit || ""}
                                readOnly={props.approvalStatus}
                                onBlur={(e) => {
                                    const inputValue = e.target.value;
                                    const requiredCount = parseInt(peopleData?.noOfWorkingPermit) || 0;

                                    // Allow only alphabetic characters, spaces, commas, and dots
                                    const validInput = inputValue.replace(/[^a-zA-Z, .]/g, "");
                                    let names = validInput.split(",").filter(name => name.trim() !== "");

                                    // Keep showing alert until condition is met
                                    if (requiredCount > 0 && names.length < requiredCount) {
                                        Swal.fire({
                                            icon: "warning",
                                            title: "Insufficient Names",
                                            text: `You need to enter at least ${requiredCount} names.`,
                                            confirmButtonColor: "#d33",
                                            confirmButtonText: "OK",
                                            allowOutsideClick: false,
                                            didClose: () => {
                                                document.getElementById("workingPeople").focus(); // Keep focus on the input field
                                            },
                                        });
                                    }

                                    handlePeopleData("namesWorkingPermit", validInput);
                                }}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const requiredCount = parseInt(peopleData?.noOfWorkingPermit) || 0;

                                    // Allow only alphabetic characters, spaces, commas, and dots
                                    const validInput = inputValue.replace(/[^a-zA-Z, .]/g, "");
                                    let names = validInput.split(",").filter(name => name.trim() !== "");

                                    // Prevent entering more names than allowed
                                    if (names.length > requiredCount) {
                                        Swal.fire({
                                            icon: "warning",
                                            title: "Too Many Names",
                                            text: `You can enter up to ${requiredCount} names only.`,
                                            confirmButtonColor: "#d33",
                                            confirmButtonText: "OK",
                                            allowOutsideClick: false,
                                        });
                                    } else {
                                        handlePeopleData("namesWorkingPermit", validInput);
                                    }
                                }}
                            />
                        </div>
                    </div>


                    <div class="form-group">
                        <label>Location
                            {isVendor && <span style={{ color: "red" }}> *</span>}
                        </label>
                        <div>
                            <input
                                class="form-control mb-1"
                                placeholder="Location"
                                readOnly={props.approvalStatus}
                                value={peopleData?.location || ""}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Use a regular expression to match only alphabets (A-Z and a-z)
                                    const alphabetsOnly = inputValue.replace(
                                        /[^a-zA-Z .]/g,
                                        ""
                                    );
                                    handlePeopleData("location", alphabetsOnly);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Receiver transferred to</label>
                    <div>
                        <input
                            class="form-control mb-1"
                            placeholder="PRINT"
                            value={props.receiverTransferTo}
                            readOnly={props.approvalStatus}
                       
                        />
                        <input
                            class="form-control"
                            placeholder="SIGN"
                            readOnly={props.approvalStatus}
                            value={peopleData?.receiverTransferToSign || ""}
                            onChange={(e) =>
                                handlePeopleData("receiverTransferToSign", e.target.value)
                            }
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Company
                        {isVendor && <span style={{ color: "red" }}> *</span>}
                    </label>
                    <div>
                        <input
                            class="form-control"
                            placeholder="PRINT"
                            readOnly={props.approvalStatus}
                            value={peopleData?.company || ""}
                            onChange={(e) => handlePeopleData("company", e.target.value)}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label>Date
                        {isVendor && <span style={{ color: "red" }}> *</span>}
                    </label>
                    <div>
                        <DatePicker
                            readOnly={props.approvalStatus}
                            value={peopleData?.date || ""}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Select a Date"
                            showYearDropdown
                            showMonthDropdown
                            customInput={<CustomDatePickerInput />}
                            closeOnSelect
                            minDate={new Date(currentDate)}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Start time
                        {isVendor && <span style={{ color: "red" }}> *</span>}
                    </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            selected={time}
                            readOnly={props.approvalStatus}
                            value={peopleData?.startTime || ""}
                            onChange={handleTimeChange("startTime")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>End time
                        {isVendor && <span style={{ color: "red" }}> *</span>}
                    </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            value={peopleData?.endTime || ""}
                            onChange={handleTimeChangeOne("endTime")}
                            readOnly={props.approvalStatus}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(SelectTime)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label>Additional Attachments</label>
                    <div>
                        <input
                            class="form-control"
                            type="text"
                            placeholder=""
                            readOnly={props.approvalStatus}
                            value={peopleData?.additionalAttachment || ""}
                            onChange={(e) =>
                                handlePeopleData("additionalAttachment", e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                {props.data.peopleList.map((item) => (
                    <div class="form-group">
                        <label>
                            {item.id}. {item.question}
                        </label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                disabled={props.approvalStatus}
                                checked={item.checked === "yes"}
                                onChange={() => {
                                    handlePeopleList(
                                        item.id,
                                        item.checked !== "yes" ? "yes" : ""
                                    );
                                }}
                                class="form-check-input"
                                id={`peopleListItemYes${item.id}`}
                            />
                            <label
                                class="form-check-label"
                                for={`peopleListItemYes${item.id}`}
                            >
                                YES
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                disabled={props.approvalStatus}
                                checked={item.checked === "no"}
                                onChange={() => {
                                    handlePeopleList(item.id, item.checked !== "no" ? "no" : "");
                                }}
                                class="form-check-input"
                                id={`peopleListItemNo${item.id}`}
                            />
                            <label
                                class="form-check-label"
                                for={`peopleListItemNo${item.id}`}
                            >
                                NO
                            </label>
                        </div>
                    </div>
                ))}
            </div>
            <div className="form-group">
                <label><strong>On-site inspection required by issuer</strong></label>
                <div className="d-flex flex-row m-0 p-0">
                    <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            disabled={props.isApproved != true || props.approvalStatus || !!vendorLoginId}
                            checked={peopleData.onSiteInspection === "yes"}
                            onChange={() => {
                                handlePeopleData("onSiteInspection", peopleData.onSiteInspection === "yes" ? "" : "yes");
                            }}
                            id="CheckOnsiteYes"
                        />
                        <label className="form-check-label mt-0" htmlFor="CheckOnsiteYes">YES</label>
                    </div>
                    <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            disabled={props.isApproved != true || props.approvalStatus || !!vendorLoginId}
                            checked={peopleData.onSiteInspection === "no"}
                            onChange={() => {
                                handlePeopleData("onSiteInspection", peopleData.onSiteInspection === "no" ? "" : "no");
                                setSelectedInspector(""); 
                            }}
                            id="CheckOnsiteNo"
                        />
                        <label className="form-check-label mt-0" htmlFor="CheckOnsiteNo">NO</label>
                    </div>
                </div>
            </div>



            <div className="row">
                <div class="form-group row">
                    <label class="col-9 col-form-label">
                        <strong> Job site inspection required see standard exception
                        </strong>
                    </label>
                </div>
                {peopleData.onSiteInspection === "yes" && (
                    <div className="form-group">
                        <label>Job Site Inspector</label>
                        <div>
                            <select
                                className={`form-control mb-1 ${dropdownError ? "is-invalid" : ""}`}
                                name="inspectorName"
                                value={peopleData.inspectorName || ""}
                                onChange={handleSelectionChange}
                                disabled={props.approvalStatus}
                            >
                                <option value="">Select Inspector</option>
                                {acceptorsList.map((acceptor) => (
                                    <option key={acceptor.code} value={acceptor.name} data-code={acceptor.code}>
                                        {acceptor.name}
                                    </option>
                                ))}
                            </select>
                            {dropdownError && (
                                <div className="invalid-feedback">
                                    Inspector is required
                                </div>
                            )}
                        </div>
                    </div>
                )}


                <div class="form-group">
                    <label for="inputTime">Start time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            readOnly={props.approvalStatus}
                            value={peopleData?.startTimeTwo || ""}
                            onChange={handleTimeChangeJobsite("startTimeTwo")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label for="inputTime">End time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            readOnly={props.approvalStatus}
                            value={peopleData?.endTimeTwo || ""}
                            onChange={handleTimeChangeJobsiteEnd("endTimeTwo")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(timeTwo)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div class="form-group row">
                    <label class="col-9 col-form-label">
                        Extended use/additional inspection needed
                    </label>
                    <div class="col-3 mt-1">
                        <div class="form-check form-check-inline d-flex align-items-center">
                            <input
                                class="form-check-input mt-0"
                                type="checkbox"
                                checked={
                                    peopleData.extendedUse.yes === true ||
                                        peopleData.extendedUse.yes === "true"
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    if (peopleData.extendedUse.yes !== "true") {
                                        handlePeopleExtendedData(peopleData, "yes", "true");
                                    }
                                }}
                                id="extendYes"
                                disabled={props.approvalStatus}
                            />
                            <label class="form-check-label mt-0" for="inlineCheckbox1">
                                YES
                            </label>
                        </div>

                        <div class="form-check form-check-inline d-flex align-items-center">
                            <input
                                class="form-check-input mt-0"
                                type="checkbox"
                                //
                                checked={
                                    peopleData.extendedUse.na === true ||
                                        peopleData.extendedUse.na === "true"
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    if (peopleData.extendedUse.na !== "true") {
                                        handlePeopleExtendedData(peopleData, "na", "true");
                                    }
                                }}
                                id="extendNO"
                                disabled={props.approvalStatus}
                            />
                            <label class="form-check-label mt-0" for="inlineCheckbox2">
                                NA
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-6">
                        {days.slice(0, 4).map((day) => (
                            <div
                                key={day}
                                className="form-check form-check-inline d-flex align-items-center"
                            >
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={props.approvalStatus}
                                    checked={
                                        peopleData.extendedUse[day] === true ||
                                            peopleData.extendedUse[day] === "true"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        handlePeopleExtendedData(
                                            peopleData,
                                            day,
                                            peopleData.extendedUse[day] === "true" ? "" : "true"
                                        );
                                    }}
                                    id={`${day}Checkbox`}
                                />
                                <label
                                    className="form-check-label mt-0"
                                    htmlFor={`${day}Checkbox`}
                                >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}_
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="col-6">
                        {days.slice(4).map((day) => (
                            <div
                                key={day}
                                className="form-check form-check-inline d-flex align-items-center"
                            >
                                <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={props.approvalStatus}
                                    checked={
                                        peopleData.extendedUse[day] === true ||
                                            peopleData.extendedUse[day] === "true"
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        handlePeopleExtendedData(
                                            peopleData,
                                            day,
                                            peopleData.extendedUse[day] === "true" ? "" : "true"
                                        );
                                    }}
                                    id={`${day}Checkbox`}
                                />
                                <label
                                    className="form-check-label mt-0"
                                    htmlFor={`${day}Checkbox`}
                                >
                                    {day.charAt(0).toUpperCase() + day.slice(1)}_
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div class="form-group">
                    <label>Start time</label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            // showIcon
                            value={peopleData.extendedUse.startTimeOne || ""}
                            readOnly={props.approvalStatus}
                            onChange={handleTimeChangeExtendedUse("startTimeOne")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>End time </label>
                    <div>
                        <DatePicker
                            className="form-control time-input"
                            value={peopleData.extendedUse.endTimeOne || ""}
                            readOnly={props.approvalStatus}
                            onChange={handleTimeChangeExtendedUseEnd("endTimeOne")}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="dd-MMM-yyyy"
                            minTime={calculateMinEndTime(timeExtend)}
                            maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                            customInput={<CustomTimePickerInput />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CorePermitM2People;
