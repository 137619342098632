
import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './SideMenu';
import Header from './Header';
import Footer from './Footer';
import '../App.css';
//for modal
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import Logout from './Logout';
// Date
import 'react-datepicker/dist/react-datepicker.css';
import ic_plus_icon from '../images/ic_plus_icon.png';

function RequestMasterTest() {
  
  const [selectedOption, setSelectedOption] = useState('option1');
  const [dob, setDob] = useState('');
  const [errorDob, setErrorDob] = useState('');
  const [mainList, setMainList] = useState([{
    productCategoryName: '', // This will hold the selected dropdown value
    subItems: [{ productRequiredName: '', productRequiredQuantity: '', unitPrice: '' }],
  }]);
 
 

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleDateChange = (event) => {
    setDob(event.target.value);
    if (dob.length > 0) {
      setErrorDob('')
    }
  };


  const handleAddMainItem = () => {
    const newMainItem = {
        productCategoryName: '', // This will hold the selected dropdown value
        subItems: [{ productRequiredName: '', productRequiredQuantity: '', unitPrice: '' }],
      };
    setMainList([...mainList, newMainItem]);
  };

  const handleDropdownChange = (index, value) => {
    const updatedMainList = [...mainList];
    updatedMainList[index].productCategoryName = value;
    setMainList(updatedMainList);
  };

  const handleSubItemChange = (mainIndex, subIndex, field, value) => {
    const updatedMainList = [...mainList];
    updatedMainList[mainIndex].subItems[subIndex][field] = value;
    setMainList(updatedMainList);
  };

  const handleAddSubItem = (mainIndex) => {
    const updatedMainList = [...mainList];
    updatedMainList[mainIndex].subItems.push({ productRequiredQuantity: '', productRequiredQuantity: '', unitPrice: '' });
    setMainList(updatedMainList);
  };
  
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div className='d-flex justify-content-center mb-3 font-weight-600'>
              <span className='modal_title' style={{ color: '#1580F3' }}>Request</span>
            </div>
            <div className="horizontal-line"></div>
            <div  className='col-md-6 col-12'>
              <div  className='col-md-6 '> <span>Request For</span></div>
              <div  className='col-md-6 '> 
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === 'option1'}
                  onChange={handleOptionChange}
                />
                Self
              </label>&nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === 'option2'}
                  onChange={handleOptionChange}
                />
                Others
              </label>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <label for="exampleInputPassword1" class="form-label text-sm font-weight-600"
              >Product Required Date</label>
              <div className="input-group input-group w-100">
                <input type="date" className="form-control p-2" value={dob} onChange={handleDateChange} />
              </div>
              <span style={{ color: 'red' }}>{errorDob}</span>
            </div>
            <div>
      {/* <button onClick={handleAddMainItem}>+ Add Dropdown and Sub-list</button> */}
      
      <br />
      {mainList.map((mainItem, mainIndex) => (
        <div key={mainIndex}>
            <div className='col-md-6 col-12'>
            <label for="exampleInputPassword1" className="form-label text-sm font-weight-600"
              >Product Category</label>
              <div className="input-group input-group w-600">
            <select id="dropdown" type="dropdown" className="form-control p-2" 
               value={mainItem.productCategoryName}
               onChange={(e) => handleDropdownChange(mainIndex, e.target.value)}>
                  <option value="-1"> Select Product Category</option>
                  <option value="1">Product Category 1</option>
                  <option value="2">Product Category 2</option>
                  <option value="3">Product Category 3</option>
                </select> &nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  src={ic_plus_icon}
                  alt="plus"
                  width="30px"
                  height="30px"
                  style={{ marginRight: '5px', marginBottom: '-10px' }} onClick={handleAddMainItem}
                />
               </div>
               </div>
        
          <ul>
            {mainItem.subItems.map((subItem, subIndex) => (
              <li key={subIndex}>
                  <div className='row' style={{marginLeft:'0.2%'}}>
                  <div className='col-md-3 col-12'>
                 <label for="exampleInputPassword1" className="form-label text-sm font-weight-600"
               >Product Required</label>
               <div className="input-group input-group w-100">
                 <select id="dropdown" type="dropdown" className="form-control p-2"  
                 value={subItem.productRequiredQuantity} 
                 onChange={(e) => handleSubItemChange(mainIndex, subIndex, 'productRequiredQuantity', e.target.value)} >
                   <option value="-1"> Select Product Required</option>
                   <option value="1">Product Required 1</option>
                   <option value="2">Product Required 2</option>
                   <option value="3">Product Required 3</option>
                 </select>
                 </div>
                 </div>
              
                <div className='col-md-3 col-12'>
               <label for="exampleInputPassword1" className="form-label text-sm font-weight-600"
               >Product Required Quantity</label>
               <div className="input-group input-group w-100">
                 <input type="number" className="form-control p-2" placeholder='productRequiredQuantity' 
                  value={subItem.productRequiredQuantity}   onChange={(e) => handleSubItemChange(mainIndex, subIndex, 'productRequiredQuantity', e.target.value)}
                    />
               </div>
             </div>                            
                 <div className='col-md-3 col-12'>
             <label for="exampleInputPassword1" className="form-label text-sm font-weight-600"
             >Unit Price</label>
             <div className="input-group input-group w-100">
               <input type="number" className="form-control p-2" placeholder='unitPrice'
               value={subItem.unitPrice}   onChange={(e) => handleSubItemChange(mainIndex, subIndex, 'unitPrice', e.target.value)}
                 /> 
                 
                  &nbsp;&nbsp;&nbsp;&nbsp;<img
                 src={ic_plus_icon}
                 alt="plus"
                 width="30px"
                 height="30px"
                 style={{ marginRight: '5px', marginBottom: '-10px' }} onClick={() => handleAddSubItem(mainIndex)}
               />
                 </div>
                 </div>
               
                </div>
              </li>
            ))}
          </ul>
          <hr />
        </div>
      ))}
    </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}

         </div> 

      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />

    </body>
  )

}

export default RequestMasterTest
