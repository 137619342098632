
import React, { useState, useEffect } from 'react'
import { getNewListWithModifyChecked } from '../../Utilities/utils';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils';
import { CustomTimePickerInput } from '../../Utilities/utils';

const currentDate = new Date().toISOString().split('T')[0];

const CorePermitChange = ({ changeData,
    setChangeData,
    suspendedBy,
    primaryRequesterName,
    isTransferred, transferToName, suspendedRemarks, suspendTime, suspendDate, receiverTransferTo, issuerTransferTo,
}) => {
    const storedSuspendedRemarks = sessionStorage.getItem("suspendedRemarks") || "";
    const approveOrRejectedByName = sessionStorage.getItem("approveOrRejectedByName") || "";

    console.log("Retrieved Suspended Remarks:", storedSuspendedRemarks);
    const [vendorName, setVendorName] = useState("");
    const [issuerName, setIssuerName] = useState("")
    const [issuerAcceptorName, setIssuerAcceptorName] = useState("")
    const [remarks, setRemarks] = useState("")


    const handleChangeData = (key, val) => {
        setChangeData((prev) => ({
            ...prev, [key]: val,
        }));
    };

    useEffect(() => {
        setVendorName(sessionStorage.getItem("COREPERMIT_VENDOR_NAME") || "");
        setIssuerName((sessionStorage.getItem("suspendedBy") || "").replace(/[\[\]"]+/g, ''));
        setRemarks((sessionStorage.getItem("suspendedRemarks") || "").replace(/[\[\]"]+/g, ''));
        setIssuerAcceptorName((sessionStorage.getItem("approveOrRejectedByName") || "").replace(/[\[\]"]+/g, ''));
    }, []);

    const handleDateChange = (date) => {
        if (date) {
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            const formattedDate = `${day}-${month}-${year}`;
            setChangeData((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
        }
    };

    const [time, setTime] = useState(new Date());
    const [SelectTime, SetSelectTime] = useState('');
    const handleTimeChange = (key) => (selectedTime) => {
        SetSelectTime(selectedTime);
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString('en-US', options);
        setChangeData((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };



    return (
        <div>
            <div class="form-group">
                <div class="col-12">
                    {changeData.changeList.map((data, index) => (<div class="form-check form-check-inline d-flex align-content-center">
                        <input onChange={() =>
                            setChangeData({
                                ...changeData,
                                environmentList: getNewListWithModifyChecked(index, changeData.changeList),
                            })
                        }
                            checked={data.checked} class="form-check-input mt-0 change-color" type="checkbox" id="inlineCheckbox1" value="option1" />
                        <label class="form-check-label mt-0 change-color" >{data.cbTitle}</label>
                    </div>))}
                </div>
            </div>
            <div class="row">
                <h3 style={{ color: "red", fontSize: "14px" }}>STOP WORK -notify permit issuer for review!</h3>
            </div>
            <div>
                <div className="Corper-subtxt change-bg-color">
                    <h6 className="card-subheading change-bg-color">If suspended, reauthorize permit to continue work!</h6>
                </div>
            </div>

            <div class="form-group">
                <label>Issuer</label>
                <div>

                    <input
                        className="form-control mb-1"
                        type="text"
                        placeholder=""
                        value={suspendedBy || issuerTransferTo}
                        readOnly
                    />
                </div>
            </div>
            <div class="form-group">
                <label >Receiver</label>
                <div>
                    <input
                        className="form-control mb-1"
                        type="text"
                        placeholder=""
                        value={primaryRequesterName}
                        readOnly
                    />
                </div>
            </div>

            <div class="form-group">
                <label >Date</label>
                <DatePicker
                    className="form-control col-12"
                    value={suspendDate}
                    dateFormat="dd-MMM-yyyy"
                />

            </div>
            <div class="form-group ">
                <label >Time</label>
                <DatePicker className='form-control time-input'
                    value={suspendTime}
                />

            </div>
            <div className="form-group">
                <label>Remarks</label>
                <div>
                    <textarea
                        className="form-control mb-1"
                        placeholder=""
                        value={suspendedRemarks}
                        rows={2}
                        readOnly
                    />
                </div>
            </div>
        </div>
    )
}

export default CorePermitChange;