import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../Pages/SideMenu";
import Header from "../../Pages/Header";
import Footer from "../../Pages/Footer";
import Logout from "../../Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllRecievedRequests } from "../../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../Utilities/Loader";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
import { submitRecievedRequests } from "../../Utilities/URLCONSTANTS";
import moment from "moment";
import { USER_PROFILE } from "../../Utils";

const RequestOrderHistory = (category, index) => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [loaderImage, setLoaderImage] = useState(
    require("../../images/cs_loader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [productIssuedDate, setProductIssuedDate] = useState();
  const [orderData, setOrderData] = useState([]);
  const [remark, setRemark] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [currentIssueQuantity, setCurrentIssueQuantity] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [submittedDate, setSubmittedDate] = useState();
  const [serverRecordID, setServerRecordID] = useState();
  const [requesterName, setRequesterName] = useState();
  const [requestId, setRequestId] = useState();
  const [totalOrderValue, setTotalOrderValue] = useState();
  const [productRequiredLocation, setProductRequiredLocation] = useState();
  const [acceptorID, setAcceptorID] = useState();
  const [acceptorName, setAcceptorName] = useState();
  // const [remarks, setRemarks] = useState();
  const [productRequiredDate, setProductRequiredDate] = useState();
  const [getLogin, setGetLogin] = useState([]);
  const [balRequiredQuantity, setBalRequiredQuantity] = useState("");
  const [employeeName, setEmployeeName] = useState();
  const [requestSelfOthers, setRequestSelfOthers] = useState();
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [employeeMobileNumber, setEmployeeMobileNumber] = useState();
  const [requesterId, setRequesterId] = useState();
  const [employeeUserID, setEmployeeUserID] = useState();
  const [sendTogatepass, setSendTogatepass] = useState();
  const [showMsg, setShowMsg] = useState("false");
  const [errRemark, setErrRemark] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errIssueDate, setErrIssueDate] = useState("");
  const [errSendToPass, setErrSendToPass] = useState("");
  const [selectedIndex, setSelectedIndex] = useState();
  const [requestOrder, setRequestOrder] = useState([
    {
      employeeName: "",
      productRequiredDate: "",
      requesterId: -1,
      totalOrderValue: 0,
      requestSelfOthers: "",
      submittedDate: "",
      serverRecordID: -1,
      productCategories: [
        {
          categoryName: "",
          categoryId: 1,
          products: [
            {
              productRequiredQuantity: "",
              productId: -1,
              productUnitPrice: "",
              serverRecordIdChild: -1,
              productName: "",
              productBatchNumber: "",
              issuedQuantity: "",
            },
          ],
        },
      ],
    },
  ]);

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const fetchData = async () => {
    const apiUrl = getAllRecievedRequests;

    try {
      const headers = await GetApiHeaders();
      setIsLoading(true);
      const response = await axios.post(apiUrl, null, {
        headers,
      });
      setIsLoading(false);
      const receivedRequests = response.data.response.receivedRequests;
      console.log("CATEGORIES_!", JSON.stringify(productCategories));
      setOrderData(receivedRequests);
      calculatePurpose(receivedRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Download Excel Reports 


  const calculatePurpose = (requests) => {
    requests.forEach((request, index) => {
      // gettotalOrderValue(request, index);
      // console.log("SAINATH_REDDY", request);
    });
  };
  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false,
      paging: true,
      lengthChange: false,
    });
    fetchData();
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  useEffect(() => {
    console.log("orderData", orderData);
  }, [orderData]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = (item, index) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedItemIndex(index);
  };
  const handleClose = () => {
    setShowModal(false);
    setShowPopup(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };


  const handleAccept = async (item, index) => {
    const urlApi = submitRecievedRequests;
    // Check if any product has a zero or empty `currentIssueQuantity`
    const invalidProducts = orderData[index].productCategories.some((category) =>
      category.products.some(
        (product) =>
          product.currentIssueQuantity === 0 || product.currentIssueQuantity === ""
      )
    );
    if (invalidProducts) {
      alert("Please Enter a Valid Issue Quantity.");
      return;
    }

    const postData = {
      productCategories: orderData[index].productCategories,
      productRequiredDate: orderData[index].productRequiredDate,
      submittedDate: orderData[index].submittedDate,
      serverRecordID: orderData[index].serverRecordID,
      requesterName: orderData[index].requesterName,
      requestId: orderData[index].requestId,
      employeeName: orderData[index].employeeName,
      employeeMobileNumber: orderData[index].employeeMobileNumber,
      employeeUserID: orderData[index].employeeUserID,
      requesterId: orderData[index].requesterId,
      requestSelfOthers: orderData[index].requestSelfOthers,
      totalOrderValue: orderData[index].totalOrderValue,
      productRequiredLocation: orderData[index].productRequiredLocation,
      acceptorName: getLogin.firstName + " " + getLogin.lastName,
      acceptorID: getLogin.userId,
      productIssuedDate: productIssuedDate + " " + "00:00:00.00",
      sendToGatePass: sendTogatepass,
      accepterRemarks: "",
      requestStatus: "accept",
    };

    const headers = await GetApiHeaders();
    if (sendTogatepass === "") {
      setErrSendToPass("Please Select Send To Gate Pass");
    } else {
      setErrSendToPass("");
    }
    if (productIssuedDate === "") {
      setErrIssueDate("Please Select Date");
    } else {
      setErrIssueDate("");
    }

    try {
      if (sendTogatepass && productIssuedDate) {
        setIsLoading(true);
        const response = await fetch(urlApi, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postData),
        });

        const responseData = await response.json();
        console.log("response:", responseData);

        if (responseData.statusCode === 200) {
          setShowResponsePopup(true);
          setsubmitResponse(responseData.message);
        } else {
          setShowResponsePopup(true);
          setsubmitResponse(responseData.message);
        }

        setErrIssueDate("");
        setErrSendToPass("");
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setErrIssueDate("Please Select Date");
        setErrSendToPass("Please Select send to pass");
        setIsSubmitted(false);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleProductChange = (
    index,
    pcIndex,
    productIndex,
    field,
    value,
    indexTotalValue
  ) => {
    if (Number.parseInt(value) < 0) {
      return;
    }
    const updatedProductData = [...orderData];
    var productRequiredQuantity =
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].productRequiredQuantity;

    var alreadyIssuedQuantity =
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].issuedQuantity;

    if (
      Number.parseInt(value) <=
      Number.parseInt(productRequiredQuantity) -
      Number.parseInt(alreadyIssuedQuantity)
    ) {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].currentIssueQuantity = Number.parseInt(value);
    } else if (value.length === 0) {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].currentIssueQuantity = "";
    } else {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].currentIssueQuantity = "";
    }

    setOrderData(updatedProductData);

    gettotalOrderValue(updatedProductData, index);
    setSelectedIndex(indexTotalValue);
  };

  const handleSubmit = async (selectedItem, selectedItemIndex) => {
    var catLst = productCategories;
    for (let pcIndex = 0; pcIndex < catLst.length; pcIndex++) {
      const categoryObj = productCategories[pcIndex];

      for (
        let productIndex = 0;
        productIndex < categoryObj.products.length;
        productIndex++
      ) {
        const productObj = categoryObj.products[productIndex];
        productObj.currentIssueQuantity = 0;
      }
    }
    setProductCategories(catLst);
    console.log("updatedProducts:", catLst);

    const rejectApi = submitRecievedRequests;

    const headers = await GetApiHeaders();
    const postDt = {
      productCategories: catLst,
      productRequiredDate: orderData[selectedItemIndex].productRequiredDate,
      employeeName: orderData[selectedItemIndex].employeeName,
      employeeMobileNumber: orderData[selectedItemIndex].employeeMobileNumber,
      employeeUserID: orderData[selectedItemIndex].employeeUserID,
      requesterId: orderData[selectedItemIndex].requesterId,
      requestSelfOthers: orderData[selectedItemIndex].requestSelfOthers,
      submittedDate: orderData[selectedItemIndex].submittedDate,
      serverRecordID: orderData[selectedItemIndex].serverRecordID,
      requesterName: orderData[selectedItemIndex].requesterName,
      requestId: orderData[selectedItemIndex].requestId,
      totalOrderValue: orderData[selectedItemIndex].totalOrderValue,
      productRequiredLocation: orderData[selectedItemIndex].productRequiredLocation,
      acceptorName: getLogin.firstName + " " + getLogin.lastName,
      acceptorID: getLogin.userId,
      sendToGatePass: sendTogatepass,
      accepterRemarks: remark,
      requestStatus: "reject",
    };
    console.log('postData', postDt);
    try {
      if (remark) {
        setIsLoading(true);
        const response = await fetch(rejectApi, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postDt),
        });

        const responseDt = await response.json();
        console.log("reject:", responseDt);
        if (responseDt.statusCode == 200) {
          setShowModal(false);
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
        }
        if (responseDt.statusCode == 500) {
          setShowModal(false);
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
        }
        if (responseDt.statusCode == 201) {
          setShowModal(false);
          // alert("aproved")
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
        }

        setErrRemark("");
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setErrRemark("Please Enter Remarks");
        setIsSubmitted(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadioChange = (value) => {
    setSendTogatepass(value);
    if (value) {
      setErrSendToPass("");
    }
  };
  const handleRemark = (e) => {
    setRemark(e.target.value);
    if (e.target.value) {
      setErrRemark("");
    }
  };
  const handleIssuedDate = (e) => {
    setProductIssuedDate(e.target.value);
    if (e.target.value) {
      setErrIssueDate("");
    }
  };
  const gettotalOrderValue = (updatedProductData, index) => {
    console.log(updatedProductData, "AJAY");
    const getTotalData = updatedProductData[index];
    let totalValue = 0;
    getTotalData.productCategories.forEach((category, indexTotalValue) => {
      category.products.forEach((product) => {
        const quantity = parseFloat(product.currentIssueQuantity);
        const issuedQuantity = parseFloat(product.issuedQuantity);
        const unitPrice = parseFloat(product.productUnitPrice);
        if (!isNaN(quantity) && !isNaN(unitPrice)) {
          const issuedValue = !isNaN(issuedQuantity)
            ? issuedQuantity * unitPrice
            : 0;
          const productValue = quantity * unitPrice + issuedValue;
          totalValue += productValue;
        }
      });
    });
    console.log("totalValue", totalValue);
    getTotalData.totalOrderValue = totalValue;
    // setTotalOrderValue(totalValue);
    return totalValue;
  };



  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Requests</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2 ">Requests</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {orderData.map((item, index) => {
                                var newDateFormat = moment(
                                  item.submittedDate
                                ).format("DD-MMM-YYYY hh:mm:ss A");
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mr-1"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            <div
                                              key={index}
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="forheaders d-flex"
                                            >

                                              <span
                                                className=" "
                                                style={{ width: "22%" }}
                                              >
                                                <b className="">
                                                  Request ID &nbsp; :
                                                </b>
                                                <span>
                                                  &nbsp;&nbsp;
                                                  {item.requestId}
                                                </span>
                                              </span>

                                              <span
                                                className=" "
                                                style={{ width: "34%" }}
                                              >
                                                <b className="">
                                                  Requester Name &nbsp; :
                                                </b>
                                                <span>
                                                  &nbsp;&nbsp;

                                                  {item.requesterName}
                                                </span>
                                              </span>



                                              {item.requestSelfOthers.toLowerCase() !== "self" && (
                                                <span className="" style={{ width: "29%" }}>
                                                  <b className=""> On Behalf Of &nbsp;: </b>
                                                  {item.requestSelfOthers &&
                                                    item.requesterName && (
                                                      <>
                                                        <span>
                                                          &nbsp;&nbsp; {item.employeeName}
                                                        </span>
                                                      </>
                                                    )}
                                                </span>
                                              )}




                                              <span
                                                className="  "
                                                style={{ width: "21%" }}
                                              >
                                                <b>Status &nbsp; :</b>
                                                &nbsp;&nbsp;{" "}
                                                <span>
                                                  &nbsp;
                                                  {item.requestStatusCode ===
                                                    2 ? (
                                                    <span
                                                      style={{
                                                        color: "#00CB0E",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Accepted
                                                    </span>
                                                  ) : item.requestStatusCode ===
                                                    3 ? (
                                                    <span
                                                      style={{
                                                        color: "#FF0000",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Rejected
                                                    </span>
                                                  ) : item.requestStatusCode ===
                                                    0 ? (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Pending
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "#C21E56",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Partially Accepted
                                                    </span>
                                                  )}
                                                </span>
                                                &nbsp; &nbsp;
                                              </span>
                                            </div>
                                          </Accordion.Header>

                                          <Accordion.Body>


                                            {item.productCategories.map(
                                              (category, pcIndex) => {
                                                return (
                                                  <div
                                                    key={pcIndex}
                                                    className="mt-3"
                                                  >
                                                    <span></span>

                                                    <div className="d-flex gap-4 align-items-center mb-3">

                                                      <p style={{ fontSize: 16 }} className="mb-0">
                                                        <b
                                                          style={{
                                                            fontSize: 18,
                                                            color: "orange",
                                                          }}
                                                        >
                                                          Category{" "}
                                                        </b>{" "}
                                                        <span
                                                          style={{
                                                            marginLeft: 47,
                                                          }}
                                                        >
                                                          :{" "}
                                                        </span>{" "}
                                                        &nbsp;
                                                        {category.categoryName}
                                                      </p>
                                                    </div>
                                                    {category.products.map(
                                                      (
                                                        product,
                                                        productIndex
                                                      ) => {
                                                        return (
                                                          <>
                                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex"   >
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Product Name
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{product.productName}</span>
                                                              </span>
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Requested Quantity
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>{product.productRequiredQuantity}</span>
                                                              </span>

                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }}>
                                                                  Issued Quantity
                                                                </span>
                                                                <span>:&nbsp;</span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>
                                                                  {product.issuedQuantity >= 0 ? product.issuedQuantity : 0}
                                                                </span>
                                                              </span>

                                                            </div>
                                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"   >
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Unit price
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>{product.productUnitPrice}</span>
                                                              </span>
                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                                  Balance
                                                                </span>
                                                                <span >  :&nbsp;
                                                                </span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>
                                                                  {!isNaN(orderData[index].productCategories[pcIndex].products[productIndex].productRequiredQuantity
                                                                  ) &&
                                                                    !isNaN(orderData[index].productCategories[pcIndex].products[productIndex].currentIssueQuantity
                                                                    ) &&
                                                                    !isNaN(orderData[index].productCategories[pcIndex].products[productIndex].issuedQuantity)
                                                                    ? orderData[index].productCategories[pcIndex].products[productIndex].productRequiredQuantity - orderData[index].productCategories[pcIndex].products[productIndex].issuedQuantity -
                                                                    orderData[index].productCategories[pcIndex].products[productIndex].currentIssueQuantity
                                                                    : orderData[index].productCategories[pcIndex].products[productIndex].productRequiredQuantity -
                                                                    orderData[index].productCategories[pcIndex].products[productIndex].issuedQuantity} </span>
                                                              </span>
                                                             

                                                              <span style={{ width: '33.4%' }}>
                                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }}>
                                                                  Issue Quantity
                                                                </span>
                                                                <span>:&nbsp;</span>
                                                                <span style={{ width: '64%', display: 'inline-block' }}>
                                                                  <input
                                                                    type="number"
                                                                    style={{ width: 50, height: 25 }}
                                                                    value={
                                                                      orderData[index].productCategories[pcIndex].products[productIndex]
                                                                        .currentIssueQuantity ?? "" // Ensure value is not undefined
                                                                    }
                                                                    onChange={(e) =>
                                                                      handleProductChange(
                                                                        index,
                                                                        pcIndex,
                                                                        productIndex,
                                                                        "currentIssueQuantity",
                                                                        e.target.value
                                                                      )
                                                                    }
                                                                  />
                                                                </span>
                                                              </span>

                                                            </div>


                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}

                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"   >
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                  Request Type
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requestSelfOthers}</span>
                                              </span>

                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                  Required Location
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '64%', display: 'inline-block' }}>{item.productRequiredLocation}</span>
                                              </span>

                                              {item.requesterRemarks !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                    Requester Remarks
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.requesterRemarks}</span>
                                                </span>
                                              ) : ""
                                              }
                                              {item.accepterRemarks !== "" ? (
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                    Acceptor Remarks
                                                  </span>
                                                  <span >  :&nbsp;
                                                  </span>
                                                  <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.accepterRemarks}</span>
                                                </span>
                                              ) : ""
                                              }

                                            </div>


                                            <div className=" d-flex ProductNameForRandM d-flex mt-2 ">
                                              <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-3"   >
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                    Total Order Value
                                                  </span>
                                                  <span >  :&nbsp;  </span>
                                                  <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>{item.totalOrderValue}</span>
                                                </span>
                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                    Send To Gate Pass
                                                  </span>
                                                  <span >  :&nbsp;  </span>
                                                  <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                    <label>
                                                      <input type="radio" name="sendTogatepass" value={sendTogatepass} onChange={() => handleRadioChange("Yes")} />  Yes
                                                    </label>  &nbsp;  <label>  <input type="radio" name="sendTogatepass" value={sendTogatepass} onChange={() => handleRadioChange("No")} />   No </label>
                                                  </span>
                                                  <div>
                                                    <span style={{ color: "red" }} > {errSendToPass}
                                                    </span>
                                                  </div>
                                                </span>

                                                <span style={{ width: '33.4%' }}>
                                                  <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                    Issued Date
                                                  </span>
                                                  <span >  :&nbsp;  </span>
                                                  <span style={{ width: '64%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
                                                    <input type="date" onChange={handleIssuedDate} style={{ width: "104px" }} />
                                                  </span>
                                                  <div>
                                                    <span style={{ color: "red" }} >  {errIssueDate} </span>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>


                                            <div style={{ fontSize: 14, width: "100%", }} className="ProductNameForRandM d-flex mt-2" >
                                              <span style={{ width: '33.4%' }}>
                                                <span style={{ width: '36%', display: 'inline-block', fontWeight: 600 }} >
                                                  Request Date&Time
                                                </span>
                                                <span >  :&nbsp;
                                                </span>
                                                <span style={{ width: '64%', display: 'inline-block' }}>{newDateFormat}</span>
                                              </span>
                                            </div>
                                            <span className="d-flex justify-content-end mt-3">
                                              <button
                                                onClick={() =>
                                                  handleAccept(item, index)
                                                }
                                                className="btns"
                                              >
                                                Accept
                                              </button>
                                              {item.requestStatusCode === 1 ? (
                                                ""
                                              ) : (
                                                <span>
                                                  <button
                                                    className="btnsReject  ml-2"
                                                    onClick={() =>
                                                      handleModalOpen(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Reject
                                                  </button>
                                                </span>
                                              )}
                                            </span>



                                          </Accordion.Body>
                                        </Accordion.Item>
                                        {isLoading && (
                                          <Loader
                                            loading={isLoading}
                                            message={
                                              "Fetching Data. Please Wait..!"
                                            }
                                            loderimage={loaderImage}
                                          />
                                        )}
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="md"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Reject Remarks
                                </span>
                              </div>
                              <div>
                                <div className="col-md-6 ">
                                  <div class="mb-3">
                                    <label
                                      for="InputText"
                                      class="form-label text-sm font-weight-600 "
                                    >
                                      Remarks
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <textarea
                                      class="form-control"
                                      id="textAreaExample1"
                                      rows="2"
                                      value={remark}
                                      onChange={handleRemark}
                                    ></textarea>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errRemark}
                                  </span>
                                </div>
                                <br />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() =>
                                    handleSubmit(
                                      selectedItem,
                                      selectedItemIndex
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Request</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RequestOrderHistory;
