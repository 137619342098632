import React, { useState, useEffect, useRef } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Footer from "./Footer";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../RandD.css";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import { COREPERMIT_DATE, COREPERMIT_MOBILE_NUMBER, COREPERMIT_VENDOR_NAME, CorePermitM2_PERMITID, FIRST_Time } from "../Utils";
const AdditionalPermitOptions = () => {
  const navigate = useNavigate();
  const [isPermitEnabled, setIsExcavationPermitEnabled] = useState(false);
  const [isGotoPermitEnabled, setisGotoPermitEnabled] = useState(false);
  const CPM2_PermitID = sessionStorage.getItem(CorePermitM2_PERMITID);
  var isVendoretime = sessionStorage.getItem(FIRST_Time);
  const COREPERMIT_MOBILE_NUMBERs = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);

  useEffect(() => {
    console.log("CPM2_PermitID", CPM2_PermitID);
    if (COREPERMIT_MOBILE_NUMBERs == "COREPERMIT_MOBILE_NUMBER"
      || COREPERMIT_MOBILE_NUMBERs == null || COREPERMIT_MOBILE_NUMBERs == undefined || COREPERMIT_MOBILE_NUMBERs.toString() == "null") {
      window.location.href = "/CorePermitOne";
      // navigate("/CorePermitOne");
    }
    if (CPM2_PermitID == "CorePermitM2_PERMITID"
      || CPM2_PermitID == null || CPM2_PermitID == undefined || CPM2_PermitID.toString() == "null") {
    }
    else {
      setIsExcavationPermitEnabled(true);
      setisGotoPermitEnabled(true);
    }
  }, [isPermitEnabled])


  

  // Handle the click event when the link is clicked
  const handleECorePermitM2LinkClick = () => {
    window.location.href = "/CorePermitM2";
  };
  // Handle the click event when the link is clicked
  const handleExcavationPermitLinkClick = () => {
    if (isPermitEnabled) {
      // window.location.href = "/ExcavationPermit?permitstatus=Submit";
      window.location.href = "/ExcavationPermit?permitStatus=Submitted";
    }
  };
  // Handle the click event when the link is clicked
  const handleCraneOperationPermitLinkClick = () => {
    if (isPermitEnabled) {
      // window.location.href = "/CranePermitMain?permitstatus=Submit";
      window.location.href = "/CranePermitMain?permitStatus=Submitted";

    }
  };
  // Handle the click event when the link is clicked
  const handleCriticalElevatedLinkClick = () => {
    if (isPermitEnabled) {
      // window.location.href = "/CriticalElevated?permitstatus=Submit";
      window.location.href = "/CriticalElevated?permitStatus=Submitted";

    }
  };
  // Handle the click event when the link is clicked
  const handleHotWorkPermitLinkClick = () => {
    if (isPermitEnabled) {
      // window.location.href = "/HotWorkPermit2Main?permitstatus=Submit";
      window.location.href = "/HotWorkPermit2Main?permitStatus=Submitted";

    }
  };

  // Handle the click event when the link is clicked
  const handleCorePermitM3LinkClick = () => {
    if (isVendoretime.toString() == 'false') {
      window.location.href = "/CorePermitM3";
    }
    if (isGotoPermitEnabled.toString() == 'true') {
      window.location.href = "/CorePermitM3";
    }
  };




  const handleBackButtonClick = () => {
    navigate(-1);
    navigate("/CorePermitOne");
  };


  useEffect(() => {
    console.log("isVendoretime::", isVendoretime);
    if (isVendoretime.toString() == 'false') {
      setisGotoPermitEnabled(true);
    }
  }, [isGotoPermitEnabled])
  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div id="content-wrapper" className="d-flex flex-column">

          <div id="content">
            {/* 
                        <Header /> */}
            <div className="row" style={{ backgroundColor: 'blue', padding: "7px" }}>
              <div className="col-md-3 col-12"  >
                <img src={arrowIcon} onClick={handleBackButtonClick} alt="Back" style={{ width: 40, height: 30, padding: 5, verticalAlign: 'middle' }} />
                <span style={{ fontSize: 20, color: 'white', marginLeft: 10, marginTop: 12 }}>Work Permits</span>
              </div>
            </div>

            <form className="CorePermit_Web-form">
              <div>
                <div>
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="m-5">
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: '#2373BE',
                              color: 'white',
                              cursor: 'pointer',
                              border: "none"
                            }}
                            onClick={handleECorePermitM2LinkClick}
                          >
                            Core Permit</Link>
                        </div>
                        <div className='row mt-2' disable>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#F58355' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed',
                              border: "none"
                            }}
                            onClick={handleCraneOperationPermitLinkClick}
                            disabled={!isPermitEnabled}  >
                            Crane Opeation Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#262828' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed',
                              border: "none"
                            }}
                            onClick={handleExcavationPermitLinkClick}
                            disabled={!isPermitEnabled}  >
                            Excavation Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#566060' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed',
                              border: "none"
                            }}
                            onClick={handleCriticalElevatedLinkClick}
                            disabled={!isPermitEnabled}  >
                            Critical Elevated Work</Link>
                        </div>
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#34B85F' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed',
                              border: "none"
                            }}
                            onClick={handleHotWorkPermitLinkClick}
                            disabled={!isPermitEnabled}  >
                            Hot Work Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isGotoPermitEnabled ? '#DC275E' : '#CCCCCC',
                              color: isGotoPermitEnabled ? 'white' : 'gray',
                              cursor: isGotoPermitEnabled ? 'pointer' : 'not-allowed',
                              border: "none"
                            }}
                            disabled={!isGotoPermitEnabled}
                            onClick={handleCorePermitM3LinkClick}
                          >
                            Go To Permit Status</Link>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </form>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div >
    </body >
  );
};

export default AdditionalPermitOptions;
