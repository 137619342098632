import React, { useState, useEffect, useRef, useContext } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';

import {
    getAllPermitsByCorePermitID,
    getAllRecievedRequests,
    getCorePermits,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import "../RandD.css";
import {
    COREPERMIT_DATE,
    COREPERMIT_MOBILE_NUMBER,
    COREPERMIT_VENDOR_NAME,
    CorePermitM2_PERMITID, SUBSITE_NAME,
    SUBSITE_ID,
    END_TIME,
    MAINSITE_ID,
} from "../Utils";
import MyContext from "./MyContext";
import CorePermitM2 from "./CorePermitM2";
import ButtonAccordion from "../Accordation/ButtonAccordion";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Loader from "../Utilities/Loader";
import { TransferCorePermit, PermitExtension } from "../Utilities/URLCONSTANTS";

//Approve
import Approved from "../images/Approved.png";
import Loader_MS from "../Utilities/Loader_MS";
import { CustomTimePickerInput } from "../Utilities/utils";


const CorePermitM3 = () => {
    const [TransferToName, setName] = useState("");
    const [TransferToMobileNumber, setMobileNumber] = useState("");
    const [submitResponse, setsubmitResponse] = useState([]);
    const [permitExtensionResponse, setPermitExtensionResponse] = useState([]);
    const [showResponsePopup, setShowResponsePopup] = useState(false);
    const [showMsg, setShowMsg] = useState("false");
    const [totalCorePermitMenu, setTotalCorePermitMenu] = useState();
    const [corePermitMenu, setCorePermitMenu] = useState();
    const [cranePermitMenu, setCranePermitMenu] = useState();
    const [excavationPermitMenu, setExcavationPermitMenu] = useState();
    const [criticalElevatedPermitMenu, setCriticalElevatedPermitMenu] =
        useState();
    const [generalHotWorkPermitMenu, setGeneralHotWorkPermitMenu] = useState();
    const [aprove, setAprove] = useState();

    const [showButton, setShowButton] = useState(false);
    const [jobSiteInspectionShow, setJobSiteInspectionShow] = useState(false);

    const [additinalPermitsButtons, setAdditinalPermitsButtons] = useState(false)
    console.log("showButtonssssss", showButton);
    const [permitExtensionStartTime, setpermitExtensionStartTime] = useState();
    const [extendedTrueOrFalse, setExtendedTrueOrFalse] = useState(false);
    const [jobSiteInspectionStatus, setJobSiteInspectionStatus] = useState("");
    const [jobScopeRemarks, setJobScopeRemarks] = useState("");
    const [jobSiteInspectorName, setJobSiteInspectorName] = useState("");

    // Button Accordians Expanding
    const [selectedPermitID, setSelectedPermitID] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isTransferd, setIsTransferd] = useState(false);
    const [isAdditional, setIsAdditional] = useState(false);
    const [isView, setIsView] = useState(false);
    const siteId = sessionStorage.getItem(MAINSITE_ID);
    const subSiteId = sessionStorage.getItem(SUBSITE_ID);


    const [accordionOpen, setAccordionOpen] = useState(true);
    const [showPermitExtension, setShowPermitExtension] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isButtonExpanded, setIsButtonExpanded] = useState(false);

    const handleMobileNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, "");
        if (/^[6-9]\d{0,9}$|^$/.test(inputNumber)) {
            setMobileNumber(inputNumber);
        }
        console.log(inputNumber);
    };

    const handleNameBeChange = (e) => {
        const inputValue = e.target.value;
        const alphaValue = inputValue.replace(/[^A-Za-z ]/g, "");
        setName(alphaValue);
        console.log(alphaValue);
    };


    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirm = (choice) => {
        if (choice === "Yes") {
            setIsButtonExpanded(true);
        }
        setIsModalOpen(false);
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${day}-${month}-${year}`;
    };
    const [startDate, setStartDate] = useState(getTodayDate());

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState({ time: '' });
    const [isSwalShown, setIsSwalShown] = useState(false);
    const [time, setTime] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [remarks, setRemarks] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showPermitExtensionModal, setShowPermitExtensionModal] = useState(false);
    const handleEndTimeChange = (key) => (selectedTime) => {
        const options = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedTime = new Date(selectedTime).toLocaleTimeString("en-US", options);
        setEndTime((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };

    const parseTime = (timeStr) => {
        if (!timeStr) return null;
        const [time, modifier] = timeStr.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier === "PM" && hours !== 12) hours += 12;
        if (modifier === "AM" && hours === 12) hours = 0;

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };
    const minEndTime = parseTime(permitExtensionStartTime);

    useEffect(() => {
        if (!permitExtensionStartTime) {
            setIsButtonDisabled(true);
            return;
        }

        const startTime = parseTime(permitExtensionStartTime);
        if (!startTime) {
            setIsButtonDisabled(true);
            return;
        }

        const enableTime = new Date(startTime);
        enableTime.setHours(startTime.getHours() - 3); // 3 hours before start time

        const disableTime = new Date(startTime);
        disableTime.setHours(startTime.getHours() - 1); // 1 hour before start time

        const currentTime = new Date();

        // Enable button only in the range from 3 hours before to 1 hour before the start time
        setIsButtonDisabled(!(currentTime >= enableTime && currentTime < disableTime));
    }, [permitExtensionStartTime]);


    const navigate = useNavigate();
    var name = sessionStorage.getItem(COREPERMIT_VENDOR_NAME);
    var mobileNumber = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
    var date = sessionStorage.getItem(COREPERMIT_DATE);
    const [isEdit, setIsEdit] = useState(false);
    const [iscoreVE, setIscoreVE] = useState();
    console.log(
        "value1" + name + ":::value2::" + mobileNumber + "::value3::" + date
    );

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [permitId, setPermitId] = useState(-1);

    const handleAddNewPermit = () => {
        console.log("CorePermitM2 :::", window.location.href);
        window.location.href = "/CorePermitM2";
    };

    const handleRemarksChange = (e) => {
        setRemarks(e.target.value);
    };

    const handlePermitExtension = async (PERMIT_EXTENSION_STATUS) => {
        console.log("Start Time:", startTime);
        console.log("End Time:", endTime);

        if (!endTime || !endTime.time) {
            alert("Please Enter The End Time.");
            return;
        }

        if (!remarks) {
            Swal.fire({
                icon: "warning",
                text: "Please Enter the Remarks.",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
            });
            return;
        }

        // Set the PERMIT_EXTENSION_STATUS in sessionStorage
        sessionStorage.setItem("PERMIT_EXTENSION_STATUS", PERMIT_EXTENSION_STATUS);
        // Get the PERMIT_EXTENSION_STATUS from sessionStorage
        const storedPermitExtensionStatus = sessionStorage.getItem("PERMIT_EXTENSION_STATUS");
        // Use the stored or passed permitExtensionStatus
        const currentStatus = storedPermitExtensionStatus || PERMIT_EXTENSION_STATUS;
        console.log("Current Status:", currentStatus);
        // Handle permit extension status conditions
        switch (currentStatus) {
            case "pending":
                alert("Acceptor Approval is Pending");
                return;

            case "approved":
                alert("Acceptor is Approved");
                return;

            case "rejected":
                alert("Acceptor is Rejected");
                return;

            default:
                break;
        }

        // Check for "submitted" status
        if (currentStatus === "submitted") {
            const dataToSend = {
                permitExtensionStartTime: permitExtensionStartTime,
                permitExtensionEndTime: endTime.time,
                corePermitID: permitId.toString(),
                permitExtensionRequesterName: name,
                permitExtensionRequesterMobileNumber: mobileNumber,
                permitExtensionRequestortorRemarks: remarks,
            };

            const URL = PermitExtension;
            try {
                setIsLoading(true);
                const response = await axios.post(URL, dataToSend);
                setIsLoading(false);

                console.log("response:::", response);

                if (response.data.statusCode === "200") {
                    if (response.data.message) {
                        Swal.fire({
                            title: 'Success!',
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                        sessionStorage.setItem(CorePermitM2_PERMITID, null);
                        setIsOpen(false);
                        setRemarks("");
                        setEndTime("");
                    }
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Axios Error:", error);
                Swal.fire({
                    title: 'Error!',
                    text: 'There was an error processing the request.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        }
    };


    const handleEyeIcon = async (permitID, status, jobSiteInspectionStatus, jobScopeRemarks) => {
        setJobSiteInspectionStatus(jobSiteInspectionStatus)
        setShowButton(status)
        const dataToSend = {
            mobileNumber: mobileNumber,
            name: name,
            PermitID: permitID.toString(),
        };
        var URL = getAllPermitsByCorePermitID;
        try {
            setIsLoading(true);
            var response = await axios.post(URL, dataToSend);
            setIsLoading(false);
            console.log("response:::", response);
            if (response.data.statusCode == 200) {
                if (
                    response.data.response != undefined &&
                    response.data.response != null
                ) {
                    setTotalCorePermitMenu(response.data.response);
                    console.log("setCorePermitMenu:::", totalCorePermitMenu);
                    setCorePermitMenu(response.data.response.core_permit);
                    console.log(
                        "CorepermitMenu12345::::::::::",
                        response.data.response.core_permit
                    );
                    setCranePermitMenu(response.data.response.crane_permit);
                    setCriticalElevatedPermitMenu(
                        response.data.response.critical_elevated_permit
                    );
                    setExcavationPermitMenu(response.data.response.excavation_permit);
                    setGeneralHotWorkPermitMenu(
                        response.data.response.general_hot_Work_permit
                    );
                    const corePermit = response.data.response.core_permit;
                    setpermitExtensionStartTime(corePermit?.[0]?.permitObject.people.endTime);
                    console.log("extendedTrueOrFalse value:", corePermit?.[0]?.extendedTrueOrFalse); // Debugging
                    setExtendedTrueOrFalse(corePermit?.[0]?.extendedTrueOrFalse);
                    setJobScopeRemarks(corePermit?.[0]?.remarks)
                    setJobSiteInspectorName(corePermit?.[0]?.jobSiteInspectorName)
                }
            }
        } catch (error) {
            console.error("Axios Error:", error);
        }
    };
    const fetchData = async () => {
        const dataToSend = {
            name: name,
            mobileNumber: mobileNumber,
            date: date,
            siteId: siteId,
            subSiteId: subSiteId,
        };
        var URL = getCorePermits;
        setTimeout(() => {
            setIsLoading(true);
        }, 500);
        var response = await axios.post(URL, dataToSend);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        if (response?.data?.statusCode == 200) {
            console.log("corePermitsResponce", response.data.response.corePermits);
            const corePermitResponse = response?.data?.response;
            const corePermitArray = corePermitResponse?.corePermits;

            if (corePermitArray != undefined && corePermitArray.length > 0) {
                setTimeout(() => {
                    setData(corePermitArray);
                }, 300);
            }
        } else {
            console.log("came to else condition");

        }
        setTimeout(() => {
            console.log("data::", data);
        }, 2000);
    };
    useEffect(() => { },
        [iscoreVE]);
    useEffect(() => {
        console.log("CorepermitMenu", corePermitMenu);
    }, [corePermitMenu]);

    useEffect(() => {
    }, [isEdit]);
    useEffect(() => {
        fetchData();
    }, []);

    const handleBackButtonClick = () => {
        navigate(-1);
    };
    const handleCore = (value) => {
        console.log("handleCore");
        var ex_true = value.toString().split("/")[0];
        var ex_permitStatus = value.toString().split("/")[1];

        if (ex_true == "true") {
            window.location =
                "/CorePermitM2?corePermitId=" +
                permitId +
                "/" +
                "true" +
                "/" +
                ex_permitStatus; //"false";
        } else {
            window.location =
                "/CorePermitM2?corePermitId=" +
                permitId +
                "/" +
                "false" +
                "/" +
                ex_permitStatus;
        }
        window.close();
    };

    const handleCraneoperation = (value) => {
        var ex_true = value.toString().split("/")[0];
        var ex_permitId = value.toString().split("/")[1];
        console.log("handleCraneoperation");
        if (ex_true == "true") {
            window.location =
                "/CranePermitMain?corePermitId=" +
                permitId +
                "/" +
                "true" +
                "/" +
                ex_permitId; //"false";
        } else {
            window.location =
                "/CranePermitMain?corePermitId=" +
                permitId +
                "/" +
                "false" +
                "/" +
                ex_permitId;
        }
        window.close();
    };
    const handleExcavation = (value) => {
        // debugger;
        console.log("handleExcavation::" + value);
        var ex_true = value.toString().split("/")[0];
        var ex_permitId = value.toString().split("/")[1];
        console.log(ex_true + " : " + ex_permitId);
        if (ex_true == "true") {
            window.location =
                "/ExcavationPermit?corePermitId=" +
                permitId +
                "/" +
                "true" +
                "/" +
                ex_permitId; //"false";
        } else {
            window.location =
                "/ExcavationPermit?corePermitId=" +
                permitId +
                "/" +
                "false" +
                "/" +
                ex_permitId;
        }
        window.close();
    };
    const handleCriticalElevated = (value) => {
        var ex_true = value.toString().split("/")[0];
        var ex_permitId = value.toString().split("/")[1];
        if (ex_true === "true") {
            window.location =
                "/CriticalElevated?corePermitId=" +
                permitId +
                "/" +
                "true" +
                "/" +
                ex_permitId; //"false";
        } else {
            window.location =
                "/CriticalElevated?corePermitId=" +
                permitId +
                "/" +
                "false" +
                "/" +
                ex_permitId;
        }
        window.close();
    };
    const handleHotWorkPermit = (value) => {
        var ex_true = value.toString().split("/")[0];
        var ex_permitId = value.toString().split("/")[1];
        if (ex_true === "true") {
            window.location =
                "/HotWorkPermit2Main?corePermitId=" +
                permitId +
                "/" +
                "true" +
                "/" +
                ex_permitId; //"false";
        } else {
            window.location =
                "/HotWorkPermit2Main?corePermitId=" +
                permitId +
                "/" +
                "false" +
                "/" +
                ex_permitId;
        }
        window.close();
    };
    const handleAddNewCore = () => {
        window.location = "/CorePermitM2";
    };
    const handleAdditionalPermit = () => {
        window.location = "/AdditionalPermitOptions?corePermitId=" + permitId;
        sessionStorage.setItem(CorePermitM2_PERMITID, permitId);
        window.close();
    };

    /******************************************************** */

    const handleAccordionClick = () => {
        if (!isTransferd) {
            setShowModal(true);
        } else {
            setIsTransferd(false);
        }
    };

    const handleConfirmExpand = () => {
        setIsTransferd(true);
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };
    // handlePermitExtensionClick
    const handlePermitExtensionClick = () => {
        if (!isOpen) {
            setShowPermitExtensionModal(true);
        } else {
            setIsOpen(false); // Collapse Accordion
        }
    };

    const handleConfirmExpandExtension = () => {
        setIsOpen(true); // Expand Accordion
        setShowPermitExtensionModal(false); // Close modal
    };

    const handleCancelExtension = () => {
        setShowPermitExtensionModal(false); // Close modal without expanding
    };

    const handleSubmit = async () => {
        if (TransferToName === "") {
            alert("Please Enter The Name.");
            return;
        }

        if (TransferToMobileNumber === "") {
            alert("Please Enter The Mobile Number.");
            return;
        }

        const dataToSend = {
            transferFromMobileNumber: mobileNumber,
            transferFromName: name,
            corePermitID: permitId.toString(),
            transferToMobileNumber: TransferToMobileNumber,
            transferToName: TransferToName,
            siteId: siteId,
            subSiteId: subSiteId,
        };
        var URL = TransferCorePermit;
        try {
            setIsLoading(true);

            var response = await axios.post(URL, dataToSend);
            setIsLoading(false);
            console.log("response:::", response);

            if (response.data.statusCode == 200) {
                if (
                    response.data.message != undefined &&
                    response.data.message != null
                ) {
                    setsubmitResponse(response.data.message);
                    setShowResponsePopup(true);
                    console.log("submitResponse:::", submitResponse);

                    setName("");
                    setMobileNumber("");
                    sessionStorage.setItem(CorePermitM2_PERMITID, null);
                    setIsTransferd(false);
                }
            }
        } catch (error) {
            console.error("Axios Error:", error);
        }

    };

    const handleresponse = () => {
        setShowResponsePopup(false);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
    }

    /******************************************************** */
    return (
        <body id="page-top">
            <div id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div
                            className="row"
                            style={{ backgroundColor: "blue", padding: "7px" }}
                        >
                            <div className="col-md-3 col-12">
                                <img
                                    src={arrowIcon}
                                    onClick={handleBackButtonClick}
                                    alt="Back"
                                    style={{
                                        width: 40,
                                        height: 30,
                                        padding: 5,
                                        verticalAlign: "middle",
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: 20,
                                        color: "white",
                                        marginLeft: 10,
                                        marginTop: 12,
                                    }}
                                >
                                    CORE PERMIT Status
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="container-fluid">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <div>
                                            <div
                                                className="card"
                                                style={{
                                                    padding: "10px",
                                                    margin: "5px",
                                                    top: "10px",
                                                    border: "1px solid #DC275E",
                                                }}
                                            >
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row mt-2">
                                                            <div
                                                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white`}
                                                                style={{
                                                                    height: "50px",
                                                                    backgroundColor: "#DC275E",
                                                                    color: "white",
                                                                    border: "none",
                                                                    marginTop: "-18px",
                                                                    borderTopLeftRadius: "8px",
                                                                    borderTopRightRadius: "8px",
                                                                }}
                                                            >
                                                                <p>CORE PERMIT</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="table-responsive">
                                                                        <div className="m-1">
                                                                            <table className="table nstable">
                                                                                {data != undefined &&
                                                                                    data.length > 0 &&
                                                                                    data.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <label for="index" className="col-form-label">
                                                                                                    {index + 1}.
                                                                                                </label>
                                                                                            </td>

                                                                                            <td>
                                                                                                <label for="permitID" className="col-form-label">
                                                                                                    Permit ID: {item.permitID},
                                                                                                </label>
                                                                                            </td>

                                                                                            <td>
                                                                                                <div className="centered-date">
                                                                                                    <label htmlFor="date" className="col-form-label pt-0">
                                                                                                        {item.date},
                                                                                                    </label>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <label
                                                                                                    htmlFor="status"
                                                                                                    className="col-form-label"
                                                                                                    style={{
                                                                                                        color: item.status === "Reject" || item.jobSiteInspectionStatus === "Reject"
                                                                                                            ? "red"
                                                                                                            : item.status === "Approve"
                                                                                                                ? "green"
                                                                                                                : item.status === "Suspend"
                                                                                                                    ? "orange"
                                                                                                                    : "black"
                                                                                                    }}
                                                                                                >
                                                                                                    {item.status === "Reject" || item.jobSiteInspectionStatus === "Reject"
                                                                                                        ? "Reject"
                                                                                                        : item.status},
                                                                                                </label>
                                                                                            </td>

                                                                                            <td>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#exampleModal"
                                                                                                    onClick={() => {
                                                                                                        handleEyeIcon(item.permitID, item.status, item.jobSiteInspectionStatus, item.remarks);
                                                                                                        setPermitId(item.permitID);
                                                                                                        setAprove(item.status);
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="eye-blue"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div>
                                                                        <div
                                                                            class="modal fade"
                                                                            id="exampleModal"
                                                                            tabindex="-1"
                                                                            role="dialog"
                                                                            aria-labelledby="exampleModalLabel"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <div class="modal-dialog" role="document">
                                                                                <div class="modal-content">
                                                                                    <div
                                                                                        class="modal-header"
                                                                                        style={{ borderBottom: "none" }}
                                                                                    >
                                                                                        <button
                                                                                            type="button"
                                                                                            className="close btn btn-danger close_btn btn-close-rd"
                                                                                            data-dismiss="modal"
                                                                                            aria-label="Close"
                                                                                        >
                                                                                            <span aria-hidden="true">
                                                                                                &times;
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                        <div></div>
                                                                                        <div
                                                                                            className="card"
                                                                                            style={{
                                                                                                padding: "10px",
                                                                                                margin: "5px",
                                                                                                top: "10px",
                                                                                                border: "none",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="card-body"
                                                                                                style={{ border: "none" }}
                                                                                            >
                                                                                                <div>
                                                                                                    <div className="row mt-2">
                                                                                                        <ButtonAccordion
                                                                                                            title="View"
                                                                                                            bgColor="#2CC743"
                                                                                                            content={
                                                                                                                <div>
                                                                                                                    {corePermitMenu !==
                                                                                                                        null &&
                                                                                                                        corePermitMenu !==
                                                                                                                        undefined &&
                                                                                                                        corePermitMenu.length >
                                                                                                                        0 &&
                                                                                                                        corePermitMenu.map(
                                                                                                                            (item, index) => (
                                                                                                                                <div className="row mt-2">
                                                                                                                                    <Link
                                                                                                                                        onClick={() => {
                                                                                                                                            handleCore(
                                                                                                                                                "false" +
                                                                                                                                                "/" +
                                                                                                                                                ""
                                                                                                                                            );
                                                                                                                                            setIscoreVE(
                                                                                                                                                false
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                        style={{
                                                                                                                                            height:
                                                                                                                                                "50px",
                                                                                                                                            backgroundColor:
                                                                                                                                                "#2373BE",
                                                                                                                                            color:
                                                                                                                                                "white",
                                                                                                                                            border:
                                                                                                                                                "none",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Core Permit
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}

                                                                                                                    {cranePermitMenu !==
                                                                                                                        null &&
                                                                                                                        cranePermitMenu !==
                                                                                                                        undefined &&
                                                                                                                        cranePermitMenu.length >
                                                                                                                        0 &&
                                                                                                                        cranePermitMenu.map(
                                                                                                                            (item, index) => (
                                                                                                                                <div className="row mt-2">
                                                                                                                                    <Link
                                                                                                                                        onClick={() =>
                                                                                                                                            handleCraneoperation(
                                                                                                                                                "false" +
                                                                                                                                                "/" +
                                                                                                                                                item.permitID
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                        style={{
                                                                                                                                            height:
                                                                                                                                                "50px",
                                                                                                                                            backgroundColor:
                                                                                                                                                "#F58355",
                                                                                                                                            color:
                                                                                                                                                "white",
                                                                                                                                            border:
                                                                                                                                                "none",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Crane
                                                                                                                                        Opeation
                                                                                                                                        Permit
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}


                                                                                                                    {excavationPermitMenu !==
                                                                                                                        null &&
                                                                                                                        excavationPermitMenu !==
                                                                                                                        undefined &&
                                                                                                                        excavationPermitMenu.length >
                                                                                                                        0 &&
                                                                                                                        excavationPermitMenu.map(
                                                                                                                            (item, index) => (
                                                                                                                                <div className="row mt-2">
                                                                                                                                    <Link
                                                                                                                                        onClick={() => {
                                                                                                                                            handleExcavation(
                                                                                                                                                "false" +
                                                                                                                                                "/" +
                                                                                                                                                item.permitID
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                        style={{
                                                                                                                                            height:
                                                                                                                                                "50px",
                                                                                                                                            backgroundColor:
                                                                                                                                                "#262828",
                                                                                                                                            color:
                                                                                                                                                "white",
                                                                                                                                            border:
                                                                                                                                                "none",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Excavation
                                                                                                                                        Permit
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}


                                                                                                                    {criticalElevatedPermitMenu !==
                                                                                                                        null &&
                                                                                                                        criticalElevatedPermitMenu !==
                                                                                                                        undefined &&
                                                                                                                        criticalElevatedPermitMenu.length >
                                                                                                                        0 &&
                                                                                                                        criticalElevatedPermitMenu.map(
                                                                                                                            (item, index) => (
                                                                                                                                <div className="row mt-2">
                                                                                                                                    <Link
                                                                                                                                        onClick={() => {
                                                                                                                                            handleCriticalElevated(
                                                                                                                                                "false" +
                                                                                                                                                "/" +
                                                                                                                                                item.permitID
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                        style={{
                                                                                                                                            height:
                                                                                                                                                "50px",
                                                                                                                                            backgroundColor:
                                                                                                                                                "#566060",
                                                                                                                                            color:
                                                                                                                                                "white",
                                                                                                                                            border:
                                                                                                                                                "none",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Critical
                                                                                                                                        Elevated
                                                                                                                                        Work
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}

                                                                                                                    {generalHotWorkPermitMenu !==
                                                                                                                        null &&
                                                                                                                        generalHotWorkPermitMenu !==
                                                                                                                        undefined &&
                                                                                                                        generalHotWorkPermitMenu.length >
                                                                                                                        0 &&
                                                                                                                        generalHotWorkPermitMenu.map(
                                                                                                                            (item, index) => (
                                                                                                                                <div className="row mt-2">
                                                                                                                                    <Link
                                                                                                                                        onClick={() =>
                                                                                                                                            handleHotWorkPermit(
                                                                                                                                                "false" +
                                                                                                                                                "/" +
                                                                                                                                                item.permitID
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                        style={{
                                                                                                                                            height:
                                                                                                                                                "50px",
                                                                                                                                            backgroundColor:
                                                                                                                                                "#34B85F",
                                                                                                                                            color:
                                                                                                                                                "white",
                                                                                                                                            border:
                                                                                                                                                "none",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Hot Work
                                                                                                                                        Permit
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        )}

                                                                                                                </div>
                                                                                                            }
                                                                                                            bordercolor="#2CC743"
                                                                                                            isOpen={isView}
                                                                                                            setIsOpen={setIsView}
                                                                                                        />

                                                                                                        <div>

                                                                                                            {/* Remarks */}
                                                                                                            {corePermitMenu &&
                                                                                                                corePermitMenu.length > 0 &&
                                                                                                                corePermitMenu.map((item, index) => (
                                                                                                                    <div className="row" key={index} style={{ display: "flex", flexDirection: "column" }}>

                                                                                                                        {/* For 'Reject' Status */}
                                                                                                                        {item.permitStatus === "Reject" && item.remarks && (
                                                                                                                            <>
                                                                                                                                {/* Show 'Rejected By' */}
                                                                                                                                {item.approveOrRejectedByName && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "blue",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Core Permit Rejected By
                                                                                                                                        </label>
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                marginBottom: "8px",
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.approveOrRejectedByName}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                {/* Show Remarks */}
                                                                                                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "red",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Core Permit Remarks
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.remarks}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}

                                                                                                                        {/* For 'Suspend' Status */}

                                                                                                                        {item.permitStatus === "Suspend" && item.suspendedBy && (
                                                                                                                            <>
                                                                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "blue",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Core Permit Suspended By
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            marginTop: "-7px",
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.suspendedBy}
                                                                                                                                    </p>
                                                                                                                                </div>

                                                                                                                                {item.suspendedRemarks && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "red",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Core Permit Suspended Remarks
                                                                                                                                        </label>&nbsp;
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                margin: 0,
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.suspendedRemarks}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ))}


                                                                                                            {/* Additionals  cranePermitMenu Remarks */}
                                                                                                            {cranePermitMenu &&
                                                                                                                cranePermitMenu.length > 0 &&
                                                                                                                cranePermitMenu.map((item, index) => (
                                                                                                                    <div className="row" key={index} style={{ display: "flex", flexDirection: "column" }}>
                                                                                                                        {item.permitStatus === "Reject" && item.remarks && (
                                                                                                                            <>
                                                                                                                                {/* Rejected By Section */}
                                                                                                                                {item.rejectedBy && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "blue",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Crane Permit Rejected By
                                                                                                                                        </label>
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                margin: 0,
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.rejectedBy}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                {/* Remarks Section */}
                                                                                                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "red",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Crane Permit Remarks
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.remarks}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ))}


                                                                                                            {/* excavationPermitMenu Remarks*/}
                                                                                                            {excavationPermitMenu &&
                                                                                                                excavationPermitMenu.length > 0 &&
                                                                                                                excavationPermitMenu.map((item, index) => (
                                                                                                                    <div className="row" key={index} style={{ display: "flex", flexDirection: "column" }}>

                                                                                                                        {/* For 'Reject' Status */}
                                                                                                                        {item.permitStatus === "Reject" && item.remarks && (
                                                                                                                            <>
                                                                                                                                {/* Show 'Rejected By' */}
                                                                                                                                {item.rejectedBy && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "blue",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Excavation Permit Rejected By
                                                                                                                                        </label>
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                margin: 0,
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.rejectedBy}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                {/* Show Remarks */}
                                                                                                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "red",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Excavation Remarks
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.remarks}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ))}

                                                                                                            {/* criticalElevatedPermitMenu Remarks*/}
                                                                                                            {criticalElevatedPermitMenu &&
                                                                                                                criticalElevatedPermitMenu.length > 0 &&
                                                                                                                criticalElevatedPermitMenu.map((item, index) => (
                                                                                                                    <div className="row" key={index} style={{ display: "flex", flexDirection: "column" }}>

                                                                                                                        {/* For 'Reject' Status */}
                                                                                                                        {item.permitStatus === "Reject" && item.remarks && (
                                                                                                                            <>
                                                                                                                                {/* Show 'Rejected By' */}
                                                                                                                                {item.rejectedBy && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "blue",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Critical Elevated Rejected By
                                                                                                                                        </label>
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                margin: 0,
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.rejectedBy}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                {/* Show Remarks */}
                                                                                                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "red",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        Critical Elevated Remarks
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.remarks}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ))}

                                                                                                            {/* generalHotWorkPermitMenu Remarks */}
                                                                                                            {generalHotWorkPermitMenu &&
                                                                                                                generalHotWorkPermitMenu.length > 0 &&
                                                                                                                generalHotWorkPermitMenu.map((item, index) => (
                                                                                                                    <div className="row" key={index} style={{ display: "flex", flexDirection: "column" }}>

                                                                                                                        {/* For 'Reject' Status */}
                                                                                                                        {item.permitStatus === "Reject" && item.remarks && (
                                                                                                                            <>
                                                                                                                                {/* Show 'Rejected By' */}
                                                                                                                                {item.rejectedBy && (
                                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                                        <label
                                                                                                                                            style={{
                                                                                                                                                color: "blue",
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                minWidth: "200px",
                                                                                                                                                whiteSpace: "nowrap",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            HotWork Permit Rejected By
                                                                                                                                        </label>
                                                                                                                                        <p
                                                                                                                                            style={{
                                                                                                                                                fontWeight: "bold",
                                                                                                                                                fontSize: "14px",
                                                                                                                                                margin: 0,
                                                                                                                                                flex: 1,
                                                                                                                                                wordBreak: "break-word",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {": " + item.rejectedBy}
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                )}

                                                                                                                                {/* Show Remarks */}
                                                                                                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                                                                                    <label
                                                                                                                                        style={{
                                                                                                                                            color: "red",
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            minWidth: "200px",
                                                                                                                                            whiteSpace: "nowrap",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        HotWork Permit Remarks
                                                                                                                                    </label>
                                                                                                                                    <p
                                                                                                                                        style={{
                                                                                                                                            fontWeight: "bold",
                                                                                                                                            fontSize: "14px",
                                                                                                                                            margin: 0,
                                                                                                                                            flex: 1,
                                                                                                                                            wordBreak: "break-word",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {": " + item.remarks}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                        </div>

                                                                                                        {jobSiteInspectionStatus === "Reject" && (jobScopeRemarks || jobSiteInspectorName) && (
                                                                                                            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            color: "blue",
                                                                                                                            fontWeight: "bold",
                                                                                                                            fontSize: "14px",
                                                                                                                            minWidth: "200px",
                                                                                                                            whiteSpace: "nowrap",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        JobSite Inspector Name
                                                                                                                    </label>
                                                                                                                    <p
                                                                                                                        style={{
                                                                                                                            fontWeight: "bold",
                                                                                                                            fontSize: "14px",
                                                                                                                            marginBottom: "8px",
                                                                                                                            flex: 1,
                                                                                                                            wordBreak: "break-word",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {": " + jobSiteInspectorName}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                {jobScopeRemarks && (
                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                        <label
                                                                                                                            style={{
                                                                                                                                color: "red",
                                                                                                                                fontWeight: "bold",
                                                                                                                                fontSize: "14px",
                                                                                                                                minWidth: "200px",
                                                                                                                                whiteSpace: "nowrap",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            JobSite Inspector Remarks
                                                                                                                        </label>
                                                                                                                        <p
                                                                                                                            style={{
                                                                                                                                fontWeight: "bold",
                                                                                                                                fontSize: "14px",
                                                                                                                                marginBottom: "8px",
                                                                                                                                flex: 1,
                                                                                                                                wordBreak: "break-word",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {": " + jobScopeRemarks}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}


                                                                                                        {/* END */}

                                                                                                        {showButton !== "Reject" && showButton !== "Suspend" && jobSiteInspectionStatus !== "Reject" ? (
                                                                                                            <ButtonAccordion
                                                                                                                title="Edit"
                                                                                                                bgColor="#007AFF"
                                                                                                                content={
                                                                                                                    <div>
                                                                                                                        {corePermitMenu !== null &&
                                                                                                                            corePermitMenu !== undefined &&
                                                                                                                            corePermitMenu.length > 0 &&
                                                                                                                            corePermitMenu.map((item, index) =>
                                                                                                                                item.permitStatus !== "Approve" ? (
                                                                                                                                    <div className="row mt-2" key={`core-${index}`}>
                                                                                                                                        <Link
                                                                                                                                            onClick={() => {
                                                                                                                                                handleCore("true" + "/" + item.permitStatus);
                                                                                                                                                setIscoreVE(true);
                                                                                                                                            }}
                                                                                                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                                                                            style={{
                                                                                                                                                height: "50px",
                                                                                                                                                backgroundColor: "#2373BE",
                                                                                                                                                color: "white",
                                                                                                                                                border: "none",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Core Permit
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            )}

                                                                                                                        {cranePermitMenu !== null &&
                                                                                                                            cranePermitMenu !== undefined &&
                                                                                                                            cranePermitMenu.length > 0 &&
                                                                                                                            cranePermitMenu.map((item, index) =>
                                                                                                                                item.permitStatus !== "Approve" && item.permitStatus !== "Reject" ? (
                                                                                                                                    <div className="row mt-2" key={`crane-${index}`}>
                                                                                                                                        <Link
                                                                                                                                            onClick={() =>
                                                                                                                                                handleCraneoperation("true" + "/" + item.permitID)
                                                                                                                                            }
                                                                                                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                                                                            style={{
                                                                                                                                                height: "50px",
                                                                                                                                                backgroundColor: "#F58355",
                                                                                                                                                color: "white",
                                                                                                                                                border: "none",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Crane Operation Permit
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            )}

                                                                                                                        {excavationPermitMenu !== null &&
                                                                                                                            excavationPermitMenu !== undefined &&
                                                                                                                            excavationPermitMenu.length > 0 &&
                                                                                                                            excavationPermitMenu.map((item, index) =>
                                                                                                                                item.permitStatus !== "Approve" && item.permitStatus !== "Reject" ? (
                                                                                                                                    <div className="row mt-2" key={`excavation-${index}`}>
                                                                                                                                        <Link
                                                                                                                                            onClick={() =>
                                                                                                                                                handleExcavation("true" + "/" + item.permitID)
                                                                                                                                            }
                                                                                                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                                                                            style={{
                                                                                                                                                height: "50px",
                                                                                                                                                backgroundColor: "#262828",
                                                                                                                                                color: "white",
                                                                                                                                                border: "none",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Excavation Permit
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            )}
                                                                                                                        {criticalElevatedPermitMenu !== null &&
                                                                                                                            criticalElevatedPermitMenu !== undefined &&
                                                                                                                            criticalElevatedPermitMenu.length > 0 &&
                                                                                                                            criticalElevatedPermitMenu.map((item, index) =>
                                                                                                                                item.permitStatus !== "Approve" && item.permitStatus !== "Reject" ? (
                                                                                                                                    <div className="row mt-2" key={`critical-${index}`}>
                                                                                                                                        <Link
                                                                                                                                            onClick={() =>
                                                                                                                                                handleCriticalElevated("true" + "/" + item.permitID)
                                                                                                                                            }
                                                                                                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                                                                            style={{
                                                                                                                                                height: "50px",
                                                                                                                                                backgroundColor: "#566060",
                                                                                                                                                color: "white",
                                                                                                                                                border: "none",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Critical Elevated Work
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            )}


                                                                                                                        {generalHotWorkPermitMenu !== null &&
                                                                                                                            generalHotWorkPermitMenu !== undefined &&
                                                                                                                            generalHotWorkPermitMenu.length > 0 &&
                                                                                                                            generalHotWorkPermitMenu.map((item, index) =>
                                                                                                                                item.permitStatus !== "Approve" && item.permitStatus !== "Reject" ? (
                                                                                                                                    <div className="row mt-2" key={`hotwork-${index}`}>
                                                                                                                                        <Link
                                                                                                                                            onClick={() =>
                                                                                                                                                handleHotWorkPermit("true" + "/" + item.permitID)
                                                                                                                                            }
                                                                                                                                            className="d-flex justify-content-center align-items-center w-100 btn btn-light text-white custom-rounded"
                                                                                                                                            style={{
                                                                                                                                                height: "50px",
                                                                                                                                                backgroundColor: "#34B85F",
                                                                                                                                                color: "white",
                                                                                                                                                border: "none",
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            Hot Work Permit
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                ) : null
                                                                                                                            )}

                                                                                                                        <div className="row mt-2">
                                                                                                                            <Link
                                                                                                                                onClick={
                                                                                                                                    handleAdditionalPermit
                                                                                                                                }
                                                                                                                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                                                                                style={{
                                                                                                                                    height: "50px",
                                                                                                                                    backgroundColor:
                                                                                                                                        "#FF0000",
                                                                                                                                    color: "white",
                                                                                                                                    border: "none",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Go To Additional
                                                                                                                                Permit
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                bordercolor="#007AFF"
                                                                                                                isOpen={isAdditional}
                                                                                                                setIsOpen={setIsAdditional}
                                                                                                            />
                                                                                                        ) : null}

                                                                                                        {showButton !== "Reject" && showButton !== "Suspend" && jobSiteInspectionStatus !== "Reject" ? (
                                                                                                            <ButtonAccordion
                                                                                                                title="Transfer Permit"
                                                                                                                bgColor="#FFA200"
                                                                                                                content={
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            <div className="form-group">
                                                                                                                                <label for="inputPassword">
                                                                                                                                    Name
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "red",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                                <div>
                                                                                                                                    <input
                                                                                                                                        className="form-control"
                                                                                                                                        id="inputPassword"
                                                                                                                                        placeholder="Enter Name"
                                                                                                                                        value={
                                                                                                                                            TransferToName
                                                                                                                                        }
                                                                                                                                        onChange={
                                                                                                                                            handleNameBeChange
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                <label for="inputPassword">
                                                                                                                                    Mobile Number
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "red",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        *
                                                                                                                                    </span>{" "}
                                                                                                                                </label>
                                                                                                                                <div>
                                                                                                                                    <input
                                                                                                                                        className="form-control"
                                                                                                                                        id="inputPassword"
                                                                                                                                        placeholder="Enter Mobile Number"
                                                                                                                                        value={
                                                                                                                                            TransferToMobileNumber
                                                                                                                                        }
                                                                                                                                        onChange={
                                                                                                                                            handleMobileNumberChange
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </div>

                                                                                                                                <div className="mt-3">
                                                                                                                                    <button
                                                                                                                                        type="submit"
                                                                                                                                        class="btn btn-primary col-12"
                                                                                                                                        onClick={
                                                                                                                                            handleSubmit
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        Transfer
                                                                                                                                    </button>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                bordercolor="#FFA200"
                                                                                                                isOpen={isTransferd}
                                                                                                                setIsOpen={handleAccordionClick}

                                                                                                            />
                                                                                                        ) : null}
                                                                                                        <div>
                                                                                                            {/* Custom Modal */}
                                                                                                            <Modal
                                                                                                                show={showModal}
                                                                                                                centered
                                                                                                                backdrop="static"
                                                                                                                keyboard={false}
                                                                                                                size="md"
                                                                                                                id="nsmodel"
                                                                                                                className="ppopup"
                                                                                                            >
                                                                                                                <Modal.Body>
                                                                                                                    <div>
                                                                                                                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Note:</p>
                                                                                                                        <ul>
                                                                                                                            <li>
                                                                                                                                <strong>
                                                                                                                                    Permit Receiver needs to have a complete understanding of job scope, and competent to accept this work.
                                                                                                                                </strong>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <strong>
                                                                                                                                    Must have undergone the site orientation.
                                                                                                                                </strong>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div className="modal-actions" style={{ textAlign: 'center' }}>
                                                                                                                            <Button
                                                                                                                                variant="primary"
                                                                                                                                onClick={handleConfirmExpand}
                                                                                                                                style={{
                                                                                                                                    backgroundColor: "#007AFF",
                                                                                                                                    color: "white",
                                                                                                                                    border: "none",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Ok
                                                                                                                            </Button>
                                                                                                                            <Button
                                                                                                                                variant="secondary"
                                                                                                                                onClick={handleCancel}
                                                                                                                                style={{
                                                                                                                                    marginLeft: '10px',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Cancel
                                                                                                                            </Button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Modal.Body>
                                                                                                            </Modal>
                                                                                                        </div>

                                                                                                        {showButton === "Approve" && jobSiteInspectionStatus !== "Reject" ? (
                                                                                                            <ButtonAccordion
                                                                                                                title="Permit Extension"
                                                                                                                bgColor="#A8B5C3"
                                                                                                                content={
                                                                                                                    <div>
                                                                                                                        <div>
                                                                                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                                                                <input
                                                                                                                                    value={startDate}
                                                                                                                                    style={{
                                                                                                                                        padding: "5px",
                                                                                                                                        fontSize: "12px",
                                                                                                                                        borderRadius: "5px",
                                                                                                                                        width: "150px",
                                                                                                                                        textAlign: "center",
                                                                                                                                        fontWeight: "bold",
                                                                                                                                    }}
                                                                                                                                    readOnly
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <label>
                                                                                                                                Start Time
                                                                                                                                <span style={{ color: "red" }}>*</span>
                                                                                                                            </label>
                                                                                                                            <input
                                                                                                                                className="form-control time-input"
                                                                                                                                value={permitExtensionStartTime || ''}
                                                                                                                                readOnly
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <label>
                                                                                                                                End Time
                                                                                                                                <span style={{ color: "red" }}>*</span>
                                                                                                                            </label>
                                                                                                                            <DatePicker
                                                                                                                                className="form-control time-input"
                                                                                                                                selected={time}
                                                                                                                                placeholderText="Select EndTime"
                                                                                                                                value={endTime.time || ""}
                                                                                                                                onChange={handleEndTimeChange("time")}
                                                                                                                                showTimeSelect
                                                                                                                                showTimeSelectOnly
                                                                                                                                timeIntervals={1}
                                                                                                                                dateFormat="hh:mm aa"
                                                                                                                                required
                                                                                                                                minTime={minEndTime}
                                                                                                                                maxTime={new Date().setHours(23, 59)}
                                                                                                                            />

                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <label>Remarks <span style={{ color: "red" }}>*</span></label>
                                                                                                                            <input type="text"
                                                                                                                                className="form-control p-2"
                                                                                                                                placeholder='Enter Remarks'
                                                                                                                                value={remarks} inputMode='text'
                                                                                                                                onChange={handleRemarksChange} />
                                                                                                                        </div>
                                                                                                                        {extendedTrueOrFalse !== true && (
                                                                                                                            <div className="mt-3">
                                                                                                                                <button
                                                                                                                                    type="button"
                                                                                                                                    className="btn btn-primary col-12"
                                                                                                                                    onClick={() => handlePermitExtension("submitted")}
                                                                                                                                    disabled={isButtonDisabled}
                                                                                                                                >
                                                                                                                                    Permit Extension
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                    </div>
                                                                                                                }
                                                                                                                bordercolor="#A8B5C3"
                                                                                                                isOpen={isOpen}
                                                                                                                setIsOpen={handlePermitExtensionClick}
                                                                                                            />
                                                                                                        ) : null}

                                                                                                        <div>
                                                                                                            {/* Custom Modal */}
                                                                                                            <Modal
                                                                                                                show={showPermitExtensionModal}
                                                                                                                centered
                                                                                                                backdrop="static"
                                                                                                                keyboard={false}
                                                                                                                size="md"
                                                                                                                id="nsmodel"
                                                                                                                className="ppopup"
                                                                                                            >
                                                                                                                <Modal.Body>
                                                                                                                    <div>
                                                                                                                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Note:</p>
                                                                                                                        <ul>
                                                                                                                            <li>
                                                                                                                                <strong>
                                                                                                                                    Only if JOB scope Not changed .
                                                                                                                                </strong>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <strong>
                                                                                                                                    Extension  request is Possible only  Before 1hr of permit  Closer .
                                                                                                                                </strong>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div className="modal-actions" style={{ textAlign: 'center' }}>
                                                                                                                            <Button
                                                                                                                                variant="primary"
                                                                                                                                onClick={handleConfirmExpandExtension}
                                                                                                                                style={{
                                                                                                                                    backgroundColor: "#007AFF",
                                                                                                                                    color: "white",
                                                                                                                                    border: "none",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Ok
                                                                                                                            </Button>
                                                                                                                            <Button
                                                                                                                                variant="secondary"
                                                                                                                                onClick={handleCancelExtension}
                                                                                                                                style={{
                                                                                                                                    marginLeft: '10px',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                Cancel
                                                                                                                            </Button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Modal.Body>
                                                                                                            </Modal>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div></div>
                                                                                                </div>
                                                                                                <div></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="card"
                                                style={{
                                                    padding: "10px",
                                                    margin: "5px",
                                                    top: "10px",
                                                    border: "none",
                                                }}
                                            >
                                                <div className="card-body" style={{ border: "none" }}>
                                                    <div>
                                                        <div className="row mt-2">
                                                            <Link
                                                                onClick={handleAddNewCore}
                                                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                style={{
                                                                    height: "50px",
                                                                    backgroundColor: "#DC275E",
                                                                    color: "white",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                Add New Permit
                                                            </Link>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up"></i>
                </a>
            </div>
            <div>
                <Modal
                    show={showResponsePopup}
                    centered
                    backdrop="static"
                    keyboard={false}
                    size="sm"
                    id="nsmodel"
                    className="ppopup"
                >
                    <Modal.Body>
                        <div>
                            <div className="row">
                                <div>
                                    <span
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "10vh",
                                        }}
                                    >
                                        <img src={Approved} alt="save" className="popupicons" />
                                    </span>
                                </div>
                            </div>
                            <div>
                                <p
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "3vh",
                                    }}
                                >
                                    <h5>Permit Transferred Successfully</h5>
                                </p>
                            </div>
                            <div>
                                <p
                                    style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0px",
                                    }}
                                >
                                    <Button
                                        variant="primary"
                                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                        style={{
                                            backgroundColor: "#007AFF",
                                            color: "white",
                                            border: "none",
                                        }}
                                        onClick={handleresponse}
                                    >
                                        Ok
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {isLoading && (
                <Loader_MS
                    loading={isLoading}
                    message={"Fetching Data. Please Wait..!"}
                />
            )}
        </body>
    );
};

export default CorePermitM3;
