import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaClock } from 'react-icons/fa';
import { FaCalendar } from 'react-icons/fa';
import { END_TIME, PEOPLE_DATE_FOR_CLOUSEOUT_DATE } from '../../Utils';
const currentDate = new Date().toISOString().split('T')[0];
const CorePermitCloseOut = ({ closeOutData, setCloseOutData,
  primaryRequesterName, approveOrRejectedByName,
  isTransferred, transferToName, closeOutDate, closeOutTime, issuerTransferTo }) => {
  const [vendorName, setVendorName] = useState("");

  const [approveOrRejectedName, setApproveOrRejectedName] = useState("");
  const [endTime, setEndTime] = useState("");
  const [peopleDateCloseOutDate, setPeopleDateCloseOutDate] = useState("");

  useEffect(() => {
    setEndTime(sessionStorage.getItem(END_TIME) || "");
    setPeopleDateCloseOutDate(sessionStorage.getItem(PEOPLE_DATE_FOR_CLOUSEOUT_DATE) || "");
  }, []);

  const handleCloseData = (key, val) => {
    setCloseOutData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const [time, setTime] = useState(new Date());
  const [SelectTime, SetSelectTime] = useState('');
  const handleTimeChange = (key) => (selectedTime) => {
    SetSelectTime(selectedTime);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = new Date(selectedTime).toLocaleTimeString('en-US', options);
    setCloseOutData((prev) => ({ ...prev, [key]: formattedTime }));
    setTime(selectedTime);
  };

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div>
      <input className='form-control w-full'
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer', padding: '5px 10px', background: 'white' }}
      />
      <FaCalendar
        style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
        onClick={onClick} />

    </div>
  );


  const handleDateChange = (date) => {
    if (date) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      setCloseOutData((prev) => ({ ...prev, date: formattedDate }));
      console.log(formattedDate);
    }
  };


  const CustomTimePickerInput = ({ value, onClick }) => (
    <div>
      <input className='form-control w-full'
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer', padding: '5px 10px', background: 'white' }}
      />
      <FaClock style={{ position: "absolute", cursor: "pointer", right: "13px", top: "10px " }}
        onClick={onClick} />
    </div>
  );
  const handleCloseOutData = (id, val) => {
    const updatedPeopleList = closeOutData.closeOutList.map((opItem) => ({
      ...opItem,
      checked: opItem.id == id ? val : opItem.checked,
      selectedValue:
        opItem.checked === true ? "yes" : opItem.checked === false ? "no" : "",
    }));
    handleCloseData("closeOutList", updatedPeopleList);
  };
  return (
    <div>
      <div>
        {closeOutData.closeOutList.map((data) => {
          return (
            <div class="form-group">
              <label>{data.cbTitle}</label>
              <div class="form-check">
                <input
                  checked={data.checked === 'yes'}
                  onChange={() => {
                    handleCloseOutData(
                      data.id,
                      data.checked !== 'yes' ? 'yes' : ""
                    );
                  }}
                  type="checkbox"
                  class="form-check-input"

                />
                <label class="form-check-label">
                  YES
                </label>
              </div>
              <div class="form-check">
                <input
                  checked={data.checked === 'no'}
                  type="checkbox"
                  class="form-check-input"

                  onChange={() => {
                    handleCloseOutData(
                      data.id,
                      data.checked !== 'no' ? 'no' : ""
                    );
                  }}
                />
                <label class="form-check-label">
                  NO
                </label>
              </div>
            </div>
          );
        })}
        <div class="form-group">
          <label>
            Special instruction, precautions, limitations, remarks:
          </label>
          <div>
            <input
              value={closeOutData.remarks}
              onChange={(e) => handleCloseData('remarks', e.target.value)}
              class="form-control col-12"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-group">
          <label>Receiver Closeout</label>
          <div>
            <input
              className="form-control mb-1"
              type="text"
              placeholder=""
              value={primaryRequesterName}
              readOnly
            />
          </div>
        </div>
        <div class="form-group">
          <label>Issuer Closeout</label>
          <div>
            <input
              className="form-control mb-1"
              type="text"
              placeholder=""
              value={issuerTransferTo || approveOrRejectedByName}
              readOnly
            />
          </div>
        </div>
        <div class="form-group">
          <label>Date</label>
          <div>
            <DatePicker
              className="form-control col-12"
              placeholderText=""
              dateFormat="dd-MMM-yyyy"
              value={closeOutDate}
            />
          </div>
        </div>
        <div class="form-group">
          <label>Time</label>
          <div>
            <DatePicker className='form-control time-input' value={closeOutTime || ""} readOnly />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorePermitCloseOut;
