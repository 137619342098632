import React, { useState, useEffect, useRef } from "react";
//import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Swal from "sweetalert2";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  PartOneExavation,
  PartTwoExavation,
  PartThreeExavation,
  EmergencyConst,
} from "../Utilities/exavationPermit-JSON";
import Emergency from "../components/excavationPermit/Emergency";
import PartONE from "../components/excavationPermit/PartONE";
import PartTWO from "../components/excavationPermit/PartTWO";
import PartTHREE from "../components/excavationPermit/PartTHREE";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import {
  USER_PROFILE,
  retrieveData,
  COREPERMIT_MOBILE_NUMBER,
  checkForEmptyValue,
} from "../Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";
import axios from "axios";
// import CorePermitInputFields from "../components/excavationPermit/corePermitInputFields";
//loader img
import Loader_Bulk from "../Utilities/Loader_Bulk";
//Approve
import Approved from "../images/Approved.png";
// import CorePermitInputFields from "../components/corePermitM2/corePermitInputFields";
import CorePermitInputFields from "../components/excavationPermit/corePermitInputFields";

const ExcavationPermit = (props) => {
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [partOne, setPartOne] = useState(PartOneExavation);
  const [partTwo, setPartTwo] = useState(PartTwoExavation);
  const [partThree, setPartThree] = useState(PartThreeExavation);
  const [activeIndex, setActiveIndex] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  //Reject
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serverRecordId, setServerRecordId] = useState(null);
  const addtionalpermitstatus = searchParams.get("permitstatus");

  //validations
  const [openAccordion, setOpenAccordion] = useState(null);
  const [emergencyToggleOn, setEmergencyToggleOn] = useState(true);
  const [partOneToggleOn, setPartOneToggleOn] = useState(true);
  const [partTwoToggleOn, setPartTwoToggleOn] = useState(true);
  const [partThreeToggleOn, setPartThreeToggleOn] = useState(true)


  useEffect(() => {
    console.log(partThree, "partOne");
  }, [partThree]);
  const toggleItem = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // api
  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    const IdFromURLwithEDit = searchParams.get("corePermitId");
    var IdFromURL;
    var isEdit = "true";
    var additional_permit_id;
    if (IdFromURLwithEDit == null) {
    } else {
      IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
      isEdit = IdFromURLwithEDit.toString().split("/")[1];
      additional_permit_id = IdFromURLwithEDit.toString().split("/")[2];
    }

    setIsEdit(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
    return () => { };
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id, additional_permit_id) => {
    console.log(id);
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);

      // Make an API request using Axios to get permits by core permit ID
      const vendormobileNumber = sessionStorage.getItem(
        "COREPERMIT_MOBILE_NUMBER"
      );
      const vendorname = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const PermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: vendormobileNumber,
        name: vendorname,
        PermitID: addtionalpermitstatus == "Submit" ? PermitID : id,
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);
      if (addtionalpermitstatus != "Submit") {
        if (response.data && response.data.response) {
          const elevated = response?.data?.response?.excavation_permit || [];
          //const dataLast = elevated[elevated.length - 1]?.permitObject || null
          const dataLast =
            elevated.find((item) => item.permitID === additional_permit_id)
              ?.permitObject || null;
          console.log("filteredArray checking", JSON.stringify(dataLast));
          if (dataLast) {
            setPermitInitials(
              dataLast.permitInitials
                ? dataLast?.permitInitials
                : permitInitials
            );
            setEmergency(dataLast.emergency || emergency);
            //setPartOne(dataLast?.partOne ? dataLast?.partOne : partOne)
            setPartOne(dataLast.partOne || partOne);
            setPartTwo(dataLast.partTwo || partTwo);
            setPartThree(dataLast.partThree || partThree);
            // setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
            setServerRecordId(
              elevated.find((item) => item.permitID === additional_permit_id)
                ?.permitID || "0"
            );
            setEmergencyToggleOn(dataLast.emergencyToggleOn !== undefined ? dataLast.emergencyToggleOn : emergencyToggleOn);
            setPartOneToggleOn(dataLast.partOneToggleOn !== undefined ? dataLast.partOneToggleOn : partOneToggleOn);
            setPartTwoToggleOn(dataLast.partTwoToggleOn !== undefined ? dataLast.partTwoToggleOn : partTwoToggleOn);
            setPartThreeToggleOn(dataLast.partThreeToggleOn !== undefined ? dataLast.partThreeToggleOn : partThreeToggleOn);
          }
        } else {
          // Handle the case where the response does not contain the expected data
          console.log("Response data is missing or invalid.");
        }
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };


  const handleApproved = async (e) => {
    e.preventDefault();
    setStatus("");
    setShowApproved(true);
  };
  const handleAprrovedOk = async (e) => {
    console.log(e, "handleOk");
    const ApproveData = {
      permitName: "generalHotWorkPermit",
      corePermitID: "5",
      additionalPermitID: "11",
      status: "Approved",
      //"remarks": "remarks"
    };
    const response = await axios.post(ApproveRejectPermit, ApproveData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      console.log("API throwing error");
    }
    setShowApproved(false);
    console.log(status);
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRemarks("");
    setShowReject(true);
  };
  const handleRejectOk = async (e) => {
    console.log(e, "handleRejectOk");

    const RejectData = {
      permitName: "cranePermit",
      corePermitID: "5",
      additionalPermitID: "7",
      status: "Rejected",
      remarks: remarks,
    };
    const response = await axios.post(ApproveRejectPermit, RejectData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      //console.log("API throwing error");
    }
    setShowReject(false);
    console.log(status);
  };

  const resetForm = () => {
    setPermitInitials(corePermitInitialsConst);
    setEmergency(EmergencyConst);
    setPartOne(PartOneExavation);
    setPartTwo(PartTwoExavation);
    setPartThree(PartThreeExavation);
    setFinalSubmit(false);
  };

  const handleToggleItems = () => {
    setEmergencyToggleOn(!emergencyToggleOn)
  }

  const handlePartOneItems = () => {
    setPartOneToggleOn(!partOneToggleOn)
  }

  const handlePartTwoItems = () => {
    setPartTwoToggleOn(!partTwoToggleOn)
  }

  const handlePartThreeItems = () => {
    setPartThreeToggleOn(!partThreeToggleOn)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const checkedItems = partOne.list.filter((item) => item.value);
    const partOneChecked = checkedItems.length >= 1 ? "true" : "";

    // part 2
    const checkedPartTwoyes = partTwo.basic.filter(
      (item) => item.title1yes === "true"
    );
    const checkedPartTwoNo = partTwo.basic.filter(
      (item) => item.title1no === "true"
    );

    const ispartTwoCheckedyes =
      checkedPartTwoyes.length >= 1
        ? "true"
        : checkedPartTwoyes.length === 0 && checkedPartTwoNo.length === 0
          ? ""
          : "false";

    const ispartTwoCheckedNa =
      checkedPartTwoNo.length >= 1
        ? "true"
        : checkedPartTwoyes.length === 0 && checkedPartTwoNo.length === 0
          ? ""
          : "false";

    // part 3

    const checkedPartThreeyes = partThree.isTheescavation.cbYes;
    const checkedPartThreeNo = partThree.isTheescavation.cbNo;
    const checkedPartThreeNa = partThree.isTheescavation.cbNA;

    const ispartThreeCheckedyes =
      checkedPartThreeyes === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";

    const ispartThreeCheckedNo =
      checkedPartThreeNo === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";

    const ispartThreeCheckedNa =
      checkedPartThreeNa === "true"
        ? "true"
        : checkedPartThreeyes === "" &&
          checkedPartThreeNo === "" &&
          checkedPartThreeNa === ""
          ? ""
          : "false";


    var valuesToCheck = [];
    if (emergencyToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        emergency: {
          phoneNumber: emergency.phoneNumber,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partOneToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partOne: {
          list: partOneChecked,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partTwoToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partTwo: {
          partTwocbyes: ispartTwoCheckedyes,
          partTwocbno: ispartTwoCheckedNa,
        },
      };
      valuesToCheck = [...updatedValues];
    }

    if (partThreeToggleOn) {
      const updatedValues = [...valuesToCheck];
      updatedValues[0] = {
        ...updatedValues[0],
        partThree: {
          partThreecbYes: ispartThreeCheckedyes,
          partThreecbNA: ispartThreeCheckedNa,
          partThreecbNo: ispartThreeCheckedNo,
        },
      };
      valuesToCheck = [...updatedValues];
    }


    console.log("valuesToCheck" + JSON.stringify(valuesToCheck));
    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {
        case "emergency":
          if (emergencyToggleOn) {
            Swal.fire({
              icon: "warning",
              text: "Fill the Emergency Data",
              confirmButtonColor: "#d33",
            });
            allCasesValid = false;
          }
          break;

        case "partOne":
          if (partOneToggleOn) {
            Swal.fire({
              icon: "warning",
              text: "Please Fill The Part One Data",
              confirmButtonColor: "#d33",
            });
            allCasesValid = false;
          }
          break;

        case "partTwo":
          if (partTwoToggleOn) {
            Swal.fire({
              icon: "warning",
              text: "Please Fill The Part Two Data",
              confirmButtonColor: "#d33",
            });
            allCasesValid = false;
          }
          break;

        case "partThree":
          if (partThreeToggleOn) {
            Swal.fire({
              icon: "warning",
              text: "Please Fill The Part Three Data",
              confirmButtonColor: "#d33",
            });
            allCasesValid = false;
          }
          break;
      }

    }
    if (allCasesValid) {
      var vendorname = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      var vendormobileNumber = sessionStorage.getItem(
        "COREPERMIT_MOBILE_NUMBER"
      );
      var corePermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const dataFromStore = await retrieveData(USER_PROFILE);
      // const dataFromStore = await retrieveData(USER_PROFILE);
      console.log(partOne, "PARTONE");
      const userProfileData = {
        id: serverRecordId ? serverRecordId : "0",
        mobileNumber: vendormobileNumber,
        name: vendorname,
        corePermitID: corePermitID,
        additionalPermitName: "excavation_permit",
      };
      const finalJson = {
        request: {
          permitInitials,
          emergency,
          partOne,
          partTwo,
          partThree,
          emergencyToggleOn,
          partOneToggleOn,
          partTwoToggleOn,
          partThreeToggleOn,
        },
        ...userProfileData,
      };
      try {
        setIsLoading(true);
        console.log(finalJson, "finalJson***");
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log(response.status, "sdmnhba");
        console.log("response", response);
        if (response.status == 200 && response) {
          console.log("success");
          // setFinalSubmit(true)
          setShowModal(true);
          setIsLoading(false);
          resetForm(); // Reset form fields
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          {finalSubmit ? (
            <>
              <h2>Form Submitted Successfully!!!</h2>
            </>
          ) : (
            <div id="content">
              {/* 
                        <Header /> */}
              <div
                className="row"
                style={{ backgroundColor: "blue", padding: "7px" }}
              >
                <div className="col-md-3 col-12">
                  <img
                    src={arrowIcon}
                    onClick={handleBackButtonClick}
                    alt="Back"
                    style={{
                      width: 40,
                      height: 30,
                      padding: 5,
                      verticalAlign: "middle",
                    }}
                  />
                  <span
                    style={{
                      fontSize: 20,
                      color: "white",
                      marginLeft: 10,
                      marginTop: 12,
                    }}
                  >
                    Excavation Permit
                  </span>
                </div>
              </div>
              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>
                  <div>
                    <form className="ExcavationPermit-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        setPermitInitials={setPermitInitials}
                        editable={false}
                      />
                      <Accordion
                        title="EMERGENCY"
                        bgColor="#DF3A1C"
                        handleToggle={handleToggleItems}
                        isToggled={emergencyToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="emergency"
                        content={
                          <Emergency
                            emergencyToggleOn={emergencyToggleOn}
                            data={emergency}
                            setEmergency={setEmergency}
                            onDataChange={(data) => {
                              setEmergency(data);
                            }}
                          />
                        }
                        bordercolor="#DF3A1C"
                        showToggle={true}
                      />

                      <Accordion
                        title="PART1"
                        bgColor="#262828"
                        handleToggle={handlePartOneItems}
                        isToggled={partOneToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="part1"
                        content={
                          <PartONE
                            data={partOne}
                            partOneToggleOn={partOneToggleOn}
                            setPartOne={setPartOne}
                            onDataChange={(data) => {
                              setPartOne(data);
                            }}
                          />
                        }
                        bordercolor="#262828"
                        showToggle={true}

                      />
                      <Accordion
                        title="PART2"
                        bgColor="#566060"
                        handleToggle={handlePartTwoItems}
                        isToggled={partTwoToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="part2"
                        content={
                          <PartTWO
                            data={partTwo}
                            partTwoToggleOn={partTwoToggleOn}
                            setPartTwo={setPartTwo}
                            onDataChange={(data) => {
                              setPartTwo(data);
                            }}
                          />
                        }
                        bordercolor="#566060"
                        showToggle={true}

                      />

                      <Accordion
                        title="PART3"
                        bgColor="#C2C9C8"
                        handleToggle={handlePartThreeItems}
                        isToggled={partThreeToggleOn}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        index="part3"
                        content={
                          <PartTHREE
                            data={partThree}
                            partThreeToggleOn={partThreeToggleOn}
                            setPartThree={setPartThree}
                            onDataChange={(data) => {
                              setPartThree(data);
                            }}
                          />
                        }
                        bordercolor="#C2C9C8"
                        showToggle={true}
                      />

                      {isEdit.toString() == "true" && (
                        <button
                          type="button"
                          className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                          style={{
                            height: "50px",
                            backgroundColor: "#007AFF",
                            color: "white",
                            border: "none",
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="sm"
                        id="nsmodel"
                        className="ppopup"
                      >
                        <Modal.Body>
                          <div>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "15vh",
                                  }}
                                >
                                  <img
                                    src={Approved}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                }}
                              >
                                <h5>Excavation Permit sent for Approval</h5>
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    handleBackButtonClick();
                                    setShowModal(false);
                                  }}
                                >
                                  Ok
                                </Button>
                              </p>
                            </div>
                          </div>
                        </Modal.Body>


                      </Modal>


                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <h3>Permit Status</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name" class="centered-text">
                                <h6>Permit Approved Successfully</h6>
                              </label>

                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={handleAprrovedOk}
                              >
                                Ok
                              </Button>
                            </div>
                            <span>{apiStatus}</span>
                          </Modal.Footer>
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "15vh",
                              }}
                            >
                              <h3> Are you sure want to Reject?</h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Reason:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Reason for Rejection"
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={handleRejectOk}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </form>
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          )}
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */}
        {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          // message={"Fetching Data. Please Wait..!"}
          message={"Processing your submission. Please wait..."}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default ExcavationPermit;
