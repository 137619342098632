import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import Search from "../images/search.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { strings } from "../Utilities/strings";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { addCategoryMaster, getCategory } from "../Utilities/URLCONSTANTS";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
import "@fortawesome/fontawesome-free/css/all.min.css";


//CategoryMaster
const CategoryMaster = () => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  // const { selectedCategory, setSelectedCategory } = useContext(CategoryContext);
  const [error, setError] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [id, setId] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [errorName, setErrorName] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [sno, setSno] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Category Master"
  );
  const [status, setStatus] = useState("");

  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    Id: "",
    name: "",
    code: "",
    Status: "",
  });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.code.toString().includes(searchTerm) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); 
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      ["S.no", "Name", "Code", "Status"], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.name,
        item.productCode,
        item.status === "1" ? "Active" : "InActive",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "CategoryMaster.xlsx");
  };

  const handleCancel_new = () => {
    setShowModal(true);
    setShowPopup(true);
  };

  console.log("data", data);

  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowWarningPopup(false);
  };

  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
  };

  const closeWarning = () => {
    setShowWarningPopup(true);
  };

  const handleNameChange = (e) => {
    const input = e.target.value;
    if (input.length > 0) {
      setErrorName("");
    }
    const result = input.replace(/[^a-z_\s]/gi, "");
    setName(result);
  };

  const handleCode = (e) => {
    setCode(e.target.value);

    if (e.target.value) {
      setErrorCode("");
    }
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleSubmit = async () => {
    if (name === "") {
      setErrorName("Please Enter a category Name");
    }
    if (code === "") {
      setErrorCode("please Enter a code");
    }
    if (status === "") {
      setErrorStatus("please Select Status");
    }
    try {
      console.log("submit", id, name, code, status);

      if (id === 0 && name !== "" && code !== "" && status !== "") {
        // alert("Submit");
        const addApi = addCategoryMaster;
        const dataToSend = {
          Id: id === 0 ? 0 : 0,
          name: name,
          code: code,
          status: status,
        };

        const headers = await GetApiHeaders();
        console.log(
          "Response sub:",
          addApi + "---" + JSON.stringify(dataToSend)
        );
        setIsLoading(true);
        const response = await axios.post(addApi, dataToSend, { headers });
        console.log("Response:", response);
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        setErrorName("");
        setErrorCode("");
        setErrorStatus("");
        setIsSubmitted(true);
      } else if (id !== 0) {
        // alert("Update");
        const addApi = addCategoryMaster;
        const dataToSend = {
          Id: id,
          name: name,
          code: code,
          status: status,
        };
        const headers = await GetApiHeaders();
 
        console.log(
          "response up:",
          JSON.stringify(dataToSend) + "---" + addApi
        );
        setIsLoading(true);
        const response = await axios.post(addApi, dataToSend, { headers });
        console.log("Response:", response);
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        setIsUpdated(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitted(false);
      handleUpdate();
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value) {
      setErrorStatus("");
    }
  };

  const handleUpdate = () => {
    if (id !== "" && name !== "" && code !== "" && status !== "") {
      setErrorName("");
      setErrorCode("");
      setErrorStatus("");

      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  };

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
    // addData();
  }, []);

  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setName("");
    setStatus("");
    setCode("");
    setModalData({ id: "", name: "", code: "", status: "" });
  };

  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };

  const updatedItems = (item, index) => {
    // setModalData(item);
    setId(item.productCategoryId);
    setName(item.name);
    setCode(item.productCode);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(true);
  };

  // This is for fetch the data
  const fetchData = async () => {
    const apiUrl = getCategory;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("response", response);
        setData(response.data.response.productCategory);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Errorfetching data:", error);
        setIsLoading(false);
      });
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Check if values are numeric
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      // Fallback for string comparison
      const aString = aValue?.toString().toLowerCase();
      const bString = bValue?.toString().toLowerCase();
      if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
      if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">Masters</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text"> Category Master</h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Category Master"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className="table table-bordered Content_data_table table-striped">
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center" onClick={() => handleSort("name")}>
                                  Name
                                  <i
                                    className={`fa ${sortConfig.key === "name"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("productCode")}>
                                  Code
                                  <i
                                    className={`fa ${sortConfig.key === "productCode"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("status")}>
                                  Status
                                  <i
                                    className={`fa ${sortConfig.key === "status"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("actions")}>
                                  Actions
                                  <i
                                    className={`fa ${sortConfig.key === "actions"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity ml-2`}
                                  ></i>
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {/* {data.map((item, index) => ( */}
                              {sortedItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={19}
                                    className="text-center"
                                    style={{ padding: 195, fontSize: 18 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems != undefined &&
                                currentItems != null &&
                                sortedItems.map((item, index) => (
                                  <Tr key={index}>
                                    <Td className="text-left">
                                      {(currentPage - 1) * itemsPerPage + index + 1}
                                    </Td>
                                    <Td>{item.name}</Td>
                                    <Td>{item.productCode}</Td>
                                    <Td className="text-center">
                                      {item.status === "1" ? (
                                        <span style={{ color: "#2CA800" }}>
                                          Active
                                        </span>
                                      ) : (
                                        <span style={{ color: "#FF0000" }}>
                                          In Active
                                        </span>
                                      )}
                                    </Td>
                                    <Td className="text-center">
                                      <div
                                        // style={{ width: 20, height: 20 }}
                                        onClick={() => updatedItems(item)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="plus_icon mr-1 text-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit Category"
                                        />
                                        {/* Add button content here */}
                                      </div>
                                    </Td>
                                  </Tr>
                                ))
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)}
                            to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp; of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${currentPage === 1 ? "active" : ""
                                  }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                className={`ul-pagination page-item ${currentPage === 1 ? "active" : ""
                                  }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${currentPage === number ? "active" : ""
                                    }`}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                    }`}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                  ? "active"
                                  : ""
                                  }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={closeWarning}
                              >
                                {" "}
                                {/*onClick={handleModalClose} */}X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">
                                  Category Master
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-md font-weight-600"
                                  >
                                    Name<span style={{ color: "red" }}>*</span>
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Name"
                                      value={name}
                                      inputMode="text"
                                      onChange={handleNameChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorName}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label text-md font-weight-600"
                                    >
                                      Code
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <input
                                      class="form-control"
                                      id="textAreaExample1"
                                      rows="2"
                                      value={code}
                                      onChange={handleCode}
                                    />

                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {errorCode}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="statuss col-md-6 ">
                                <label
                                  for="Input"
                                  class="form-label text-md font-weight-600"
                                >
                                  Status <span style={{ color: "red" }}>*</span>
                                </label>

                                <div className="input-group input-group w-100 ">
                                  <select
                                    className=" statusW form-select p-2 mb-4"
                                    value={status}
                                    onChange={handleStatus}
                                  >
                                    <option value="" disabled selected>
                                      Select Status
                                    </option>
                                    <option value="1">Active</option>
                                    <option value="0">In-Active</option>
                                  </select>
                                </div>

                                <span style={{ color: "red" }}>
                                  {" "}
                                  {errorStatus}{" "}
                                </span>
                              </div>

                              {error && (
                                <span
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {error}
                                </span>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => handleSubmit()}
                                >
                                  {buttonText}
                                </Button>

                                <div style={{ width: "20px" }}></div>

                                <Button
                                  className="btnn"
                                  variant="danger"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Category Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>

                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Category Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">
                                  Category Master
                                </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}
          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->
       <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <div
        className="modal fade"
        id="logoutModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a className="btn btn-primary" href="login.html">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default CategoryMaster;
