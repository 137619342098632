import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// for Sidebar And Navbar
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { addRoleMaster, getRoleMaster } from "../Utilities/URLCONSTANTS";
// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import ViewIcon from "../images/viewIcon.png";
import closedProducts from "../images/closed-products.png";
import user from "../images/user.png";
import productrequestImage from "../images/closed-products.png";
import issuedProducts from "../images/issued-products.png";
import PendingProducts from "../images/Pending_products.png";
import {
  getAllRecievedRequestsFarmOperation,
  requestAcceptReject,getMasters
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { USER_PROFILE } from "../Utils";
import moment from "moment";
import * as XLSX from "xlsx";
//UnitMeasureMaster

//UnitMeasureMaster
const RequestFarmOperation1 = () => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [getAccName, setGetAccName] = useState([]);
  const [remark, setRemark] = useState();
  const [errRemark, setErrRemark] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [estDate, setEstDate] = useState("");
  const [errEstimated, setErrEstimated] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [getMasterData, setGetMasterData] = useState([])
  // const [cropFunctionId, setCropFunctionId] = useState()

  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
    // window.location.reload();
  };
  const handleCancel = () => {
    setShowPopup(false);
    setShowModal(true);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    //yes
    // alert("handleCancel");
    // setPopupText('Custom popup text here');
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalOpen = (item, index) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedItemIndex(index);
  };

  const handleRemark = (e) => {
    setRemark(e.target.value);
    if (e.target.value) {
      setErrRemark("");
    }
  };
  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetAccName(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

 
  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredDat = data.filter((item) => {
    console.log('data filetered',item);
    return (
      selectedLocation === "" || item.subSiteName === selectedLocation
   
    );
  });
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDat.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const fetchGetMaster = async () => {
    const getData = getMasters;
    const headers = await GetApiHeaders(); 
    try {
      const responseGetData = await axios.post(getData, null, { headers }); 
      setGetMasterData(responseGetData.data.response.storeLocation)
    } catch (err) {
      console.log("getError", err);
    }
  };
  useEffect(()=>{
    fetchGetMaster()
  },[])
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const fetchData = async () => {
    const fetchApi = getAllRecievedRequestsFarmOperation;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.get(fetchApi, { headers });
      console.log("farmOperation Get", response);

      setData(response.data.response.receivedRequests);
      setIsLoading(false);
    } catch (err) {
      console.group(err);
    }
  };

  const handleApprove = async (index) => {
  if (!estDate) {
    setErrEstimated("Please select an Estimated Date");
    return; 
  }

  if (!remark) {
    setErrRemark("Please Enter a Remarks");
    return; 
  }
    const acceptApi = requestAcceptReject;
    const headers = await GetApiHeaders();
    const postData = {
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      cropFunctionId: data[index].cropFunctionId,
      requesterUserId: data[index].requesterUserId,
      mainSiteId: data[index].mainSiteId,
      subSiteName: data[index].subSiteName,
      subSiteId: data[index].subSiteId,
      requiredDate: data[index].requiredDate,
      submittedDate: data[index].submittedDate,
      serverRequestId: data[index].serverRequestId,
      acceptorMobileNo: data[index].acceptorMobileNo,
      plotAcreageName: data[index].plotAcreageName,
      typeOfOperationName: data[index].typeOfOperationName,
      requesterName: data[index].requesterName,
      plotNoLocationName: data[index].plotNoLocationName,
      requesterMobileNo: data[index].requesterMobileNo,
      mainSiteName: data[index].mainSiteName,
      requesterRemarks: data[index].requesterRemarks,
      actualCropId: data[index].actualCropId,
      serverRecordId: data[index].serverRecordId,
      acceptorUserId: getAccName.userId,
      plantedArea: data[index].plantedArea,
      actualCropName: data[index].actualCropName,
      typeOfOperationId: data[index].typeOfOperationId,
      plotAcreageId: data[index].plotAcreageId,
      cropFunctionName: data[index].cropFunctionName,
      plotNoLocationId: data[index].plotNoLocationId,
      status: "Approved",
      acceptorRemarks: remark,
      estDate: estDate, 

    };
    try {
      if(estDate && remark){
      setIsLoading(true);
      const response = await axios.post(acceptApi, postData, { headers });
      console.log("Farm operation Accept", response);
      setsubmitResponse(response.data.message);
      setShowModal(false);
      setShowResponsePopup(true);
      setIsSubmitted(true);
      setIsLoading(false);
      } 
    } catch (err) {
      console.log("error farm Operation", err);
    }
  };

  const handleReject = async ( selectedItem,selectedItemIndex) => {


    const acceptApi = requestAcceptReject;
    const headers = await GetApiHeaders();
    const postData = {
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      cropFunctionId: data[selectedItemIndex].cropFunctionId,
      requesterUserId: data[selectedItemIndex].requesterUserId,
      mainSiteId: data[selectedItemIndex].mainSiteId,
      subSiteName: data[selectedItemIndex].subSiteName,
      subSiteId: data[selectedItemIndex].subSiteId,
      requiredDate: data[selectedItemIndex].requiredDate,
      submittedDate: data[selectedItemIndex].submittedDate,
      serverRequestId: data[selectedItemIndex].serverRequestId,
      acceptorMobileNo: data[selectedItemIndex].acceptorMobileNo,
      plotAcreageName: data[selectedItemIndex].plotAcreageName,
      typeOfOperationName: data[selectedItemIndex].typeOfOperationName,
      requesterName: data[selectedItemIndex].requesterName,
      plotNoLocationName: data[selectedItemIndex].plotNoLocationName,
      requesterMobileNo: data[selectedItemIndex].requesterMobileNo,
      mainSiteName: data[selectedItemIndex].mainSiteName,
      requesterRemarks: data[selectedItemIndex].requesterRemarks,
      actualCropId: data[selectedItemIndex].actualCropId,
      serverRecordId: data[selectedItemIndex].serverRecordId,
      acceptorUserId: getAccName.userId,
      plantedArea: data[selectedItemIndex].plantedArea,
      actualCropName: data[selectedItemIndex].actualCropName,
      typeOfOperationId: data[selectedItemIndex].typeOfOperationId,
      plotAcreageId: data[selectedItemIndex].plotAcreageId,
      cropFunctionName: data[selectedItemIndex].cropFunctionName,
      plotNoLocationId: data[selectedItemIndex].plotNoLocationId,
      status: "Rejected",
      acceptorRemarks: remark,
      estDate: estDate, 

    };
    try {
      if (remark) {
        setIsLoading(true);
        const response = await axios.post(acceptApi, postData, { headers });
        console.log("Farm operation Accept", response);
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);
        setErrRemark("");
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setErrRemark("Please Enter Reamarks");
        setIsSubmitted(false);
      }
    } catch (err) {
      console.log("error farm Operation", err);
      setIsSubmitted(false);
    }
  };
  const handleClear = () => {
    setSearchTerm("");
  };
  const handleDateChange = (e) => {
    setEstDate(e.target.value);
    if (e.target.value) {
      setErrEstimated("");
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const sortedItems = [...currentItems].sort((a, b) => {
      if (sortConfig.key) {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          // Check if values are numeric
          if (!isNaN(aValue) && !isNaN(bValue)) {
              return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
          }

          // Fallback for string comparison
          const aString = aValue?.toString().toLowerCase();
          const bString = bValue?.toString().toLowerCase();
          if (aString < bString) return sortConfig.direction === "asc" ? -1 : 1;
          if (aString > bString) return sortConfig.direction === "asc" ? 1 : -1;
          return 0;
      }
      return 0;
  });

  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Farm Operations</h5>
                <div>
                  <h5 className="mb-0 Sub_role_text"> Requests</h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      {/* <span className="d-inline-block" tabindex="0" data-toggle="tooltip" title="Add Roll">
                        <Button className='buttons' variant="primary"  onClick={handleModalOpen}>
                          <FontAwesomeIcon icon={faPlus} className='plus_icon mr-1' />Add 
                        </Button>
                      </span> */}
                    </div>
                    <div className="mcrc_hrline "></div>
                    <div>
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "20px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> Entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>
                              <select  className="selector border border-info mt-2"  id="dropdown" type="dropdown"
                          onChange={(e) => setSelectedLocation(e.target.value)}
                          value={selectedLocation}
                        >
                          <option>Select Sub Site</option>
                          {getMasterData != undefined &&
                            getMasterData.map((items, itemsIndex) => {
                              return (
                                <option key={items.code} value={items.name}>
                                  {items.name}
                                </option>
                              );
                            })}
                        </select>
                                
                              </div>
                              &nbsp;
                            
                            </div>
                          </div>

                          <Table className="table table-bordered  table-striped Content_data_table ">
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>                              
                                <Th className="text-center" onClick={() => handleSort("serverRequestId")}>
                                Request Id{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "serverRequestId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("requesterName")}>
                                Requester Name{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "requesterName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("requesterMobileNo")}>
                                Mobile No{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "requesterMobileNo"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("mainSiteName")}>
                                Main Site{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "mainSiteName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("subSiteName")}>
                                Sub Site{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "subSiteName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                               
                                 <Th className="text-center" onClick={() => handleSort("SubmitedDate")}>
                                 Submitted Date & Time
                                 {" "}
                                  <i
                                    className={`fa ${sortConfig.key === "SubmitedDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center">Action</Th>
                              </Tr>
                            </Thead>
                            {sortedItems.length === 0 ? ( // Check if no data is found
                              <Tr>
                                <Td
                                  colSpan={8}
                                  className="text-center "
                                  style={{ padding: "17%" }}
                                >
                                  Data is not available
                                </Td>
                              </Tr>
                            ) : (
                              sortedItems.map((item, index) => {
                                var newDateFormat = moment(
                                  item.requiredDate
                                ).format("DD-MMM-YYYY ");
                                var SubmitedDate = moment(
                                  item.submittedDate
                                ).format("DD-MMM-YYYY hh:mm:ss A ");
                                return (
                                  <Tbody>
                                    <Tr key={index}>
                                      <Td className="text-left">{(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td className="text-left">
                                        {item.serverRequestId}
                                      </Td>
                                      <Td className="text-left">
                                        {item.requesterName}
                                      </Td>
                                      <Td className="text-left">
                                        {item.requesterMobileNo}
                                      </Td>
                                      <Td className="text-left">
                                        {item.mainSiteName}
                                      </Td>
                                      <Td className="text-left">
                                        {item.subSiteName}
                                      </Td>
                                      <Td className="text-left">
                                        {SubmitedDate}
                                      </Td>

                                      <Td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="plus_icon mr-1 text-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="View"
                                            onClick={() => toggleDetails(index)}
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                    {selectedRowIndex === index && (
                                      <Tr>
                                        <Td style={{ padding: 24 }} colSpan="9">
                                          <div className="request-card">
                                          <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                          <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  PlotNo/Location Name
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotNoLocationName}</span>
                                               </span>
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot Acreage Name
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotAcreageName}</span>
                                               </span>
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Planted Area
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plantedArea}</span>
                                               </span> 
                                            </div>

                                            <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                            <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Crop in Field
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.actualCropName}</span>
                                               </span>
                                            
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                    Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.functionName}</span>
                                               </span> 
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Sub Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.subFunctionName}</span>
                                               </span> 
                                            </div>

                                             <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                            
                                             <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Type Of Operation
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.typeOfOperationName}</span>
                                               </span> 
                                           

                                                 <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  {/* Required Date */}
                                                  Category
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.categoryName}</span>
                                               </span> 


                                              
                                               {item.requesterRemarks !== "" ? (
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                Requester Remarks
                                                </span> 
                                                 <span  >  :&nbsp; 
                                                 </span>  
                                               <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.requesterRemarks}</span>
                                             </span>  ) : (
                                                ""
                                              )}                                 
                                            </div>

                                            <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >                                         
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Required Date
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{newDateFormat}</span>
                                               </span> 
                                    
  
                                               <div className="mt-3" style={{ width: '33.4%' }}>
  <span style={{ width: '45%', display: 'inline-block', fontWeight: 600 }}>
    Estimated Date
  </span>
  <span>:&nbsp;</span>
  <span style={{ width: '55%', display: 'inline-flex', whiteSpace: "break-spaces" }}>
    <input
      type="date"
      style={{ width: 108, height: 32 }}
      min={getCurrentDate()}
      value={estDate} 
      onChange={handleDateChange}
    />
   
  </span>
</div>


                                            <span className=" mt-3"style={{width:'33.4%',  }}>
                                                <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                Acceptor Remarks
                                                </span> 
                                                 <span  >  :&nbsp; 
                                                 </span>  
                                               <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>
                                                 <textarea
                                                      type="text"
                                                      style={{ borderColor: "silver",  borderRadius: 5,     }}
                                                      value={remark} 
                                                      onChange={handleRemark}  /></span>
                                                      <div>
                                                      {errRemark && (
                                                      <span style={{ color: 'red', fontSize: '15px', display: 'block', marginTop: '5px' }}>
                                                        {errRemark}
                                                      </span>
                                                    )}
                                                      </div>
                                             </span> 
                                            

                                            </div>

                                            {errEstimated && (
                                          <span style={{ color: 'red', fontSize: '15px', display: 'block', marginTop: '4px' }}>
                                            {errEstimated} 
                                              </span>
                                            )}
                                            
                                            <div>
                                              {
                                                <div
                                                  className="mt-4 d-flex"
                                                  style={{ gap: "8rem" }}
                                                >
                                                  <div className="w-50">
                                                    <span className="fw-bold">
                                                      {item.fertilizers.length >
                                                      0
                                                        ? "Fertilizers :"
                                                        : ""}
                                                    </span>

                                                    {item.fertilizers.length >
                                                      0 && (
                                                      <div className="d-flex justify-content-between mt-3">
                                                        <div className="d-flex flex-column">
                                                          <span className="custome-text-md fw-bold text-decoration-underline">
                                                            Name
                                                          </span>
                                                          {item.fertilizers.map(
                                                            (
                                                              fertilizer,
                                                              index
                                                            ) => (
                                                              <span
                                                                key={index}
                                                                className="custome-text-md font-weight-300 mt-2"
                                                              >
                                                                {fertilizer.name ||
                                                                  "No Name"}
                                                              </span>
                                                            )
                                                          )}
                                                        </div>

                                                        <div className="d-flex flex-column">
                                                          <span className="custome-text-md fw-bold text-decoration-underline">
                                                            {/* Dosage */}
                                                            Quantity Used
                                                          </span>
                                                          {item.fertilizers.map(
                                                            (
                                                              fertilizer,
                                                              index
                                                            ) => (
                                                              <span
                                                                key={index}
                                                                className="custome-text-md font-weight-300 mt-2"
                                                              >
                                                                {
                                                                  fertilizer.dosage
                                                                }
                                                              </span>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className="w-50">
                                                    <span className="fw-bold">
                                                      {item.chemicals.length > 0
                                                        ? "Chemicals :"
                                                        : ""}
                                                    </span>

                                                    {item.chemicals.length >
                                                      0 && (
                                                      <div className="d-flex justify-content-between mt-3">
                                                        <div className="d-flex flex-column">
                                                          <span className="custome-text-md fw-bold text-decoration-underline">
                                                            Name
                                                          </span>
                                                          {item.chemicals.map(
                                                            (
                                                              chemical,
                                                              index
                                                            ) => (
                                                              <span
                                                                key={index}
                                                                className="custome-text-md font-weight-300 mt-2"
                                                              >
                                                                {chemical.name ||
                                                                  "No Name"}
                                                              </span>
                                                            )
                                                          )}
                                                        </div>

                                                        <div className="d-flex flex-column">
                                                          <span className="custome-text-md fw-bold text-decoration-underline">
                                                            {/* Dosage */}
                                                            Quantity Used
                                                          </span>
                                                          {item.chemicals.map(
                                                            (
                                                              chemical,
                                                              index
                                                            ) => (
                                                              <span
                                                                key={index}
                                                                className="custome-text-md font-weight-300 mt-2"
                                                              >
                                                                {
                                                                  chemical.dosage
                                                                }
                                                              </span>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                            <span
                                              style={{}}
                                              className="d-flex justify-content-end mt-5"
                                            >
                                              <span>
                                                <button
                                                  className="btns"
                                                  onClick={() =>
                                                    handleApprove(index)
                                                  }
                                                >
                                                  Approve
                                                </button>
                                              </span>

                                              <span>
                                                <button
                                                  className="btnsReject  ml-2 "
                                                  onClick={() =>
                                                    handleModalOpen(item, index)
                                                  }
                                                >
                                                  Reject
                                                </button>
                                              </span>
                                            </span>
                                          </div>
                                        </Td>
                                      </Tr>
                                    )}
                                  </Tbody>
                                );
                              })
                            )}

                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                          <div
                            className="d-flex justify-content-between mb-10"
                            style={{ borderRadius: 5 }}
                          >
                            <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                         &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                          of {totalItems} entries
                            </div>
                            <div>
                              <div className="div-button text-center">
                                <button
                                  className="borderleft-radius-5"
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>

                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage == 1 ? "#0056b3" : "white",
                                    color: currentPage == 1 ? "white" : "black",
                                  }}
                                  key={1}
                                  onClick={() => handlePageClick(1)}
                                  disabled={currentPage === 1}
                                >
                                  {1}
                                </button>
                                {pageNumbers.map((number) => (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage == number
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage == number
                                          ? "white"
                                          : "black",
                                    }}
                                    key={number}
                                    onClick={() => handlePageClick(number)}
                                    disabled={currentPage === number}
                                  >
                                    {number}
                                  </button>
                                ))}
                                {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) - 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      ...
                                    </button>
                                  )}
                                {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                          ? "white"
                                          : "black",
                                    }}
                                    key={Math.ceil(totalItems / itemsPerPage)}
                                    onClick={() =>
                                      handlePageClick(
                                        Math.ceil(totalItems / itemsPerPage)
                                      )
                                    }
                                    disabled={
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                    }
                                  >
                                    {Math.ceil(totalItems / itemsPerPage)}
                                  </button>
                                )}
                                <button
                                  className="borderright-radius-5"
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                  disabled={indexOfLastItem >= totalItems}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Modal
                            className="modalBlur"
                            show={showPopup}
                            onHide={handleCancel_new}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Request </span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close this Window
                                    ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Request</span>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={handleModalClose}
                    centered
                    backdrop="static"
                    keyboard={false}
                    size="md"
                  >
                    <Modal.Header className="border-0 d-flex justify-content-center">
                      <Modal.Title> </Modal.Title>

                      <Button
                        variant="link"
                        className="close btn btn-danger close_btn"
                        onClick={handleCancel_new}
                      >
                        X
                      </Button>
                    </Modal.Header>

                    <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                      <div className="d-flex justify-content-center mb-3 font-weight-600">
                        <span className="modal_title">Reject</span>
                      </div>
                      <div>
                        <div className="col-md-6 ">
                          <div className="mb-3">
                            <label
                              for="InputText"
                              className="form-label text-sm font-weight-600"
                            >
                              Remarks
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="textAreaExample1"
                              rows="2"
                              value={remark}
                              onChange={handleRemark}
                            ></textarea>
                          </div>
                          <span style={{ color: "red" }}>{errRemark}</span>
                        </div>

                        <br />
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="primary"
                          className="btnn"
                          onClick={() =>
                            handleReject(selectedItem, selectedItemIndex)
                          }
                        >
                          Submit
                        </Button>
                        <div style={{ width: "20px" }}></div>
                        <Button
                          variant="danger"
                          className="btnn"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <Modal
                    show={showResponsePopup}
                    centered
                    backdrop="static"
                    keyboard={false}
                    size="ml"
                  >
                    <Modal.Header className="border-0 d-flex justify-content-center">
                      <Modal.Title>
                        {" "}
                        <span className="modal_title">Requests</span>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                      <div className="d-flex justify-content-center mb-3 font-weight-600">
                        {showMsg ? (
                          <p style={{ color: "green" }}>{submitResponse}</p>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="primary"
                          className="d-flex justify-content-center cancel_button"
                          onClick={handleresponse}
                        >
                          Okay
                        </Button>
                        &nbsp;&nbsp;
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RequestFarmOperation1;
