import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
import "@fortawesome/fontawesome-free/css/all.min.css";

// for Sidebar And Navbar
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { getAllRecievedRequestsForUpdateActivity ,getMasters, UpdateActivitygGet} from "../Utilities/URLCONSTANTS";
// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import ViewIcon from "../images/viewIcon.png";
import closedProducts from "../images/closed-products.png";
import user from "../images/user.png";
import productrequestImage from "../images/product_request.png";
import issuedProducts from "../images/issued-products.png";
import PendingProducts from "../images/Pending_products.png";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import moment from "moment";
import * as XLSX from "xlsx";
//UnitMeasureMaster
const UpdateActivity = ({}) => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [loading, setLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [showDetails, setShowDetails] = useState({});
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [getMasterData, setGetMasterData] = useState([])
  const [selectedLocation, setSelectedLocation] = useState("");
  const [activityData, setActivityData] = useState(null);


  

  const fetchActivityData = async (serverRecordId) => {
    try {
      const headers = await GetApiHeaders(); 
      const response = await fetch(UpdateActivitygGet, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers, 
        },
        body: JSON.stringify({
          requestId: serverRecordId,
        }),
      });
      const data = await response.json();
      if (data.statusCode === "200" && data.response.updateHistory.length > 0) {
        setActivityData(data.response.updateHistory[0].updatedWorks); 
      } else {
        setActivityData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }; 
  



  const fetchGetMaster = async () => {
    const getData = getMasters;
    const headers = await GetApiHeaders(); 
    try {
      const responseGetData = await axios.post(getData, null, { headers }); 
      setGetMasterData(responseGetData.data.response.storeLocation)
    } catch (err) {
      console.log("getError", err);
    }
  };

  useEffect(() => {
    fetchGetMaster()
    if (data != null && data != undefined) {
      setTotalItems(data.length);
    }
  }, [data]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredDat = data.filter((item) => {
    console.log('data filetered',item);
    return (
      selectedLocation === "" || item.subSiteName === selectedLocation
   
    );
  });
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDat.slice(indexOfFirstItem, indexOfLastItem);

 
  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
 
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
  };
  const handleClose = () => { 
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false, 
    });
    fetchData();
  }, []);

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const fetchData = async () => {
    const fetchApi = getAllRecievedRequestsForUpdateActivity;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.get(fetchApi, { headers }); 
      setData(response.data.response.activitiesList);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleClear = () => {
    setSearchTerm("");
  };
 

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

const sortedItems = [...currentItems].sort((a, b) => {
  if (sortConfig.key) {
    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];

    if (aValue && bValue) {
      if (aValue instanceof Date && bValue instanceof Date) {
        aValue = aValue.getTime(); 
        bValue = bValue.getTime(); 
      } else {
        aValue = aValue?.toString().toLowerCase();
        bValue = bValue?.toString().toLowerCase();
      }
      
      // Compare the values
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
  }
  return 0;
});


  // Handle column sorting
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };


  const formatTime = (timeString) => {
    if (!timeString) return "N/A"; // Return 'N/A' if no time provided
  
    // Check if time is already in 12-hour format
    if (/AM|PM/.test(timeString)) {
      return timeString; // Return as-is
    }
  
    // Parse and convert 24-hour time to 12-hour format
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0/24 to 12
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };
  

 
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Farm Operations </h5>
              
                <div>
                  <h5 className="mb-0 Sub_role_text">Update Activity</h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      
                    </div>

                    <div className="mcrc_hrline ">
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "10px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{}}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                               value={itemsPerPage} onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                              &nbsp;&nbsp;
                              <label> Entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>  
                               <select  className="selector border border-info mt-2"  id="dropdown" type="dropdown"
                          onChange={(e) => setSelectedLocation(e.target.value)}
                          value={selectedLocation}
                        >
                          <option>Select Sub Site</option>
                          {getMasterData != undefined &&
                            getMasterData.map((items, itemsIndex) => {
                              return (
                                <option key={items.code} value={items.name}>
                                  {items.name}
                                </option>
                              );
                            })}
                        </select>
                               
                              </div>
                              &nbsp;
                            
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div>
                          <div className="card data_tableCard mr-2 ml-1">
                            <div className="card-body ">
                              <Table className="table table-bordered Content_data_table table-striped ">
                                <Thead>
                                  <Tr>
                                    <Th className="text-center">S.No</Th>
                                    <Th className="text-center" onClick={() => handleSort("serverRequestId")}>
                                    Request Id{" "}
                                  <i
                                    className={`fa ${sortConfig.key === "serverRequestId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>                               

                               <Th className="text-center" onClick={() => handleSort("serverRequestId")}>
                                    Requester Name
                                  {" "}
                                  <i
                                    className={`fa ${sortConfig.key === "serverRequestId"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                   
                         <Th className="text-center" onClick={() => handleSort("typeOfOperationName")}>
                                Type Of Operation                                  {" "}
                                  <i
                                    className={`fa ${sortConfig.key === "typeOfOperationName"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                   
                                    <Th className="text-center" onClick={() => handleSort("submittedDate")}>
                                    Submitted Date&Time                              {" "}
                                  <i
                                    className={`fa ${sortConfig.key === "submittedDate"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                <Th className="text-center" onClick={() => handleSort("status")}>
                                Status                                 {" "}
                                  <i
                                    className={`fa ${sortConfig.key === "status"
                                      ? sortConfig.direction === "asc"
                                        ? "fa-sort-up"
                                        : "fa-sort-down"
                                      : "fa-sort"
                                      } fa-xs icon-opacity`}
                                  ></i>
                                </Th>
                                    <Th className="text-center">Action</Th>
                                  </Tr>
                                </Thead>
                                {sortedItems.length === 0 ? (
                                  <Tr>
                                    <Td colSpan={8} className="text-center">
                                      Data is not available
                                    </Td>
                                  </Tr>
                                ) : (
                                  sortedItems.map((item, index) => {
                                    var newDateFormat = moment(
                                      item.requiredDate
                                    ).format("DD-MMM-YYYY ");
                                    var SubmitedDate = moment( item.submittedDate ).format("DD-MMM-YYYY hh:mm:ss A ");
                                    return (
                                      <Tbody>
                                        <Tr key={index}>
                                          <Td className="text-left">
                                          {(currentPage - 1) * itemsPerPage + index + 1}
                                          </Td>
                                          <Td className="text-left">
                                            {item.serverRequestId}
                                          </Td>
                                          <Td className="text-left">
                                            {item.requesterName}
                                          </Td>
                                          <Td className="text-left">
                                            {item.typeOfOperationName}
                                          </Td>
                                          <Td className="text-left">
                                            {SubmitedDate}
                                          </Td>
                                         
                                          <Td >
                                            {item.status === "InProgress"?(<span style={{color:'orange',  fontWeight: 600}}>InProgress</span>)
                                            :item.status === "Approved" ?(<span style={{color:'#008000',  fontWeight: 600}}>Approved</span>):item.status}
                                          </Td>
                                          <Td
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="plus_icon mr-1 text-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Details"
                                                onClick={() =>{
                                                  toggleDetails(index)
                                                  fetchActivityData(item.serverRecordId);
                                                }
                                              }

                                              />
                                            </div>
                                          </Td>
                                        </Tr>
                                        {selectedRowIndex === index && (
                                          <Tr>
                                            <Td
                                              style={{ padding: 24 }}
                                              colSpan="9"
                                            >
                                              <div className="request-card">
                                              <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Main Site
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.mainSiteName}</span>
                                               </span>
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Sub Site
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.subSiteName}</span>
                                               </span>
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot No/Location
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotNoLocationName}</span>
                                               </span> 
                                                </div>

                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot Acreage
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotAcreageName}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Planted Area
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plantedArea}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Crop In Field
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.actualCropName}</span>
                                               </span> 
                                                  
                                                </div>
                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.functionName}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Sub Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.subFunctionName}</span>
                                               </span> 
                                             
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Required Date
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{newDateFormat}</span>
                                               </span> 
                                                </div>


                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" > 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Category
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.categoryName}</span>
                                               </span> 
                                              

<span className="mt-3" style={{ width: "33.4%" }}>
  <span style={{ width: "45%", display: "inline-block", fontWeight: 600 }}>
    Estimated Completion Date
  </span>
  <span> :&nbsp;</span>
  <span style={{ width: "55%", display: "inline-flex", whiteSpace: "break-spaces" }}>
    {item.estimatedcompDate
      ? (() => {
          const date = new Date(item.estimatedcompDate);
          const formattedDate = date
            .toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })
            .replace(/\//g, "-"); // Converts 02/01/2025 to 02-01-2025

          const formattedTime = date.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          });

          return `${formattedDate} ${formattedTime}`;
        })()
      : "-"}
  </span>
</span>

                                               {item.requesterRemarks !==
                                                  "" ? (
                                                    <span className=" mt-3"style={{width:'33.4%',  }}>
                                                    <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                    Requester Remarks
                                                    </span> 
                                                     <span  >  :&nbsp; 
                                                     </span>  
                                                   <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.requesterRemarks}</span>
                                                 </span> ) : (
                                                    ""
                                                  )}
                                               
                                                </div>

{/* //////////////////// */}



 
                                      <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >                                                                                                                                          
                                                  {item.acceptorRemarks !==
                                                  "" ? (
                                                    <span className=" mt-3"style={{width:'33.4%',  }}>
                                                    <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                    Acceptor Remarks
                                                    </span> 
                                                     <span  >  :&nbsp; 
                                                     </span>  
                                                   <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.acceptorRemarks}</span>
                                                 </span> ) : (
                                                    ""
                                                  )}
                                                </div>

                                                <div>
                                                  {
                                                    <div
                                                      className="mt-4 d-flex"
                                                      style={{ gap: "8rem" }}
                                                    >
                                                      <div className="w-50">
                                                        <span className="fw-bold">
                                                          {item.fertilizers
                                                            .length > 0
                                                            ? "Fertilizers :"
                                                            : ""}
                                                        </span>

                                                        {item.fertilizers
                                                          .length > 0 && (
                                                          <div className="d-flex justify-content-between mt-3">
                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Name
                                                              </span>
                                                              {item.fertilizers.map(
                                                                (
                                                                  fertilizer,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {fertilizer.name ||
                                                                      "No Name"}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>

                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Dosage
                                                              </span>
                                                              {item.fertilizers.map(
                                                                (
                                                                  fertilizer,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                   
                                                                   {fertilizer.uom.includes("Kgs") ? fertilizer.dosage + " " + "(gm/kg)" : fertilizer.dosage + " " + "(ml/ltr)"}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>

                                                      <div className="w-50">
                                                        <span className="fw-bold">
                                                          {item.chemicals
                                                            .length > 0
                                                            ? "Chemicals :"
                                                            : ""}
                                                        </span>

                                                        {item.chemicals.length >
                                                          0 && (
                                                          <div className="d-flex justify-content-between mt-3">
                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Name
                                                              </span>
                                                              {item.chemicals.map(
                                                                (
                                                                  chemical,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {chemical.name ||
                                                                      "No Name"}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>

                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Dosage
                                                              </span>
                                                              {item.chemicals.map(
                                                                (
                                                                  chemical,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"

                                                                  >
                                                             
                                                                      {chemical.uom.includes("Kgs") ? chemical.dosage + " " + "(gm/kg)" : chemical.dosage + " " + "(ml/ltr)"}
                                                                    {/* // chemical.dosage */}

                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  } 
                                                </div>
                                                                                            
             {/* UI for InProgress Status */}
          
<div>
  {item.status === "InProgress" ? (
    activityData && activityData.length > 0 ? (
      activityData.map((work, index) => (
        <div
          className="row mt-4"
          style={{
            rowGap: "10px",
            maxHeight: "250px",
            overflow: "auto",
          }}
          key={index}
        >
          <span className="fw-bold">Updated Activity Details:</span>
          <div
            className="card w-full p-2"
            style={{ backgroundColor: "#FFF6E5", textAlign: "left" }}
          >
            {/* General Information */}
          {work.updateDate && (
           <div 
          className="custome-text-md font-weight-600 d-flex justify-content-between align-items-center px-3 mb-3"
           >
           <span style={{width:'500px', fontWeight:'bold'}}>Date</span> :
          <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
             {work.updateDate}
           </span>
            </div>
            )}
           {work.startTime && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Start Time:</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
                {work.startTime ? formatTime(work.startTime) : " "}
              </span>
            </div>
          )}

{work.endTime?.trim() && (
  <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
    <span style={{width:'500px', fontWeight:'bold'}}>End Time:</span> :
    <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
      {formatTime(work.endTime)}
    </span>
  </div>
)}

            {work.typeOfOperationName && (
              <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span  style={{width:'500px',fontWeight:'bold'}}>Type of Operation</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.typeOfOperationName || " "}</span>
            </div>
            )}

           
             {work.fieldPreparation && (
             <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span  style={{width:'500px',fontWeight:'bold'}}>Field Preparation</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.fieldPreparation || " "}</span>
            </div>)}

            {work.machineStartTime && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span  style={{width:'500px',fontWeight:'bold'}}>Machine Start Time </span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.machineStartTime || " "}</span>
            </div>)}

            {work.machineEndTime && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span  style={{width:'500px',fontWeight:'bold'}}>Machine End Time </span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.machineEndTime || " "}</span>
            </div>)}

            {work.machineHoursRequired && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span  style={{width:'500px',fontWeight:'bold'}}>Machine Hours </span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.machineHoursRequired || " "}</span>
            </div>)}


            {/* {work.manpowerRequired && ( */}
            {(work.manpowerRequired !== null && work.manpowerRequired !== undefined) && (
  <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
    <span style={{ width: "500px", fontWeight: "bold" }}>Manpower Usage</span> :
    <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
      {work.manpowerRequired !== "" ? work.manpowerRequired : "-"}
    </span>
  </div>
)}


            {work.requiredHours && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Manual Hours</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.requiredHours || " "}</span>
            </div>)}


          {work.updatedRemarks && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Updated Remarks:</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.updatedRemarks || " "}</span>
            </div>)}
      
            {work.subSiteName && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Sub Site</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.subSiteName || " "}</span>
            </div>)}


            {work.acceptorMobileNo && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Acceptor Mobile No</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.acceptorMobileNo || " "}</span>
            </div>)}

            {work.requiredDate && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Required Date</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.requiredDate || " "}</span>
            </div>)}

            {work.categoryName && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Category</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.categoryName || " "}</span>
            </div>)}


            {work.plotAcreageName && (
           <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Plot Acreage</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.plotAcreageName || " "}</span>
            </div>)}
            
            {work.requesterName && (
           <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Requester</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.requesterName || " "}</span>
            </div>)}


            {work.plotNoLocationName && (
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Plot No. Location </span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.plotNoLocationName || " "}</span>
            </div>)}

            {work.mechanicalOperations && (    
            <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Mechanical Operations</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.mechanicalOperations || " "}</span>
            </div>)}

            {work.manualOperation && (    
           <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
              <span style={{width:'500px',fontWeight:'bold'}}>Manual Operations</span> :
              <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>{work.manualOperation || " "}</span>
            </div>)}

        

            {work.dosagePerLiter !== null && work.dosagePerLiter !== undefined && (    
  <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
    <span style={{ width: '500px', fontWeight: 'bold' }}>Dosage Per Liter</span> :
    <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
      {work.dosagePerLiter}
    </span>
  </div>
)}

            {/* Fertilizers */}
           
{work.fertilizers?.length > 0 && (
  <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
    <span style={{ width: '500px', fontWeight: 'bold' }}>Fertilizers</span> :
    <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
      {work.fertilizers.map((fertilizer, index) => (
        <div key={index} className="d-flex justify-content-between">
          <div>{fertilizer.name}</div>
          <div style={{ textAlign: 'right' }}>
            <strong>Quantity Used:</strong> 
            {fertilizer.uom.includes("Kgs") 
              ? fertilizer.dosage + " " + "(gm/kg)" 
              : fertilizer.dosage + " " + "(ml/ltr)"}
          </div>
        </div>
      ))}
    </span>
  </div>
)}
            {/* Chemicals */}
           
{work.chemicals?.length > 0 && (
  <div className="px-3 custome-text-md font-weight-600 d-flex justify-content-between mb-3">
    <span style={{ width: '500px', fontWeight: 'bold' }}>Chemicals</span> :
    <span className="d-block ml-2" style={{ flex: 1, textAlign: "start" }}>
      {work.chemicals.map((chemicals, index) => (
        <div key={index} className="d-flex justify-content-between">
          <div>{chemicals.name}</div>
          <div style={{ textAlign: 'right' }}>
            <strong>Quantity Used:</strong> 
            {chemicals.uom.includes("Kgs") 
              ? chemicals.dosage + " " + "(gm/kg)" 
              : chemicals.dosage + " " + "(ml/ltr)"}
          </div>
        </div>
      ))}
    </span>
  </div>
)}
          </div>
        </div>
      ))
    ) : (
      <p>No activity updates available.</p>
    )
  ) : null}
</div>
                                                                                  
                                              </div>
                                            </Td>
                                          </Tr>
                                        )}
                                      </Tbody>
                                    );
                                  })
                                )}

                                {isLoading && (
                                  <Loader
                                    loading={isLoading}
                                    message={"Fetching Data. Please Wait..!"}
                                    loderimage={loaderImage}
                                  />
                                )}
                              </Table>
                              <div
                                className="d-flex justify-content-between mb-10"
                                style={{ borderRadius: 5, padding: 15 }}
                              >
                                <div>
                                Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                         &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                          of {totalItems} Entries
                                </div>
                                <div>
                                  <div className="div-button text-center">
                                    <button
                                      className="borderleft-radius-5"
                                      // className='ul-pagination'
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      Previous
                                    </button>

                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage == 1
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage == 1 ? "white" : "black",
                                      }}
                                      key={1}
                                      onClick={() => handlePageClick(1)}
                                      disabled={currentPage === 1}
                                    >
                                      {1}
                                    </button>
                                    {pageNumbers.map((number) => (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage == number
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage == number
                                              ? "white"
                                              : "black",
                                        }}
                                        key={number}
                                        onClick={() => handlePageClick(number)}
                                        disabled={currentPage === number}
                                      >
                                        {number}
                                      </button>
                                    ))}
                                    {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                      currentPage !==
                                        Math.ceil(totalItems / itemsPerPage) -
                                          1 &&
                                      currentPage !==
                                        Math.ceil(
                                          totalItems / itemsPerPage
                                        ) && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                        >
                                          ...
                                        </button>
                                      )}
                                    {Math.ceil(totalItems / itemsPerPage) >
                                      1 && (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage ==
                                            Math.ceil(totalItems / itemsPerPage)
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage ==
                                            Math.ceil(totalItems / itemsPerPage)
                                              ? "white"
                                              : "black",
                                        }}
                                        key={Math.ceil(
                                          totalItems / itemsPerPage
                                        )}
                                        onClick={() =>
                                          handlePageClick(
                                            Math.ceil(totalItems / itemsPerPage)
                                          )
                                        }
                                        disabled={
                                          currentPage ===
                                          Math.ceil(totalItems / itemsPerPage)
                                        }
                                      >
                                        {Math.ceil(totalItems / itemsPerPage)}
                                      </button>
                                    )}
                                    <button
                                      className="borderright-radius-5"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={indexOfLastItem >= totalItems}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <Modal
                              className="modalBlur"
                              show={showPopup}
                              onHide={handleModalClose}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">
                                    Role Master
                                  </span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      Are You Sure, Do You want close this
                                      Window ?
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleClose}
                                  >
                                    Yes
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    variant="danger"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </Button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>

                          <div>
                            <Modal
                              show={showResponsePopup}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">
                                    Role Master
                                  </span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      {submitResponse}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleresponse}
                                  >
                                    Okay
                                  </Button>
                                  &nbsp;&nbsp;
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default UpdateActivity;
