import React, { useEffect, useState } from "react";
function HazardIdentific(props) {
  const handlePeopleData = (key, val) => {
    props.setHazardIdentification((prev) => ({ ...prev, [key]: val }));
  };
  const handleHazardList = (id, val) => {
    const updatedPeopleList = props.data.hazard.map((opItem, index) => ({
      ...opItem,
      checked: index == id ? val : opItem.checked,
      description: index === id && !val ? "" : opItem.description, 
    }));
    handlePeopleData("hazard", updatedPeopleList);
  };
  const updateInputs = (id, val) => {
    const updatedPeopleList = props.data.hazard.map((opItem, index) => ({
      ...opItem,
      description: index == id ? val : opItem.description,
    }));
    handlePeopleData("hazard", updatedPeopleList);
  };


  return (
    <div>
      <div className="col-12 col-lg-2">
        <div>
          <div className="col-12" style={{ marginTop: "-20px" }}>
            <div className="Corper-subtxt wenv-bg-color">
              <p className="card-subheading wenv-bg-color" style={{ marginRight: "-23px", marginLeft: "-23px", marginTop: "-20px" }}>Specific fall hazards:<span className='required'> *</span> </p>
            </div>
          </div>
          {props.data.hazard.map((item, index) => (
            <>
              <div class="form-group row mt-2">
                <div
                  class="col-12"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div class="col-12 form-check form-check-inline">
                    <input
                      class="form-check-input  mt-1"
                      checked={item.checked}
                      onChange={(e) => {
                        handleHazardList(
                          index,
                          e.target.checked
                        );
                      }}
                      type="checkbox"
                      id={`hazardList${index}`}
                      value="option1"
                    />
                    <label class="form-check-label  mt-0" for={`hazardList${index}`}>
                      {item.cbTitle}:
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-1">
                <div>
                  <input
                    onChange={(e) => {
                      updateInputs(index, e.currentTarget.value);
                    }}
                    class="form-control col-12"
                    id="inputPassword"
                    placeholder=""
                    value={item.description}
                  />
                </div>
              </div>
            </>
          ))}

          <div class="form-group">
            <label >Specific falling object hazards:</label>
            <div>
              <input
                class="form-control"
                onChange={(e) =>
                  handlePeopleData(
                    "Specific falling object hazards",
                    e.target.value
                  )
                }
                value={props.data?.["Specific falling object hazards"]}
                type="text"
                id="inputPassword"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HazardIdentific;
